/** @format */
import Select from "react-select";
import { useSearchParamsStateSticky } from "../../hooks/useSearchParamsStateSticky";
import { useOktaQuery } from "../../hooks/useOktaQuery";
import { LoadingSpinner } from "../LoadingSpinners";

interface PeriodPickerChoice {
  label: string;
  value: string;
}

type PeriodPickerOptions = {
  defaultPeriod?: string;
  fetchFromStorage?: boolean;
  monthsOnly?: boolean;
};
const PeriodPickerOptionsDefaults: PeriodPickerOptions = {
  fetchFromStorage: true,
  monthsOnly: false,
};
const usePeriodPicker = (options?: PeriodPickerOptions) => {
  const calculatedOptions = Object.assign(
    {},
    PeriodPickerOptionsDefaults,
    options ?? {},
  );
  let [incomingCurrentPeriod, setCurrentPeriod] = useSearchParamsStateSticky(
    "period",
    calculatedOptions.defaultPeriod ?? "",
    calculatedOptions.fetchFromStorage
      ? undefined
      : { loadFromSessionStorage: false, loadFromLocalStorage: false },
  );

  // TODO - should we allow for different default behaviors?
  // like: Accountant Dashboard page
  //       should start on "last month"
  //       so its precedence should be
  //         - queryString param
  //         - per-page default of "last month"
  //         - (always end up stopping here)
  //         - session storage
  //         - local storage

  const {
    data: availablePeriods,
    isLoading: availablePeriodsLoading,
    isError: availablePeriodsError,
  } = useOktaQuery<PeriodPickerChoice[]>(
    `/api/requirementDueDates/pickerOptions?monthsOnly=${calculatedOptions.monthsOnly}`,
  );
  let currentPeriod = incomingCurrentPeriod;
  if (availablePeriods) {
    // setCurrentPeriod (which sets URL params) in render is a React warning.
    // Maybe don't set here and just use a fallback if not provided
    if (!currentPeriod) {
      currentPeriod =
        calculatedOptions.defaultPeriod ?? availablePeriods[0]?.value;
    }
    // TODO - else if currentPeriod from URL or storage was bad/old/doesn't exist, reset
    else if (!availablePeriods.find((ap) => ap.value === currentPeriod)) {
      currentPeriod =
        calculatedOptions.defaultPeriod ?? availablePeriods[0]?.value;
    }
  }
  return {
    currentPeriod,
    setCurrentPeriod,
    availablePeriods,
    isError: availablePeriodsError,
    isLoading: availablePeriodsLoading,
    renderPicker: () => {
      if (availablePeriodsError) {
        return <p>error</p>;
      }
      if (!availablePeriods || availablePeriodsLoading) {
        return <LoadingSpinner />;
      }
      return (
        <Select
          id={"due-date-period"}
          aria-label={"Time period picker"}
          options={availablePeriods}
          onChange={(v: any) => {
            setCurrentPeriod(v?.value);
          }}
          onBlur={() => {}}
          value={availablePeriods.find((p) => p.value === currentPeriod)}
        />
      );
    },
  };
};

const withPeriodPicker = (ComposedComponent: any) => {
  const PeriodPickerWrapperComponent = (props: any) => {
    let {
      renderPicker: renderPeriodPicker,
      currentPeriod,
      availablePeriods,
      isLoading: periodPickerLoading,
      isError: periodPickerError,
    } = usePeriodPicker();
    return (
      <ComposedComponent
        {...props}
        renderPeriodPicker={renderPeriodPicker}
        currentPeriod={currentPeriod}
        availablePeriods={availablePeriods}
        periodPickerLoading={periodPickerLoading}
        periodPickerError={periodPickerError}
      />
    );
  };

  return PeriodPickerWrapperComponent;
};

export { usePeriodPicker, withPeriodPicker };
