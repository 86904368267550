/** @format */
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { useOktaAuth } from "@okta/okta-react";
import {
  deleteWithToken,
  patchWithToken,
  postWithToken,
} from "../util/FetchUtils";
import { AbstractAuthState } from "../util/OktaUtils";

/* This is nearly a drop-in replacement for Tim's older useFetchJson and useFetchWithLoadingHook.
 * Will need to switch from `loading` to `isLoading`
 * Will need to make some changes to:
 *   keepDataAcrossReload - change to {keepDataAcrossReload: true}
 *   setData - not sure the best way to migrate this behavior
 *   forceReload - call `refresh` instead
 */
type METHOD = "POST" | "PATCH" | "DELETE";
type UseOktaMutationOptions = {
  authState?: AbstractAuthState; // To override in tests, not normally provided
  method?: METHOD;
};

const useOktaMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  url: string | ((variables: TVariables) => string),
  options: UseOktaMutationOptions &
    UseMutationOptions<TData, TError, TVariables, TContext> = {
    method: "POST",
  },
) => {
  const { authState: oktaAuthState } = useOktaAuth();
  const { authState: fakeAuthState, method, ...rqOptions } = options;
  const authState = fakeAuthState ?? oktaAuthState;

  const mutationFn = async (data: TVariables) => {
    let targetUrl;
    if (typeof url === "string") {
      targetUrl = url;
    } else {
      targetUrl = url(data);
    }
    if (!(authState?.accessToken && targetUrl)) {
      // TODO - url should be provided, but use options.enabled to not run
      return Promise.reject(new Error("Missing either token or URL"));
    }
    switch (method) {
      case "POST":
        return postWithToken(targetUrl, authState, data);
      case "PATCH":
        return patchWithToken(targetUrl, authState, data);
      case "DELETE":
        return deleteWithToken(targetUrl, authState);
      default:
        return Promise.reject(new Error("Unsupported method"));
    }
  };

  // const logged = (fn: any) => {
  //   return async () => {
  //     let result;
  //     try {
  //       result = await fn();
  //       console.log(`got result `);
  //       return result;
  //     } catch (err) {
  //       console.error(`caught error ${err}`);
  //       throw err;
  //     }
  //   };
  // };

  return useMutation<TData, TError, TVariables, TContext>({
    mutationFn,
    ...rqOptions,
  }); // let react-query do all the work
};

export { useOktaMutation };
