/** @format */

import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import OperatorRddPeriod from "../../components/operator-rdd-card/OperatorRddPeriod";
import { useLocation } from "react-router-dom-v5-compat";
import RequirementDueDate from "../../types/RequirementDueDate";
import { LoadingSpinner } from "../../components/LoadingSpinners";
import useAdHocTokenAuth from "../../hooks/useAdHocTokenAuth";
import { useOktaQuery } from "../../hooks/useOktaQuery";

const AdHocSubmissionDashboard = () => {
  let query = new URLSearchParams(useLocation().search);
  let fakeAuthState = useAdHocTokenAuth();

  const { data, isLoading, isError, refetch } = useOktaQuery(
    `/api/requirementDueDates/adHocDashboard?token=${query.get("token")}`,
    { authState: fakeAuthState },
  );

  // clean this up if we start using react-query mutations for this kind of data
  const receiveUpdatedRequirementDueDate = (rdd: RequirementDueDate) => {
    refetch();
  };

  if (!isLoading && isError) {
    return (
      <Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                This link has expired. Please contact Welltower for assistance.
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
  if (isLoading) return <LoadingSpinner />;
  return (
    <Container>
      {data &&
        data.map((rddPeriod: any) => {
          return (
            <OperatorRddPeriod
              authState={fakeAuthState}
              key={rddPeriod.id}
              rddPeriod={rddPeriod}
              keepExpanded={true}
              receiveUpdatedRequirementDueDate={
                receiveUpdatedRequirementDueDate
              }
            />
          );
        })}
      {data.length === 0 && !isLoading && (
        <Row>
          <Col>
            <Card>
              <CardBody>
                No required documents found. Please contact your Welltower
                accountant for assistance.
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default AdHocSubmissionDashboard;
