/** @format */

import React, { useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Select from "react-select";
import AccountantRddPeriod from "../../components/accountant-rdd-card/AccountantRddPeriod";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom-v5-compat";
import { ContextOperatorPicker } from "../../components/filters/ContextOperatorPicker";
import { ContextDocumentTypePicker } from "../../components/filters/ContextDocumentTypePicker";
import { DocumentTypeContext } from "../../components/filters/PickerContext";
import { useSearchParamsStateSticky } from "../../hooks/useSearchParamsStateSticky";
import { usePeriodPicker } from "../../components/filters/PeriodPicker";
import { useOktaQuery } from "../../hooks/useOktaQuery";
import DashboardPeriodDto from "../../types/DashboardPeriodDto";
import { LoadingSpinnerBig } from "../../components/LoadingSpinners";
import {
  filterRequirementDueDatesByDocumentType,
  filterRequirementDueDatesByOpenOverdue,
  openOverdueOptions,
} from "../OperatorDashboard";

const AccountantOperatorRddsPage: React.FC = () => {
  let { authState } = useOktaAuth();
  let [filterOpenOverdue, setFilterOpenOverdue] = useSearchParamsStateSticky(
    "filterOpenOverdue",
    "all",
  );

  const { operatorId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isSelected: isDocumentTypeSelected } =
    useContext(DocumentTypeContext);

  let {
    renderPicker: renderPeriodPicker,
    currentPeriod,
    //availablePeriods,
    isLoading: periodPickerLoading,
    isError: periodPickerError,
  } = usePeriodPicker();

  const {
    data: unfilteredRddPeriods,
    isLoading: rddPeriodsLoading,
    //isError: rddPeriodsError,
    refetch,
  } = useOktaQuery<DashboardPeriodDto[]>(
    `/api/requirementDueDates/operatorDashboard/${operatorId}/byPeriod/${currentPeriod}`,
    { enabled: !!currentPeriod },
  );

  if (periodPickerError)
    return (
      <Row>
        <Col>
          <Card>
            <CardBody>An error has occurred.</CardBody>
          </Card>
        </Col>
      </Row>
    );
  if (periodPickerLoading) return <LoadingSpinnerBig />;

  let rddPeriods = unfilteredRddPeriods
    ? filterRequirementDueDatesByDocumentType(
        filterRequirementDueDatesByOpenOverdue(
          unfilteredRddPeriods,
          filterOpenOverdue,
        ),
        isDocumentTypeSelected,
      )
    : [];

  return (
    <Container>
      <Row className="pb-2">
        <Col sm={"4"}>
          <ContextOperatorPicker
            selectedId={
              operatorId ? parseInt(operatorId) : "NOT_SELECTED" // This lets us send the URL's operatorId into the picker to set it and make it sticky.
            }
            onSelectionChange={(newOperatorId) => {
              if (
                typeof newOperatorId === "number" &&
                newOperatorId !== parseInt(operatorId ?? "")
              ) {
                //console.log(`ContextOperatorPicker navigating from ${location.pathname}?${location.search} to /accountant-requirement-due-dates/${newOperatorId}?period=${currentPeriod}`,);
                navigate(
                  `/accountant-requirement-due-dates/${newOperatorId}?period=${currentPeriod}`,
                );
              }
            }}
            requireChoice
          />
        </Col>
      </Row>
      <Row>
        <Col md="3">{renderPeriodPicker()}</Col>
        <Col md="3">
          <Select
            id={"open-overdue-select"}
            options={openOverdueOptions}
            onChange={(v: any) => {
              setFilterOpenOverdue(v.value);
            }}
            onBlur={() => {}}
            value={openOverdueOptions.find(
              (a: any) => a.value === filterOpenOverdue,
            )}
          />
        </Col>
        <Col md={"3"}>
          <ContextDocumentTypePicker showAllChoice />
        </Col>
      </Row>
      {!rddPeriodsLoading &&
        rddPeriods &&
        rddPeriods.map((rddPeriod: any) => {
          return (
            <div
              /* TODO - can we just link back to the right spot?
              ref={
               ormatDateISOMonthYear(
                 this.props.location.state &&
                   this.props.location.state.rddPeriodEnd,
               ) === rddPeriod.id
                 ? this.myRef
                 : undefined
              }*/
              key={rddPeriod.id}
            >
              <AccountantRddPeriod
                rddPeriod={rddPeriod}
                authState={authState}
                receiveUpdatedRequirementDueDate={
                  () => refetch() //receiveUpdatedRequirementDueDate
                  // TODO react-query updates from mutations https://tanstack.com/query/latest/docs/framework/react/guides/updates-from-mutation-responses
                }
                keepExpanded={/^\d{4}-\d{2}$/.test(currentPeriod)}
                location={location}
                /* TODO - build this URL to pass around?
                location={{
                  pathname: this.props.location.pathname,
                  state: {
                    filterDocumentTypeId: this.props.selectedDocumentTypeId,
                    filterOpenOverdue: this.state.filterOpenOverdue,
                    currentPeriod: currentPeriod,
                  },
                }}*/
              />
            </div>
          );
        })}
      {rddPeriodsLoading && (
        <FontAwesomeIcon
          icon={"spinner"}
          spin
          size={"3x"}
          color={"#888"}
          className={"mt-5"}
        />
      )}
      {!rddPeriodsLoading && (!rddPeriods || rddPeriods.length === 0) && (
        <Row>
          <Col>
            <Card>
              <CardBody>No requirements match the selected filters</CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );

  /*








  constructor(props: any) {
    super(props);
    this.state = {
      availablePeriods: null,
      selectedPeriodRdds: [],
      error: null,
      currentPeriod:
        (props.location.state && props.location.state.currentPeriod) || "",
      operatorAccountGroupings: [],
      filterOpenOverdue: "all",
      loading: true,
    };
    this.filterRequirementDueDatesByDocumentType =
      this.filterRequirementDueDatesByDocumentType.bind(this);
    this.filterRequirementDueDatesByOpenOverdue =
      this.filterRequirementDueDatesByOpenOverdue.bind(this);
    this.receiveUpdatedRequirementDueDate =
      this.receiveUpdatedRequirementDueDate.bind(this);
  }

  private myRef: RefObject<HTMLDivElement> = createRef();

  scrollIntoView = () => {
    if (this.myRef.current) {
      this.myRef.current.scrollIntoView();
    }
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<any>): void {
    const {
      match: { params },
    } = this.props;

    const {
      match: { params: prevParams },
    } = prevProps;

    if (params?.operatorId !== prevParams?.operatorId) {
      this.loadData();
    }
  }

  async loadData() {
    this.setState({
      loading: true,
    });

    const selectedPeriodRdds = await this.loadOperatorRdds(currentPeriod.value);

    this.setState(
      {
        availablePeriods: transformedAvailablePeriods,
        selectedPeriodRdds,
        currentPeriod,
        loading: false,
      },
      () => {
        this.scrollIntoView();
      },
    );
  }

  async loadAvailablePeriods() {
    try {
      return await getAvailablePeriodMonths(this.props.authState);
    } catch (error) {
      this.setState({ error });
    }
  }
  async getCurrentPeriod() {
    const {
      match: { params },
    } = this.props;
    try {
      const available = await getOperatorDashboardPeriodsByOperatorId(
        params.operatorId,
        this.props.authState,
      );
      const current = getDefaultDashboardMonth(available);
      return current
        ? { label: current.displayName, value: current.id }
        : available && available[0]
          ? { label: available[0].displayName, value: available[0].id }
          : null;
    } catch (error) {
      this.setState({ error });
    }
  }

  receiveUpdatedRequirementDueDate(rdd: RequirementDueDate) {
    if (!rdd) return;

    const selectedPeriodRdds = [...this.state.selectedPeriodRdds];
    if (selectedPeriodRdds) {
      let hasChange = false;
      selectedPeriodRdds.forEach((period: any) => {
        const existingIdx = _.findIndex(
          period.dashboardRequirementDueDates,
          (rdd2: RequirementDueDate) => rdd2.id === rdd.id,
        );
        if (existingIdx !== -1) {
          period.dashboardRequirementDueDates[existingIdx] = rdd;
          hasChange = true;
        }
      });
      if (hasChange) {
        this.setState({ selectedPeriodRdds }, () => {
          // TODO - I think once we've scrolled, don't scroll anymore, and this doesn't seem to work anyway?
          this.scrollIntoView(); //Maybe there's a more efficient way to do this, but we have to keep scroll to the right place as the page shifts.
        });
      }
    }
  }

  filterRequirementDueDatesByDocumentType(rdds: Array<any>) {
    let filteredReqs: Array<any> = [];
    rdds.forEach((rdd) => {
      const rddCopy = { ...rdd };
      rddCopy.dashboardRequirementDueDates =
        rdd.dashboardRequirementDueDates.filter(
          (item: RequirementDueDate) =>
            !this.props.isDocumentTypeSelected ||
            this.props.isDocumentTypeSelected(item.requirement.documentType),
        );
      if (rddCopy.dashboardRequirementDueDates.length > 0) {
        filteredReqs.push(rddCopy);
      }
    });

    return filteredReqs;
  }

  filterRequirementDueDatesByOpenOverdue(rdds: Array<any>) {
    let filteredReqs: Array<any> = rdds;

    if (
      this.state.filterOpenOverdue === "open" ||
      this.state.filterOpenOverdue === "open-overdue"
    ) {
      filteredReqs = [];
      rdds.forEach((rdd) => {
        const rddCopy = { ...rdd };
        rddCopy.dashboardRequirementDueDates =
          rdd.dashboardRequirementDueDates.filter(
            (item: any) => !item.status.welltowerComplete,
          );
        if (rddCopy.dashboardRequirementDueDates.length > 0) {
          filteredReqs.push(rddCopy);
        }
      });

      if (this.state.filterOpenOverdue === "open-overdue") {
        const filteredAgain: Array<any> = [];
        const today = dayjs();
        filteredReqs.forEach((rdd) => {
          const rddCopy = { ...rdd };
          rddCopy.dashboardRequirementDueDates =
            rdd.dashboardRequirementDueDates.filter(
              (item: any) => today.diff(dayjs(item.dueDate), "day") >= 0,
            );
          if (rddCopy.dashboardRequirementDueDates.length > 0) {
            filteredAgain.push(rddCopy);
          }
        });

        filteredReqs = filteredAgain;
      }
    }

    return filteredReqs;
  }

  async loadOperatorRdds(period: string) {
    const {
      match: { params },
    } = this.props;
    try {
      return await getOperatorDashboardPeriodsByYearMonth(
        params.operatorId,
        period,
        this.props.authState,
      );
    } catch (error) {
      this.setState({ error });
    }
  }

  render() {
    if (!this.props.authState.isAuthenticated)
      return (
        <FontAwesomeIcon
          icon={"spinner"}
          spin
          size={"3x"}
          color={"#888"}
          className={"mt-5"}
        />
      );
    if (!isWelltower(this.props.authState)) {
      return <Redirect to="/" />;
    }
    const { availablePeriods, currentPeriod, selectedPeriodRdds, loading } =
      this.state;

    const {
      match: { params },
    } = this.props;

    const filteredRddPeriods = this.filterRequirementDueDatesByOpenOverdue(
      this.filterRequirementDueDatesByDocumentType(selectedPeriodRdds),
    );

    const openOverdueOptions = [
      { value: "all", label: "All Requirements" },
      { value: "open", label: "Open Requirements" },
      { value: "open-overdue", label: "Overdue Requirements" },
    ];

    return (
      <Container>
        <Row className="pb-2">
          <Col sm={"4"}>
            <ContextOperatorPicker
              selectedId={
                params.operatorId ? parseInt(params.operatorId) : "NOT_SELECTED" // This lets us send the URL's operatorId into the picker to set it and make it sticky.
              }
              onSelectionChange={(operatorId) => {
                if (typeof operatorId === "number")
                  this.props.history?.push(
                    `/accountant-requirement-due-dates/${operatorId}`,
                  );
              }}
              requireChoice
            />
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <Select
              id={"due-date-period"}
              aria-label={"Year picker"}
              options={availablePeriods}
              onChange={(selectedPeriod: any) =>
                this.setState(
                  {
                    currentPeriod: selectedPeriod,
                  },
                  this.loadData,
                )
              }
              onBlur={() => {}}
              value={currentPeriod}
            />
          </Col>
          <Col md="3">
            <Select
              id={"open-overdue-select"}
              options={openOverdueOptions}
              onChange={(v: any) => {
                this.setState({ filterOpenOverdue: v.value });
              }}
              onBlur={() => {}}
              value={openOverdueOptions.find(
                (a: any) => a.value === this.state.filterOpenOverdue,
              )}
            />
          </Col>
          <Col md={"3"}>
            <ContextDocumentTypePicker showAllChoice />
          </Col>
        </Row>
        {!loading &&
          filteredRddPeriods &&
          filteredRddPeriods.map((rddPeriod: any) => {
            return (
              <div
                ref={
                  formatDateISOMonthYear(
                    this.props.location.state &&
                      this.props.location.state.rddPeriodEnd,
                  ) === rddPeriod.id
                    ? this.myRef
                    : undefined
                }
                key={rddPeriod.id}
              >
                <AccountantRddPeriod
                  rddPeriod={rddPeriod}
                  authState={this.props.authState}
                  receiveUpdatedRequirementDueDate={
                    this.receiveUpdatedRequirementDueDate
                  }
                  keepExpanded={pickByMonth}
                  location={{
                    pathname: this.props.location.pathname,
                    state: {
                      filterDocumentTypeId: this.props.selectedDocumentTypeId,
                      filterOpenOverdue: this.state.filterOpenOverdue,
                      currentPeriod: currentPeriod,
                    },
                  }}
                />
              </div>
            );
          })}
        {loading && (
          <FontAwesomeIcon
            icon={"spinner"}
            spin
            size={"3x"}
            color={"#888"}
            className={"mt-5"}
          />
        )}
        {!loading &&
          (!filteredRddPeriods || filteredRddPeriods.length === 0) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    No requirements match the selected filters
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
      </Container>
    );
  }
  */
};

export default AccountantOperatorRddsPage;
