/** @format */

import { useOktaAuth } from "@okta/okta-react";
import { useState } from "react";
import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";
import {
  canViewReports,
  canEditWelltowerBuSettings,
  isWelltower,
  canSeeRPADashboard,
  canManageAdHocTokens,
  getUserDisplayName,
  isWelltowerDataAdmin,
} from "../../util/OktaUtils";
import "./Navbar.scss";
import { Link } from "react-router-dom";
import { DeveloperModeConsumer, DeveloperModeOnly } from "../DeveloperMode";
import ServerHealthIndicator from "../ServerHealthIndicator";
import { OktaAuth } from "@okta/okta-auth-js";
import { rpaDashboards } from "../../pages/rpa-dashboard/RPADashboardPicker";
import { appLoadTime } from "../../App";
import dayjs from "dayjs";
import useInterval from "../../hooks/useInterval";
import { relativeTimeShort } from "../../util/DateUtils";
import { useKonamiSessionStorageToggle } from "../../hooks/useKonamiState";

// Making this accept OktaAuth was giving some weird TypeScript error
const logout = (oktaAuth: OktaAuth) => {
  //event.preventDefault();
  // Redirect to '/' after logout
  // Logout is buggy https://github.com/okta/samples-js-react/issues/25
  oktaAuth.signOut({
    postLogoutRedirectUri: window.location.origin + "/",
  }); /*.catch((err: any) => {
        if (err.errorCode === "E0000007") {
          // Eat an Okta error (related to third-party cookies) and redirect back to home.
          window.location.href = "/";
        } else throw err;
      });*/
  // return false;
};

// <DropdownItem><Link> wasn't working in IE
// TODO - can we remove this since IE is dead?
// Using just <Link> doesn't close the Bootstrap Dropdown
// Push history ourselves
const DINavLink = ({ to, children, ...props }: any) => (
  <DropdownItem tag={Link} to={to} {...props}>
    {children}
  </DropdownItem>
);

const OktaTokenDetails = ({ onClick }: { onClick: any }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [index, setIndex] = useState(1);
  useInterval(() => {
    setIndex(index + 1);
  }, 1000);

  if (!authState?.isAuthenticated)
    return <NavbarText className={"px-2"}>no okta</NavbarText>;

  return (
    <>
      <NavbarText
        onClick={onClick}
        className={"px-2"}
        style={{ fontVariantNumeric: "tabular-nums" }}
        index={index}
      >
        {authState?.idToken?.claims?.email}
      </NavbarText>
      <NavbarText
        onClick={onClick}
        className={"px-2"}
        style={{ fontVariantNumeric: "tabular-nums" }}
        index={index}
      >
        Last reload {relativeTimeShort(appLoadTime)}
      </NavbarText>
      <NavbarText
        onClick={onClick}
        className={"px-2"}
        style={{ fontVariantNumeric: "tabular-nums" }}
        index={index}
      >
        Okta Token will refresh{" "}
        {relativeTimeShort(
          dayjs.unix(
            oktaAuth.tokenManager.getExpireTime(authState.accessToken!) ||
              authState.accessToken?.expiresAt ||
              0,
          ),
        )}
      </NavbarText>
    </>
  );
};

const WtNavbar = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpen);
  const [showOkta, hideOkta] = useKonamiSessionStorageToggle(
    "showOktaTimer",
    [79, 75, 84, 65],
  );

  const welltower = isWelltower(authState);
  const welltowerDataAdmin = isWelltowerDataAdmin(authState);
  const showRPA = canSeeRPADashboard(authState);
  const showETLDashboard = welltower && process.env.REACT_APP_STAGE_LABEL; // Show for any non-prod stages
  const viewReports = canViewReports(authState);
  const viewWelltowerBuSettings = canEditWelltowerBuSettings(authState);
  const canManageAdHoc = canManageAdHocTokens(authState);

  return (
    <Navbar light expand="md" className="wtop-navbar">
      <NavbarBrand tag={Link} to={"/"}>
        {process.env.REACT_APP_STAGE_LABEL && (
          <div className="corner-ribbon top-left">
            {process.env.REACT_APP_STAGE_LABEL}
          </div>
        )}
        <span
          className={"welltower-logo"}
          style={{
            display: "inline-block",
            width: "5em",
            height: "1.25em",
            marginRight: "0.25em",
          }}
        />{" "}
        Operator Portal
        <DeveloperModeConsumer>
          {({ developerMode }) =>
            (developerMode || process.env.REACT_APP_STAGE_LABEL) && (
              <ServerHealthIndicator />
            )
          }
        </DeveloperModeConsumer>
      </NavbarBrand>
      {showOkta && <OktaTokenDetails onClick={hideOkta} />}
      <NavbarToggler onClick={toggleOpen} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          {/*<NavItem>
                <NavLink>Help</NavLink>
              </NavItem>*/}
          {authState?.isAuthenticated ? (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {getUserDisplayName(authState)}
              </DropdownToggle>
              <DropdownMenu right>
                {!welltower && (
                  <DINavLink to={"/operator-dashboard"}>
                    Monthly Requirements
                  </DINavLink>
                )}
                {welltower && (
                  <DINavLink to={"/dashboard"}>Operator Status</DINavLink>
                )}
                {(showRPA || showETLDashboard) && (
                  <>
                    <DINavLink
                      to={"/rpa-dashboard"}
                      data-botid={"rpa-dashboard-link"}
                    >
                      RPA Dashboard
                    </DINavLink>
                    {showRPA &&
                      rpaDashboards.map((b: any) => (
                        <DINavLink key={b.id} to={b.url} data-botid={b.id}>
                          {b.title}
                        </DINavLink>
                      ))}
                  </>
                )}

                <DropdownItem divider />

                {welltower && (
                  <DINavLink to={"/account-mappings"}>
                    Account Mappings
                  </DINavLink>
                )}
                {welltower && (
                  <DINavLink to={"/entity-mappings"}>
                    Property Mappings
                  </DINavLink>
                )}
                {welltower && (
                  <DINavLink to={"/combo-mappings"}>Combo Mappings</DINavLink>
                )}
                {welltower && (
                  <DINavLink to={"/segment-mappings"}>
                    Segment Mappings
                  </DINavLink>
                )}
                {welltower && (
                  <DINavLink to={"/column-mappings"}>Column Mappings</DINavLink>
                )}
                {welltower && <DropdownItem divider />}

                {welltower && (
                  <DINavLink to={"/operators"}>Operator List</DINavLink>
                )}
                {welltower && (
                  <DINavLink to={"/operator-settings"}>
                    Operator Settings
                  </DINavLink>
                )}
                {canManageAdHoc && (
                  <DINavLink to={"/adHocTokens"}>Ad-Hoc Upload Links</DINavLink>
                )}

                <DINavLink to={"/users"}>Operator Portal Users</DINavLink>

                {welltower && (
                  <DINavLink to={"/requirements"}>Edit Requirements</DINavLink>
                )}

                {welltower && (
                  <DINavLink to={"/master-data"}>Manage Master Data</DINavLink>
                )}

                {welltowerDataAdmin && (
                  <DINavLink to={"/national-holidays"}>
                    Manage National Holidays
                  </DINavLink>
                )}

                {welltower && (
                  <DINavLink to={"/overdue"}>Overdue Preview</DINavLink>
                )}

                <DropdownItem divider />

                {viewReports && <DINavLink to={"/reports"}>Reports</DINavLink>}

                {viewWelltowerBuSettings && (
                  <DINavLink to={"/welltower-bu-settings"}>
                    Welltower BU/Entity Settings
                  </DINavLink>
                )}

                {(viewReports || viewWelltowerBuSettings) && (
                  <DropdownItem divider />
                )}

                {authState.isAuthenticated && (
                  <DINavLink to={"/notification-preferences"}>
                    My Notification Preferences
                  </DINavLink>
                )}

                <DeveloperModeOnly>
                  <DINavLink to={"/token"}>Okta Token</DINavLink>
                  <DINavLink to={"/hopper-status"}>
                    Check Yardi ETL queue
                  </DINavLink>
                </DeveloperModeOnly>
                <DropdownItem onClick={() => logout(oktaAuth)}>
                  Log out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            <DeveloperModeOnly>
              <DropdownToggle>not logged in</DropdownToggle>
            </DeveloperModeOnly>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default WtNavbar;
