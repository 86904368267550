/** @format */

import React from "react";
import { Field, Formik, FormikProps, ErrorMessage } from "formik";
import { Button } from "reactstrap";
import { postWithToken, putWithToken } from "../../util/FetchUtils";
import { operatorsUrl, updateOperatorUrl } from "../../util/ApiUrlUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationDialog from "../operator-users/ConfirmationDialog";

export default class OperatorEditForm extends React.Component<any, any> {
  static defaultProps = {
    operator: {
      name: "",
      externalName: "",
      vantageOperatorId: "",
      salesforceAccountId: "",
      jdeOperatorCatCode08Code: "",
      oktaGroupName: "",
    },
  };

  constructor(props: any) {
    super(props);
    this.onSaveOperator = this.onSaveOperator.bind(this);
  }

  componentDidMount() {}

  async onSaveOperator(data: any, callback: Function) {
    if (this.props.operator && this.props.operator.id) {
      putWithToken(
        updateOperatorUrl(this.props.operator.id),
        this.props.authState,
        data.operator,
      ).then((data: any) => {
        console.log("successfully updated operator");
        callback && callback.call(null);
        this.props.closeModal();
        this.props.onSaveOperator();
      });
    } else {
      postWithToken(operatorsUrl(), this.props.authState, data.operator).then(
        (data: any) => {
          console.log("successfully created operator");
          callback && callback.call(null);
          this.props.closeModal();
          this.props.onSaveOperator();
        },
      );
    }
  }

  render() {
    let { operator, deleteOperator, closeModal } = this.props;

    return (
      <Formik
        initialValues={{ operator: operator }}
        onSubmit={(values, actions) => {
          this.onSaveOperator(values, () => actions.setSubmitting(false));
        }}
        validate={(values: any) => {
          let errors: any = { operator: {} };
          let operator: any = values.operator;

          if (!operator.name) {
            errors.operator.name = "Please enter an operator name.";
          }

          if (!operator.oktaGroupName) {
            errors.operator.oktaGroupName = "Please enter an Okta group name.";
          }

          if (!operator.vantageOperatorId) {
            errors.operator.vantageOperatorId =
              "Please enter a Vantage Operator ID.";
          }

          if (!operator.salesforceAccountId) {
            errors.operator.salesforceAccountId =
              "Please enter a Salesforce Account ID.";
          } else if (operator.salesforceAccountId.length !== 18) {
            errors.operator.salesforceAccountId =
              "Salesforce Account ID must be 18 characters.";
          }

          if (operator.yardiOperator?.length > 80) {
            errors.operator.yardiOperator =
              "Yardi Operator is too long. Must be 80 characters or fewer.";
          }

          if (Object.keys(errors.operator).length > 0) {
            return errors;
          } else {
            return {};
          }
        }}
      >
        {(props: FormikProps<any>) => {
          return (
            <div className={"editForm"}>
              <h1 className="propertyName">
                {operator.name ? operator.name : "Create Operator"}
              </h1>
              <form className="editFormForm" onSubmit={props.handleSubmit}>
                <div className="form-group">
                  <label htmlFor={`operator.name`} className={"label-required"}>
                    Name
                  </label>
                  <Field
                    name={`operator.name`}
                    type={"text"}
                    className={"form-control"}
                  />
                  <ErrorMessage
                    name={"operator.name"}
                    component={"div"}
                    className={"input-feedback"}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={`operator.externalName`}>External Name</label>
                  <Field
                    name={`operator.externalName`}
                    type={"text"}
                    className={"form-control"}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor={`operator.vantageOperatorId`}
                    className={"label-required"}
                  >
                    Vantage Operator ID
                  </label>
                  <Field
                    name={`operator.vantageOperatorId`}
                    type={"text"}
                    className={"form-control"}
                  />
                  <ErrorMessage
                    name={"operator.vantageOperatorId"}
                    component={"div"}
                    className={"input-feedback"}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor={`operator.salesforceAccountId`}
                    className={"label-required"}
                  >
                    Salesforce Account ID
                  </label>
                  <Field
                    name={`operator.salesforceAccountId`}
                    type={"text"}
                    className={"form-control"}
                  />
                  <ErrorMessage
                    name={"operator.salesforceAccountId"}
                    component={"div"}
                    className={"input-feedback"}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor={`operator.oktaGroupName`}
                    className={"label-required"}
                  >
                    Okta Group (case-sensitive)
                  </label>
                  <Field
                    name={`operator.oktaGroupName`}
                    type={"text"}
                    className={"form-control"}
                  />
                  <ErrorMessage
                    name={"operator.oktaGroupName"}
                    component={"div"}
                    className={"input-feedback"}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={`operator.jdeOperatorCatCode08Code`}>
                    JDE Cat Codes (usually just one, or sometimes multiples
                    separated by commas)
                  </label>
                  <Field
                    name={`operator.jdeOperatorCatCode08Code`}
                    type={"text"}
                    className={"form-control"}
                  />
                  <label htmlFor={`operator.yardiOperator`}>
                    Yardi Operator name (attribute subgroup24; just one for now)
                  </label>
                  <Field
                    name={`operator.yardiOperator`}
                    type={"text"}
                    className={"form-control"}
                  />
                </div>
                {props.errors && props.errors.submission && (
                  <div
                    className="invalid-feedback pr-3"
                    style={{ display: "inline-block", width: "24em" }}
                  >
                    {props.errors.submission as string}
                  </div>
                )}

                {operator && operator.id && deleteOperator && (
                  <ConfirmationDialog
                    title={"Delete operator?"}
                    message={`Are you sure you want to delete this operator? (It won't work if this operator has any requirements or submitted files.)`}
                    buttonColor={"danger"}
                    confirmLabel={"Delete"}
                    action={() => {
                      deleteOperator(operator)
                        .then((response: any) => {
                          if (response.data.status === "error") {
                            props.setErrors({
                              submission: response.data.error,
                            });
                          } else {
                            closeModal && closeModal();
                          }
                        })
                        .catch((error: any) =>
                          props.setErrors({
                            submission: error.message || error,
                          }),
                        );
                    }}
                  >
                    {({ onClick }: { onClick: any }) => (
                      <Button
                        onClick={onClick}
                        color={"danger"}
                        disabled={props.isSubmitting}
                      >
                        <FontAwesomeIcon
                          icon={["far", "trash-alt"]}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </Button>
                    )}
                  </ConfirmationDialog>
                )}

                <Button
                  type={"submit"}
                  color={"primary"}
                  disabled={props.isSubmitting}
                  className={"float-right wtop-btn"}
                >
                  Save Updates
                </Button>
              </form>
            </div>
          );
        }}
      </Formik>
    );
  }
}
