/** @format */

import React from "react";

import SubmittedFile from "../../types/SubmittedFile";

import RequirementDueDate from "../../types/RequirementDueDate";
import {
  ACCEPTABLE_PROCESSED_FILE_TYPES_READABLE,
  DROPZONE_MAX_FILE_SIZE_MB,
  DROPZONE_MAX_PROCESSED_FILE_SIZE_MB,
  UPLOAD_STATUS_REFRESH_INTERVAL,
} from "../../util/constants";
import {
  deleteSubmittedFile,
  getSubmittedFiles,
  loadFileUnmappedItems,
  resumeFile,
  reuploadFile,
  retractFile,
  submitFile,
  sendToRPA,
  undoRPA,
  failFile,
} from "../../api/SubmittedFileAPI";

import {
  hasDeletedOrRetractedStatus,
  hasInProgressStatus,
  hasMissingMappingsStatus,
} from "../../util/StatusUtils";
import "./BaseRddCard.scss";
import { downloadUrl } from "../../util/OneTimeDownloader";
import _ from "lodash";
import { loadOperatorCaptionsForCOA } from "../../api/SharedAPI";
import FinancialCaption from "../../types/FinancialCaption";
import { AbstractAuthState } from "../../util/OktaUtils";
import { FileRejection } from "react-dropzone";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeveloperModeOnly } from "../DeveloperMode";

export interface IBaseRddCardProps {
  requirementDueDate: RequirementDueDate;
  receiveUpdatedRequirementDueDate: (rdd: any, hideModal?: boolean) => void;
  authState: AbstractAuthState;
  showModal?: any;
  modalContent?: any;
  location?: any;
  ref?: any;
  rerenderParentCallback?: any;
  reload?: boolean;
}

export interface IBaseRddCardState {
  accepted: Array<any>;
  uploaded: Array<any>;
  rejected: Array<any>;
  previousFiles: Array<SubmittedFile>;
  latestFile?: any;
  showExisting: boolean;
  uploadingFilename?: string;
  uploadingBatchProgress?: string;
  uploadingFilePercent?: number;
  error?: any;
  success?: any;
  warning?: any;
  block?: boolean;
  blockMessage?: string;
  unmappedAccounts?: Array<any>;
  unmappedProperties?: Array<any>;
  unmappedColumns?: Array<any>;
  operatorAccountGroupings?: any;
  showCloudticityForm?: boolean;
  updateFilesStatus?: boolean;
  cancelFilesStatusLoop?: boolean;
}

export const nonEmptyFileValidator = (file: File) => {
  if (file.size === 0) {
    // TODO - also check undefined/null? We saw this get through once. Or maybe we don't care if we aren't sure it's an empty file?
    return {
      code: "empty-file",
      message: `Uploaded file is empty. If you tried to upload a file from inside a zip file, please unzip the zip file and try again.`,
    };
  }
  return null;
};

// TODO - try to mask over the card and then get a floating opaque card above it?
export const LoadingIndicatorRow = ({
  onClose,
  message,
}: {
  onClose?: any;
  message?: string;
}) => (
  <Row className={"rdd-card-block position-absolute"}>
    <Col className={"text-center mt-5"}>
      <FontAwesomeIcon
        icon={"spinner"}
        spin
        size={"3x"}
        color={"#444"}
        className={"mt-5"}
        title={"Loading..."}
      />
      {message && <p style={{ color: "black" }}>{message}</p>}
      <DeveloperModeOnly>
        <span
          className={"cursor-pointer"}
          style={{
            position: "absolute",
            bottom: "1em",
            right: "2em",
          }}
          onClick={() => onClose && onClose()}
        >
          <FontAwesomeIcon icon={["fas", "times"]} color={"#444"} />
        </span>
      </DeveloperModeOnly>
    </Col>
  </Row>
);

export default class BaseRddCard extends React.Component<
  IBaseRddCardProps,
  IBaseRddCardState
> {
  constructor(props: IBaseRddCardProps) {
    super(props);
    this.state = {
      accepted: [],
      uploaded: [],
      rejected: [], // TODO - is this used anywhere?
      previousFiles: [],
      unmappedAccounts: [],
      unmappedProperties: [],
      unmappedColumns: [],
      showExisting: true,
      uploadingFilename: undefined,
      uploadingBatchProgress: undefined,
      uploadingFilePercent: undefined,
      error: null,
      success: null,
      warning: null,
      block: false,
      updateFilesStatus: false,
      cancelFilesStatusLoop: false,
    };
    this.block = this.block.bind(this);
    this.unblock = this.unblock.bind(this);
    this.loadFiles = this.loadFiles.bind(this);
  }

  async componentDidMount() {
    this.loadFiles(this.props.requirementDueDate);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.reload !== prevProps.reload) {
      this.loadFiles(this.props.requirementDueDate);
    }
  }

  block(message?: string) {
    this.setState({ block: true, blockMessage: message });
  }

  unblock() {
    this.setState({ block: false, blockMessage: undefined });
  }

  async loadFiles(
    requirementDueDate: RequirementDueDate,
    callback?: () => any,
    isUploading?: boolean,
    isInProgressUpdateLoop?: boolean,
  ) {
    const { authState } = this.props;
    if (this.state.cancelFilesStatusLoop) {
      this.setState({ block: false, cancelFilesStatusLoop: false });
      this.unblock();
      return;
    }
    try {
      this.block();
      let response = (
        await getSubmittedFiles(requirementDueDate, authState)
      ).filter((f) => !hasDeletedOrRetractedStatus(f.status));
      let latestFile = response[response.length - 1];

      if (
        latestFile &&
        hasInProgressStatus(latestFile.status) &&
        !this.state.error
      ) {
        // Keep refreshing files until the latest is no longer being processed
        // this.state.error will get the file's status error message if applicable,
        // but it is also set by an underlying http error, and we want to stop this
        // refresh loop in that case too.
        setTimeout(
          () => this.loadFiles(requirementDueDate, callback, isUploading, true),
          UPLOAD_STATUS_REFRESH_INTERVAL,
        );
        const statusChanged =
          (this.state.latestFile &&
            this.state.latestFile.status &&
            this.state.latestFile.status.id) !==
          (latestFile && latestFile.status && latestFile.status.id);

        this.setState({
          latestFile,
          previousFiles: response,
        });
        if (statusChanged) {
          this.props.receiveUpdatedRequirementDueDate(
            latestFile.requirementDueDate,
            !isUploading,
          );
        }
        return;
      } else {
        const error =
          latestFile &&
          isInProgressUpdateLoop &&
          latestFile.requirementDueDate.status.operatorPopupMessage &&
          latestFile.requirementDueDate.status.operatorStatusColor === "ERROR"
            ? latestFile.requirementDueDate.status.operatorPopupMessage
            : null;
        const warning =
          latestFile &&
          isInProgressUpdateLoop &&
          latestFile.requirementDueDate.status.operatorPopupMessage &&
          latestFile.requirementDueDate.status.operatorStatusColor === "WARNING"
            ? latestFile.requirementDueDate.status.operatorPopupMessage
            : null;
        const statusChanged =
          (this.state.latestFile &&
            this.state.latestFile.status &&
            this.state.latestFile.status.id) !==
          (latestFile && latestFile.status && latestFile.status.id);

        this.setState(
          {
            latestFile,
            error,
            warning,
            unmappedAccounts: [],
            unmappedProperties: [],
            unmappedColumns: [],
            previousFiles: response,
            uploadingFilename: undefined,
            uploadingBatchProgress: undefined,
            uploadingFilePercent: undefined,
            block: false,
          },

          this.loadFileUnmappedItems,
        );
        // Force an upstream reload to pick up updated RDD object, only if we've already been in the refresh loop, or if we just posted an update that changed the status
        if ((isInProgressUpdateLoop || statusChanged) && latestFile) {
          this.props.receiveUpdatedRequirementDueDate(
            latestFile.requirementDueDate,
            !isUploading,
          );
        }
        if (
          latestFile &&
          this.state.updateFilesStatus &&
          (latestFile.status.id === "ACCEPTED_WELLTOWER" ||
            latestFile.status.id === "AWAITING_RPA" ||
            latestFile.status.id === "AWAITING_ETL_RESULTS" ||
            latestFile.status.id === "AWAITING_PULL_RESULTS")
        ) {
          this.props.rerenderParentCallback &&
            this.props.rerenderParentCallback();
          this.setState({ updateFilesStatus: false });
        }
      }
    } catch (error) {
      // TODO - should an error here clear the reload loops?
      this.setState({ error, block: false, cancelFilesStatusLoop: true });
    }
  }

  loadFileUnmappedItems = async () => {
    const { authState } = this.props;
    const { latestFile } = this.state;

    if (latestFile && hasMissingMappingsStatus(latestFile.status)) {
      try {
        let unmappedAccounts = await loadFileUnmappedItems(
          latestFile,
          authState,
          "account",
        );

        let unmappedProperties = await loadFileUnmappedItems(
          latestFile,
          authState,
          "entity",
        );
        let unmappedColumns = await loadFileUnmappedItems(
          latestFile,
          authState,
          "column",
        );

        this.setState({
          unmappedAccounts,
          unmappedProperties,
          unmappedColumns,
          block: false,
        });
        latestFile.requirementDueDate.requirement.chartOfAccounts &&
          loadOperatorCaptionsForCOA(
            latestFile.requirementDueDate.requirement.chartOfAccounts.id,
            authState,
          )
            .then((captions) =>
              this.setState({
                operatorAccountGroupings: _.sortBy(captions, "sortOrder").map(
                  (fc: FinancialCaption) => {
                    return { value: fc.id, label: fc.displayName };
                  },
                ),
              }),
            )
            .catch((error) => console.error(error));
      } catch (error) {
        this.setState({ error, block: false });
      }
    }
  };

  delete = async (file: SubmittedFile) => {
    try {
      this.block();
      let data: any = await deleteSubmittedFile(file, this.props.authState);
      this.props.receiveUpdatedRequirementDueDate(data, true);
      return this.loadFiles(data, this.unblock);
    } catch (error) {
      this.setState({ error, block: false });
    }
  };

  resume = async (file: SubmittedFile) => {
    try {
      this.block();
      let data: any = await resumeFile(file, this.props.authState);
      this.props.receiveUpdatedRequirementDueDate(data, true);
      this.loadFiles(data, undefined, true);
    } catch (error) {
      console.log(error);
      this.setState({ error: "Resume failed", block: false });
    }
  };

  fail = async (file: SubmittedFile) => {
    try {
      this.block();
      let data: any = await failFile(file, this.props.authState);
      this.props.receiveUpdatedRequirementDueDate(data, true);
      return this.loadFiles(data, this.unblock, true);
    } catch (error) {
      console.log(error);
      this.setState({ error: "Fail failed", block: false });
    }
  };

  reupload = async (file: SubmittedFile) => {
    try {
      this.block();
      let data: any = await reuploadFile(file, this.props.authState);
      this.props.receiveUpdatedRequirementDueDate(data, true);
      return this.loadFiles(data, undefined, true);
    } catch (error) {
      console.log(error);
      this.setState({ error: "Reupload failed", block: false });
    }
  };

  retract = async (file: SubmittedFile) => {
    try {
      this.block();
      let data: any = await retractFile(file, this.props.authState);
      this.props.receiveUpdatedRequirementDueDate(data, true);
      return this.loadFiles(data, this.unblock, true);
    } catch (error) {
      this.setState({ error, block: false });
    }
  };

  sendToRPA = async (file: SubmittedFile) => {
    try {
      this.block();
      let data: any = await sendToRPA(file, this.props.authState);
      this.props.receiveUpdatedRequirementDueDate(data, true);
      this.loadFiles(data, undefined, true);
    } catch (error) {
      console.log(error);
      this.setState({ error, block: false });
    }
  };

  // TODO - testing only
  undoRPA = async (file: SubmittedFile) => {
    try {
      this.block();
      let data: any = await undoRPA(file, this.props.authState);
      this.props.receiveUpdatedRequirementDueDate(data, true);
      this.loadFiles(data, undefined, true);
    } catch (error) {
      console.log(error);
      this.setState({ error: "Undo Bot failed", block: false });
    }
  };

  downloadFile = async (file: SubmittedFile) => {
    downloadUrl(this.props.authState, file.originalFileDownloadTokenURI);
  };

  downloadFileFullLogEntry = async (file: SubmittedFile) => {
    const url = `/api/requirementDueDates/${file.requirementDueDate.id}/files/${file.id}/logEntries/downloadToken`;
    downloadUrl(this.props.authState, url);
  };

  downloadAllFiles = async () => {
    const { requirementDueDate } = this.props;
    const url = `/api/requirementDueDates/${requirementDueDate.id}/files/all/downloadToken`;
    downloadUrl(this.props.authState, url);
  };

  uploadProgressCallback = (
    progressEvent: ProgressEvent,
    filename?: string,
    batchProgress?: string,
  ) => {
    var percent = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    if (percent >= 100) {
      this.setState({
        uploadingFilename: filename,
        uploadingBatchProgress: batchProgress,
        uploadingFilePercent: 100,
      });
    } else {
      this.setState({
        uploadingFilename: filename,
        uploadingBatchProgress: batchProgress,
        uploadingFilePercent: percent,
      });
    }
  };

  clearUploadProgress = () => {
    this.setState({
      uploadingFilename: undefined,
      uploadingBatchProgress: undefined,
      uploadingFilePercent: undefined,
    });
  };

  uploadFile = async (accepted: File[], additionalData?: any) => {
    try {
      this.block();
      let data: any = await submitFile(
        accepted,
        this.props.authState,
        this.props.requirementDueDate,
        this.uploadProgressCallback,
        additionalData,
      );

      this.clearUploadProgress();
      this.unblock();
      this.props.receiveUpdatedRequirementDueDate(data.requirementDueDate);
      this.setState({ updateFilesStatus: true });
      this.loadFiles(data.requirementDueDate, undefined, true, true);
    } catch (error) {
      console.error(error);
      if ((error as any).response?.headers) {
        console.debug((error as any).response?.data);
        console.debug((error as any).response?.headers);
      }
      this.setState({ error: "Upload failed", block: false });
    }
  };

  onDrop = async (
    accepted: File[],
    fileRejections: FileRejection[],
    additionalData?: any,
  ) => {
    if (fileRejections && fileRejections.length) {
      this.setState({
        error: (
          <ul className={"mb-0"}>
            {fileRejections.map((fr) => (
              <li key={fr.file.name}>{`${fr.file.name}: ${fr.errors
                .map((e) => {
                  switch (e.code) {
                    case "file-invalid-type":
                      return `Unexpected file type. Expected types are ${ACCEPTABLE_PROCESSED_FILE_TYPES_READABLE}.`;
                    case "file-too-large":
                      return `File too large. Maximum allowed size is ${
                        this.props.requirementDueDate?.requirement
                          ?.portalProcessed
                          ? DROPZONE_MAX_PROCESSED_FILE_SIZE_MB
                          : DROPZONE_MAX_FILE_SIZE_MB
                      } MB.`;
                    default:
                      return e.message;
                  }
                })
                .join(", ")}`}</li>
            ))}
          </ul>
        ),
        uploadingFilename: undefined,
        uploadingBatchProgress: undefined,
        uploadingFilePercent: undefined,
        block: false,
      });
    } else {
      this.setState({ error: undefined }, () => {
        this.uploadFile(accepted, additionalData);
      });
    }
  };

  render() {
    return <div className={`base-rdd-card`}></div>;
  }
}
