/** @format */

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import Operator from "../../types/Operator";
import {
  addOperatorAsFavorite,
  deleteOperatorAsFavorite,
} from "../../api/OperatorAPI";
import Select, { SelectInstance } from "react-select";
import useFetchJson from "../../hooks/useFetchJson";
import { useOktaAuth } from "@okta/okta-react";
import { OperatorContext } from "./PickerContext";
import { selectTheme } from "../form/WrappedSelectInput";
import {
  IOperatorPickerProps,
  OperatorChoice,
  OperatorPickerId,
} from "../../types/PickerTypes";
import { PickerSelectOption } from "./generic/ContextPicker";

// TODO Favorites should be working but haven't been tested. I don't think we ever actually used Favorite operators, and maybe we don't need that with react-select?

/*
 * Basic data flow: (common with ContextDocumentTypePicker)
 *
 * OperatorContextWrapper
 *  ...
 *   Page
 *    ...
 *     ContextOperatorPicker
 *
 *
 * OperatorContextWrapper creates and manages the context, which stores the selected ID (of type PickerID) and usually the selected object (if single)
 *   TODO - maybe stop storing the single object and let the caller get it if it needs it?
 *          or should the ContextWrapper always hold and pass down a list of all selected objects (incl ALL and FAVORITES)?
 *
 * When the Page is an ID-routed path (like /accountant-requirement-due-dates/:operatorId -> AccountantOperatorRddsPage),
 * the page should pass the route's ID to the picker
 *    selectedId={
 *      params.operatorId ? parseInt(params.operatorId) : "NOT_SELECTED" // This lets us send the URL's operatorId into the picker to set it and make it sticky.
 *    }
 * and if the picker changes, the page should send a navigation event by passing
 *   onSelectionChange={(operatorId) => {
 *     if (typeof operatorId === "number")
 *       this.props.history.push(
 *         `/accountant-requirement-due-dates/${operatorId}`,
 *       );
 *   }}
 * This mostly defeats the purpose of a sticky context-based picker, but I think it still makes sense overall.
 *
 * When a Page is just displaying some per-operator data without IDs in the route (like /requirements), the page can
 * include the picker simply as:
 *   <ContextOperatorPicker requireChoice />
 * and the page can get the selected operator as props.selectedOperatorId by wrapping a component in withOperatorContext()
 * or from a hook like
 *   const { selectedId: selectedOperatorId } = useContext(OperatorContext);
 *
 *
 * Stickiness:
 *   TODO describe the sessionStorage stickiness (and that browsers don't copy this to a new tab/window)
 *   TODO - maybe also fall back to localStorage to emulate this (keep tabs on their own sticky, but let new tabs get the last one stuck?)
 *
 * Picker options:
 *   TODO - maybe remove some of the magic/logic around picking the default selection (see some TODOs in interface IFilterPickerProps)
 *   requireChoice - defaults to false. If true, the first operator will be picked if there's no sticky in the session
 *   showAllChoice - defaults to false. If true, "All Operators" is an option, but will not be picked by default
 *   Not currently an easy way to default to All Operators (because usually that's not a view that makes sense or would load way too much data to display)
 * TODO document favorites options for DocumentTypePicker
 *
 *
 * Favorites:
 *   Doesn't really make sense for Operators, because most(all?) views are single-operator
 *   DocumentTypePicker lets the user select multiple choices as Favorites and pick "Favorites"
 *
 */

const traceLogging = process.env.REACT_APP_PICKER_LOGGING || false;

const BaseOperatorPicker = (props: IOperatorPickerProps) => {
  const { authState } = useOktaAuth();
  const { data: operators, loading } =
    useFetchJson<Operator[]>("/api/operators");

  const {
    data: favorites,
    //loading: loadingFavorites,
    forceReload: reloadFavorites,
  } = useFetchJson<Operator[]>("/api/operators/my", {
    keepDataAcrossReload: true,
  });

  const selectedOperatorId = props.selectedId;

  // If we haven't selected an operator, pick a default choice instead of starting blank (TODO - maybe allow blanks for some Reports pickers that don't allow All?)
  useEffect(() => {
    // if we require a choice (meaning we don't allow All or Favorites), choose the first one
    const operatorSelected =
      selectedOperatorId && selectedOperatorId !== "NOT_SELECTED";

    const allSelected = selectedOperatorId && selectedOperatorId === "ALL";
    const loaded = !loading && operators && operators.length > 0;

    if (!selectedOperatorId && props.default) {
      traceLogging &&
        console.debug(
          `setting default of ${props.default} because previously unselected`,
        );
      if (props.onSelectionChange)
        props.onSelectionChange(props.default, undefined);
    } else if (selectedOperatorId && selectedOperatorId === props.default) {
      traceLogging &&
        console.debug(
          `Had default of ${props.default} but didn't switch to it because already on ${selectedOperatorId}`,
        );
    } else if (!operatorSelected && props.requireChoice && loaded) {
      const sortedOperators = _.sortBy(operators, ["name"]);
      // This makes sticky the first operator if one hasn't been chosen. Do we need to do that explicitly? Or can we let the Select require a choice and call us back (not sure if that causes the same setState-in-render issue?)
      traceLogging &&
        console.debug("setting first operator because previously unselected");
      if (props.onSelectionChange)
        props.onSelectionChange(sortedOperators[0].id, sortedOperators[0]);
    }
    // else if we don't require a choice and allow All, choose All
    else if (
      !operatorSelected &&
      !props.requireChoice &&
      props.showAllChoice
      // Technically we don't have to wait for loadedOperators yet here, but that's probably simpler
    ) {
      traceLogging &&
        console.debug(
          "setting ALL because previously unselected and requireOperatorChoice=false",
        );
      if (props.onSelectionChange) props.onSelectionChange("ALL");
    } else if (
      operatorSelected &&
      !allSelected && // TODO if copying back to DocumentTypePicker, DTP should also check !favoritesSelected here too
      !props.selectedObj &&
      loaded &&
      props.onSelectionChange
    ) {
      // We loaded a sticky id, call back to populate the object
      traceLogging &&
        console.debug(
          "setting operator object because we just loaded from an ID",
        );
      if (props.onSelectionChange)
        props.onSelectionChange(
          selectedOperatorId,
          operators.find((o) => o.id === selectedOperatorId),
        );
    }
    // TODO - remove this after some testing
    else {
      traceLogging &&
        console.debug(
          `not setting anything in useEffect: loading=${loading}, selectedOperatorId=${selectedOperatorId}, operatorSelected=${operatorSelected}, requireOperatorChoice=${props.requireChoice}, showAllOperatorChoice=${props.showAllChoice}`,
        );
    }
  }, [
    props,
    props.requireChoice,
    selectedOperatorId,
    props.showAllChoice,
    props.default,
    loading,
    operators,
    props.onSelectionChange,
  ]);
  // TODO - can we trigger this when removing the last favorite from an allowAllAndFavorites picker that all

  const getFavorite = (operator: Operator) => {
    return _.find(favorites, (fav: any) => {
      return fav.operator && fav.operator.id === operator.id;
    });
  };

  const onAddFavorite = async (operatorId: number) => {
    try {
      await addOperatorAsFavorite(operatorId, authState);
      reloadFavorites().then((favs) => {
        if (props.onSelectionChange) props.onSelectionChange("FAVORITES", favs);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onDeleteFavorite = async (favorite: any) => {
    try {
      await deleteOperatorAsFavorite(favorite.id, authState);
      reloadFavorites().then((favs) => {
        if (props.onSelectionChange) {
          if (favs.length > 0) {
            props.onSelectionChange("FAVORITES", favs);
          } else if (props.showAllChoice) {
            // Switch back to All Operators when removing last favorite
            props.onSelectionChange("ALL");
          } else {
            // If we're here, an operator should already be selected, so no need to change anything?
            // TODO - I think this should trigger the useEffect that assigns all or first? (and if so, I think we can remove the "ALL" above here and let "NOT_SELECTED" do that logic too?)
            props.onSelectionChange("NOT_SELECTED");
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (loading || !operators || operators.length === 0) {
    traceLogging &&
      console.debug(`operators loading=${loading} or ${operators?.length}`);
    return null;
  }
  const enableAllAndFavorites = !props.requireChoice;
  const enableAll = props.showAllChoice;
  const enableFavorites = false; // Not using favorite operators for now
  if (enableAllAndFavorites)
    console.warn("OperatorPickerSelect has requireOperatorChoice=false");
  //if (enableAll)
  //  console.warn("OperatorPickerSelect has showAllOperatorChoice=true");
  const chosenOperator =
    selectedOperatorId &&
    operators.find((opr: Operator) => opr.id === selectedOperatorId);

  if (
    chosenOperator &&
    props.selectedId &&
    props.selectedId !== chosenOperator.id
  ) {
    // We loaded from session, send a callback to parent
    // TODO - this logs a "cannot update state during render" from the parent component
    // TODO - not sure how we can trigger this, unless we move sticky operator up to a higher context, which I think I started at one point?
    console.info(
      `Got a session operator ${chosenOperator.id}, but we were passed in a different operator ${props.selectedId}. Trying to push the one from the session.`,
    );
    props.onSelectionChange &&
      props.onSelectionChange(selectedOperatorId, chosenOperator);
  }

  if (props.requireChoice && operators && operators.length === 1) {
    console.warn(
      "not returning operator picker because there's only one operator",
    ); // TODO - does this really ever happen?
    return null;
  }
  // @ts-ignore
  let allOperatorChoices: OperatorChoice[] = [];

  if (enableAllAndFavorites || enableAll) {
    allOperatorChoices.push({
      value: "ALL",
      label: "All Operators",
      obj: null,
    });
  }
  if (enableFavorites) {
    allOperatorChoices.push({
      value: "FAVORITES",
      label: "Favorite Operators",
      obj: null,
    });
    allOperatorChoices.push({
      value: "DIVIDER",
      label: "DIVIDER",
      obj: null,
      isDisabled: true,
    });
  }

  // @ts-ignore
  allOperatorChoices = allOperatorChoices.concat(
    // @ts-ignore
    _.sortBy(operators, ["name"]).map((opr: Operator) => ({
      value: opr.id,
      label: opr.name,
      obj: opr,
    })),
  );

  return (
    <Select
      data-testid={"operator-picker-select"} // Somehow this isn't visible in tests?
      className={"operator-picker-select"}
      theme={selectTheme}
      options={allOperatorChoices}
      isMulti={false}
      isClearable={false}
      onChange={(v) => {
        if (props.onSelectionChange)
          props.onSelectionChange(v!.value as OperatorPickerId, v?.obj!);
      }}
      value={allOperatorChoices.find(
        (a: OperatorChoice) =>
          a.value === selectedOperatorId /* || "NOT_SELECTED"*/,
      )}
      components={{ Option: PickerSelectOption }}
      // @ts-ignore
      enableAllAndFavorites={enableAllAndFavorites}
      enableFavorites={enableFavorites}
      enableAll={enableAll}
      getFavorite={getFavorite}
      onAddFavorite={onAddFavorite}
      onDeleteFavorite={onDeleteFavorite}
      ref={props.selectRef}
    />
  );
};

// This is just a picker with callbacks, for use outside of the PickerContext (as in "not being used with", not "not inside of in the React component tree", because the PickerContext wraps most routes)
// Use this one for multiples on a page like Reports. It expects requireOperatorChoice, doesn't handle Favorites, and probably expects showAllOperatorChoice
export const StandaloneOperatorPicker = (props: IOperatorPickerProps) => {
  const [selectedOperatorId, setOperatorId] = useState<OperatorPickerId>(
    props.default ?? props.selectedId ?? "NOT_SELECTED",
  );
  const [selectedOperatorObj, setOperatorObj] = useState<Operator>();

  const propsOnSelectionChange = props.onSelectionChange;
  const onSelectionChange = useCallback(
    (id: OperatorPickerId, op?: any) => {
      traceLogging && console.debug(`OperatorPickerSelect: Select new: ${id}`);
      setOperatorId(id);
      setOperatorObj(op);
      if (propsOnSelectionChange) propsOnSelectionChange(id, op); // TODO - remove this? and just call context setter
    },
    [setOperatorId, propsOnSelectionChange],
  );

  return (
    <BaseOperatorPicker
      {...props}
      selectedId={selectedOperatorId}
      selectedObj={selectedOperatorObj}
      onSelectionChange={onSelectionChange}
    />
  );
};

// Use this one when Operator is a main option on the page, this one is sticky and context-based.
export const ContextOperatorPicker = (props: IOperatorPickerProps) => {
  const {
    selectedId: contextOperatorId,
    selectedObj: contextOperatorObj,
    setSelection,
  } = useContext(OperatorContext);

  // slash shortcut to focus
  const selectRef = useRef<SelectInstance<OperatorChoice> | null>(null);
  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === "/" || (event.code === "KeyO" && event.altKey)) {
      const tagName = (event.target as any)?.tagName?.toLowerCase();
      if (tagName === "input" || tagName === "textarea") {
        traceLogging && console.debug(`ignoring keypress in ${tagName}`);
      } else {
        traceLogging &&
          console.debug(
            `SearchSlashListener saw a slash or alt-O from target  ${tagName}`,
          );
        selectRef.current?.focus();
        event.preventDefault();
      }
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const selectedOperatorId = props.selectedId || contextOperatorId; // || props.selectedOperatorId; // TODO - can we remove the passed-in operator?
  const selectedOperatorObj = contextOperatorObj;
  // This all seems a little complicated, but the interactions between loading operators here and potentially updating an empty selectedOperatorId in the Context are tricky.
  const propsOnSelectionChange = props.onSelectionChange;
  const onSelectionChange = useCallback(
    (id: OperatorPickerId, op?: any) => {
      traceLogging && console.debug(`OperatorPickerSelect: Select new: ${id}`);
      setSelection(id, op);
      if (propsOnSelectionChange) propsOnSelectionChange(id, op); // TODO - remove this? and just call context setter
      // unfocus select here because that feels more like a navigation
      selectRef.current?.blur();
      // That makes it less convenient to filter on an operator, tab, filter on a document type, but not many pages have that
    },
    [setSelection, propsOnSelectionChange],
  );

  useEffect(() => {
    if (props.selectedId && props.selectedId !== contextOperatorId) {
      // If an operator was passed in from the parent, and it doesn't match the context, update the context
      console.info(
        `Got a passed-in operator ${props.selectedId}, but the sticky context had ${contextOperatorId}. Trying to push the prop back up to context.`,
      );
      setSelection(props.selectedId);
    }
  }, [contextOperatorId, props.selectedId, setSelection]);

  return (
    <BaseOperatorPicker
      {...props}
      selectRef={selectRef}
      selectedId={selectedOperatorId}
      selectedObj={selectedOperatorObj}
      onSelectionChange={onSelectionChange}
    />
  );
};
