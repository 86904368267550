/** @format */
import Operator from "../../types/Operator";
import React, { useContext, useState } from "react";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { useRouteMatch } from "react-router-dom";
import {
  DocumentTypePickerId,
  IDocumentTypeContext,
  IOperatorContext,
  OperatorPickerId,
} from "../../types/PickerTypes";
import DocumentType from "../../types/DocumentType";
import useFetchJson from "../../hooks/useFetchJson";

export const OperatorContext = React.createContext<IOperatorContext>({
  selectedId: "NOT_SELECTED",
  setSelection: () => {},
  isSelected: (obj: Operator) => false,
});

export const DocumentTypeContext = React.createContext<IDocumentTypeContext>({
  selectedId: "NOT_SELECTED",
  setSelection: () => {},
  isSelected: (obj: DocumentType) => false,
});

export const MockOperatorPickerContextWrapper = ({
  children,
  selectedId,
}: {
  children: any;
  selectedId: OperatorPickerId;
}) => {
  const operatorValue: IOperatorContext = {
    selectedId,
    selectedObj: undefined,
    setSelection: (id: OperatorPickerId, op?: Operator) => {},
    isSelected: (obj: Operator) => {
      return selectedId === obj.id;
    },
  };
  return (
    <OperatorContext.Provider value={operatorValue}>
      {children}
    </OperatorContext.Provider>
  );
};
export const MockDocumentTypePickerContextWrapper = ({
  children,
  selectedId,
}: {
  children: any;
  selectedId: DocumentTypePickerId;
}) => {
  const documentTypeValue: IDocumentTypeContext = {
    selectedId,
    selectedObj: undefined,
    setSelection: (id: DocumentTypePickerId, dt?: DocumentType) => {},
    isSelected: (obj: DocumentType) => {
      return selectedId === obj.id;
    },
  };
  return (
    <DocumentTypeContext.Provider value={documentTypeValue}>
      {children}
    </DocumentTypeContext.Provider>
  );
};

// TODO - probably break this apart and just have two Wrappers that each wrap their own Contexts

const traceLogging = process.env.REACT_APP_PICKER_LOGGING || false;

export const PickerContextWrapper = ({ children }: { children: any }) => {
  const [sessionOperatorId, setSessionOperatorId] = useSessionStorage(
    "com.welltower.operatorportal.operatorpicker",
    null,
  );
  traceLogging &&
    console.debug(`OperatorContextWrapper: Session: ${sessionOperatorId}`);

  // TODO - I'm not sure we get a route because this wrapper is above all of the routes? Revisit after react-router 6?
  const { params }: { params: any } = useRouteMatch();
  traceLogging &&
    console.debug(`OperatorContextWrapper: Route: ${params?.operatorId}`);

  //const [operatorId, setOperatorId] =
  //  useState<OperatorPickerId>("NOT_SELECTED");
  const [operatorObj, setOperatorObj] = useState<Operator>();
  const operatorValue: IOperatorContext = {
    selectedId: sessionOperatorId,
    selectedObj: operatorObj,
    setSelection: (id: OperatorPickerId, op?: Operator) => {
      setSessionOperatorId(id);
      setOperatorObj(op);
    },
    isSelected: (obj: Operator) => {
      //console.debug(`sessionOperatorId=${sessionOperatorId}, testing ${obj}`);
      if (sessionOperatorId === "NOT_SELECTED") {
        return false;
      } else if (sessionOperatorId === "ALL") {
        return true;
      } else if (sessionOperatorId === "FAVORITES") {
        return false; // TODO - handle OperatorFavorites or ignore?
        // if (favorites) debugger;
        // return !!favorites?.find((f) => f.operator.id === obj.id);
      } else {
        return sessionOperatorId === obj.id;
      }
    },
  };

  const [sessionDocumentTypeId, setSessionDocumentTypeId] = useSessionStorage(
    "com.welltower.operatorportal.documenttypepicker",
    null,
  );
  traceLogging &&
    console.debug(
      `DocumentTypeContextWrapper: Session: ${sessionDocumentTypeId}`,
    );
  const [documentTypeObj, setDocumentTypeObj] = useState<DocumentType>();
  //const [favorites, setFavorites] = useState<DocumentType[]>();

  const { data: favorites, forceReload: reloadFavorites } = useFetchJson<
    DocumentType[]
  >("/api/documentTypes/my2", { keepDataAcrossReload: true });

  // TODO - handle add/remove favorites here?

  const documentTypeValue: IDocumentTypeContext = {
    selectedId: sessionDocumentTypeId,
    selectedObj: documentTypeObj,
    favorites: favorites,
    setSelection: (id: DocumentTypePickerId, dt?: DocumentType) => {
      setSessionDocumentTypeId(id);
      if (id === "FAVORITES") {
        reloadFavorites();
        setDocumentTypeObj(undefined);
      } else {
        setDocumentTypeObj(dt);
      }
    },
    isSelected: (obj: DocumentType) => {
      if (sessionDocumentTypeId === "FAVORITES") {
        traceLogging && console.debug(`favorites ${favorites?.length}`);
      }
      //console.debug(
      //  `sessionDocumentTypeId=${sessionDocumentTypeId}, testing ${obj}`,
      //);
      if (sessionDocumentTypeId === "NOT_SELECTED") {
        return false;
      } else if (sessionDocumentTypeId === "ALL") {
        return true;
      } else if (sessionDocumentTypeId === "FAVORITES") {
        //if (favorites) debugger;
        return !!favorites?.find((f) => f.id === obj.id);
      } else {
        return sessionDocumentTypeId === obj.id;
      }
    },
  };

  return (
    <OperatorContext.Provider value={operatorValue}>
      <DocumentTypeContext.Provider value={documentTypeValue}>
        {children}
      </DocumentTypeContext.Provider>
    </OperatorContext.Provider>
  );
};

export interface OperatorContextProps {
  selectedOperatorId?: OperatorPickerId;
  selectedOperatorObj?: Operator;
  // favoriteOperators;
  // allOperators ?
  setOperator: (id: OperatorPickerId, op?: Operator) => void | Promise<void>;
  isOperatorSelected: (obj: Operator) => boolean | undefined;
}

export const useOperatorContext = (): OperatorContextProps => {
  const {
    selectedId: selectedOperatorId,
    selectedObj: selectedOperatorObj,
    setSelection: setOperator,
    isSelected: isOperatorSelected,
  } = useContext(OperatorContext);

  return {
    selectedOperatorId,
    selectedOperatorObj,
    setOperator,
    isOperatorSelected,
  };
};

export function withOperatorContext<P extends OperatorContextProps>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<Omit<P, keyof OperatorContextProps>> {
  return function WithOperatorContext(
    props: Omit<P, keyof OperatorContextProps>,
  ) {
    const operatorContext = useOperatorContext();
    return <WrappedComponent {...(props as P)} {...operatorContext} />;
  };
}

export interface DocumentTypeContextProps {
  selectedDocumentTypeId?: DocumentTypePickerId;
  selectedDocumentTypeObj?: DocumentType;
  favoriteDocumentTypes?: DocumentType[];
  // allDocumentTypes ?
  setDocumentType: (
    id: DocumentTypePickerId,
    op?: DocumentType,
  ) => void | Promise<void>;
  isDocumentTypeSelected: (obj: DocumentType) => boolean | undefined;
}

export const useDocumentTypeContext = (): DocumentTypeContextProps => {
  const {
    selectedId: selectedDocumentTypeId,
    selectedObj: selectedDocumentTypeObj,
    setSelection: setDocumentType,
    favorites: favoriteDocumentTypes,
    isSelected: isDocumentTypeSelected,
  } = useContext(DocumentTypeContext);

  return {
    selectedDocumentTypeId,
    selectedDocumentTypeObj,
    setDocumentType,
    favoriteDocumentTypes,
    // TODO - should we abstract this out here? like FAVORITES ? favorites : NOT_SELECTED? [] : [obj]
    isDocumentTypeSelected,
  };
};

export function withDocumentTypeContext<P extends DocumentTypeContextProps>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<Omit<P, keyof DocumentTypeContextProps>> {
  return function WithDocumentTypeContext(
    props: Omit<P, keyof DocumentTypeContextProps>,
  ) {
    const documentTypeContext = useDocumentTypeContext();
    return <WrappedComponent {...(props as P)} {...documentTypeContext} />;
  };
}
