/** @format */

import React from "react";
import { Input, Label } from "reactstrap";
import "./ToggleSwitch.scss";

interface IToggleSwitchProps {
  disabled?: boolean;
  wait?: boolean;
  isChecked?: boolean;
  onValueChanged?: Function;
  title?: string;
}

interface IToggleSwitchState {
  isChecked: boolean;
}

export default class ToggleSwitch extends React.Component<
  IToggleSwitchProps,
  IToggleSwitchState
> {
  public static defaultProps: Partial<IToggleSwitchProps> = {
    disabled: false,
  };

  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      isChecked: false,
    };
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.isChecked !== prevState.isChecked) {
      return { isChecked: nextProps.isChecked };
    } else {
      return null;
    }
  }

  handleChange() {
    // TODO: Enables calling API endpoint when setting changed
    if (this.props.onValueChanged) {
      this.props.onValueChanged(!this.state.isChecked);
    }
    this.setState({ isChecked: !this.state.isChecked });
  }

  render() {
    return (
      <Label
        className={`toggle-switch ${this.props.wait ? "cursor-wait" : ""}`}
        title={this.props.title}
      >
        <Input
          type="checkbox"
          checked={this.state.isChecked}
          onChange={this.handleChange}
          disabled={this.props.disabled}
          value={this.state.isChecked.toString()}
        />
        <div className="slider" />
      </Label>
    );
  }
}
