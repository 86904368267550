/** @format */

import React from "react";
import { withOktaAuth } from "@okta/okta-react";

import { operatorsUrl } from "../../util/ApiUrlUtils";

import { deleteWithToken, fetchJsonWithToken } from "../../util/FetchUtils";
import { Col, Container, Row, Table } from "reactstrap";
import OperatorEditFormButton from "../../components/operator-edit-form/OperatorEditFormButton";
import "./Operators.scss";
import Operator from "../../types/Operator";
import _ from "lodash";
import { DeveloperModeOnly } from "../../components/DeveloperMode";
import { canEditOperators } from "../../util/OktaUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Operators extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      operators: [],
    };

    this.renderOperator = this.renderOperator.bind(this);
    this.loadOperators = this.loadOperators.bind(this);
    this.deleteOperator = this.deleteOperator.bind(this);
  }

  async componentDidMount() {
    this.loadOperators();
  }

  async loadOperators() {
    fetchJsonWithToken(operatorsUrl(), this.props.authState).then((data) => {
      this.setState({
        operators: data,
      });
    });
  }

  async deleteOperator(operator: Operator, props: any) {
    if (!operator.id) return;

    const url = `/api/operators/${operator.id}`;
    const auth = this.props.authState;
    return deleteWithToken(url, auth).then((response) => {
      this.setState((prevState: any) => ({
        operators: prevState.operators.filter(
          (o2: any) => o2.id !== operator.id,
        ),
      }));
      return response;
    }); // Let caller catch error to display in UI
  }

  renderOperator(operator: any, index: number, canEdit: boolean) {
    const salesforceIDStatus = (id?: string) => {
      if (!id || id.length === 0) {
        return (
          <FontAwesomeIcon
            icon={"minus-circle"}
            size={"sm"}
            className={`text-warning`}
            title={"No Salesforce ID"}
          />
        );
      } else if (/001[A-Za-z0-9]{15}/.test(id)) {
        return (
          <FontAwesomeIcon
            icon={"check-circle"}
            size={"sm"}
            className={`text-success`}
            title={id}
          />
        );
      } else
        return (
          <FontAwesomeIcon
            icon={"times-circle"}
            size={"sm"}
            className={`text-danger`}
            title={id}
          />
        );
    };
    const vantageIDStatus = (id?: string) => {
      if (!id || id.length === 0) {
        return (
          <FontAwesomeIcon
            icon={"minus-circle"}
            size={"sm"}
            className={`text-warning`}
            title={"No Vantage ID"}
          />
        );
      } else if (/[A-Za-z0-9]{32}/.test(id) || /O-[0-9]{2,3}/.test(id)) {
        return (
          <FontAwesomeIcon
            icon={"check-circle"}
            size={"sm"}
            className={`text-success`}
            title={id}
          />
        );
      } else
        return (
          <FontAwesomeIcon
            icon={"times-circle"}
            size={"sm"}
            className={`text-danger`}
            title={id}
          />
        );
    };

    return (
      <tr key={operator.id}>
        <DeveloperModeOnly>
          <td className={"text-muted"}>{operator.id}</td>
        </DeveloperModeOnly>
        <td>{operator.name}</td>
        <td>{operator.externalName}</td>
        <td>{operator.oktaGroupName}</td>
        <td>{operator.jdeOperatorCatCode08Code}</td>
        <td>{operator.yardiOperator}</td>
        <td>{salesforceIDStatus(operator.salesforceAccountId)}</td>
        <td>{vantageIDStatus(operator.vantageOperatorId)}</td>
        <td>
          {canEdit && (
            <OperatorEditFormButton
              operator={operator}
              authState={this.props.authState}
              onSaveOperator={this.loadOperators}
              deleteOperator={this.deleteOperator}
            />
          )}
        </td>
      </tr>
    );
  }

  render() {
    const canEdit = canEditOperators(this.props.authState);
    return (
      <Container className="wtop-accountant-operators">
        <Row className="header-row">
          <Col>
            {canEdit && (
              <OperatorEditFormButton
                authState={this.props.authState}
                onSaveOperator={this.loadOperators}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  <DeveloperModeOnly>
                    <th>ID</th>
                  </DeveloperModeOnly>
                  <th>Name</th>
                  <th>External Name</th>
                  <th>Okta Group Name</th>
                  <th>JDE Cat Code</th>
                  <th>Yardi Operator</th>
                  <th>Salesforce ID</th>
                  <th>Vantage ID</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {_.sortBy(this.state.operators, "name").map((o, idx) =>
                  this.renderOperator(o, idx, canEdit),
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withOktaAuth(Operators);
