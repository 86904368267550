/** @format */

import React from "react";
import { Formik, FormikProps, FormikValues } from "formik";
import {
  calculateEndMonthOptions,
  calculateStartMonthOptions,
} from "../../util/DateUtils";
import WrappedTextInput from "../../components/form/WrappedTextInput";
import WrappedSelectInput from "../../components/form/WrappedSelectInput";
import { Button } from "reactstrap";
import WrappedNumericInput from "../../components/form/WrappedNumericInput";
import WrappedCheckboxInput from "../../components/form/WrappedCheckboxInput";

const SegmentMappingForm = (props: any) => {
  const {
    initialValues,
    welltowerAccounts,
    welltowerBusinessUnits,
    segmentCodes,
    onSubmitMapping,
    onDeleteMapping,
    currentStartDate,
    currentEndDate,
    active,
  } = props;
  const startMonthOptions = calculateStartMonthOptions(currentStartDate);
  const endMonthOptions = calculateEndMonthOptions(
    currentStartDate,
    currentEndDate,
  );
  return (
    <Formik
      initialValues={initialValues || {}}
      onSubmit={(values, actions) => onSubmitMapping(values, actions)}
      validate={(values: any) => {
        let errors: any = {};

        if (!values.subledgerCode) {
          errors.subledgerCode = "Please enter a segment.";
        }

        if (!values.subledgerValue) {
          errors.subledgerValue = "Please enter a segment value.";
        }

        if (!values.multiplier) {
          errors.multiplier = "Please enter a multiplier.";
        }

        if (!values.startMonth) {
          errors.startMonth = "Please enter a mapping period.";
        }

        if (!values.account) {
          errors.account = "Please enter a welltower account.";
        }

        if (!values.businessUnitCode) {
          errors.businessUnitCode = "Please enter a business unit.";
        }

        return errors;
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => {
        return (
          <form
            onSubmit={formikProps.handleSubmit}
            name={"Segment Mapping Form"}
          >
            <div className="form-group">
              <WrappedSelectInput
                id={`businessUnitCode`}
                label={"Welltower BU"}
                value={
                  welltowerBusinessUnits
                    ? welltowerBusinessUnits.find(
                        (it: any) =>
                          it.value === formikProps.values.businessUnitCode,
                      )
                    : null
                }
                required={true}
                options={welltowerBusinessUnits}
                disabled={initialValues && !!initialValues.id}
                clearable={initialValues && !!initialValues.id}
              />
            </div>
            <div className="form-group">
              <WrappedSelectInput
                id={`account`}
                label={"Account"}
                value={
                  welltowerAccounts
                    ? welltowerAccounts.find(
                        (it: any) => it.value === formikProps.values.account,
                      )
                    : null
                }
                required={true}
                options={welltowerAccounts}
                disabled={initialValues && !!initialValues.id}
                clearable={initialValues && !!initialValues.id}
              />
            </div>
            <div className="form-group">
              <WrappedNumericInput
                id="multiplier"
                required={true}
                label="Multiplier"
                fieldProps={{
                  type: "number",
                  step: "0.00000001",
                  max: "1",
                  min: "0",
                }}
              />
            </div>
            <div className="form-group">
              <WrappedSelectInput
                id={`subledgerCode`}
                label={"Segment"}
                value={segmentCodes?.find(
                  (it: any) => it.value === formikProps.values.subledgerCode,
                )}
                required={true}
                options={segmentCodes}
              />
            </div>
            <div className="form-group">
              <WrappedTextInput
                id="subledgerValue"
                required={true}
                label="Segment Value"
              />
            </div>

            <div className="form-group">
              <WrappedCheckboxInput
                id={`active`}
                label={"Active"}
                value={active}
                disabled
              />
            </div>
            <div className="form-group">
              <WrappedSelectInput
                id={`startMonth`}
                label={"Start Month"}
                value={
                  startMonthOptions
                    ? startMonthOptions.find(
                        (it: any) => it.value === formikProps.values.startMonth,
                      )
                    : null
                }
                required={true}
                options={startMonthOptions}
              />
              <WrappedSelectInput
                id={`endMonth`}
                label={"End Month"}
                value={
                  endMonthOptions
                    ? endMonthOptions.find(
                        (it: any) => it.value === formikProps.values.endMonth,
                      )
                    : null
                }
                required={false}
                options={endMonthOptions}
              />
            </div>
            {formikProps.errors?.submission && (
              <div
                className="invalid-feedback"
                style={{ display: "inline-block" }}
              >
                {formikProps.errors.submission as string}
              </div>
            )}

            {initialValues && initialValues.id && !initialValues.lastSeen && (
              <Button
                color={"danger"}
                onClick={() => {
                  onDeleteMapping(initialValues);
                }}
                className={"float-left wtop-btn"}
              >
                Delete
              </Button>
            )}
            <Button
              type={"submit"}
              color={"primary"}
              disabled={!formikProps.isValid || formikProps.isSubmitting}
              className={"float-right wtop-btn"}
            >
              Submit
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default SegmentMappingForm;
