/** @format */

import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom-v5-compat";
import Requirement from "../../types/Requirement";

const AccountantUnmappedItemSummary = ({
  operatorId,
  unmappedAccounts,
  unmappedProperties,
  unmappedColumns,
  requirement,
  backLink,
  backLinkName,
}: {
  operatorId: number;
  unmappedAccounts: Array<any>;
  unmappedProperties: Array<any>;
  unmappedColumns: Array<any>;
  requirement: Requirement;
  backLink: string;
  backLinkName: string;
}) => {
  if (!operatorId) {
    return null;
  }

  return (
    <React.Fragment>
      {unmappedAccounts.length > 0 && (
        <Row className={"mb-3"}>
          <Col sm={"4"} className={"offset-sm-4"}>
            <Link
              className={"mappings-link"}
              data-testid={"unmapped-accounts-link"}
              to={"/account-mappings"}
              state={{
                // TODO - move these to querystring params too
                currentOperatorId: operatorId,
                currentOperator: requirement.operator,
                currentMappingSet: requirement.accountMappingSet,
                backLink: backLink,
                backLinkName: backLinkName,
              }}
            >
              <Button block className={"py-3"} color={"primary"}>
                {`ACCOUNT DETAILS (${unmappedAccounts.length})`}
              </Button>
            </Link>
          </Col>
        </Row>
      )}
      {unmappedProperties.length > 0 && (
        <Row className={"mb-3"}>
          <Col sm={"4"} className={"offset-sm-4"}>
            <Link
              className={"mappings-link"}
              data-testid={"unmapped-properties-link"}
              to={"/entity-mappings"}
              state={{
                // TODO - move these to querystring params too
                currentOperatorId: operatorId,
                currentOperator: requirement.operator,
                currentMappingSet: requirement.entityMappingSet,
                backLink: backLink,
                backLinkName: backLinkName,
              }}
            >
              <Button block className={"py-3"} color={"primary"}>
                {`PROPERTY DETAILS (${unmappedProperties.length})`}
              </Button>
            </Link>
          </Col>
        </Row>
      )}
      {unmappedColumns.length > 0 && (
        <Row className={"mb-3"}>
          <Col sm={"4"} className={"offset-sm-4"}>
            <Link
              className={"mappings-link"}
              data-testid={"unmapped-columns-link"}
              to={"/column-mappings"}
              state={{
                // TODO - move these to querystring params too
                currentOperatorId: operatorId,
                currentOperator: requirement.operator,
                currentMappingSet: requirement.entityMappingSet,
                currentDocumentType: requirement.documentType,
                backLink: backLink,
                backLinkName: backLinkName,
              }}
            >
              <Button block className={"py-3"} color={"primary"}>
                {`Unmapped Columns (${unmappedColumns.length})`}
              </Button>
            </Link>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default AccountantUnmappedItemSummary;
