/** @format */

import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { allOperatorUsersURL } from "../../api/OperatorAPI";
import OktaUsersTable from "./OktaUsersTable";
import { Col, Row } from "reactstrap";
import { canSeeUserDetails } from "../../util/OktaUtils";
import OktaUser from "../../types/OktaUser";
import useFetchJson from "../../hooks/useFetchJson";

const AllOperatorUsers = () => {
  const { authState } = useOktaAuth();

  const { data, loading } = useFetchJson<OktaUser[]>(allOperatorUsersURL());
  const canSeeDetails = canSeeUserDetails(authState);

  return loading ? (
    <p>loading...</p>
  ) : (
    <>
      <Row>
        <Col md={"8"}>
          <h3>Users</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <OktaUsersTable
            oktaUsers={data!}
            showDetails={canSeeDetails}
            userActions={false}
            showOperator={true}
          />
        </Col>
      </Row>
    </>
  );
};

export default AllOperatorUsers;
