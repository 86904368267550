/** @format */

import React from "react";
import { Field, Formik, FormikProps } from "formik";
import { Button } from "reactstrap";
import {
  buSettingsVantageCapacityUrl,
  updateBuSettingUrl,
} from "../../util/ApiUrlUtils";
import { canManageWelltowerBusinessUnitCensusSettings } from "../../util/OktaUtils";
import { useOktaMutation } from "../../hooks/useOktaMutation";
import { useOktaAuth } from "@okta/okta-react";
import { useOktaQuery } from "../../hooks/useOktaQuery";
import { LoadingSpinner } from "../LoadingSpinners";

const WelltowerBuSettingEditForm = ({ buSetting, closeModal, onSave }: any) => {
  const { authState } = useOktaAuth();

  const {
    data: vantageCapacity,
    isLoading: vantageCapacityLoading,
    // TODO isError: vantageCapacityError,
  } = useOktaQuery(buSettingsVantageCapacityUrl(buSetting.vantageCode), {
    enabled: !!buSetting.vantageCode && buSetting.vantageCode !== "NPXX",
  });

  // TODO - why did Henry make this a PATCH? There's only one property on the Settings object.
  const mutation = useOktaMutation(updateBuSettingUrl(buSetting.buCode), {
    method: "PATCH",
    onSuccess: (data, variables, context) => {
      onSave && onSave();
      closeModal();
    },
  });

  return (
    <Formik
      initialValues={{ buSetting: buSetting }}
      onSubmit={(values: any, actions) => {
        const patchObj: any = {};
        Object.keys(values.buSetting).forEach((key) => {
          if (values.buSetting[key] !== buSetting[key]) {
            patchObj[key] = values.buSetting[key];
          }
        });
        mutation.mutate(patchObj, {
          // TODO - on Error?
          onSettled: (data, variables, context) => {
            actions.setSubmitting(false);
          },
        });
      }}
      validate={(values: any) => {
        let errors: any = { buSetting: {} };
        if (Object.keys(errors.buSetting).length > 0) {
          return errors;
        } else {
          return {};
        }
      }}
    >
      {(props: FormikProps<any>) => {
        return (
          <div className={"editForm"}>
            <h3 className="propertyName">Business Unit Setting</h3>
            <form className="editFormForm" onSubmit={props.handleSubmit}>
              <p>
                {buSetting.buCode} - {buSetting.buName}
              </p>

              {buSetting.vantageCode && buSetting.vantageCode !== "NPXX" && (
                <>
                  <p>Vantage Code: {buSetting.vantageCode}</p>
                  <p>
                    Vantage Capacity:{" "}
                    {vantageCapacityLoading ? (
                      <LoadingSpinner />
                    ) : (
                      vantageCapacity
                    )}
                  </p>
                  <div className="form-group">
                    <label>
                      <Field
                        name={`buSetting.adjustCensusToVantageCapacity`}
                        type={"checkbox"}
                        disabled={
                          !canManageWelltowerBusinessUnitCensusSettings(
                            authState,
                          )
                        }
                      />{" "}
                      Adjust Census To Vantage Capacity
                    </label>
                  </div>
                </>
              )}
              <Button
                type={"submit"}
                color={"primary"}
                disabled={props.isSubmitting}
                className={"float-right wtop-btn"}
              >
                Save Updates
              </Button>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default WelltowerBuSettingEditForm;
