/** @format */
import { useLocation } from "react-router-dom-v5-compat";
import { useRef } from "react";
import { buildAdHocTokenAuthState } from "../util/OktaUtils";

const useAdHocTokenAuth = () => {
  let query = new URLSearchParams(useLocation().search);
  //console.log(`fakeAuth token from URL: ${query.get("token")}`);
  const fakeAuth = buildAdHocTokenAuthState(query.get("token") ?? "");
  //console.log(`fakeAuth token built: ${fakeAuth?.accessToken}`);

  return useRef(fakeAuth).current; // useRef essentially memoizes so we get a constant constructed object that useFetchJson can use as an effect param
};

export default useAdHocTokenAuth;
