/** @format */

import React from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RequirementDueDate from "../../types/RequirementDueDate";
import _ from "lodash";
import { AbstractAuthState } from "../../util/OktaUtils";

interface IRddPeriodProps {
  rddPeriod: any;
  welltowerAccounts?: any;
  welltowerBusinessUnits?: any;
  authState: AbstractAuthState;
  receiveUpdatedRequirementDueDate: any;
  keepExpanded?: boolean;
  location?: any;
}

interface IRddPeriodState {
  isExpanded: any;
  reload: boolean;
}

export default class RddPeriod extends React.Component<
  IRddPeriodProps,
  IRddPeriodState
> {
  constructor(props: IRddPeriodProps) {
    super(props);
    this.renderRddCard = this.renderRddCard.bind(this);
    this.state = { isExpanded: false, reload: false };
  }

  componentDidMount() {
    const { rddPeriod } = this.props;
    this.setState({
      isExpanded:
        rddPeriod &&
        this.hasIncompleteRdds(rddPeriod.dashboardRequirementDueDates) &&
        rddPeriod.dashboardRequirementDueDates.length > 0,
    });
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<IRddPeriodProps>,
    nextContext: any,
  ) {
    const { rddPeriod } = nextProps;
    if (rddPeriod.id !== this.props.rddPeriod.id) {
      this.setState({
        isExpanded:
          rddPeriod &&
          this.hasIncompleteRdds(rddPeriod.dashboardRequirementDueDates) &&
          rddPeriod.dashboardRequirementDueDates.length > 0,
      });
    }
  }

  toggle = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  hasIncompleteRdds = (rdds: Array<RequirementDueDate>) => {
    // TODO - this should probably be operatorComplete for operator users
    // TODO - and should probably count FAILED_WELLTOWER as incomplete for Welltower
    // TODO - and we'll probably redo this page and get rid of the collapsable months
    return _.find(rdds, (rdd: RequirementDueDate) => {
      return rdd.status.welltowerComplete === false;
    });
  };

  renderRddCard(rdd: any) {
    return;
  }

  render() {
    const { rddPeriod } = this.props;
    const rdds = rddPeriod ? rddPeriod.dashboardRequirementDueDates : [];
    if (!rddPeriod || !rddPeriod.dashboardRequirementDueDates) {
      return null;
    }

    const hasIncompleteRdds = this.hasIncompleteRdds(rdds);

    return (
      <div className={"rdd-period py-5"} data-testid={"rdd-period"}>
        <Row
          className={`rdd-period-heading ${this.props.keepExpanded ? "" : "pointer"}`}
          onClick={this.props.keepExpanded ? undefined : this.toggle}
        >
          <Col sm={"3 text-left"}>
            {hasIncompleteRdds && (
              <FontAwesomeIcon size={"2x"} icon={"calendar-alt"} />
            )}
            {!hasIncompleteRdds &&
              rddPeriod.dashboardRequirementDueDates.length > 0 && (
                <FontAwesomeIcon size={"2x"} icon={"check"} color={"#28a745"} />
              )}
            <h4 className={"d-inline ml-3 font-weight-lighter"}>
              {rddPeriod.displayName}
            </h4>
          </Col>
          {!this.props.keepExpanded && (
            <Col className={"text-left"}>
              <Button color={"link"} className={"px-0 ml-n3 font-weight-light"}>
                {this.state.isExpanded ? "Collapse month" : "Expand month"}
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Collapse isOpen={this.props.keepExpanded || this.state.isExpanded}>
              {rddPeriod.dashboardRequirementDueDates.length === 0 && (
                <p className={"mt-3"}>No requirements for this period.</p>
              )}
              {rdds.map(this.renderRddCard)}
            </Collapse>
          </Col>
        </Row>
      </div>
    );
  }
}
