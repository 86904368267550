/** @format */
import React from "react";
import { Col, Row } from "reactstrap";
import { formatDateShort } from "../../util/DateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RequirementDueDateDashboardDto from "../../types/RequirementDueDateDashboardDto";
import { DeveloperModeOnly } from "../../components/DeveloperMode";

export const RequirementSubmissionStats = ({
  rdddDto,
}: {
  rdddDto: RequirementDueDateDashboardDto;
}) => (
  <Row className={"requirement-row"}>
    <Col md={"2"}>
      <DeveloperModeOnly>
        <span className={"text-muted"}>{rdddDto.id} </span>
      </DeveloperModeOnly>
      {rdddDto.requirementName}
    </Col>
    <Col md={"2"} className={"text-left"}>
      {formatDateShort(rdddDto.dueDate) || "-"}
      {rdddDto.locked ? (
        <FontAwesomeIcon
          icon={["fas", "lock"]}
          size={"sm"}
          data-testid="rdd-locked-icon"
          className="rdd-lock-icon"
          style={{
            marginLeft: "0.375rem",
            verticalAlign: "unset",
          }}
        />
      ) : (
        <FontAwesomeIcon
          icon={["fas", "lock-open"]}
          size={"sm"}
          data-testid="rdd-locked-icon"
          className="rdd-lock-icon"
          style={{
            marginLeft: "0.375rem",
            verticalAlign: "unset",
          }}
        />
      )}
    </Col>
    <Col md={"2"} className={"text-center"}>
      {rdddDto.failedSubmissionCount || "-"}
    </Col>
    <Col md={"2"} className={"text-center"}>
      {formatDateShort(rdddDto.latestAcceptedDate) || "-"}
    </Col>
    <Col md={"2"} className={"text-center"}>
      {formatDateShort(rdddDto.latestLoadedDate) || "-"}
    </Col>
    <Col md={"2"}>{rdddDto.welltowerStatusLong}</Col>
  </Row>
);
