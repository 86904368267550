/** @format */

import React from "react";
import { Button, Col, Input } from "reactstrap";
import { saveFileJDEBatch } from "../../api/SubmittedFileAPI";
import { AbstractAuthState, getUserLogin } from "../../util/OktaUtils";
import DocumentType from "../../types/DocumentType";
import { recordsBatchNumber } from "../../util/StatusUtils";
import WelltowerAccountingLoad from "../../types/WelltowerAccountingLoad";

interface IJDEInfoProps {
  requirementDueDateId: number;
  documentType: DocumentType;
  fileId: number;
  authState: AbstractAuthState;
}

interface IJDEInfoState {
  jdeBatchNumber: string;
  glDate: any;
  jdeInfoSaved: boolean;
}

export default class JDEInfo extends React.Component<
  IJDEInfoProps,
  IJDEInfoState
> {
  constructor(props: IJDEInfoProps) {
    super(props);
    this.state = {
      jdeBatchNumber: "",
      glDate: undefined, //new Date(),
      jdeInfoSaved: false,
    };
  }

  async successClick() {
    let payload = {
      uploadIdentifier: this.state.jdeBatchNumber,
      uploadTime: new Date(),
      uploadUserId: getUserLogin(this.props.authState),
      status: "SUCCESS",
    };
    await saveFileJDEBatch(
      payload as WelltowerAccountingLoad,
      this.props.requirementDueDateId,
      this.props.fileId,
      this.props.authState,
    );
    this.setState({ jdeInfoSaved: true });
  }
  async failClick() {
    let payload = {
      uploadIdentifier: this.state.jdeBatchNumber,
      uploadTime: new Date(),
      uploadUserId: getUserLogin(this.props.authState),
      status: "FAILED",
    };
    await saveFileJDEBatch(
      payload as WelltowerAccountingLoad,
      this.props.requirementDueDateId,
      this.props.fileId,
      this.props.authState,
    );
    this.setState({ jdeInfoSaved: true });
  }
  render() {
    const requireBatchNumber = recordsBatchNumber(
      this.props.documentType,
      undefined,
      { fileId: this.props.fileId },
    );

    if (this.state.jdeInfoSaved) {
      return (
        <div data-botid={"jdeBatchSaved"} data-rpaid={"batch-saved"}>
          Batch saved
        </div>
      );
    }

    const { fileId } = this.props;
    return (
      <React.Fragment>
        <Col>
          {requireBatchNumber ? (
            <Input
              name={`${fileId}-jdebatch`}
              data-botid={"jdeBatchNumber"}
              data-rpaid={`file-${fileId}-batch`}
              onChange={(e) => {
                this.setState({ jdeBatchNumber: e.target.value });
              }}
            />
          ) : null}
        </Col>
        <Col>
          <Button
            data-botid={"jdeInfoSubmitButton"}
            data-rpaid={`file-${fileId}-success`}
            color={"success"}
            block={true}
            disabled={requireBatchNumber && !this.state.jdeBatchNumber}
            onClick={() => this.successClick()}
          >
            Success
          </Button>
        </Col>
        <Col>
          <Button
            data-botid={"jdeInfoFailButton"}
            data-rpaid={`file-${fileId}-fail`}
            color={"danger"}
            block={true}
            onClick={() => this.failClick()}
          >
            Fail
          </Button>
        </Col>
      </React.Fragment>
    );
  }
}
