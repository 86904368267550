/** @format */
import React from "react";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { ErrorMessage, Field, FormikProps } from "formik";
import WrappedSelectInput from "../../form/WrappedSelectInput";
import ExternalSystem from "../../../types/ExternalSystem";

interface IUnmappedAccountProps {
  unmappedItem: any;
  index: number;
  operatorAccountGroupings: Array<any>;
  destination?: ExternalSystem;
  welltowerAccounts?: Array<any>;
  isWelltowerAccountant: boolean;
  formikProps: FormikProps<any>;
}

export default class UnmappedAccount extends React.Component<
  IUnmappedAccountProps,
  any
> {
  constructor(props: IUnmappedAccountProps) {
    super(props);
    this.renderEditableFields = this.renderEditableFields.bind(this);
  }

  renderEditableFields() {
    let {
      unmappedItem,
      formikProps,
      index,
      operatorAccountGroupings,
      isWelltowerAccountant,
    } = this.props;

    return [
      <Col
        key={`unmappedItem.${index}.${index}`}
        sm={!isWelltowerAccountant ? "3" : ""}
      >
        <WrappedSelectInput
          id={`unmappedItems.${index}.operatorAccountGrouping`}
          label={"Operator Caption"}
          value={operatorAccountGroupings.find(
            (it: any) => it.value === unmappedItem.operatorAccountGrouping,
          )}
          required={true}
          options={operatorAccountGroupings}
          onChange={formikProps.setFieldValue}
          onBlur={formikProps.setFieldTouched}
        />
      </Col>,
      <Col
        key={`unmappedItem.${index}.${index + 1}`}
        sm={!isWelltowerAccountant ? "3" : ""}
      >
        <label
          htmlFor={`unmappedItems.${index}.additionalInformation`}
          className={"label-required"}
        >
          Detailed Description
        </label>
        <Field
          name={`unmappedItems.${index}.additionalInformation`}
          validate={(value: string) => {
            if (value && value.length > 255) {
              return "Must be less than 256 characters";
            }
          }}
        >
          {({ field }: any) => (
            <>
              <Input
                {...field}
                value={unmappedItem.additionalInformation || ""}
                title={unmappedItem.additionalInformation || ""}
              />
              <ErrorMessage
                name={`unmappedItems.${index}.additionalInformation`}
                component={"div"}
                className={"input-feedback"}
              />
            </>
          )}
        </Field>
      </Col>,
    ];
  }

  render() {
    let {
      unmappedItem,
      formikProps,
      index,
      welltowerAccounts,
      destination,
      isWelltowerAccountant,
    } = this.props;

    const destinationAccounts = welltowerAccounts?.filter(
      (a) =>
        !destination ||
        a.value === "IGNORE" ||
        !a.object?.source?.name ||
        a.object?.source?.name === destination.name,
    );

    if (
      !this.props.operatorAccountGroupings ||
      this.props.operatorAccountGroupings.length === 0
    )
      return null;
    return (
      <Card key={`${unmappedItem.id}-${index}`}>
        <CardBody className={"px-0 py-3"}>
          <Container>
            <Row className="unmapped-account">
              <Col sm={!isWelltowerAccountant ? "2" : ""}>
                <label htmlFor={`unmappedItems.${index}.identifier`}>
                  Account
                </label>
                <Field name={`unmappedItems.${index}.identifier`}>
                  {(field: any) => (
                    <Input
                      {...field}
                      value={unmappedItem.identifier || ""}
                      readOnly={true}
                      title={unmappedItem.identifier || ""}
                    />
                  )}
                </Field>
              </Col>

              <Col sm={!isWelltowerAccountant ? "2" : ""}>
                <label htmlFor={`unmappedItems.${index}.description`}>
                  Description
                </label>
                <Field
                  type={"text"}
                  name={`unmappedItems.${index}.description`}
                >
                  {({ field }: any) => (
                    <Input
                      {...field}
                      value={unmappedItem.description || ""}
                      readOnly={true}
                      title={unmappedItem.description || ""}
                    />
                  )}
                </Field>
              </Col>

              <Col sm={!isWelltowerAccountant ? "2" : ""}>
                <label htmlFor={`unmappedItems.${index}.currentMonthActivity`}>
                  Current Activity
                </label>
                <Field name={`unmappedItems.${index}.currentMonthActivity`}>
                  {(field: any) => (
                    <Input
                      {...field}
                      readOnly={true}
                      value={`$${
                        unmappedItem.currentMonthActivity
                          ? unmappedItem.currentMonthActivity
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                          : "0.00"
                      }`}
                    />
                  )}
                </Field>
              </Col>
              {!isWelltowerAccountant && this.renderEditableFields()}
            </Row>
            {isWelltowerAccountant && (
              <Row className={"mt-3"}>
                {this.renderEditableFields()}

                <Col>
                  <WrappedSelectInput
                    id={`unmappedItems.${index}.welltowerAccount`}
                    label={"Welltower Account"}
                    value={
                      destinationAccounts
                        ? destinationAccounts.find(
                            (it: any) =>
                              it.value === unmappedItem.welltowerAccount,
                          )
                        : null
                    }
                    required={true}
                    options={destinationAccounts}
                    onChange={formikProps.setFieldValue}
                    onBlur={formikProps.setFieldTouched}
                  />
                </Col>
              </Row>
            )}
          </Container>
        </CardBody>
      </Card>
    );
  }
}
