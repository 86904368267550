/** @format */

import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RequirementDueDate from "../../types/RequirementDueDate";
import { putWithToken } from "../../util/FetchUtils";
import { AbstractAuthState, canLockDueDates } from "../../util/OktaUtils";

const setLocked = (
  rdd: RequirementDueDate,
  auth: AbstractAuthState,
  locked: boolean,
) =>
  putWithToken(
    `/api/operators/${rdd.requirement.operator.id}/requirements/${rdd.requirement.id}/dueDates/${rdd.id}/locked`,
    auth,
    locked.toString(),
  );

const RddLockStatus = ({
  rdd,
  callback,
  onError,
}: {
  rdd: RequirementDueDate;
  callback: any;
  onError: any;
}) => {
  const { authState } = useOktaAuth();

  if (!authState?.isAuthenticated) return null;
  const canLock = canLockDueDates(
    authState,
    rdd.requirement.operator,
    rdd.requirement,
  );

  const onclick = canLock
    ? async () =>
        setLocked(rdd, authState, !rdd.locked)
          .then(callback)
          .catch((error) => {
            console.error(error);
            onError("An error occurred");
          })
    : undefined;

  return rdd.locked ? (
    <FontAwesomeIcon
      icon={["fas", "lock"]}
      size={"lg"}
      data-testid="rdd-locked-icon"
      className={`rdd-lock-icon ${canLock ? "cursor-pointer" : ""}`}
      onClick={onclick}
      style={{
        marginRight: "5px" /* compensate for the shackle on the open padlock */,
      }}
    />
  ) : (
    <FontAwesomeIcon
      icon={["fas", "lock-open"]}
      size={"lg"}
      data-testid="rdd-unlocked-icon"
      className={`rdd-lock-icon ${canLock ? "cursor-pointer" : ""}`}
      onClick={onclick}
    />
  );
};

export default RddLockStatus;
