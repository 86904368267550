/** @format */

import { useEffect, useState } from "react";
import { fetchPageableAllWithToken } from "../util/FetchUtils";
import { AbstractAuthState } from "../util/OktaUtils";

// Fetching data with hooks pattern from https://www.robinwieruch.de/react-hooks-fetch-data/
// Then extracted to a custom hook https://reactjs.org/docs/hooks-custom.html

/** @deprecated TODO - replace with useOktaQuery() that wraps react-query */
const useFetchJsonWithLoading = (
  url: string | null | undefined, // pass null for a no-op because you can't conditionally call this hook if you haven't loaded a needed ID yet
  auth: AbstractAuthState,
): {
  data: any;
  loading: boolean;
  error: any;
  forceReload: Function;
  setData: any;
} => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [forceReloadCount, setForceReloadCount] = useState(0);

  // If you optimize an effect by providing deps, we need to construct a fake dep
  // to use to trigger a reload. A simple counter seems to work.

  useEffect(() => {
    // Need to declare this async arrow function here and then call it, because
    // the effect function can't be async.
    const fetchData = async (
      url: string | null | undefined,
      auth: AbstractAuthState,
    ) => {
      console.debug(`Fetching data from ${url}`);
      if (!url) return Promise.reject();
      return fetchPageableAllWithToken(url, auth);
    };
    fetchData(url, auth)
      .then((d) => {
        setData(d);
        setError(undefined);
      })
      .catch((error) => {
        setData([]);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
    // Return a cleanup function to set the loading flag if any deps change.
    return () => {
      setLoading(true);
      setData([]);
      setError(undefined);
    };
  }, [auth, url, forceReloadCount]);

  const forceReload = () => {
    setLoading(true);
    setForceReloadCount(forceReloadCount + 1);
  };

  return { data, loading, error, forceReload, setData };
};

export default useFetchJsonWithLoading;
