/** @format */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentType from "../../types/DocumentType";
import { formatDate } from "../../util/DateUtils";
import { DeveloperModeOnly } from "../DeveloperMode";
import RequirementDueDate from "../../types/RequirementDueDate";

export const DocumentTypeIcon = ({
  documentType,
}: {
  documentType: DocumentType;
}) => {
  if (!documentType) {
    return null;
  }

  let icon: any = ["fas", "file-lines"];
  switch (documentType.id) {
    case "TRIAL_BALANCE":
      icon = "file-invoice";
      break;
    case "MONTHLY_CENSUS":
    case "WEEKLY_CENSUS":
      icon = ["fas", "users"];
      break;
    case "CAP_EX":
      icon = ["fas", "file-lines"];
      break;
  }

  return (
    <FontAwesomeIcon className="mr-3" size="2x" icon={icon} color="#000" />
  );
};

const RddCardHeader = ({
  rdd,
  documentType,
  rddName = "",
  rddDueDate,
}: {
  rdd?: RequirementDueDate;
  documentType: DocumentType;
  rddName: string | undefined;
  rddDueDate: Date;
}) => {
  return (
    <div className={"mb-4"}>
      <DocumentTypeIcon documentType={documentType} />
      {rdd && (
        <DeveloperModeOnly>
          <span className={"text-muted"}>
            {rdd.requirement.operator.id}.{rdd.requirement.id}.{rdd.id}{" "}
          </span>
        </DeveloperModeOnly>
      )}
      <h5 className={"d-inline font-weight-bold mr-3"}>{rddName}</h5>
      <span className={"text-muted"}>Due {formatDate(rddDueDate)}</span>
    </div>
  );
};

export default RddCardHeader;
