/** @format */

import React, { useState } from "react";
import { Carousel, CarouselItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const items = [
  //{
  //  icon: ["fad", "user-ninja"],
  //},
  {
    icon: ["fad", "procedures"],
  },
  {
    icon: ["fad", "user-shield"],
  },
  {
    icon: ["fad", "notes-medical"],
  },
  {
    icon: ["fad", "user-md"],
  },
];

const IconCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={_.join(item.icon, "-")}
      >
        <FontAwesomeIcon
          icon={item.icon as IconProp}
          size={"lg"}
          fixedWidth={true}
          data-testid="rdd-hipaa-icon"
          className={`rdd-hipaa-icon`}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      autoPlay={true}
      interval={5000}
      slide={false}
      ride={"carousel"}
      enableTouch={false}
    >
      {slides}
    </Carousel>
  );
};

export default IconCarousel;
