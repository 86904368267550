/** @format */

import React from "react";
import { Formik, FormikProps } from "formik";
import { Button } from "reactstrap";
import { postWithToken, putWithToken } from "../../util/FetchUtils";
import { adHocTokensUrl, updateAdHocTokenUrl } from "../../util/ApiUrlUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WrappedDateInput from "../form/WrappedDateInput";
import ConfirmationDialog from "../operator-users/ConfirmationDialog";
import WrappedTextInput from "../form/WrappedTextInput";

export default class AdHocTokenEditForm extends React.Component<any, any> {
  static defaultProps = {
    token: {
      token: "",
      createdUserId: "",
      createdTime: null,
      expirationTime: null,
      submissionName: "",
      uploadUserId: "",
      uploadUserDisplayName: "",
      submissionDate: new Date(),
      directUploadS3PathPrefix: "",
    },
  };

  constructor(props: any) {
    super(props);
    this.onSaveToken = this.onSaveToken.bind(this);
  }

  componentDidMount() {}

  async onSaveToken(data: any, callback: Function) {
    if (this.props.token && this.props.token.id) {
      putWithToken(
        updateAdHocTokenUrl(this.props.token.id),
        this.props.authState,
        data.token,
      ).then((data: any) => {
        callback && callback.call(null);
        this.props.closeModal();
        this.props.onSaveToken();
      });
    } else {
      postWithToken(adHocTokensUrl(), this.props.authState, data.token).then(
        (data: any) => {
          callback && callback.call(null);
          this.props.closeModal();
          this.props.onSaveToken();
        },
      );
    }
  }
  /*async loadJDECatCode08s() {
    fetchPageableAllWithToken(
      jdeBUCatcodeUrl("08"),
      this.props.authState
    )
      .then((jdeCatCode08s: any) => {
        let transformedCodes = jdeCatCode08s.map((code: any) => {
          return { value: code.value, label: code.description };
        });
        this.setState({ jdeCatCode08s: transformedCodes });
      })
      .catch((error) => {
        console.error(error);
      });
  }*/

  render() {
    let { token, deleteToken, closeModal } = this.props;

    return (
      <Formik
        initialValues={{ token: token }}
        onSubmit={(values, actions) => {
          // Deep copy, just in case this values object isn't ours to mutate.
          let payload = JSON.parse(JSON.stringify(values));

          // clear some nested objects that we don't want to send to Spring
          if (values.token && values.token.requirementDueDate)
            payload.token.requirementDueDate = {
              id: payload.token.requirementDueDate.id,
            };

          this.onSaveToken(payload, () => actions.setSubmitting(false));
        }}
        validate={(values: any) => {
          let errors: any = { token: {} };
          let token: any = values.token;

          if (!token.submissionName) {
            errors.token.submissionName = "Please enter a submission name.";
          }

          if (!token.submissionDate) {
            errors.token.submissionDate = "Please enter a submission date.";
          }

          if (!token.uploadUserId) {
            errors.token.uploadUserId =
              "Please enter an email for the uploading user.";
          }

          if (!token.uploadUserDisplayName) {
            errors.token.uploadUserDisplayName =
              "Please enter a name for the uploading user.";
          }

          if (
            token.directUploadS3PathPrefix &&
            token.directUploadS3PathPrefix.match(/[^-0-9a-zA-Z!_.*'()/]/)
          ) {
            errors.token.directUploadS3PathPrefix =
              "The S3 path should only contain 0-9a-zA-Z!_-.*'()/";
          }

          if (Object.keys(errors.token).length > 0) {
            return errors;
          } else {
            return {};
          }
        }}
      >
        {(props: FormikProps<any>) => {
          return (
            <div className={"editForm"}>
              <h1 className="submissionName">
                {token.token
                  ? "Edit ad-hoc upload link"
                  : "Create ad-hoc upload link"}
              </h1>
              <form className="editFormForm" onSubmit={props.handleSubmit}>
                <div className="form-group">
                  <WrappedTextInput
                    id="token.submissionName"
                    placeholder="Submission Name"
                    label="Submission Name (displayed to user)"
                    required={true}
                  />
                </div>

                <div className="form-group">
                  <WrappedDateInput
                    id={"token.submissionDate"}
                    label={"Submission Date"}
                    value={props.values.token.submissionDate}
                    required={true}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                  />
                </div>

                <div className="form-group">
                  <WrappedTextInput
                    id="token.directUploadS3PathPrefix"
                    placeholder="Upload S3 path"
                    label="Upload S3 path"
                  />
                  <small>
                    <i>
                      Leave blank to organize uploads by submission name and
                      date
                    </i>
                  </small>
                </div>
                <div className="form-group">
                  <WrappedTextInput
                    id="token.uploadUserId"
                    placeholder="Uploader email"
                    label="Uploader email"
                    required={true}
                  />
                </div>
                <div className="form-group">
                  <WrappedTextInput
                    id="token.uploadUserDisplayName"
                    placeholder="Uploader name"
                    label="Uploader name"
                    required={true}
                  />
                </div>

                {token && token.id && deleteToken && (
                  <ConfirmationDialog
                    buttonColor={"danger"}
                    title={"Delete link?"}
                    confirmLabel={"Delete"}
                    message={
                      <p>
                        Are you sure you want to delete this upload link?
                        {token.filePaths.length > 0 && (
                          <>
                            <br />
                            Any uploaded files will <b>not</b> be deleted.
                          </>
                        )}
                      </p>
                    }
                    action={() => {
                      deleteToken(token).then((response: any) => {
                        closeModal && closeModal();
                      });
                    }}
                  >
                    {({ onClick }: { onClick: any }) => (
                      <Button
                        onClick={onClick}
                        color={"danger"}
                        disabled={props.isSubmitting}
                      >
                        <FontAwesomeIcon
                          icon={["far", "trash-alt"]}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </Button>
                    )}
                  </ConfirmationDialog>
                )}

                <Button
                  type={"submit"}
                  color={"primary"}
                  disabled={props.isSubmitting}
                  className={"float-right wtop-btn"}
                >
                  Save Updates
                </Button>
              </form>
            </div>
          );
        }}
      </Formik>
    );
  }
}
