/** @format */

import { withOktaAuth } from "@okta/okta-react";

import {
  operatorMappingsUpdateUrl,
  comboMappingSetsUrl,
  comboMappingsUrl,
  comboMappingSetRequirementsUrl,
  deleteComboMappingsUrl,
  comboMappingSetMappableAccountsUrl,
  comboMappingSetSegmentCodesUrl,
} from "../../util/ApiUrlUtils";

import {
  COMBO_MAPPING_FILTERABLE_FIELDS,
  COMBO_MAPPING_HEADERS,
} from "../../util/constants";

import BaseMappingsPage from "./BaseMappingsPage";
import ComboMappingForm from "./ComboMappingForm";
import React from "react";
import { withOperatorContext } from "../../components/filters/PickerContext";
import withRouterCompat from "../../util/router/WithRouterCompat";

class ComboMappings extends BaseMappingsPage {
  async componentDidMount() {
    await super.componentDidMount();
    if (this.props.selectedOperatorId)
      await this.loadBusinessUnits(this.props.selectedOperatorId);
  }
  async componentDidUpdate(prevProps: any, prevState: any) {
    super.componentDidUpdate(prevProps, prevState);
    if (prevProps.selectedOperatorId !== this.props.selectedOperatorId) {
      this.loadBusinessUnits(this.props.selectedOperatorId);
    }
  }

  getEditForm(mapping: any, callback?: Function) {
    return (
      <ComboMappingForm
        initialValues={mapping}
        welltowerBusinessUnits={this.state.buCodes}
        welltowerAccounts={this.state.welltowerAccounts}
        segmentCodes={this.state.segmentCodes}
        operatorAccountGroupings={this.state.operatorAccountGroupings}
        currentStartDate={mapping ? mapping.startMonth : new Date()}
        currentEndDate={mapping ? mapping.endMonth : new Date()}
        onSubmitMapping={this.onEditFormSubmit(callback)}
        onDeleteMapping={(rawData: any) => {
          if (callback) {
            callback();
          }
          return this.onDeleteMapping(rawData);
        }}
      />
    );
  }
  getFormattedItems(data: any) {
    throw new Error("not implemented");
  }

  formatIndividualMapping(rawMappingData: any): any {
    return Object.assign({}, rawMappingData, {
      startMonth: rawMappingData.startMonth.value
        ? rawMappingData.startMonth.value
        : rawMappingData.startMonth,
      endMonth:
        rawMappingData.endMonth && rawMappingData.endMonth.value
          ? rawMappingData.endMonth.value
          : rawMappingData.endMonth,
      welltowerAccount: rawMappingData.welltowerAccount.value
        ? rawMappingData.welltowerAccount.value
        : rawMappingData.welltowerAccount,
      welltowerBusinessUnitCode: rawMappingData.welltowerBusinessUnitCode.value
        ? rawMappingData.welltowerBusinessUnitCode.value
        : rawMappingData.welltowerBusinessUnitCode,
      segmentCode: rawMappingData.segmentCode?.value
        ? rawMappingData.segmentCode.value
        : rawMappingData.segmentCode,
      // TODO yardi - flatten destination object to enum string?
    });
  }

  getSaveMappingsUrl(operatorId: number) {
    return operatorMappingsUpdateUrl(operatorId);
  }

  getUnmappedItemsUrl(operatorId: number) {
    return "";
  }

  async loadOperatorUnmappedItems(operatorId: number) {
    // No unmapped items on this page.
  }

  getPageTitle() {
    return "Combo Mappings";
  }

  getFilterableTableProps() {
    return {
      headers: COMBO_MAPPING_HEADERS,
      fields: COMBO_MAPPING_FILTERABLE_FIELDS,
      noDataMsg: "There are currently no combo mappings for this operator.",
      searchInputPlaceholder: "Search mappings",
    };
  }

  getMappingType() {
    return "combo";
  }

  getMappingSetUrl(operatorId: number) {
    return comboMappingSetsUrl(operatorId);
  }

  getMappingsUrl(operatorId: number, mappingSetId: number) {
    return comboMappingsUrl(operatorId, mappingSetId);
  }

  getMappingSetMappableAccountsUrl(
    operatorId: number,
    mappingSetId: number,
  ): string {
    return comboMappingSetMappableAccountsUrl(operatorId, mappingSetId);
  }

  getMappingSetSegmentCodesUrl(
    operatorId: number,
    mappingSetId: number,
  ): string {
    return comboMappingSetSegmentCodesUrl(operatorId, mappingSetId);
  }

  deleteMappingsUrl(
    operatorId: number,
    mappingSetId: number,
    mappingId: number,
  ) {
    return deleteComboMappingsUrl(operatorId, mappingSetId, mappingId);
  }

  getRequirementsUrl(operatorId: number, mappingSetId: number) {
    return comboMappingSetRequirementsUrl(operatorId, mappingSetId);
  }
}

export default withRouterCompat(
  withOktaAuth(withOperatorContext(ComboMappings)),
);
