/** @format */

import { useModal } from "../WithModal";
import React from "react";
import WelltowerBuSettingEditForm from "./WelltowerBuSettingEditForm";
import { Button } from "reactstrap";
import { useOktaAuth } from "@okta/okta-react";

const WelltowerBuSettingEditFormButton = ({
  buSetting,
  onSave,
  onClick,
  ...props
}: {
  buSetting: any;
  onSave: Function;
  onClick?: Function;
} & Partial<any>) => {
  const { showModal, modalContent, closeModal } = useModal();
  const { authState } = useOktaAuth();
  return (
    <div>
      <Button
        color={"primary"}
        className="wtop-btn"
        onClick={() => {
          onClick && onClick();
          showModal();
        }}
      >
        Edit
      </Button>
      {modalContent(
        <WelltowerBuSettingEditForm
          {...props}
          buSetting={buSetting}
          authState={authState}
          onSave={onSave}
          closeModal={closeModal}
        />,
        {
          showCloseIcon: true,
          modalStyles: { width: "600px" },
          closeOnOverlayClick: false,
        },
      )}
    </div>
  );
};

export default WelltowerBuSettingEditFormButton;
