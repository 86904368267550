/** @format */

import React from "react";
import { Button } from "reactstrap";
import AdHocUploadToken from "../../types/AdHocUploadToken";
import WithModal from "../WithModal";
import AdHocTokenEditForm from "./AdHocTokenEditForm";
import { AbstractAuthState } from "../../util/OktaUtils";

/** @format */

const TokenEditFormButton = WithModal(
  ({
    token,
    authState,
    onSaveToken,
    ...props
  }: {
    token: AdHocUploadToken;
    authState: AbstractAuthState;
    onSaveToken: Function;
  } & Partial<any>) => (
    <div>
      <Button color={"primary"} className="wtop-btn" onClick={props.showModal}>
        {token && token.id ? "Edit" : "New Link"}
      </Button>
      {props.modalContent(
        <AdHocTokenEditForm
          token={token}
          {...props}
          authState={authState}
          onSaveToken={onSaveToken}
        />,
        {
          showCloseIcon: true,
          modalStyles: { width: "600px" },
          closeOnOverlayClick: false,
        }
      )}
    </div>
  )
);

export default TokenEditFormButton;
