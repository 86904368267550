/** @format */

import React from "react";
import { withOktaAuth } from "@okta/okta-react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { getOperatorDashboardPeriods } from "../api/RequirementsAPI";
import SubmissionStatus from "../types/SubmissionStatus";
import OperatorRddPeriod from "../components/operator-rdd-card/OperatorRddPeriod";
import { getAvailablePeriodMonths } from "../api/SharedAPI";
import _ from "lodash";
import Select from "react-select";
import RequirementDueDate from "../types/RequirementDueDate";
import dayjs from "dayjs";
import { getDefaultDashboardMonth } from "../util/CollectionUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardPeriodDto from "../types/DashboardPeriodDto";
import { ContextDocumentTypePicker } from "../components/filters/ContextDocumentTypePicker";
import { withDocumentTypeContext } from "../components/filters/PickerContext";

interface OperatorDashboardState {
  loading: boolean;
  currentPeriod: any;
  availablePeriods: Array<any>;
  selectedPeriodRdds: Array<any>;
  rddPeriods: Array<DashboardPeriodDto>;
  filterOpenOverdue: "open" | "open-overdue" | "all";
  error?: string | unknown;
  fileValidationStatus?: SubmissionStatus;
}

const pickByMonth = true;

class OperatorDashboardOld extends React.Component<
  any,
  OperatorDashboardState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      currentPeriod: "",
      availablePeriods: [],
      selectedPeriodRdds: [],
      rddPeriods: [],
      filterOpenOverdue: "open",
    };
    this.loadRequirementDueDates = this.loadRequirementDueDates.bind(this);
    this.receiveUpdatedRequirementDueDate =
      this.receiveUpdatedRequirementDueDate.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    try {
      this.setState({
        loading: true,
      });

      const availablePeriods = await this.loadAvailablePeriods();

      //const rdds = await this.loadRequirementDueDates();
      //const currentRdd = getDefaultDashboardMonth(rdds);
      //const availablePeriods = await getAvailablePeriodMonths(
      //  this.props.authState,
      //);

      let transformedAvailablePeriods;
      if (pickByMonth) {
        transformedAvailablePeriods = Object.keys(availablePeriods).map((k) => {
          return {
            label: availablePeriods[k],
            value: k,
          };
        });
      } else {
        const uniqueYears = _.uniq(
          Object.keys(availablePeriods).map((p) => p.slice(0, 4)),
        );
        transformedAvailablePeriods = uniqueYears.map((year) => {
          return {
            label: year,
            value: year,
          };
        });
      }
      console.log("transformedAvailable", transformedAvailablePeriods);
      let currentPeriod = this.state.currentPeriod;
      console.log("state.currentPeriod", this.state.currentPeriod);
      if (!currentPeriod) {
        console.log("getting currentPeriod");
        currentPeriod = await this.getCurrentPeriod();
        if (!pickByMonth) {
          currentPeriod = currentPeriod && {
            label: currentPeriod.value.slice(0, 4),
            value: currentPeriod.value.slice(0, 4),
          };
        }
      }

      if (!currentPeriod) {
        currentPeriod =
          transformedAvailablePeriods &&
          _.maxBy(transformedAvailablePeriods, "value");
      }
      console.log(`currentPeriod: `, currentPeriod);
      const selectedPeriodRdds = await this.loadRequirementDueDates(
        currentPeriod.value,
      );

      this.setState({
        availablePeriods: transformedAvailablePeriods,
        selectedPeriodRdds,
        currentPeriod,
        loading: false,
      });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }
  async loadAvailablePeriods() {
    try {
      return await getAvailablePeriodMonths(this.props.authState);
    } catch (error) {
      this.setState({ error });
    }
  }

  async getCurrentPeriod() {
    try {
      const available = await getOperatorDashboardPeriods(
        this.props.authState,
        "",
      );
      console.log("getCurrentPeriod available", available);
      const current = getDefaultDashboardMonth(available);

      console.log("getCurrentPeriod default", current);
      return current
        ? { label: current.displayName, value: current.id }
        : available && available[0]
          ? { label: available[0].displayName, value: available[0].id }
          : null;
    } catch (error) {
      this.setState({ error });
    }
  }

  async loadRequirementDueDates(period: string) {
    try {
      const accessToken = this.props.authState;
      const rddPeriods = await getOperatorDashboardPeriods(accessToken, period);
      this.setState({ rddPeriods, loading: false });
      return rddPeriods;
    } catch (error) {
      this.setState({ error, loading: false });
      return [];
    }
  }

  filterRequirementDueDatesByDocumentType(rdds: Array<any>) {
    let filteredReqs: Array<any> = [];

    rdds.forEach((rdd) => {
      const rddCopy = { ...rdd };
      rddCopy.dashboardRequirementDueDates =
        rdd.dashboardRequirementDueDates.filter((item: RequirementDueDate) =>
          this.props.isDocumentTypeSelected(item.requirement.documentType),
        );
      if (rddCopy.dashboardRequirementDueDates.length > 0) {
        filteredReqs.push(rddCopy);
      }
    });

    return filteredReqs;
  }

  filterRequirementDueDatesByOpenOverdue(rdds: Array<any>) {
    let filteredReqs: Array<any> = rdds;

    if (
      this.state.filterOpenOverdue === "open" ||
      this.state.filterOpenOverdue === "open-overdue"
    ) {
      filteredReqs = [];
      rdds.forEach((rdd) => {
        const rddCopy = { ...rdd };
        rddCopy.dashboardRequirementDueDates =
          rdd.dashboardRequirementDueDates.filter(
            (item: any) => !item.status.operatorComplete,
          );
        if (rddCopy.dashboardRequirementDueDates.length > 0) {
          filteredReqs.push(rddCopy);
        }
      });

      if (this.state.filterOpenOverdue === "open-overdue") {
        const filteredAgain: Array<any> = [];
        const today = dayjs();
        filteredReqs.forEach((rdd) => {
          const rddCopy = { ...rdd };
          rddCopy.dashboardRequirementDueDates =
            rdd.dashboardRequirementDueDates.filter(
              (item: any) => today.diff(dayjs(item.dueDate), "day") >= 0,
            );
          if (rddCopy.dashboardRequirementDueDates.length > 0) {
            filteredAgain.push(rddCopy);
          }
        });

        filteredReqs = filteredAgain;
      }
    }

    return filteredReqs;
  }

  receiveUpdatedRequirementDueDate(rdd: RequirementDueDate) {
    if (!rdd) return;

    const rddPeriods = [...this.state.rddPeriods];
    if (rddPeriods) {
      let hasChange = false;
      rddPeriods.forEach((period: any) => {
        const existingIdx = _.findIndex(
          period.dashboardRequirementDueDates,
          (rdd2: RequirementDueDate) => rdd2.id === rdd.id,
        );
        if (existingIdx !== -1) {
          period.dashboardRequirementDueDates[existingIdx] = rdd;
          hasChange = true;
        }
      });
      if (hasChange) {
        this.setState({ rddPeriods });
      }
    }
  }

  render() {
    const { currentPeriod, availablePeriods, loading } = this.state;
    const rddPeriods = this.filterRequirementDueDatesByOpenOverdue(
      this.filterRequirementDueDatesByDocumentType(this.state.rddPeriods),
    );

    const openOverdueOptions = [
      { value: "all", label: "All Requirements" },
      { value: "open", label: "Open Requirements" },
      { value: "open-overdue", label: "Overdue Requirements" },
    ];

    return (
      <Container>
        <Row>
          <Col md={"3"}>
            <Select
              id={"due-date-period"}
              aria-label={"Year picker"}
              options={availablePeriods}
              onChange={(v: any) => {
                this.setState({ currentPeriod: v }, this.loadData);
              }}
              onBlur={() => {}}
              value={currentPeriod}
            />
          </Col>
          <Col md="4">
            <Select
              id={"open-overdue-select"}
              options={openOverdueOptions}
              onChange={(v: any) => {
                this.setState({ filterOpenOverdue: v.value });
              }}
              onBlur={() => {}}
              value={openOverdueOptions.find(
                (a: any) => a.value === this.state.filterOpenOverdue,
              )}
            />
          </Col>
          <Col md={"3"}>
            <ContextDocumentTypePicker showAllChoice />
          </Col>
        </Row>
        {loading && (
          <FontAwesomeIcon
            icon={"spinner"}
            spin
            size={"3x"}
            color={"#888"}
            className={"mt-5"}
          />
        )}
        {!loading &&
          rddPeriods &&
          rddPeriods.map((rddPeriod: any) => {
            return (
              <OperatorRddPeriod
                authState={this.props.authState}
                key={rddPeriod.id}
                rddPeriod={rddPeriod}
                keepExpanded={pickByMonth}
                receiveUpdatedRequirementDueDate={
                  this.receiveUpdatedRequirementDueDate
                }
              />
            );
          })}
        {availablePeriods.length === 0 && !loading && (
          <Row>
            <Col>
              <Card>
                <CardBody>
                  No required documents found. Please contact your Welltower
                  accountant for assistance.
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {availablePeriods.length !== 0 &&
          !loading &&
          rddPeriods.length === 0 && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    No requirements match the selected filters
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
      </Container>
    );
  }
}

export default withOktaAuth(withDocumentTypeContext(OperatorDashboardOld));
