/** @format */

import React, { useContext, useState } from "react";
import Konami from "react-konami-code";

/*

// To just hide or show UI based on mode, wrap in <DeveloperModeOnly>

// To get the developerMode flag in class-based components, use DeveloperModeConsumer and its renderprop:

  <DeveloperModeConsumer>
    {({ developerMode }) => {
      ...
      if(developerMode) {
        ...
      }
      ...
      return (
        ...
      )
    }
  </DeveloperModeConsumer>

// To get the developerMode flag in a functional component, useContext(DeveloperContext):

  const { developerMode } = useContext(DeveloperContext);

*/

const DeveloperContext = React.createContext<any>({
  developerMode: false,
  enableDeveloperMode: () => {},
});

const DeveloperModeWrapper = ({ children }: { children: any }) => {
  const [developerMode, setDeveloperMode] = useState(false);
  const providerValue = {
    developerMode,
    enableDeveloperMode: () => {
      setDeveloperMode(true);
      window && window.scrollTo(0, 0);
    },
  };

  return (
    <DeveloperContext.Provider value={providerValue}>
      <div
        className={
          "developerModeWrapper" +
          (providerValue.developerMode ? " developerMode" : "")
        }
      >
        <Konami resetDelay={10000} action={providerValue.enableDeveloperMode} />
        {children}
      </div>
    </DeveloperContext.Provider>
  );
};
const DeveloperModeConsumer = DeveloperContext.Consumer;
const DeveloperModeOnly = ({ children }: { children: any }) => {
  const { developerMode } = useContext(DeveloperContext);
  return developerMode ? children : null;
};
const useDeveloperMode = (): boolean => {
  const { developerMode } = useContext(DeveloperContext);
  return developerMode;
};

function withDeveloperMode(WrappedComponent: any) {
  return function DeveloperMode(props: any) {
    const { developerMode } = useContext(DeveloperContext);

    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    return <WrappedComponent developerMode={developerMode} {...props} />;
  };
}

export {
  DeveloperModeWrapper,
  DeveloperModeOnly,
  DeveloperModeConsumer,
  DeveloperContext,
  useDeveloperMode,
  withDeveloperMode,
};
