/** @format */

import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Container, Card, CardBody, CardHeader, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFilteredGroups, getUserLogin } from "../util/OktaUtils";
import { DeveloperModeOnly } from "../components/DeveloperMode";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "../components/LoadingSpinners";
import { useOktaQuery } from "../hooks/useOktaQuery";
import { ListOrItem } from "./HopperStatusPage";
import useInterval from "../hooks/useInterval";
import { relativeTimeShort } from "../util/DateUtils";
import { appLoadTime } from "../App";
import dayjs from "dayjs";

const copy = require("clipboard-copy");

const ServerHello = () => {
  const { data, isLoading, isError } = useOktaQuery("/api/hello");

  return isLoading ? (
    <LoadingSpinner />
  ) : isError ? (
    <p>Error</p>
  ) : (
    <>
      <p>Login: {data.login}</p>
      <div>
        Groups:{" "}
        {data.groups ? (
          <ul>
            {data.groups.map((g: any) => (
              <li key={g}>{g}</li>
            ))}
          </ul>
        ) : (
          "none"
        )}
        <DeveloperModeOnly>
          Okta group prefix: {data.oktaGroupPrefix}
        </DeveloperModeOnly>
      </div>
    </>
  );
};

const DataSourceStatus = () => {
  const { data, isLoading, isError } = useOktaQuery("/api/datasource/status");
  return isLoading ? (
    <FontAwesomeIcon icon={["fas", "spinner"]} pulse />
  ) : isError ? (
    <p>Error</p>
  ) : (
    ListOrItem(data)
  );
};

const ServerHealth = () => {
  const { data, isLoading, isError } = useOktaQuery("/api/health");
  return isLoading ? (
    <LoadingSpinner />
  ) : isError ? (
    <p>Error</p>
  ) : (
    ListOrItem(data)
  );
};

const OktaTokenTimes = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [index, setIndex] = useState(1);
  useInterval(() => {
    setIndex(index + 1);
  }, 1000);

  if (!authState?.isAuthenticated) return null;

  return (
    <div>
      <ul>
        <li>Last page reload {relativeTimeShort(appLoadTime)}</li>
        <li>
          Okta Token will refresh{" "}
          {relativeTimeShort(
            dayjs.unix(
              (authState.accessToken &&
                oktaAuth.tokenManager.getExpireTime(authState.accessToken)) ||
                authState.accessToken?.expiresAt ||
                0,
            ),
          )}
        </li>
      </ul>
    </div>
  );
};

const TokenPage = () => {
  const { authState } = useOktaAuth();

  if (!authState?.isAuthenticated) return null;
  return (
    <Container>
      <Card>
        <CardHeader tag={"h5"}>Details from React:</CardHeader>
        <CardBody>
          <div
            onClick={() => copy(authState.accessToken?.accessToken)}
            title={"Click to copy"}
            className={"oktaToken"}
          >
            Token:
            <FontAwesomeIcon
              icon={["far", "copy"]}
              style={{ marginLeft: "0.75em" }}
            />
            <small>
              {/* small > .small applies 80% twice, which is pretty much what I want. Could be  */}
              <pre className={"small"} style={{ marginBottom: "0" }}>
                {authState.accessToken?.accessToken}
              </pre>
            </small>
          </div>
          <p>Login: {getUserLogin(authState)}</p>
          <div>
            Groups:{" "}
            <ul>
              {getFilteredGroups(authState).map((g: any) => (
                <li key={g}>{g}</li>
              )) || <li>none</li>}
            </ul>
            <DeveloperModeOnly>
              Okta group prefix: {process.env.REACT_APP_OKTA_GROUP_PREFIX}
            </DeveloperModeOnly>
          </div>
          <OktaTokenTimes />
        </CardBody>
      </Card>
      <Card>
        <CardHeader tag={"h5"}>Response from server&apos;s /health:</CardHeader>
        <CardBody>
          <ServerHealth />
        </CardBody>
      </Card>
      <Card>
        <CardHeader tag={"h5"}>
          Response from server&apos;s /api/hello:
        </CardHeader>
        <CardBody>
          <ServerHello />
        </CardBody>
      </Card>
      <DeveloperModeOnly>
        <Card>
          <CardHeader tag={"h5"}>
            Response from server&apos;s /api/datasource/status:
          </CardHeader>
          <CardBody>
            <DataSourceStatus />
          </CardBody>
        </Card>
      </DeveloperModeOnly>
      <DeveloperModeOnly>
        <Card>
          <CardHeader tag={"h5"}>Break my Okta token</CardHeader>
          <CardBody>
            <Button
              color={"danger"}
              onClick={() => {
                const item = localStorage.getItem("okta-token-storage");
                if (item) {
                  let ts = JSON.parse(item);
                  delete ts.accessToken;
                  localStorage.setItem(
                    "okta-token-storage",
                    JSON.stringify(ts),
                  );
                }
              }}
            >
              Click if you dare
            </Button>
          </CardBody>
          <CardBody>
            <Link to={"/login"}>Log in again</Link> if you&apos;re all busted.
          </CardBody>
        </Card>
      </DeveloperModeOnly>
    </Container>
  );
};

export default TokenPage;
