/** @format */
import React from "react";
import { Formik, FormikProps, FormikValues } from "formik";
import WrappedSelectInput from "../../components/form/WrappedSelectInput";
import { Button } from "reactstrap";
import WrappedTextInput from "../../components/form/WrappedTextInput";

namespace ColumnMappingForm {
  export interface Props {
    // TODO fill these in
    initialValues: any;
    documentTypeColumns: any;
    columnOptions: any;
    getColumnOptions: any;
    onSubmitMapping: any;
    onDeleteMapping: any;
  }
}

// TODO - React complains about some of these fields changing, I think because we start strings as nulls instead of empty strings. See if we can fix that in WrappedTextInput so I don't have to inject everywhere else

// TODO - can we share some of these fields with UnmappedColumnForm?
export function ColumnMappingForm(props: ColumnMappingForm.Props) {
  const {
    initialValues,
    documentTypeColumns,
    columnOptions,
    getColumnOptions,
    onSubmitMapping,
    onDeleteMapping,
  } = props;

  return (
    <Formik
      initialValues={initialValues || {}}
      onSubmit={(values, actions) => {
        onSubmitMapping(values, actions);
      }}
      validate={(values: any) => {
        let errors: any = {};

        if (!values.columnId) {
          errors.columnId = "Please select a Column.";
        }

        if (!values.operatorValue) {
          errors.operatorValue = "Please enter an Operator value.";
        }

        if (!values.welltowerSelectedOptionId) {
          errors.welltowerSelectedOptionId =
            "Please select a Welltower Mapping.";
        }

        return errors;
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => {
        let columnFieldOptions =
          columnOptions ??
          (getColumnOptions &&
            formikProps.values?.columnId &&
            getColumnOptions(
              formikProps.values.columnId.value ?? formikProps.values.columnId,
            ));

        return (
          <form
            onSubmit={formikProps.handleSubmit}
            name={"Column Mapping Form"}
          >
            <div className="form-group">
              Document Type: {formikProps.values.documentTypeName}
            </div>
            <div className="form-group">
              <WrappedSelectInput
                id={`columnId`}
                label={"Column"}
                value={documentTypeColumns?.find(
                  (it: any) => it.value === formikProps.values.columnId,
                )}
                required={true}
                disabled={!!initialValues.id}
                options={documentTypeColumns}
                onChange={formikProps.setFieldValue}
                onBlur={formikProps.setFieldTouched}
              />
            </div>
            <div className="form-group">
              <WrappedTextInput
                id="operatorValue"
                required={true}
                label="Operator Value"
                disabled={initialValues && !!initialValues.id}
              />
            </div>
            <div className="form-group">
              <WrappedTextInput
                id="detailedDescription"
                required={false}
                label="Description"
              />
            </div>
            <div className="form-group">
              <WrappedSelectInput
                id={`operatorSelectedOptionId`}
                label={"Operator Mapping"}
                value={columnFieldOptions?.find(
                  (it: any) =>
                    it.value === formikProps.values.operatorSelectedOptionId,
                )}
                required={true}
                options={columnFieldOptions}
                onChange={formikProps.setFieldValue}
                onBlur={formikProps.setFieldTouched}
              />
            </div>
            <div className="form-group">
              <WrappedSelectInput
                id={`welltowerSelectedOptionId`}
                label={"Welltower Mapping"}
                value={columnFieldOptions?.find(
                  (it: any) =>
                    it.value === formikProps.values.welltowerSelectedOptionId,
                )}
                required={true}
                options={columnFieldOptions}
                onChange={formikProps.setFieldValue}
                onBlur={formikProps.setFieldTouched}
              />
            </div>

            {initialValues && initialValues.id && (
              <Button
                color={"danger"}
                onClick={() => {
                  onDeleteMapping(initialValues);
                }}
                className={"float-left wtop-btn"}
              >
                Delete
              </Button>
            )}
            <Button
              type={"submit"}
              color={"primary"}
              disabled={!formikProps.isValid || formikProps.isSubmitting}
              className={"float-right wtop-btn"}
            >
              Submit
            </Button>
          </form>
        );
      }}
    </Formik>
  );
}
export default ColumnMappingForm;
