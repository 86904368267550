/** @format */
import React from "react";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom-v5-compat";

// TODO - the Yardi Notifications Bot is looking for "RPA Dashboard - Yardi ETL". Maybe remove plain "RPA Dashboard" from menu?
// Probably don't need the Snowflake Pull dashboard because they're just hitting the API.
const rpaDashboards = [
  {
    url: "/rpa-dashboard",
    id: "dashboardYardiETL",
    title: "RPA Dashboard - Yardi ETL",
  },
  {
    url: "/rpa-dashboard/PULL",
    id: "dashboardSnowflakePull",
    title: "RPA Dashboard - Snowflake Pull",
  },
];

const RPADashboardPicker = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Select
      data-botid={"rpa-dashboard-picker"}
      id={"rpa-dashboard-picker"}
      options={rpaDashboards.map((o: any) => ({
        value: o.url,
        label: o.title,
      }))}
      onChange={(v: any) => {
        navigate(v.value);
      }}
      onBlur={() => {}}
      value={rpaDashboards
        .map((o: any) => ({ value: o.url, label: o.title }))
        .find((o: any) => o.value === location.pathname)}
    />
  );
};

export { rpaDashboards };
export default RPADashboardPicker;
