/** @format */

import React from "react";
import { withFormik } from "formik";
import WithModal from "./WithModal";
import { Button } from "reactstrap";
import { withOktaAuth } from "@okta/okta-react";
import { formatDate, formatDateISO } from "../util/DateUtils";
import { describeFrequencyType, welltowerName } from "../util/NameUtils";
import WrappedDateInput from "./form/WrappedDateInput";
import { DeveloperModeOnly } from "./DeveloperMode";
import Requirement from "../types/Requirement";
import { postWithToken } from "../util/FetchUtils";
import { LoadingSpinner } from "./LoadingSpinners";

const RequirementDueDateManualCreationForm = (props: any) => {
  const {
    requirement,
    values,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    closeModal,
  } = props;

  return (
    <div className="editForm requirementManualDueDateForm">
      <p>
        {welltowerName(requirement.operator)} {welltowerName(requirement)}
        <br />
        {describeFrequencyType(requirement)}
        <br />
        {requirement.endDate
          ? `${formatDate(requirement.startDate)} - ${formatDate(
              requirement.endDate,
            )}`
          : `Start Date: ${formatDate(requirement.startDate)}`}
        <br />
        <DeveloperModeOnly>
          {" "}
          <span className={"text-muted"}>
            {requirement.operator.id}.{requirement.id}
          </span>
        </DeveloperModeOnly>
      </p>
      <form className="editFormForm" onSubmit={handleSubmit}>
        <div className={"form-group"}>
          <p className={"font-italic"}>
            Future due dates will be created automatically as they become
            available.
          </p>
          <p>If necessary, you can create any missing due dates between:</p>
          <div className="form-row">
            <div className="form-group col-sm-6">
              <WrappedDateInput
                id={"startDate"}
                label={"From"}
                value={values.startDate}
                required={true}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </div>
            <div className="form-group col-sm-6">
              <WrappedDateInput
                id={"endDate"}
                label={"To"}
                value={values.endDate}
                required={true}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </div>
          </div>
        </div>
        <div className="editFormButton">
          <span className="requiredNote" style={{ float: "left" }}>
            * required fields
          </span>
          <Button onClick={closeModal} color={"link"} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" color={"primary"} disabled={isSubmitting}>
            {"Create"}
          </Button>
        </div>
        {isSubmitting && (
          <div
            style={{
              position: "absolute",
              top: "1.2rem",
              height: "calc( 100% - 2.4rem)",
              width: "calc( 100% - 2.4rem)",
              backgroundColor: "white",
              opacity: 0.5,
            }}
          >
            <div
              style={{
                fontSize: "48px",
                position: "relative",
                top: "calc(50% - 24px)",
                left: "calc(50% - 24px)",
              }}
            >
              <LoadingSpinner />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

const EnhancedForm = withOktaAuth(
  withFormik({
    mapPropsToValues: (props) => {
      // Return relation .id or enum.name here
      const requirement = props.requirement;
      return {
        requirement: requirement && requirement.id,
      };
    },
    /* Building a Yup schema with a cyclic dependency and the noSortEdges "escape hatch" worked in Chrome, but somehow blew up in IE.
    I tried to figure it out, but eventually had to give up and write a simple custom validation.
     */
    validate: (values: any, props) => {
      let errors: any = {};

      if (!values.startDate) {
        errors.startDate = "Please enter a start date";
      } else if (values.startDate < props.requirement.startDate) {
        errors.startDate =
          "Please enter a start date on or after the requirement start date.";
      }
      if (!values.endDate) {
        errors.endDate = "Please enter an end date";
      } else if (
        props.requirement.endDate &&
        values.endDate > props.requirement.endDate
      ) {
        errors.endDate =
          "Please enter an end date on or before the requirement end date.";
      }

      return errors;
    },

    handleSubmit: async (
      values: any,
      { props, setSubmitting }: { props: any; setSubmitting: any },
    ) => {
      postWithToken(
        `/api/operators/${props.requirement.operator.id}/requirements/${
          props.requirement.id
        }/validateDueDates/${formatDateISO(values.startDate)}/${formatDateISO(
          values.endDate,
        )}`,
        props.authState,
        {},
      ).then((response) => {
        setSubmitting(false);
        props.callback();
        props.closeModal && props.closeModal();
      });

      return;
    },

    displayName: "RequirementDueDateManualCreationForm", // helps with React DevTools
  })(RequirementDueDateManualCreationForm),
);

const RequirementDueDateManualCreationFormButton = WithModal(
  ({ requirement, ...props }: { requirement: Requirement } & Partial<any>) => (
    <div>
      <Button color={"light"} onClick={props.showModal}>
        Create Due Dates
      </Button>
      {props.modalContent(
        <EnhancedForm requirement={requirement} {...props} />,
        {
          showCloseIcon: true,
          modalStyles: { width: "400px" },
          closeOnOverlayClick: false,
          noAutoFocus: true,
        },
      )}
    </div>
  ),
);

export default RequirementDueDateManualCreationFormButton;
