/** @format */

import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  getOperatorUserActions,
  operatorUsersURL,
} from "../../api/OperatorAPI";
import OktaUsersTable from "./OktaUsersTable";
import { Col, Row } from "reactstrap";
import OperatorUserFormButton from "./OperatorUserFormButton";
import { canManageUsers, canSeeUserDetails } from "../../util/OktaUtils";
import Operator from "../../types/Operator";
import { decideName } from "../../util/NameUtils";
import OktaUser from "../../types/OktaUser";
import useFetchJson from "../../hooks/useFetchJson";

const OperatorUsers = ({ operator }: { operator: Operator }) => {
  const { authState } = useOktaAuth();
  const { data, loading, forceReload } = useFetchJson<OktaUser[]>(
    operator ? operatorUsersURL(operator.id) : null,
  );

  const canManage = canManageUsers(authState, operator);
  const canSeeDetails = canSeeUserDetails(authState, operator);
  return loading || !operator ? (
    <p>loading...</p>
  ) : (
    <>
      <Row>
        <Col md={"8"}>
          <h3>{decideName(authState, operator)} Users</h3>
        </Col>
        <Col sm />
        <Col sm={"auto"} className={"text-end"}>
          {canManage && (
            <OperatorUserFormButton
              saveAction={
                getOperatorUserActions(operator.id, authState, forceReload)
                  .createUser
              }
              operatorId={operator.id}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <OktaUsersTable
            oktaUsers={data!}
            showDetails={canSeeDetails}
            userActions={
              canManage &&
              getOperatorUserActions(operator.id, authState, forceReload)
            }
            showOperator={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default OperatorUsers;
