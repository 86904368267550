/** @format */

import React, { PropsWithChildren } from "react";
import { CardBody, Col, Collapse, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IRddUploadHistoryProps {
  files: Array<any>;
  locked: boolean; // Just to trigger re-render
  downloadAllFiles?: any;
}
class RddUploadHistory extends React.Component<
  PropsWithChildren<IRddUploadHistoryProps>,
  any
> {
  static defaultProps = {
    files: [],
    downloadAllFiles: null,
  };

  constructor(props: IRddUploadHistoryProps) {
    super(props);
    this.state = { collapse: false };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { files, downloadAllFiles } = this.props;

    if (files.length === 0) {
      return null;
    }
    return (
      <CardBody
        className={"rdd-upload-history p-3"}
        data-testid={"rdd-upload-history"}
      >
        <Row>
          <Col>
            <Row className={"justify-content-between"}>
              <Col onClick={this.toggle} className={"cursor-pointer"}>
                <FontAwesomeIcon
                  size={"1x"}
                  color={"#007bff"}
                  icon={this.state.collapse ? "minus-circle" : "plus-circle"}
                  className={"mr-2"}
                />
                <span className={"header-text"}>
                  {this.state.collapse
                    ? "Hide Uploads"
                    : `Show Uploads (${files.length})`}
                </span>
              </Col>
              {files.length > 1 && downloadAllFiles && (
                <Col
                  xs={"auto"}
                  className={"cursor-pointer text-right"}
                  onClick={downloadAllFiles}
                >
                  <FontAwesomeIcon
                    size={"1x"}
                    color={"#007bff"}
                    icon={["far", "file-archive"]}
                    className={"mr-2"}
                  />
                  <span
                    style={{ color: "#007bff" }}
                    className={
                      "font-weight-light pr-1 align-baseline header-text"
                    }
                  >
                    Download all (Zip)
                  </span>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <Collapse isOpen={this.state.collapse}>
                  <ul className={"file-list"}>{this.props.children}</ul>
                </Collapse>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    );
  }
}

export default RddUploadHistory;
