/** @format */

import { useState } from "react";
import { useKonami } from "react-konami-code";

//export function useKonamiState(code: number[], initialState = false) {
//  const [show, setShow] = useState(initialState);
//  useKonami(() => setShow(true), { code });
//  return [show, setShow];
//}
export function useKonamiSessionStorageToggle(
  key: string,
  code: number[],
  initialState = false,
) {
  const [state, setState] = useState(
    sessionStorage.getItem(key) === "true" || initialState,
  );

  const toggle = () => {
    if (state) {
      setState(false);
      sessionStorage.setItem(key, "false");
    } else {
      setState(true);
      sessionStorage.setItem(key, "true");
    }
  };

  useKonami(toggle, { code });
  return [state, toggle];
}
