/** @format */
import React from "react";
import { Col, Input, Row } from "reactstrap";
import { ErrorMessage, Field, FormikProps } from "formik";
import WrappedSelectInput from "../../form/WrappedSelectInput";
import ExternalSystem from "../../../types/ExternalSystem";

interface IUnmappedPropertyProps {
  unmappedItem: any;
  index: number;
  buCodes: Array<any>;
  destination?: ExternalSystem;
  isWelltowerAccountant: boolean;
  formikProps: FormikProps<any>;
}

export default class UnmappedProperty extends React.Component<
  IUnmappedPropertyProps,
  any
> {
  render() {
    let {
      unmappedItem,
      formikProps,
      index,
      buCodes,
      isWelltowerAccountant,
      destination,
    } = this.props;
    const destinationBuCodes = buCodes.filter(
      (bu) =>
        !destination ||
        bu.value === "IGNORE" ||
        bu.object?.source?.name === destination.name,
    );

    return (
      <Row
        key={`${unmappedItem.id}-${index}`}
        className="unmapped-account mb-3"
      >
        <Col>
          <label htmlFor={`unmappedItems.${index}.identifier`}>Property</label>
          <Field name={`unmappedItems.${index}.identifier`}>
            {(field: any) => (
              <Input
                {...field}
                value={unmappedItem.identifier || ""}
                disabled={true}
                title={unmappedItem.identifier || ""}
              />
            )}
          </Field>
        </Col>

        <Col>
          <label
            htmlFor={`unmappedItems.${index}.additionalInformation`}
            className={"label-required"}
          >
            Detailed Description
          </label>
          <Field
            name={`unmappedItems.${index}.additionalInformation`}
            validate={(value: string) => {
              if (value && value.length > 255) {
                return "Must be less than 256 characters";
              }
            }}
          >
            {({ field }: any) => (
              <>
                <Input
                  {...field}
                  value={unmappedItem.additionalInformation || ""}
                  title={unmappedItem.additionalInformation || ""}
                />
                <ErrorMessage
                  name={`unmappedItems.${index}.additionalInformation`}
                  component={"div"}
                  className={"input-feedback"}
                />
              </>
            )}
          </Field>
        </Col>
        {isWelltowerAccountant && (
          <Col>
            <WrappedSelectInput
              id={`unmappedItems.${index}.welltowerSelectedBUCode`}
              label={"Welltower BU/Entity"}
              value={unmappedItem.welltowerSelectedBUCode}
              required={true}
              options={destinationBuCodes}
              onChange={formikProps.setFieldValue}
              onBlur={formikProps.setFieldTouched}
            />
          </Col>
        )}
      </Row>
    );
  }
}
