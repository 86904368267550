/** @format */

import {
  deleteWithToken,
  fetchJsonWithToken,
  patchWithToken,
  postWithToken,
} from "../util/FetchUtils";
import { AbstractAuthState } from "../util/OktaUtils";

export const getAllOperators = (auth: AbstractAuthState) => {
  return fetchJsonWithToken("/api/operators", auth);
};

export const getFavoriteOperators = (auth: AbstractAuthState) => {
  return fetchJsonWithToken(`/api/operators/my`, auth);
};

export const getOperatorRequirements = async (
  operatorId: number,
  auth: AbstractAuthState,
) => {
  const operatorRequirementsEndpoint = `/api/operators/${operatorId}/requirements`;
  return fetchJsonWithToken(operatorRequirementsEndpoint, auth);
};

export const getOperatorSettings = (
  operatorId: number,
  portfolio: string,
  auth: AbstractAuthState,
) => {
  const url = `/api/operatorSettings/byOperator/${operatorId}/${portfolio}`;
  return fetchJsonWithToken(url, auth);
};

export const getOperatorCustomHeadings = (
  operatorId: number,
  auth: AbstractAuthState,
) => {
  const url = `/api/operators/${operatorId}/customHeadings`;
  return fetchJsonWithToken(url, auth);
};

export const updateOperatorSettings = (
  settingsId: number,
  auth: AbstractAuthState,
  settings: any,
) => {
  const url = `/api/operatorSettings/${settingsId}`;
  return patchWithToken(url, auth, settings);
};

export const operatorUsersURL = (operatorId: number) =>
  `/api/operators/${operatorId}/users`;

export const allOperatorUsersURL = () => `/api/users`;

const operatorUserAction = (
  operatorId: number,
  userId: string,
  action: "suspend" | "unsuspend" | "deactivate" | "activate" | "reactivate",
  auth: AbstractAuthState,
) => {
  const url = `${operatorUsersURL(operatorId)}/${userId}/${action}`;
  return postWithToken(url, auth, {});
};

export const getOperatorUserActions = (
  operatorId: number,
  authState: AbstractAuthState,
  triggerReload?: any,
) => ({
  activateUser: async (userId: string) =>
    operatorUserAction(operatorId, userId, "activate", authState).then(
      triggerReload,
    ),
  reactivateUser: async (userId: string) =>
    operatorUserAction(operatorId, userId, "reactivate", authState).then(
      triggerReload,
    ),
  suspendUser: async (userId: string) =>
    operatorUserAction(operatorId, userId, "suspend", authState).then(
      triggerReload,
    ),
  unsuspendUser: async (userId: string) =>
    operatorUserAction(operatorId, userId, "unsuspend", authState).then(
      triggerReload,
    ),
  deactivateUser: async (userId: string) =>
    operatorUserAction(operatorId, userId, "deactivate", authState).then(
      triggerReload,
    ),
  deleteUser: async (userId: string) =>
    deleteWithToken(
      `${operatorUsersURL(operatorId)}/${userId}`,
      authState,
    ).then(triggerReload),
  createUser: async (payload: any) =>
    postWithToken(operatorUsersURL(operatorId), authState, payload).then(
      triggerReload,
    ),
  updateUser: async (userId: string, payload: any) =>
    postWithToken(
      operatorUsersURL(operatorId) + "/" + userId,
      authState,
      payload,
    ).then(triggerReload),
});

export const addOperatorAsFavorite = (
  operatorId: number,
  auth: AbstractAuthState,
) => {
  const url = `/api/operators/${operatorId}/my`;
  return postWithToken(url, auth, {});
};

export const deleteOperatorAsFavorite = (
  operatorId: number,
  auth: AbstractAuthState,
) => {
  const url = `/api/operators/my/${operatorId}`;
  return deleteWithToken(url, auth);
};

export const addOperatorCustomHeading = (
  operatorId: number,
  payload: any,
  auth: AbstractAuthState,
) => {
  const url = `/api/operators/${operatorId}/customHeadings`;
  return postWithToken(url, auth, payload);
};

export const deleteOperatorCustomHeading = (
  operatorId: number,
  customHeadingId: number,
  auth: AbstractAuthState,
) => {
  const url = `/api/operators/${operatorId}/customHeadings/${customHeadingId}`;
  return deleteWithToken(url, auth);
};
