/** @format */
import React from "react";
import { formatDateISO, parseDate } from "../../util/DateUtils";
import DatePicker from "react-date-picker";
import { ErrorMessage } from "formik";

interface WrappedDateInputProps {
  id: string;
  label?: string;
  value?: any;
  required?: boolean;
  onChange: (s: string, v?: any) => void;
  onBlur: (s: string, v?: any) => void;
}

const WrappedDateInput = (props: WrappedDateInputProps) => {
  const { id, label, value, required, onChange } = props;
  const handleChange = (value: any) => {
    // this is going to call setFieldValue and manually update formik values
    onChange(id, formatDateISO(value));
  };

  // react-select has blur hooks to call Formik, but react-date-picker doesn't
  //handleBlur = () => {
  //  // this is going to call setFieldTouched and manually update formik touched
  //  this.props.onBlur(this.props.id, true);
  //};

  return (
    <div>
      <label htmlFor={id} className={required ? "label-required" : ""}>
        {label}
      </label>
      <DatePicker
        className={"form-control"}
        onChange={handleChange}
        value={parseDate(value)}
        calendarType={"US" /* TODO - localize by user?*/}
        clearIcon={required ? null : undefined}
        autoFocus={false}
      />
      <ErrorMessage name={id} component={"div"} className={"input-feedback"} />
    </div>
  );
};

export default WrappedDateInput;
