/** @format */
import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import SubmittedFile from "../../types/SubmittedFile";
import { downloadUrl } from "../../util/OneTimeDownloader";
import useFetchJsonWithLoading from "../../hooks/useFetchJsonWithLoadingHook";
import RequirementDueDate from "../../types/RequirementDueDate";
import DatePicker from "react-date-picker";
import dayjs from "dayjs";
import RPADashboardPicker from "../rpa-dashboard/RPADashboardPicker";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "../../components/LoadingSpinners";

const RPADownload = () => {
  const [dateFilter, setDateFilter] = useState(
    dayjs().date(1).subtract(1, "day"),
  );
  const { authState } = useOktaAuth();
  const { documentType }: any = useParams();
  const { data: requirementDueDates, loading } = useFetchJsonWithLoading(
    `/api/requirementDueDates/byDocumentType/${documentType}/byMonth/${dateFilter.format(
      "YYYY-MM",
    )}`,
    authState,
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  // Loop through remaining RDDs and fetch all submitted files
  const submittedFiles = requirementDueDates
    .map((rdd: RequirementDueDate) => {
      if (rdd.latestFile) {
        rdd.latestFile.requirementDueDate = rdd;
      }
      return rdd.latestFile;
    })
    .filter((f: SubmittedFile) => !!f);

  return (
    <Container className={"rpa-dashboard"}>
      <Row>
        <Col>
          <RPADashboardPicker />
        </Col>
      </Row>
      <Row>
        <Col>
          <DatePicker
            className="form-control"
            format="MM/yyyy"
            maxDetail="year"
            view="year"
            onChange={(val: any) => {
              setDateFilter(dayjs(val));
            }}
            value={dateFilter.toDate()}
            calendarType={"US" /* TODO - localize by user?*/}
            clearIcon={null}
          />
        </Col>
      </Row>
      {!submittedFiles.length ? (
        <Row>
          <Col className={"text-center"}>
            <Card>
              <CardBody data-botid={"downloadNoFiles"}>
                {!documentType
                  ? "No document type specified"
                  : `No ${documentType} files available for the selected month`}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        submittedFiles.map((file: SubmittedFile) => {
          const operatorName =
            file.requirementDueDate.requirement.operator.name;
          const periodDate = dayjs(file.requirementDueDate.periodEndDate);
          const extension = file.originalFilename.split(".").pop();
          const fileName = `${operatorName} ${periodDate.format(
            "YYYY_MM",
          )} OOD.${extension}`;
          return (
            <div key={file.id}>
              <Button
                color={"link"}
                style={{ color: "#007bff" }}
                className={"font-weight-light pr-1 align-baseline"}
                onClick={() =>
                  downloadUrl(
                    authState,
                    `${file.originalFileDownloadTokenURI}?downloadFileName=${fileName}`,
                  )
                }
              >
                {fileName}
              </Button>
            </div>
          );
        })
      )}
    </Container>
  );
};

export default RPADownload;
