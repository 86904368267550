/** @format */
import React, { useState } from "react";
import _ from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import { AbstractAuthState, isWelltowerDataAdmin } from "../../util/OktaUtils";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import useFetchJsonWithLoading from "../../hooks/useFetchJsonWithLoadingHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { formatDateDayOfWeek } from "../../util/DateUtils";
import { downloadUrl } from "../../util/OneTimeDownloader";
import FileUploadButton from "../../components/file-upload-button/FileUploadButton";
import Exception from "../../components/Exception";
import Modal from "react-responsive-modal";
import ConfirmationDialog from "../../components/operator-users/ConfirmationDialog";
import {
  deleteWithToken,
  postWithToken,
  putWithToken,
} from "../../util/FetchUtils";
import WithModal from "../../components/WithModal";
import { Formik, FormikProps } from "formik";
import WrappedDateInput from "../../components/form/WrappedDateInput";
import WrappedSelectInput from "../../components/form/WrappedSelectInput";
import {
  helperObjectToSelectOption,
  realValueToSelectOption,
} from "../../util/FormUtils";
import dayjs from "dayjs";
import WrappedTextInput from "../../components/form/WrappedTextInput";
import { LoadingSpinner } from "../../components/LoadingSpinners";

const NationalHolidayEditForm = (props: any) => {
  const saveHoliday = (data: any, callback: Function) => {
    if (props.holiday?.id) {
      putWithToken(
        `/api/nationalHolidays/${props.holiday?.id}`,
        props.authState,
        data.holiday,
      ).then((data: any) => {
        callback && callback.call(null);
        props.closeModal();
        props.onSave();
      });
    } else {
      postWithToken(
        "/api/nationalHolidays",
        props.authState,
        data.holiday,
      ).then((data: any) => {
        callback && callback.call(null);
        props.closeModal();
        props.onSave();
      });
    }
  };

  let { holiday, deleteHoliday, closeModal, countryOptions } = props;

  const initialValues = {
    id: holiday?.id,
    country: holiday?.country?.name,
    date: holiday?.date && dayjs(holiday?.date).format("YYYY-MM-DD"),
    comment: holiday?.comment,
  };

  return (
    <Formik
      initialValues={{ holiday: initialValues }}
      onSubmit={(values, actions) => {
        // Deep copy, just in case this values object isn't ours to mutate.
        let payload = JSON.parse(JSON.stringify(values));

        // Convert Country from string id or react-select object to an enum string
        if (values.holiday.country) {
          payload.holiday.country =
            (values.holiday.country && values.holiday.country.value) ||
            values.holiday.country;
        }

        saveHoliday(payload, () => actions.setSubmitting(false));
      }}
      validate={(values: any) => {
        let errors: any = { holiday: {} };
        let holiday: any = values.holiday;

        if (!holiday.date) {
          errors.holiday.date = "Please enter a date.";
        }

        if (Object.keys(errors.holiday).length > 0) {
          return errors;
        } else {
          return {};
        }
      }}
    >
      {(props: FormikProps<any>) => {
        return (
          <div className={"editForm"}>
            <h1 className="propertyName">National Holiday</h1>
            <form className="editFormForm" onSubmit={props.handleSubmit}>
              <div className="form-group">
                <WrappedSelectInput
                  id={"holiday.country"}
                  label={"Country"}
                  value={realValueToSelectOption(
                    props.values.holiday.country,
                    countryOptions,
                  )}
                  disabled={!!holiday?.id}
                  required={true}
                  clearable={false}
                  options={countryOptions.map(helperObjectToSelectOption)}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                />

                <WrappedDateInput
                  id={"holiday.date"}
                  label={"Date"}
                  value={props.values.holiday.date}
                  required={true}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                />
                <WrappedTextInput
                  id="holiday.comment"
                  placeholder="Comment"
                  label="Comment"
                />
              </div>

              {holiday?.id && deleteHoliday && (
                <ConfirmationDialog
                  key={"Delete holiday?"}
                  title={"Delete holiday?"}
                  confirmLabel={"Delete holiday"}
                  buttonColor={"danger"}
                  message={
                    <>
                      <p>Are you sure you want to delete this holiday?</p>
                      <p>
                        <i>{holiday.date || ""}</i>
                      </p>
                    </>
                  }
                  action={() => {
                    deleteHoliday(holiday.id).then((response: any) => {
                      closeModal && closeModal();
                    });
                  }}
                >
                  {({ onClick }: { onClick: any }) => (
                    <Button
                      title={"Delete holiday"}
                      color={"danger"}
                      onClick={onClick}
                      disabled={props.isSubmitting}
                    >
                      <small data-testid={"delete-noliday-button"}>
                        <FontAwesomeIcon
                          icon={["far", "trash-alt"]}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </small>
                    </Button>
                  )}
                </ConfirmationDialog>
              )}

              <Button
                type={"submit"}
                color={"primary"}
                disabled={props.isSubmitting}
                className={"float-right wtop-btn"}
              >
                Save Updates
              </Button>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

const NationalHolidayEditFormButton = WithModal(
  ({
    holiday,
    authState,
    onSave,
    ...props
  }: {
    holiday: any;
    authState: AbstractAuthState;
    onSave: Function;
  } & Partial<any>) => (
    <div>
      <Button color={"primary"} className="wtop-btn" onClick={props.showModal}>
        {holiday && holiday.id ? "Edit" : "New Holiday"}
      </Button>
      {props.modalContent(
        <NationalHolidayEditForm
          holiday={holiday}
          {...props}
          authState={authState}
          onSave={onSave}
        />,
        {
          showCloseIcon: true,
          modalStyles: { width: "400px" },
          closeOnOverlayClick: false,
          noAutoFocus: true,
        },
      )}
    </div>
  ),
);

const NationalHolidaysPage = () => {
  const { authState } = useOktaAuth();

  const { data: countryOptions } = useFetchJsonWithLoading(
    "/api/countries",
    authState,
  );
  const { data: years, forceReload: reloadYears } = useFetchJsonWithLoading(
    "/api/nationalHolidays/years",
    authState,
  );

  const [showUploadErrorModal, setShowUploadErrorModal] = useState(false);
  const [uploadError, setUploadError] = useState();

  const [currentCountry, setCurrentCountry] = useState();
  const [currentYear, setCurrentYear] = useState();

  const loadCountry = currentCountry || "US";
  const loadYear = currentYear || _.max(years) || new Date().getFullYear();

  const loadUrl =
    !!loadCountry && !!loadYear
      ? `/api/nationalHolidays/${loadCountry}/${loadYear}`
      : undefined;
  const {
    data: holidays,
    loading: holidaysLoading,
    forceReload: reloadHolidays,
  } = useFetchJsonWithLoading(loadUrl, authState);

  const canEdit = isWelltowerDataAdmin(authState);

  if (!canEdit)
    return (
      <Card>
        <CardBody>Access denied.</CardBody>
      </Card>
    );

  const allYears = years.map((l: number) => ({
    value: l,
    label: l,
  }));

  const allCountries = countryOptions.map((l: any) => ({
    value: l.name,
    label: l.displayName,
  }));

  const deleteHoliday = async (id: number) => {
    await deleteWithToken(`/api/nationalHolidays/${id}`, authState);
    reloadHolidays();
  };

  return (
    <Container>
      <Row>
        <Col lg={12}>
          <h3 style={{ margin: "0.75rem 0" }}>National Holidays</h3>
          <p>
            Weekday holidays are used to calculate Business Days overdue dates.
            You can enter holidays that fall on weekends, but they will not
            affect any overdue date calculations.
          </p>
          <p>
            Uploading a file will replace <em>all</em> holidays for any
            (country, year) combinations in the file. e.g. If you upload a file
            with a single UK 2022 holiday, all UK 2022 holidays will be deleted
            before loading the holiday from the file.
          </p>
        </Col>
        <Col lg={3}>
          <Select
            id={"country-select"}
            options={allCountries}
            onChange={(e: any) => {
              setCurrentCountry(e.value);
            }}
            value={allCountries?.find(
              (a: any) => a.value === (currentCountry || "US"),
            )}
          />
        </Col>
        <Col lg={3}>
          <Select
            id={"year-select"}
            options={allYears}
            onChange={(e: any) => {
              setCurrentYear(e.value);
            }}
            value={
              currentYear
                ? allYears.find((a: any) => a.value === currentYear)
                : _.maxBy(allYears, "value")
            }
          />
        </Col>
        <Col lg={3}>
          <Button
            className="wtop-btn"
            color={"primary"}
            onClick={() =>
              downloadUrl(authState, `${loadUrl}/download/downloadToken`)
            }
          >
            Download
          </Button>
        </Col>
        <Col lg={3}>
          <FileUploadButton
            onFileUploadSuccess={(data: any) => {
              reloadYears() && reloadHolidays();
            }}
            btnLabel="Upload"
            uploadUrl={"/api/nationalHolidays/upload"}
            onFileUploadError={(err: any) => {
              setShowUploadErrorModal(true);
              setUploadError(err && err.response && err.response.data);
            }}
          />
          <Modal
            open={showUploadErrorModal}
            onClose={() => {
              setShowUploadErrorModal(false);
            }}
            showCloseIcon={true}
            closeOnOverlayClick={true}
            styles={{ modal: { width: "600px" } }}
          >
            <Row>
              <Col>
                <Row>
                  <Col>
                    <FontAwesomeIcon
                      icon={"circle-exclamation"}
                      size={"3x"}
                      color={"#dc3545"}
                    />
                    <h4
                      className={"text-center"}
                      style={{ marginTop: "-2rem" }}
                    >
                      {"Error"}
                    </h4>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>An error occurred uploading the file.</p>
                    <Exception e={uploadError} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
      {!(loadCountry && loadYear) || holidaysLoading ? (
        <Row>
          <Col>
            <LoadingSpinner />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <NationalHolidayEditFormButton
                onSave={reloadHolidays}
                canEdit={canEdit}
                authState={authState}
                countryOptions={countryOptions}
              />
            </Col>
          </Row>
          {holidays && holidays.length > 0 ? (
            <Row>
              <Col>
                <Table style={{ margin: "0.75em 0" }}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Weekday</th>
                      <th>Comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {holidays.map((nh: any) => (
                      <tr key={nh.id}>
                        <td>{nh.date}</td>
                        <td>{formatDateDayOfWeek(nh.date)}</td>
                        <td>{nh.comment}</td>
                        {canEdit && (
                          <>
                            <td>
                              <NationalHolidayEditFormButton
                                holiday={nh}
                                onSave={reloadHolidays}
                                deleteHoliday={deleteHoliday}
                                canEdit={canEdit}
                                authState={authState}
                                countryOptions={countryOptions}
                              />
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col>No holidays loaded for the selected country and year.</Col>
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default NationalHolidaysPage;
