/** @format */

import React, { createRef, RefObject } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import _ from "lodash";
import {
  deleteWithToken,
  fetchPageableAllWithToken,
  postWithToken,
  putWithToken,
} from "../../util/FetchUtils";
import WrappedSelectInput from "../../components/form/WrappedSelectInput";
import "./BaseMappings.scss";
import FileUploadButton from "../../components/file-upload-button/FileUploadButton";
import MappingsTable from "../../components/mappings-table/MappingsTable";
import {
  ArrayHelpers,
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import { downloadUrl } from "../../util/OneTimeDownloader";
import { loadWelltowerBusinessUnits } from "../../api/SharedAPI";
import { canEditMappings, isWelltower } from "../../util/OktaUtils";
import Modal from "react-responsive-modal";
import UnmappedAccount from "../../components/submitted-file/unmapped-items/UnmappedAccount";
import UnmappedProperty from "../../components/submitted-file/unmapped-items/UnmappedProperty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeveloperModeOnly } from "../../components/DeveloperMode";
import { getOperatorRequirements } from "../../api/OperatorAPI";
import Requirement from "../../types/Requirement";
import {
  getMappings,
  getMappingSetRequirements,
  getMappingSets,
  updateMapping,
  deleteMapping,
  getMappingSetOperatorCaption,
  getMappingSetMappableAccounts,
  getMappingSetSegmentCodes,
} from "../../api/MappingsAPI";
import Exception from "../../components/Exception";
import { Redirect } from "react-router-dom";
import { chartOfAccountsCaptionsUrl } from "../../util/ApiUrlUtils";
import { Link } from "react-router-dom";
import Operator from "../../types/Operator";
import { formatDateTime } from "../../util/DateUtils";
import { buPickerLabel, mappingSetLabel } from "../../util/NameUtils";
import { OperatorPickerId } from "../../types/PickerTypes";
import { ContextOperatorPicker } from "../../components/filters/ContextOperatorPicker";

// TODO - probably should try to rebuild these pages without JS inheritance and as functional components
//        May be able to move shared logic to hooks? Or just separate functional components?
export default class BaseMappingsPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      loadingReqs: true,
      loadingRequirementCount: true,
      mappingSets: [],
      mappings: [],
      mappingSetRequirements: [],
      totalRequirementCount: 0,
      unmappedItems: [],
      showSuccessMsg: false,
      welltowerAccounts: [],
      segmentCodes: [],
      operatorAccountGroupings: [],
      buCodes: [],
      isWelltowerAccountant: false,
      showConfirmDeleteModal: false,
      showRenameMappingSetModal: false,
      showUploadErrorModal: false,
      showCreateMappingModal: false,
      uploadError: "",
      currentMappingSetName:
        props.location.state && props.location.state.currentMappingSet
          ? props.location.state.currentMappingSet.name
          : "",
      currentOperator:
        props.location.state && props.location.state.currentOperator,
      currentMappingSet:
        props.location.state && props.location.state.currentMappingSet
          ? {
              value: props.location.state.currentMappingSet.id,
              label: mappingSetLabel(props.location.state.currentMappingSet),
              object: props.location.state.currentMappingSet,
            }
          : null,
      collapsed: new Map(),
    };

    this.updateCurrentMappingSet = this.updateCurrentMappingSet.bind(this);
    this.onSaveUnmappedItems = this.onSaveUnmappedItems.bind(this);
    this.updateIndividualMapping = this.updateIndividualMapping.bind(this);
    this.loadMappingSets = this.loadMappingSets.bind(this);
    this.loadMappings = this.loadMappings.bind(this);
    this.loadOperatorAccountGrouping =
      this.loadOperatorAccountGrouping.bind(this);
    this.loadWelltowerAccounts = this.loadWelltowerAccounts.bind(this);
    this.loadSegmentCodes = this.loadSegmentCodes.bind(this);
    this.updateIndividualMapping = this.updateIndividualMapping.bind(this);
    this.onSubmitMapping = this.onSubmitMapping.bind(this);
    this.getEditForm = this.getEditForm.bind(this);
    this.loadUnmappedItemExtraData = this.loadUnmappedItemExtraData.bind(this);
  }

  private myRef: RefObject<HTMLDivElement> = createRef();

  async componentDidMount() {
    const isWelltowerAccountant = isWelltower(this.props.authState);
    this.setState(
      {
        isWelltowerAccountant,
      },
      () => {
        if (
          this.props.location.state &&
          this.props.location.state.currentOperator
        ) {
          //console.log(
          //  `BaseMappingsPage componentDidMount got state operator ${this.props.location.state.currentOperator.id}`
          //);
          this.props.setOperator(
            this.props.location.state.currentOperator.id,
            this.props.location.state.currentOperator,
          );
          this.updateCurrentOperator(
            this.props.selectedOperatorId,
            this.props.selectedOperatorObj,
            true,
          );
        } else if (this.props.location.state?.currentOperatorId) {
          //console.log(
          //  `BaseMappingsPage componentDidMount got state operator ${this.props.location.state.currentOperator.id}`
          //);
          this.props.setOperator(
            this.props.location.state.currentOperatorId,
            null, // TODO ??
          );
          this.updateCurrentOperator(
            this.props.selectedOperatorId,
            this.props.selectedOperatorObj,
            true,
          );
        } else {
          this.updateCurrentOperator(
            this.props.selectedOperatorId,
            this.props.selectedOperatorObj,
          );
        }

        //if (this.state.currentOperatorId) {
        //  this.updateCurrentOperator(
        //    this.state.currentOperatorId,
        //    this.state.currentOperator,
        //    true
        //  );
        //}
        if (this.state.currentMappingSet) {
          this.updateCurrentMappingSet(
            "mapping-set-select",
            this.state.currentMappingSet,
          );
        }
      },
    );
  }
  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.selectedOperatorId !== this.props.selectedOperatorId) {
      //console.debug(
      //  `BaseMappingsPage componentDidUpdate prop change ${prevProps.selectedOperatorId} => ${this.props.selectedOperatorId}`
      //);
      this.updateCurrentOperator(
        this.props.selectedOperatorId,
        this.props.selectedOperatorObj,
      );
    }
  }

  async loadMappingSets(
    operatorId: number,
    doNotResetCurrentMappingSet?: boolean,
    callback?: () => {},
  ) {
    try {
      const url = this.getMappingSetUrl(operatorId);
      const accessToken = this.props.authState;
      const mappingSets = await getMappingSets(url, accessToken);

      if (!doNotResetCurrentMappingSet && mappingSets.length > 0) {
        this.loadMappings(operatorId, mappingSets[0].id);
        this.loadOperatorAccountGrouping(operatorId, mappingSets[0].id);
        this.loadWelltowerAccounts(operatorId, mappingSets[0].id);
        this.loadSegmentCodes(operatorId, mappingSets[0].id);
        this.loadMappingSetRequirements(operatorId, mappingSets[0].id);
        this.loadTotalRequirementCount(operatorId);
      }

      this.setState(
        {
          mappingSets: mappingSets,
          mappings: [],
          mappingSetRequirements: [],
          loading: mappingSets.length === 0 ? false : true,
          loadingReqs: mappingSets.length === 0 ? false : true,
          loadingRequirementCount: true,
          totalRequirementCount: 0,
          currentMappingSetName: doNotResetCurrentMappingSet
            ? this.state.currentMappingSetName
            : mappingSets && mappingSets[0] && mappingSets[0].name,
          currentMappingSet: doNotResetCurrentMappingSet
            ? this.state.currentMappingSet
            : mappingSets && mappingSets[0]
              ? {
                  value: mappingSets[0].id,
                  label: mappingSetLabel(mappingSets[0]),
                  object: mappingSets[0],
                }
              : null,
        },
        callback,
      );
    } catch (error) {
      console.error(error);
      this.setState({ loading: false, loadingReqs: false });
    }
  }

  // TODO: this should be specific to a particular mapping set
  async loadOperatorUnmappedItems(operatorId: number) {
    let { authState } = this.props;

    fetchPageableAllWithToken(this.getUnmappedItemsUrl(operatorId), authState)
      .then((data: any) => {
        const unmappedItems = _.sortBy(
          data
            ? data.filter((i: any) =>
                this.state.isWelltowerAccountant
                  ? !i.welltowerComplete
                  : !i.operatorComplete,
              )
            : [],
          "identifier",
        );
        this.setState({
          unmappedItems,
        });

        return data;
      })
      .then(this.loadUnmappedItemExtraData)
      .catch((error) => {
        console.error(error);
      });
  }

  async loadMappings(operatorId: number, mappingSetId: number) {
    try {
      const url = this.getMappingsUrl(operatorId, mappingSetId);
      const accessToken = this.props.authState;
      const mappings = await getMappings(url, accessToken);
      this.setState({ mappings, loading: false });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async loadOperatorAccountGrouping(operatorId: number, mappingSetId: number) {
    try {
      const url = this.getOperatorAccountGroupingUrl(operatorId, mappingSetId);
      if (!url) {
        return;
      }
      const accessToken = this.props.authState;
      const operatorCaptions = await getMappingSetOperatorCaption(
        url,
        accessToken,
      );

      const operatorAccountGroupings = operatorCaptions.map((it: any) => ({
        value: it.id,
        label: it.displayName,
      }));

      this.setState({ operatorAccountGroupings });
    } catch (error) {
      console.error(error);
    }
  }

  async updateIndividualMapping(data: any) {
    try {
      const currentOperatorId = this.props.selectedOperatorId;
      const { currentMappingSet } = this.state;
      const url = this.getMappingsUrl(
        currentOperatorId,
        currentMappingSet.value,
      );
      const accessToken = this.props.authState;
      const formattedData = this.formatIndividualMapping(data);
      const updatedMapping = await updateMapping(
        url,
        accessToken,
        formattedData,
      );
      this.setState((prevState: any) => {
        let newMappings = _.concat(prevState.mappings);
        const existingIdx = _.findIndex(
          newMappings,
          (existingMapping: any) => existingMapping.id === updatedMapping.id,
        );
        if (existingIdx !== -1) {
          newMappings[existingIdx] = updatedMapping;
        } else {
          newMappings.unshift(updatedMapping);
        }
        return {
          mappings: newMappings,
          loading: false,
        };
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async loadMappingSetRequirements(operatorId: number, mappingSetId: number) {
    try {
      const url = this.getRequirementsUrl(operatorId, mappingSetId);
      const accessToken = this.props.authState;
      const mappingSetRequirements = await getMappingSetRequirements(
        url,
        accessToken,
      );
      this.setState({ mappingSetRequirements, loadingReqs: false });
    } catch (error) {
      console.error(error);
      this.setState({ loadingReqs: false });
    }
  }
  async loadTotalRequirementCount(operatorId: number) {
    if (operatorId > 0) {
      try {
        const totalRequirements = await getOperatorRequirements(
          operatorId,
          this.props.authState,
        );

        this.setState({
          totalRequirementCount: totalRequirements
            ? totalRequirements.length
            : 0,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.setState({ loadingRequirementCount: false });
      }
    } else {
      this.setState({ loadingRequirementCount: false });
    }
  }

  async loadWelltowerAccounts(operatorId: number, mappingSetId: number) {
    let { authState } = this.props;
    try {
      const url = this.getMappingSetMappableAccountsUrl(
        operatorId,
        mappingSetId,
      );
      if (!url) {
        return;
      }
      const welltowerAccounts = await getMappingSetMappableAccounts(
        url,
        authState,
      );

      let transformedAccounts = welltowerAccounts.map((a: any) => {
        return {
          ...a, // TODO - change to object: a
          object: a,
          value: a.tableName ? a.name : a.id,
          label: a.tableName
            ? `${a.tableName} ${a.name} ${
                a.description === a.name ? "" : a.description
              }`.trim()
            : `${a.accountFull} ${a.description}`,
        };
      });
      transformedAccounts.unshift({ value: "IGNORE", label: "IGNORE" });
      this.setState({ welltowerAccounts: transformedAccounts });
    } catch (error) {
      console.error(error);
    }
  }

  async loadSegmentCodes(operatorId: number, mappingSetId: number) {
    let { authState } = this.props;
    try {
      const url = this.getMappingSetSegmentCodesUrl(operatorId, mappingSetId);
      if (!url) {
        return;
      }
      const segmentCodes = await getMappingSetSegmentCodes(url, authState);

      let transformedCodes = segmentCodes.map((a: string) => {
        return {
          value: a,
          label: a,
        };
      });
      this.setState({ segmentCodes: transformedCodes });
    } catch (error) {
      console.error(error);
    }
  }

  async loadBusinessUnits(operatorId: number) {
    try {
      let buCodes = await loadWelltowerBusinessUnits(
        operatorId,
        this.props.authState,
      );
      let transformedBuCodes = buCodes
        ? buCodes.map((bu: any) => {
            return {
              value: bu.code,
              label: buPickerLabel(bu),
              object: bu,
            };
          })
        : [];
      transformedBuCodes.unshift({ value: "IGNORE", label: "IGNORE" });
      this.setState({ buCodes: transformedBuCodes });
    } catch (error) {
      console.error(error);
    }
  }

  async loadUnmappedItemExtraData(data: any[]) {}

  getFormattedItems(data: any): any {
    return data;
  }

  formatIndividualMapping(rawMappingData: any): any {
    return rawMappingData;
  }

  getSaveMappingsUrl(operatorId: number): string {
    return "";
  }

  getUnmappedItemsUrl(operatorId: number): string {
    return "";
  }

  getPageTitle(): string {
    return "Account Mappings";
  }

  getFilterableTableProps(): any {
    return {
      headers: [],
      fields: [],
      noDataMsg: "",
      searchInputPlaceholder: "",
    };
  }

  getMappingType(): string {
    return "";
  }

  getMappingTypeDisplayName(): string {
    return "item";
  }

  getMappingTypeDisplayNamePlural(): string {
    return "items";
  }

  getMappingSetUrl(operatorId: number): string {
    return "";
  }

  getMappingsUrl(operatorId: number, mappingSetId: number): string {
    return "";
  }

  getOperatorAccountGroupingUrl(
    operatorId: number,
    mappingSetId: number,
  ): string {
    return "";
  }

  getMappingSetMappableAccountsUrl(
    operatorId: number,
    mappingSetId: number,
  ): string {
    return "";
  }

  getMappingSetSegmentCodesUrl(
    operatorId: number,
    mappingSetId: number,
  ): string {
    return "";
  }

  deleteMappingsUrl(
    operatorId: number,
    mappingSetId: number,
    mappingId: number,
  ): string {
    return "";
  }

  getRequirementsUrl(operatorId: number, mappingSetId: number): string {
    return "";
  }

  getEditForm(mapping: any, callback?: Function): React.Component | any {
    return null;
  }

  onEditFormSubmit(callback: any) {
    const onSubmit = this.onSubmitMapping;
    return function (rawData: any, formikActions: FormikHelpers<any>) {
      return onSubmit(rawData)
        .then((result) => {
          console.log("onSubmit then");
          if (callback) {
            callback();
          }
          return result;
        })
        .catch((error: any) =>
          formikActions.setErrors({ submission: error?.message || error }),
        )
        .finally(() => formikActions.setSubmitting(false));
    };
  }

  async renameMappingSet(name: string, mappingSetId: number) {
    try {
      const renamedMapping = await putWithToken(
        `${this.getMappingSetUrl(
          this.props.selectedOperatorId,
        )}/${mappingSetId}`,
        this.props.authState,
        { name },
      );

      let mappingSets = this.state.mappingSets.filter((mappingSet: any) => {
        return mappingSet.id !== mappingSetId;
      });
      mappingSets.push(renamedMapping);
      this.setState({
        mappingSets,
        currentMappingSet: {
          value: renamedMapping.id,
          label: mappingSetLabel(renamedMapping),
          object: renamedMapping,
        },
        currentMappingSetName: "",
      });
    } catch (error) {
      console.error(error);
    }
  }

  async delete(operatorId: number, { value: id }: { value: number }) {
    const url = `${this.getMappingSetUrl(operatorId)}/${id}`;
    deleteWithToken(url, this.props.authState)
      .then(() => {
        this.setState({ showConfirmDeleteModal: false });
        this.loadMappingSets(this.props.selectedOperatorId);
      })
      .catch((err) => {
        window.alert(err); // TODO - something better?
        this.setState({ showConfirmDeleteModal: false });
        this.loadMappingSets(this.props.selectedOperatorId);
      });
  }
  getMappingsDownloadUrl(operatorId: number, mappingSetId: number): string {
    return `${this.getMappingSetUrl(
      operatorId,
    )}/${mappingSetId}/mappingsDownload/downloadToken`;
  }

  scrollIntoView = () => {
    if (this.myRef.current) {
      this.myRef.current.scrollIntoView();
    }
  };

  async onSaveUnmappedItems(data: any, actions: any) {
    let formattedItems = this.getFormattedItems(data);
    this.setState({ showSuccessMsg: false }, () => {
      postWithToken(
        this.getSaveMappingsUrl(this.props.selectedOperatorId),
        this.props.authState,
        formattedItems,
      )
        .then((data: any) => {
          this.setState(
            (prevState: any) => ({
              showSuccessMsg: true,
              submissionError: undefined,
            }),
            () => {
              this.scrollIntoView();
              this.loadOperatorUnmappedItems(this.props.selectedOperatorId);
            },
          );
          setTimeout(() => {
            //this.setState({ showSuccessMsg: false }, () => {});
            actions.setSubmitting(false);
          }, 2500);
        })
        .catch((error: any) => {
          this.setState({
            submissionError: error.message || error,
            showSuccessMsg: false,
          });
        });
    });
  }

  async onSubmitMapping(mapping: any) {
    try {
      const currentOperatorId = this.props.selectedOperatorId;
      const { currentMappingSet } = this.state;
      const url = this.getMappingsUrl(
        currentOperatorId,
        currentMappingSet.value,
      );
      const accessToken = this.props.authState;
      const formattedMapping = this.formatIndividualMapping(mapping);
      await updateMapping(url, accessToken, formattedMapping);
      if (!mapping.id) {
        this.setState({ showCreateMappingModal: false });
      }
      this.loadMappings(currentOperatorId, currentMappingSet.value);
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      throw error; // So the caller doesn't continue its .then()
    }
  }

  async onDeleteMapping(mapping: any) {
    if (!mapping.id) {
      this.setState({ showCreateMappingModal: false });
    }

    try {
      const currentOperatorId = this.props.selectedOperatorId;
      const { currentMappingSet } = this.state;
      const url = this.deleteMappingsUrl(
        currentOperatorId,
        currentMappingSet.value,
        mapping.id,
      );
      const accessToken = this.props.authState;
      await deleteMapping(url, accessToken);
      this.loadMappings(currentOperatorId, currentMappingSet.value);
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async updateCurrentOperator(
    operatorId: OperatorPickerId,
    operator?: Operator,
    dontResetMapping?: boolean,
  ) {
    //console.log(`updateCurrentOperator ${operatorId}`);
    if (typeof operatorId === "number") {
      this.setState({
        //currentOperatorId: operatorId,
        currentOperator: operator, // TODO - can we replace this too?
      });
      if (operatorId) {
        await this.loadMappingSets(operatorId, dontResetMapping);
        await this.loadOperatorUnmappedItems(operatorId);
      }
    }
  }

  updateCurrentMappingSet(key: string, mappingSet: any) {
    this.setState({
      currentMappingSet: mappingSet,
      currentMappingSetName: mappingSet.object?.name || mappingSet.label,
    });
    this.loadMappings(this.props.selectedOperatorId, mappingSet.value);
    this.loadOperatorAccountGrouping(
      this.props.selectedOperatorId,
      mappingSet.value,
    );
    this.loadWelltowerAccounts(this.props.selectedOperatorId, mappingSet.value);
    this.loadSegmentCodes(this.props.selectedOperatorId, mappingSet.value);
    this.loadMappingSetRequirements(
      this.props.selectedOperatorId,
      mappingSet.value,
    );
    this.loadTotalRequirementCount(this.props.selectedOperatorId);
  }

  render() {
    if (!this.props.authState.isAuthenticated)
      return (
        <FontAwesomeIcon
          icon={"spinner"}
          spin
          size={"3x"}
          color={"#888"}
          className={"mt-5"}
        />
      );
    if (!isWelltower(this.props.authState)) {
      return <Redirect to="/" />;
    }

    //  let multiplesMessage: any = null;
    //
    //  // TODO - I think we should be submitting these only by mappingSet, and we should filter out duplicates within the mappingSet, and don't apply new mappings to other mappingSets.
    //  if (this.state.unmappedItems) {
    //    const count = this.state.unmappedItems.length;
    //    const identifierCount = _.uniq(
    //      this.state.unmappedItems.map((um: any) => um.identifier)
    //    ).length;
    //    if (count !== identifierCount) {
    //      multiplesMessage = (
    //        <p>
    //          If an Operator {this.getMappingTypeDisplayName()} appears more than
    //          once below, map it once and then click "Save Updates", and they will
    //          all be updated.
    //        </p>
    //      );
    //    }
    //  }

    const canEdit = canEditMappings(this.props.authState, this.state.operator);

    //const tooManyNewMappingsToShowMessage = this.state.unmappedItems.length >
    //  50 && (
    //  <p>
    //    More than 50 unmapped {this.getMappingTypeDisplayNamePlural()} were
    //    found. Only the first 50 are displayed.
    //  </p>
    //);

    //const mappingSetType =
    //  this.getMappingType() === "account"
    //    ? "accountMappingSet"
    //    : "entityMappingSet";
    let items = _.orderBy(
      this.state.unmappedItems,
      ["submittedFileSorter"],
      ["desc"],
    );

    let itemsGroupedByFile = _.groupBy(items, "submittedFileDescription");
    let fileDescriptions = new Map();
    Object.keys(itemsGroupedByFile).forEach((d) => {
      let parts = d.split("|||");

      fileDescriptions.set(d, {
        filename: parts[0],
        uploadUser: parts[1],
        uploadTime: parts[2], // not formatted
        requirementName: parts[3],
        date: parts[4], // already formatted
        mappingSetId: parts[5],
        mappingSetDestination: parts[6],
        mappingSetName: parts[7],
      });
    });

    // TODO - if we need another one of these Map<String,bool> with configurable default, extract to helper/hook
    const collapsed = this.state.collapsed;
    const initialCollapsed = fileDescriptions.size > 1;
    const isCollapsed = (fileDescription: string) => {
      if (!collapsed.has(fileDescription)) return initialCollapsed;
      return collapsed.get(fileDescription);
    };
    const toggleCollapse = (fileDescription: string) => {
      this.setState((prevState: any) => {
        const collapsedMap = prevState.collapsed; // Not actually new, mutate the same object but set it immediately

        const prevCollapsed = collapsedMap.has(fileDescription)
          ? collapsedMap.get(fileDescription)
          : initialCollapsed;

        collapsedMap.set(fileDescription, !prevCollapsed);
        return { collapsed: collapsedMap };
      });
    };
    return (
      <div ref={this.myRef}>
        <Container className="wtop-accountant-mappings">
          <Row className="header-row">
            <Col sm="4"></Col>
            <Col sm="4">
              <h4>{this.getPageTitle()}</h4>
            </Col>
          </Row>
          <Row className="header-row">
            <Col sm="4">
              <ContextOperatorPicker requireChoice />
            </Col>
          </Row>
          <Modal
            open={this.state.showConfirmDeleteModal}
            onClose={() => {
              this.setState({ showConfirmDeleteModal: false });
            }}
            showCloseIcon={true}
            closeOnOverlayClick={true}
            styles={{ modal: { width: "600px" } }}
          >
            <h3>Confirm</h3>
            <hr />
            <Row>
              <Col>
                <p>Are you sure you want to delete this mapping set?</p>
                <p>
                  <i>
                    {this.state.currentMappingSet
                      ? this.state.currentMappingSet.label
                      : "unknown"}
                  </i>
                </p>
              </Col>
            </Row>
            <Button
              color={"danger"}
              className={"wtop-btn"}
              block={true}
              onClick={() =>
                this.delete(
                  this.props.selectedOperatorId,
                  this.state.currentMappingSet,
                )
              }
            >
              Delete mapping set
            </Button>
          </Modal>
          <Modal
            open={this.state.showRenameMappingSetModal}
            onClose={() => {
              this.setState({ showRenameMappingSetModal: false });
            }}
            showCloseIcon={true}
            closeOnOverlayClick={true}
            styles={{ modal: { width: "600px" } }}
          >
            <h3>Rename</h3>
            <hr />
            <Row>
              <Col>
                <Input
                  className={"rename-input"}
                  value={this.state.currentMappingSetName}
                  onChange={(e) => {
                    this.setState({ currentMappingSetName: e.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  color={"primary"}
                  className={"wtop-btn"}
                  block={true}
                  onClick={() => {
                    this.setState({ showRenameMappingSetModal: false }, () => {
                      this.renameMappingSet(
                        this.state.currentMappingSetName,
                        this.state.currentMappingSet.value,
                      );
                    });
                  }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Modal>
          <Modal
            open={this.state.showUploadErrorModal}
            onClose={() => {
              this.setState({ showUploadErrorModal: false });
            }}
            showCloseIcon={true}
            closeOnOverlayClick={true}
            styles={{ modal: { width: "600px" } }}
          >
            <Row>
              <Col>
                <Row>
                  <Col>
                    <FontAwesomeIcon
                      icon={"circle-exclamation"}
                      size={"3x"}
                      color={"#dc3545"}
                    />
                    <h4
                      className={"text-center"}
                      style={{ marginTop: "-2rem" }}
                    >
                      {"Error"}
                    </h4>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>An error occurred uploading the file.</p>
                    <Exception e={this.state.uploadError} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal>
          <Row>
            <Col>
              {/* TODO - move this error/success handling down to the Formik per-file form */}
              {this.state.submissionError && (
                <Alert color="danger" fade={true}>
                  {this.state.submissionError}
                </Alert>
              )}
              {this.state.showSuccessMsg && (
                <Alert color="success" fade={true}>
                  {`Mapping saved.`}
                </Alert>
              )}
              {this.props.location.state &&
                this.props.location.state.backLink && (
                  <p>
                    Back to{" "}
                    <Link to={this.props.location.state.backLink}>
                      {this.props.location.state.backLinkName}
                    </Link>
                  </p>
                )}

              {items.length > 0 && (
                <h3>Unmapped {this.getMappingTypeDisplayNamePlural()}:</h3>
              )}

              {Object.keys(itemsGroupedByFile).map((fileDescription) => {
                const descriptionObj = fileDescriptions.get(fileDescription);
                return (
                  <Card key={`${fileDescription}`}>
                    <CardHeader>
                      <div className={"d-flex align-items-center"}>
                        <div
                          onClick={() => toggleCollapse(fileDescription)}
                          className={"mr-3"}
                        >
                          <FontAwesomeIcon
                            size={"2x"}
                            color={"#007bff"}
                            icon={
                              isCollapsed(fileDescription)
                                ? "chevron-right"
                                : "chevron-down"
                            }
                            className={"mr-2"}
                            title={
                              isCollapsed(fileDescription) ? "Show" : "Hide"
                            }
                          />
                        </div>
                        <div>
                          <div className={""}>
                            <h5 className={"d-inline font-weight-bold mr-3"}>
                              {descriptionObj.requirementName}
                            </h5>
                            <span className={"text-muted"}>
                              {descriptionObj.date}
                            </span>
                          </div>
                          <div>
                            <span
                              className={"font-weight-light align-baseline"}
                            >
                              {descriptionObj.filename}{" "}
                              <small>{`${
                                descriptionObj.uploadUser
                              } on ${formatDateTime(
                                descriptionObj.uploadTime,
                              )}`}</small>
                            </span>
                          </div>
                          <div>
                            <span>
                              {itemsGroupedByFile[fileDescription].length}{" "}
                              unmapped {this.getMappingTypeDisplayNamePlural()}{" "}
                              not in mapping set:{" "}
                            </span>
                            <span className={" font-weight-bold "}>
                              {descriptionObj.mappingSetName} (
                              {descriptionObj.mappingSetDestination})
                            </span>
                          </div>
                        </div>
                      </div>
                    </CardHeader>
                    <Collapse isOpen={!isCollapsed(fileDescription)}>
                      <CardBody className={"zzpx-0 zzpy-3"}>
                        {/* TODO - refactor this whole form out so it can track its own validation/success/failure separately from any other submittedFile forms */}
                        <Formik
                          initialValues={{
                            unmappedItems: itemsGroupedByFile[fileDescription],
                          }}
                          enableReinitialize={true}
                          onSubmit={
                            canEdit ? this.onSaveUnmappedItems : () => {}
                          }
                        >
                          {(props: FormikProps<any>) => {
                            return (
                              <Form>
                                <FieldArray
                                  name={"unmappedItems"}
                                  render={(arrayHelpers: ArrayHelpers) =>
                                    props.values.unmappedItems.map(
                                      (unmappedItem: any, index: number) => {
                                        if (
                                          this.getMappingType() === "account"
                                        ) {
                                          return (
                                            <UnmappedAccount
                                              unmappedItem={unmappedItem}
                                              index={index}
                                              formikProps={props}
                                              key={`${unmappedItem.identifier}-${index}`}
                                              operatorAccountGroupings={
                                                this.state
                                                  .operatorAccountGroupings
                                              }
                                              welltowerAccounts={
                                                this.state.welltowerAccounts
                                              }
                                              isWelltowerAccountant={
                                                this.state.isWelltowerAccountant
                                              }
                                              destination={
                                                unmappedItem.destination
                                              }
                                            />
                                          );
                                        } else {
                                          return (
                                            <UnmappedProperty
                                              unmappedItem={unmappedItem}
                                              index={index}
                                              formikProps={props}
                                              key={`${unmappedItem.identifier}-${index}`}
                                              buCodes={this.state.buCodes}
                                              isWelltowerAccountant={
                                                this.state.isWelltowerAccountant
                                              }
                                              destination={
                                                unmappedItem.destination
                                              }
                                            />
                                          );
                                        }
                                      },
                                    )
                                  }
                                />
                                <div className={"text-right"}>
                                  <Button
                                    type={"submit"}
                                    color={"primary"}
                                    disabled={!canEdit || props.isSubmitting}
                                    className={"wtop-btn"}
                                  >
                                    Save Updates
                                  </Button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </CardBody>
                    </Collapse>
                  </Card>
                );
              })}
            </Col>
          </Row>
          <Row className={"align-items-end"}>
            <Col>
              {/* Need a dummy Formik wrapper for context */}
              <Formik onSubmit={() => {}} initialValues={{}}>
                <WrappedSelectInput
                  id={"mapping-set-select"}
                  label={"Mapping Set"}
                  value={this.state.currentMappingSet}
                  options={this.state.mappingSets.map((ms: any) => {
                    return {
                      object: ms,
                      value: ms.id,
                      label: mappingSetLabel(ms),
                    };
                  })}
                  clearable={false}
                  onChange={this.updateCurrentMappingSet}
                  onBlur={() => {}}
                />
              </Formik>
            </Col>
            <Col className={"mb-3"}>
              {this.state.currentMappingSet && (
                <UncontrolledButtonDropdown
                  className={"mapping-set-action-dropdown"}
                >
                  <DropdownToggle caret>Actions</DropdownToggle>
                  <DropdownMenu>
                    {canEdit && (
                      <DropdownItem
                        onClick={() => {
                          this.setState({
                            showRenameMappingSetModal: true,
                          });
                        }}
                        data-testid="rename-mapping-set-menu-item"
                      >
                        Rename
                      </DropdownItem>
                    )}

                    <DropdownItem
                      onClick={() =>
                        downloadUrl(
                          this.props.authState,
                          this.getMappingsDownloadUrl(
                            this.props.selectedOperatorId,
                            this.state.currentMappingSet.value,
                          ),
                        )
                      }
                      data-testid="download-mapping-set-menu-item"
                    >
                      Download
                    </DropdownItem>

                    {canEdit && (
                      <DropdownItem
                        onClick={() => {
                          this.setState({ showConfirmDeleteModal: true });
                        }}
                        data-testid={"delete-mapping-set-menu-item"}
                      >
                        Delete
                      </DropdownItem>
                    )}
                    <DropdownItem
                      onClick={() => {
                        this.setState({ showCreateMappingModal: true });
                      }}
                      data-testid={"create-new-mapping-menu-item"}
                    >
                      Create New Mapping
                    </DropdownItem>
                    {this.getMappingType() === "account" && (
                      <DropdownItem
                        onClick={() =>
                          downloadUrl(
                            this.props.authState,
                            chartOfAccountsCaptionsUrl(),
                          )
                        }
                        data-testid={"download-available-captions-menu-item"}
                      >
                        Download List of Available Captions
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              )}
            </Col>
            {canEdit && (
              <Col sm={"3"} className={"mb-3"}>
                <FileUploadButton
                  onFileUploadSuccess={(data: any) => {
                    this.loadMappingSets(this.props.selectedOperatorId);
                  }}
                  btnLabel="Upload"
                  uploadUrl={this.getMappingSetUrl(
                    this.props.selectedOperatorId,
                  )}
                  onFileUploadError={(err: any) => {
                    this.setState({
                      showUploadErrorModal: true,
                      uploadError: err && err.response && err.response.data,
                    });
                  }}
                />
              </Col>
            )}
          </Row>
          {(this.state.loadingReqs || this.state.loadingRequirementCount) && (
            <Row>
              <Col>...</Col>
            </Row>
          )}
          {!this.state.loadingReqs && !this.state.loadingRequirementCount && (
            <Row>
              <Col>
                <p style={{ marginTop: "1rem" }}>
                  <DeveloperModeOnly>
                    <span className={"text-muted"}>
                      ID{" "}
                      {this.state.currentMappingSet &&
                        this.state.currentMappingSet.value}{" "}
                    </span>
                  </DeveloperModeOnly>
                  {this.state.currentMappingSet &&
                    (this.state.totalRequirementCount > 0 ? (
                      <>
                        Used in {this.state.mappingSetRequirements.length} of{" "}
                        {this.state.totalRequirementCount} requirement
                        {this.state.totalRequirementCount === 1 ? "" : "s"}.
                        {this.state.mappingSetRequirements.length > 0 &&
                          this.state.mappingSetRequirements.length <
                            this.state.totalRequirementCount &&
                          " - " +
                            this.state.mappingSetRequirements
                              .map((r: Requirement) => r.name)
                              .join(",")}
                      </>
                    ) : (
                      <>
                        Used in {this.state.mappingSetRequirements.length}{" "}
                        requirement
                        {this.state.mappingSetRequirements.length === 1
                          ? ""
                          : "s"}
                        .
                      </>
                    ))}
                </p>
              </Col>
            </Row>
          )}
        </Container>
        <Container fluid className="wtop-accountant-mappings">
          <Row>
            <Col>
              <MappingsTable
                data={this.state.mappings}
                welltowerAccounts={this.state.welltowerAccounts}
                segmentCodes={this.state.segmentCodes}
                accountGroupings={this.state.operatorAccountGroupings}
                welltowerBusinessUnits={this.state.buCodes}
                getEditForm={this.getEditForm}
                mappingsType={this.getMappingType()}
                canEdit={canEdit}
                {...this.getFilterableTableProps()}
                updateIndividualMapping={this.updateIndividualMapping}
              />
            </Col>
          </Row>
          <Modal
            data-testid={"create-mapping"}
            open={this.state.showCreateMappingModal}
            onClose={() => {
              this.setState({ showCreateMappingModal: false });
            }}
            showCloseIcon={true}
            closeOnOverlayClick={true}
            styles={{ modal: { width: "600px" } }}
          >
            <h2>Create New Mapping</h2>
            {this.getEditForm({})}
          </Modal>
        </Container>
      </div>
    );
  }
}
