/** @format */

import { ErrorMessage, Field, FieldInputProps } from "formik";
import React from "react";

const WrappedCheckboxInput = (m: any, props: any) => (
  /* For some reason, calling this as <TextInput> blurs after each char, but calling as a function in {} works fine? */
  <div className="editFormInput" key={m.id}>
    <div style={{ width: "100%" }}>
      <Field type="checkbox" name={m.id}>
        {({ field, form }: { field: FieldInputProps<any>; form: any }) => (
          <input
            type="checkbox"
            id={m.id}
            {...field}
            checked={
              form.values[
                m.id
              ] /* TODO - I think we can remove checked= after Formik 2.0.1 */
            }
            className={
              form.errors[m.id] && form.touched[m.id]
                ? "form-control text-input error"
                : "form-control text-input"
            }
            style={{
              display: "inline-block",
              verticalAlign: "bottom",
              marginRight: "1em",
              marginBottom: "3px",
              width: "auto",
            }}
            disabled={m.disabled}
          />
        )}
      </Field>
      <label
        htmlFor={m.id}
        className={m.required ? "label-required" : ""}
        style={{ display: "inline-block" }}
      >
        {m.label}
      </label>
    </div>
    <ErrorMessage name={m.id} component={"div"} className={"input-feedback"} />
  </div>
);

export default WrappedCheckboxInput;
