/** @format */

import React from "react";
import { withFormik } from "formik";
import WithModal from "./WithModal";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { AxiosRequestConfig, Method } from "axios";
import { withOktaAuth } from "@okta/okta-react";
import dayjs from "dayjs";
import Requirement from "../types/Requirement";
import WrappedSelectInput from "./form/WrappedSelectInput";
import WrappedDateInput from "./form/WrappedDateInput";
import {
  fetchJsonWithToken,
  fetchToState,
  getOktaTokenAuthHeader,
} from "../util/FetchUtils";
import WrappedNumericInput from "./form/WrappedNumericInput";
import {
  helperObjectToSelectOption,
  realValueToSelectOption,
} from "../util/FormUtils";
import { canEditRequirements } from "../util/OktaUtils";
import _ from "lodash";
import DocumentType, { DocumentTypeId } from "../types/DocumentType";
import ConfirmationDialog from "./operator-users/ConfirmationDialog";
import WrappedTextInput from "./form/WrappedTextInput";
import { LoadingSpinner } from "./LoadingSpinners";

// Formik pieces come almost directly from their examples.

// TODO - move these to flags on DocumentType too?
const postingBookDocTypeIds: DocumentTypeId[] = [
  "TRIAL_BALANCE",
  "TRIAL_BALANCE_NNN",
  "TRIAL_BALANCE_HLBV",
];
const segmentMappingDocTypeIds: DocumentTypeId[] = [
  "TRIAL_BALANCE",
  "TRIAL_BALANCE_HLBV",
];
const comboMappingDocTypeIds: DocumentTypeId[] = [
  "TRIAL_BALANCE",
  "TRIAL_BALANCE_HLBV",
];
const needsEtlDocTypeIds: DocumentTypeId[] = [
  "WEEKLY_LEASING_SUMMARY",
  "WEEKLY_SALES_FUNNEL",
];
const needsCoaDocTypeIds: DocumentTypeId[] = [
  "CAP_EX",
  "BUDGET_CAP_EX",
  "MONTHLY_CENSUS",
  "MONTHLY_CENSUS_NNN",
  "MONTHLY_CENSUS_HLBV",
];

const needsChartOfAccounts = (documentType: DocumentType | undefined) =>
  documentType &&
  (documentType.mapAccounts || needsCoaDocTypeIds.includes(documentType.id));

const RequirementEditForm = (props: any) => {
  const {
    requirement,
    values,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    closeModal,
    deleteRequirement,
    allOverdueDaysTypes,
    allGlDateSettings,
    allFrequencies,
    allDocumentTypes,
    allAccountMappingSets,
    allEntityMappingSets,
    allComboMappingSets,
    allSegmentMappingSets,
    allAccountNormalizationMethods,
    allPostingBooks,
    allPortfolios,
    allCountries,
    allReportingPriorities,
    allRpaEnabledTypes,
    allChartsOfAccounts,
    allNonCompliantTypes,
    canEdit,
    errors,
    setErrors,
  } = props;

  const allNotifyOperatorOnOverdueTypes = [true, false];

  const operator = props.operator || (requirement && requirement.operator);

  const operatorAccountMappingSets = allAccountMappingSets.filter(
    (s: any) => s.operator.id === operator.id,
  );
  const operatorEntityMappingSets = allEntityMappingSets.filter(
    (s: any) => s.operator.id === operator.id,
  );
  const operatorComboMappingSets = allComboMappingSets.filter(
    (s: any) => s.operator.id === operator.id,
  );
  //console.log("form", values);

  const documentTypeObj: DocumentType =
    (values.documentType && values.documentType.obj) ||
    allDocumentTypes.find((t: any) => t.id === values.documentType);

  const chartOfAccountsObj =
    (values.chartOfAccounts && values.chartOfAccounts.obj) ||
    allChartsOfAccounts.find((t: any) => t.id === values.chartOfAccounts);

  const bookPostingOptionsAll = allPostingBooks.map(helperObjectToSelectOption);
  const bookPostingOptions = bookPostingOptionsAll.filter((bso: any) =>
    chartOfAccountsObj?.books?.find((it: any) => bso.obj?.name === it.name),
  );

  const isCompliant = !realValueToSelectOption(
    values.nonCompliantFormat,
    allNonCompliantTypes,
  )?.value;
  return (
    <div className="editForm">
      <h1 className="propertyName">
        {operator ? operator.name : "New Requirement"}
      </h1>
      <form
        className="editFormForm"
        onSubmit={canEdit ? handleSubmit : () => false}
      >
        {!operator && (
          <div className="editFormInput">
            {
              <WrappedSelectInput
                id={"operator"}
                label={"Operator"}
                value={realValueToSelectOption(
                  values.operator,
                  props.allOperators,
                )}
                required={true}
                clearable={false}
                options={props.allOperators.map(helperObjectToSelectOption)}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            }
          </div>
        )}

        <div className="form-row">
          <div className="form-group col-sm-6">
            <WrappedSelectInput
              id={"documentType"}
              label={"Document Type"}
              value={realValueToSelectOption(
                values.documentType,
                allDocumentTypes,
              )}
              required={true}
              clearable={false}
              options={allDocumentTypes.map(helperObjectToSelectOption)}
              onChange={(id: string, value: any) => {
                setFieldValue(id, value);
                if (value.obj && value.obj.defaultFrequency)
                  setFieldValue(
                    "frequency",
                    helperObjectToSelectOption(value.obj.defaultFrequency),
                  );
                if (
                  value.obj &&
                  ![null, undefined].includes(
                    value.obj.defaultNotifyOperatorOnOverdue,
                  )
                )
                  setFieldValue(
                    "notifyOperatorOnOverdue",
                    helperObjectToSelectOption(
                      value.obj.defaultNotifyOperatorOnOverdue,
                    ),
                  );
                if (value.obj && !value.obj.process)
                  setFieldValue("nonCompliantFormat", undefined);
              }}
              onBlur={setFieldTouched}
            />
          </div>
          <div className="form-group col-sm-3">
            <WrappedSelectInput
              id={"frequency"}
              label={"Frequency"}
              value={realValueToSelectOption(values.frequency, allFrequencies)}
              required={true}
              clearable={false}
              options={allFrequencies.map(helperObjectToSelectOption)}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </div>
          {documentTypeObj?.process && (
            <div className="form-group col-sm-3">
              <WrappedSelectInput
                id={"nonCompliantFormat"}
                label={"Format"}
                value={realValueToSelectOption(
                  values.nonCompliantFormat,
                  allNonCompliantTypes,
                )}
                required={true}
                clearable={false}
                options={allNonCompliantTypes.map(helperObjectToSelectOption)}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-group col-sm-6">
            <WrappedTextInput
              id="name"
              required={true}
              placeholder="Requirement name"
              label="Requirement Name"
            />
          </div>
          <div className="form-group col-sm-6">
            <WrappedTextInput
              id="externalName"
              placeholder="Operator-facing name"
              label="Operator-facing Name"
            />
          </div>
        </div>
        {documentTypeObj &&
          isCompliant &&
          (documentTypeObj.mapEntities ||
            documentTypeObj.mapAccounts ||
            needsChartOfAccounts(documentTypeObj)) && (
            <div className="form-row">
              {documentTypeObj.mapEntities && (
                <div className="form-group col-sm-6">
                  <WrappedSelectInput
                    id={"entityMappingSet"}
                    label={"Property Mapping Set"}
                    value={realValueToSelectOption(
                      values.entityMappingSet,
                      operatorEntityMappingSets,
                    )}
                    required={true}
                    options={operatorEntityMappingSets.map(
                      helperObjectToSelectOption,
                    )}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </div>
              )}

              {documentTypeObj.mapAccounts && (
                <div className="form-group col-sm-6">
                  <WrappedSelectInput
                    id={"accountMappingSet"}
                    label={"Account Mapping Set"}
                    value={realValueToSelectOption(
                      values.accountMappingSet,
                      operatorAccountMappingSets,
                    )}
                    required={true}
                    options={operatorAccountMappingSets.map(
                      helperObjectToSelectOption,
                    )}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </div>
              )}
              {documentTypeObj.mapEntities && documentTypeObj.mapAccounts && (
                <>
                  {comboMappingDocTypeIds.includes(documentTypeObj.id) && (
                    <div className="form-group col-sm-6">
                      <WrappedSelectInput
                        id={"comboMappingSet"}
                        label={"Combo Mapping Set"}
                        value={realValueToSelectOption(
                          values.comboMappingSet,
                          operatorComboMappingSets,
                        )}
                        options={operatorComboMappingSets.map(
                          helperObjectToSelectOption,
                        )}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </div>
                  )}
                  {segmentMappingDocTypeIds.includes(documentTypeObj.id) && (
                    <div className="form-group col-sm-6">
                      <WrappedSelectInput
                        id={"subledgerMappingSet"} // TODO yardi finally rename?
                        label={"Segment Mapping Set"}
                        value={realValueToSelectOption(
                          values.subledgerMappingSet, // TODO yardi finally rename?
                          allSegmentMappingSets,
                        )}
                        options={allSegmentMappingSets.map(
                          helperObjectToSelectOption,
                        )}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </div>
                  )}
                </>
              )}
              {needsChartOfAccounts(documentTypeObj) && (
                <div className="form-group col-sm-6">
                  <WrappedSelectInput
                    id={"chartOfAccounts"}
                    label={"WELL Chart of Accounts"}
                    value={realValueToSelectOption(
                      values.chartOfAccounts,
                      allChartsOfAccounts,
                    )}
                    required={true}
                    options={allChartsOfAccounts.map(
                      helperObjectToSelectOption,
                    )}
                    onChange={(id: string, value: any) => {
                      setFieldValue(id, value);
                      if (value?.obj?.defaultPostingBook)
                        setFieldValue(
                          "postingBook",
                          helperObjectToSelectOption(
                            value.obj.defaultPostingBook,
                          ),
                        );
                      setFieldTouched("postingBook");
                    }}
                    onBlur={setFieldTouched}
                  />
                </div>
              )}
              {documentTypeObj.mapAccounts && (
                <div className="form-group col-sm-6">
                  <WrappedSelectInput
                    id={"accountNormalizationMethod"}
                    label={"Modify Operator Accounts"}
                    value={realValueToSelectOption(
                      values.accountNormalizationMethod,
                      allAccountNormalizationMethods,
                    )}
                    options={allAccountNormalizationMethods.map(
                      helperObjectToSelectOption,
                    )}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </div>
              )}
            </div>
          )}

        <div className="form-row">
          <div className="form-group col-sm-3">
            <WrappedDateInput
              id={"startDate"}
              label={"First Submission Date"}
              value={values.startDate}
              required={true}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </div>
          <div className="form-group col-sm-3">
            <WrappedDateInput
              id={"endDate"}
              label={"Last Submission Date"}
              value={values.endDate}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </div>
          {documentTypeObj &&
            isCompliant &&
            postingBookDocTypeIds.includes(documentTypeObj.id) &&
            bookPostingOptions?.length > 0 && (
              <div className="form-group col-sm-3">
                <WrappedSelectInput
                  id={"postingBook"}
                  label={"Book"}
                  value={realValueToSelectOption(
                    values.postingBook ||
                      chartOfAccountsObj?.defaultPostingBook,
                    allPostingBooks,
                  )}
                  required={true}
                  clearable={false}
                  options={bookPostingOptions}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </div>
            )}
          {documentTypeObj &&
            isCompliant &&
            documentTypeObj.id === "TRIAL_BALANCE" && (
              <div className="form-group col-sm-3">
                <WrappedSelectInput
                  id={"glDateSetting"}
                  label={"GL Date Setting"}
                  value={realValueToSelectOption(
                    values.glDateSetting,
                    allGlDateSettings,
                  )}
                  required={true}
                  clearable={false}
                  options={allGlDateSettings.map(helperObjectToSelectOption)}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </div>
            )}
          {documentTypeObj &&
            documentTypeObj.uploadMethod &&
            documentTypeObj.uploadMethod.name === "CLOUDTICITY" && (
              <div className="form-group col-sm-4">
                <WrappedTextInput
                  id="directUploadS3PathPrefix"
                  placeholder="Upload prefix"
                  label="Upload prefix"
                />
              </div>
            )}
        </div>
        <div className="form-row">
          <div className="form-group col-sm-3">
            <WrappedSelectInput
              id={"reportingPriority"}
              label={"Reporting Priority"}
              value={realValueToSelectOption(
                values.reportingPriority,
                allReportingPriorities,
              )}
              required={false}
              clearable={true}
              options={allReportingPriorities.map(helperObjectToSelectOption)}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </div>
          <div className="form-group col-sm-3">
            <WrappedSelectInput
              id={"portfolio"}
              label={"Portfolio"}
              value={realValueToSelectOption(values.portfolio, allPortfolios)}
              required={false}
              clearable={true}
              options={allPortfolios.map(helperObjectToSelectOption)}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </div>
          <div className="form-group col-sm-2">
            <WrappedSelectInput
              id={"country"}
              label={"Country"}
              value={realValueToSelectOption(values.country, allCountries)}
              required={false}
              clearable={true}
              options={allCountries.map(helperObjectToSelectOption)}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </div>
          {documentTypeObj && isCompliant && documentTypeObj.rpaEligible && (
            <div className="form-group col-sm-4">
              <WrappedSelectInput
                id={"rpaEnabled"}
                label={"ETL automation"}
                value={realValueToSelectOption(
                  values.rpaEnabled,
                  allRpaEnabledTypes,
                )}
                required={true}
                clearable={false}
                options={allRpaEnabledTypes.map(helperObjectToSelectOption)}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-group col-sm-3">
            <WrappedNumericInput
              id="daysUntilOverdue"
              required={true}
              placeholder={"10"}
              label={"Days until Overdue"}
            />
          </div>
          <div className="form-group col-sm-3">
            <WrappedSelectInput
              id={"overdueDaysType"}
              label={"Overdue days type"}
              value={realValueToSelectOption(
                values.overdueDaysType,
                allOverdueDaysTypes,
              )}
              required={true}
              clearable={false}
              options={allOverdueDaysTypes.map(helperObjectToSelectOption)}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </div>
          <div className="form-group col-sm-6">
            <WrappedSelectInput
              id={"notifyOperatorOnOverdue"}
              label={"Notify operator when overdue"}
              value={realValueToSelectOption(
                values.notifyOperatorOnOverdue,
                allNotifyOperatorOnOverdueTypes,
              )}
              required={true}
              clearable={false}
              options={allNotifyOperatorOnOverdueTypes.map(
                helperObjectToSelectOption,
              )}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </div>
        </div>
        <div className="editFormButton">
          <span className="requiredNote" style={{ float: "left" }}>
            * required fields
          </span>
          {errors && errors.submission && (
            <div
              className="invalid-feedback pr-3"
              style={{ display: "inline-block", width: "24em" }}
            >
              {errors.submission}
            </div>
          )}
          {canEdit && requirement && requirement.id && (
            <ConfirmationDialog
              title={"Delete requirement?"}
              message={`Are you sure you want to delete this requirement? (It won't work if there are submitted files or due dates.)`}
              buttonColor={"danger"}
              confirmLabel={"Delete"}
              action={() => {
                deleteRequirement(requirement)
                  .then((response: any) => {
                    if (response.data.status === "error") {
                      setErrors({ submission: response.data.error });
                    } else {
                      props.closeModal && props.closeModal();
                    }
                  })
                  .catch((error: any) =>
                    setErrors({ submission: error.message || error }),
                  );
              }}
            >
              {({ onClick }: { onClick: any }) => (
                <Button
                  onClick={onClick}
                  color={"danger"}
                  disabled={isSubmitting}
                >
                  <FontAwesomeIcon
                    icon={["far", "trash-alt"]}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Button>
              )}
            </ConfirmationDialog>
          )}
          <Button
            onClick={closeModal}
            color={"link"}
            disabled={/*!dirty || */ isSubmitting}
          >
            Cancel
          </Button>
          {canEdit && (
            <Button type="submit" color={"primary"} disabled={isSubmitting}>
              {requirement && requirement.id ? "Update" : "Create"}
            </Button>
          )}
        </div>
        {isSubmitting && (
          <div
            style={{
              position: "absolute",
              top: "1.2rem",
              height: "calc( 100% - 2.4rem)",
              width: "calc( 100% - 2.4rem)",
              backgroundColor: "white",
              opacity: 0.5,
            }}
          >
            <div
              style={{
                fontSize: "48px",
                position: "relative",
                top: "calc(50% - 24px)",
                left: "calc(50% - 24px)",
              }}
            >
              <LoadingSpinner />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

const EnhancedForm = withFormik({
  mapPropsToValues: (props) => {
    // Return relation .id or enum.name here
    const operator =
      (props.requirement && props.requirement.operator) || props.operator;
    let accountMappingSet, entityMappingSet;

    // For new requirements (not when editing existing), pre-choose a single mapping set
    if (operator && !(props.requirement && props.requirement.id)) {
      const operatorAccountMappingSets = props.allAccountMappingSets.filter(
        (s: any) => s.operator.id === operator.id,
      );
      const operatorEntityMappingSets = props.allEntityMappingSets.filter(
        (s: any) => s.operator.id === operator.id,
      );
      if (operatorAccountMappingSets.length === 1) {
        accountMappingSet = operatorAccountMappingSets[0].id;
      }
      if (operatorEntityMappingSets.length === 1) {
        entityMappingSet = operatorEntityMappingSets[0].id;
      }
      // Don't auto-pick a combo mapping set because those are much less common.
      // I think we can still auto-pick both account and entity mapping sets, even if the document type doesn't map one or both
    }
    //const calendarDays = props.allOverdueDaysTypes.find(t => t.id)

    const values = {
      operator: operator && operator.id,
      name: props.requirement && props.requirement.name,
      externalName: props.requirement && props.requirement.externalName,
      documentType: props.requirement && props.requirement.documentType.id,
      frequency: props.requirement && props.requirement.frequency.name,
      rpaEnabled:
        props.requirement &&
        props.requirement.rpaEnabled &&
        props.requirement.rpaEnabled.name,
      postingBook:
        props.requirement &&
        props.requirement.postingBook &&
        props.requirement.postingBook.name,
      portfolio:
        props.requirement &&
        props.requirement.portfolio &&
        props.requirement.portfolio.name,
      country:
        props.requirement &&
        props.requirement.country &&
        props.requirement.country.name,
      reportingPriority:
        props.requirement &&
        props.requirement.reportingPriority &&
        props.requirement.reportingPriority.name,
      accountNormalizationMethod:
        props.requirement &&
        props.requirement.accountNormalizationMethod &&
        props.requirement.accountNormalizationMethod.name,
      chartOfAccounts:
        props.requirement &&
        props.requirement.chartOfAccounts &&
        props.requirement.chartOfAccounts.id,
      accountMappingSet:
        (props.requirement &&
          props.requirement.accountMappingSet &&
          props.requirement.accountMappingSet.id) ||
        accountMappingSet,
      entityMappingSet:
        (props.requirement &&
          props.requirement.entityMappingSet &&
          props.requirement.entityMappingSet.id) ||
        entityMappingSet,
      comboMappingSet:
        props.requirement &&
        props.requirement.comboMappingSet &&
        props.requirement.comboMappingSet.id,
      // TODO yardi finally rename?
      subledgerMappingSet:
        props.requirement &&
        props.requirement.subledgerMappingSet &&
        props.requirement.subledgerMappingSet.id,
      startDate:
        props.requirement &&
        dayjs(props.requirement.startDate).format("YYYY-MM-DD"),
      //endDate: props.requirement.endDate,
      endDate:
        props.requirement &&
        props.requirement.endDate &&
        dayjs(props.requirement.endDate).format("YYYY-MM-DD"),
      daysUntilOverdue:
        // Can't just use || 10 because zero is a valid daysUntilOverdue but is also falsy
        !props.requirement || props.requirement.daysUntilOverdue === undefined
          ? 10
          : props.requirement && props.requirement.daysUntilOverdue,
      overdueDaysType:
        (props.requirement && props.requirement.overdueDaysType.name) ||
        "CALENDAR_DAYS",
      glDateSetting:
        (props.requirement &&
          props.requirement.glDateSetting &&
          props.requirement.glDateSetting.name) ||
        "MONTH_END",
      notifyOperatorOnOverdue:
        props.requirement && props.requirement.notifyOperatorOnOverdue,
      nonCompliantFormat:
        props.requirement && props.requirement.nonCompliantFormat,
      directUploadS3PathPrefix:
        props.requirement && props.requirement.directUploadS3PathPrefix,
    };
    //console.log("mapPropsToValues", values);
    return values;
  },

  validate: (values: any, props) => {
    let errors: any = {};
    const isCompliant = !realValueToSelectOption(
      values.nonCompliantFormat,
      props.allNonCompliantTypes,
    )?.value;

    if (!values.name) {
      errors.name = "Please enter a requirement name";
    }

    if (!values.operator) {
      errors.operator = "Please select an operator";
    }
    if (!values.documentType) {
      errors.documentType = "Please select a document type";
    } else {
      const documentTypeObj =
        (values.documentType && values.documentType.obj) ||
        props.allDocumentTypes.find((t: any) => t.id === values.documentType);

      let mapsAccounts =
        documentTypeObj && isCompliant && documentTypeObj.mapAccounts;
      if (mapsAccounts && !values.accountMappingSet) {
        // Only error if the operator has mapping sets loaded.
        if (
          props.allAccountMappingSets.find(
            (s: any) => s.operator.id === values.operator,
          )
        ) {
          console.log("Please select an account mapping set");
          errors.accountMappingSet = "Please select an account mapping set";
        }
      }
      let needsCOA =
        documentTypeObj && isCompliant && needsChartOfAccounts(documentTypeObj);
      if (needsCOA && !values.chartOfAccounts) {
        console.log("Please select a Chart Of Accounts");
        errors.chartOfAccounts = "Please select a Chart Of Accounts";
      }

      if (needsCOA && values.chartOfAccounts?.value) {
        if (
          documentTypeObj.id === "TRIAL_BALANCE_HLBV" &&
          values.chartOfAccounts?.value !== "YardiSHO"
        ) {
          errors.chartOfAccounts =
            "HLBV TBs must use Yardi SHO Chart of Accounts";
        }
        if (
          documentTypeObj.id === "MONTHLY_CENSUS_HLBV" &&
          values.chartOfAccounts?.value !== "YardiNNNMC"
        ) {
          errors.chartOfAccounts =
            "HLBV Census must use Yardi NNN Stats Chart of Accounts";
        }
      }
      // TODO - should we enforce more COAs by DocumentType?

      let mapsEntities =
        documentTypeObj && isCompliant && documentTypeObj.mapEntities;
      if (mapsEntities && !values.entityMappingSet) {
        // Only error if the operator has mapping sets loaded.
        if (
          props.allEntityMappingSets.find(
            (s: any) => s.operator.id === values.operator,
          )
        ) {
          console.log("Please select a property mapping set");
          errors.entityMappingSet = "Please select a property mapping set";
        }
      }
      if (
        documentTypeObj &&
        isCompliant &&
        documentTypeObj.rpaEligible &&
        postingBookDocTypeIds.includes(documentTypeObj.id)
      ) {
        if (!values.postingBook) {
          errors.postingBook = "Posting Book is required";
        } else {
          // TODO - move this to postingBook fields?
          if (
            documentTypeObj.id === "TRIAL_BALANCE" &&
            (values.postingBook.value || values.postingBook) === "AU"
          ) {
            // TODO remove this old JDE check
            errors.postingBook = "Trial Balance cannot post to AU ledger";
          }
          if (
            documentTypeObj.id === "TRIAL_BALANCE_NNN" &&
            (values.postingBook.value || values.postingBook) !== "NNN_Accrual"
          ) {
            // Also update check in YardiETLService
            errors.postingBook =
              "NNN Trial Balance must post to NNN_Accrual book";
          }
          // TODO - verify that HLBV can post to any SHO book?
        }
      }
      if (
        documentTypeObj &&
        isCompliant &&
        documentTypeObj.rpaEligible &&
        needsEtlDocTypeIds.includes(documentTypeObj.id)
      ) {
        if (
          !values.rpaEnabled ||
          (values.rpaEnabled.value || values.rpaEnabled) === "OFF"
        ) {
          errors.rpaEnabled = "ETL automation is required";
        }
      }
      if (documentTypeObj?.process) {
        if ([null, undefined].includes(values.nonCompliantFormat)) {
          errors.nonCompliantFormat =
            "Please select whether this requirement uses the standard template";
        }
      }
      // Combo mappings are optional
      // Segment mappings are optional

      const accountMappingDest =
        mapsAccounts &&
        props.allAccountMappingSets.find(
          (ms: any) =>
            ms.id ===
            ((values.accountMappingSet && values.accountMappingSet.value) ||
              values.accountMappingSet),
        )?.destination?.displayName;
      const entityMappingDest =
        mapsEntities &&
        props.allEntityMappingSets.find(
          (ms: any) =>
            ms.id ===
            ((values.entityMappingSet && values.entityMappingSet.value) ||
              values.entityMappingSet),
        )?.destination?.displayName;
      const comboMappingDest =
        mapsAccounts &&
        mapsEntities &&
        props.allComboMappingSets.find(
          (ms: any) =>
            ms.id ===
            ((values.comboMappingSet && values.comboMappingSet.value) ||
              values.comboMappingSet),
        )?.destination?.displayName;
      const segmentMappingDest =
        mapsAccounts &&
        props.allSegmentMappingSets.find(
          (ms: any) =>
            ms.id ===
            ((values.subledgerMappingSet && values.subledgerMappingSet.value) ||
              values.subledgerMappingSet),
        )?.destination?.displayName;
      const coaDest =
        needsCOA &&
        props.allChartsOfAccounts.find(
          (coa: any) =>
            coa.id ===
            ((values.chartOfAccounts && values.chartOfAccounts.value) ||
              values.chartOfAccounts),
        )?.source?.displayName;
      const bookDest =
        documentTypeObj &&
        isCompliant &&
        documentTypeObj.rpaEligible &&
        postingBookDocTypeIds.includes(documentTypeObj.id) &&
        props.allPostingBooks.find(
          (b: any) =>
            b.name ===
            ((values.postingBook && values.postingBook.value) ||
              values.postingBook),
        )?.source?.displayName;
      const destConflict =
        _.uniq(
          [
            accountMappingDest,
            entityMappingDest,
            comboMappingDest,
            segmentMappingDest,
            coaDest,
            bookDest,
          ].filter((it) => !!it),
        ).length > 1;

      if (destConflict) {
        const message =
          "Mapping Sets, Posting Book, and COA must all belong to the same system.";
        if (mapsAccounts && accountMappingDest)
          errors.accountMappingSet = message;
        if (mapsEntities && entityMappingDest)
          errors.entityMappingSet = message;
        if (mapsAccounts && mapsEntities && comboMappingDest)
          errors.comboMappingSet = message;
        if (mapsAccounts && segmentMappingDest)
          errors.subledgerMappingSet = message;
        if (needsCOA && coaDest) errors.chartOfAccounts = message;
        if (
          documentTypeObj &&
          isCompliant &&
          documentTypeObj.rpaEligible &&
          postingBookDocTypeIds.includes(documentTypeObj.id) &&
          bookDest
        )
          errors.postingBook = message;
      }
    }
    if (!values.frequency) {
      errors.frequency = "Please select a frequency";
    }
    if (!values.startDate) {
      errors.startDate = "Please enter a start date";
    }
    // portfolio is optional // TODO - maybe require for some doc types?
    //if (!values.portfolio) {
    //  errors.portfolio = "Please select a portfolio";
    //}
    // reporting priority is optional // TODO - maybe require for some doc types?
    //if (!values.reportingPriority) {
    //  errors.reportingPriority = "Please select a reporting priority";
    //}
    // country is optional // TODO - maybe require for some doc types?
    //if (!values.country) {
    //  errors.country = "Please select a country";
    //}
    // endDate is optional
    if (
      Number.isNaN(parseInt(values.daysUntilOverdue)) ||
      parseInt(values.daysUntilOverdue) < 0
    ) {
      errors.daysUntilOverdue = "Please enter days until overdue";
    }
    if (!values.overdueDaysType) {
      errors.overdueDaysType = "Please select an overdue days type";
    }
    if (isCompliant && !values.glDateSetting) {
      errors.glDateSetting = "Please select a GL Date Setting";
    }
    if ([null, undefined].includes(values.notifyOperatorOnOverdue)) {
      errors.notifyOperatorOnOverdue =
        "Please select whether to notify operator users when this requirement is overdue";
    }

    // Account normalization method is optional
    if (Object.keys(errors)) console.debug(errors);
    return errors;
  },

  handleSubmit: async (
    values: any,
    {
      props,
      setSubmitting,
      setErrors,
    }: { props: any; setSubmitting: any; setErrors: Function },
  ) => {
    // Deep copy, just in case this values object isn't ours to mutate.
    let payload = JSON.parse(JSON.stringify(values));

    // I think it makes sense to preserve mappingSets and other attributes for non-compliant requirements, with the expectation that they should flip (back) eventually?
    // So we are intentionally not checking isCompliant here.

    // Replace react-select objects with enum names for Spring intake.
    if (values.overdueDaysType && values.overdueDaysType.value) {
      payload.overdueDaysType = values.overdueDaysType.value;
    }

    if (values.daysUntilOverdue) {
      payload.daysUntilOverdue = parseInt(values.daysUntilOverdue);
    }
    if (values.frequency && values.frequency.value) {
      payload.frequency = values.frequency.value;
    }
    if (
      values.accountNormalizationMethod &&
      values.accountNormalizationMethod.value
    ) {
      payload.accountNormalizationMethod =
        values.accountNormalizationMethod.value;
    }

    if (values.rpaEnabled && values.rpaEnabled.value) {
      payload.rpaEnabled = values.rpaEnabled.value;
    }

    // Convert postingBook from string id or react-select object to an {id:} object for JPA
    if (values.postingBook) {
      payload.postingBook = {
        name:
          (values.postingBook && values.postingBook.value) ||
          values.postingBook,
      };
    }

    if (values.portfolio && values.portfolio.value) {
      payload.portfolio = values.portfolio.value;
    }
    if (values.country && values.country.value) {
      payload.country = values.country.value;
    }
    if (values.reportingPriority && values.reportingPriority.value) {
      payload.reportingPriority = values.reportingPriority.value;
    }

    if (
      values.notifyOperatorOnOverdue &&
      ![null, undefined].includes(values.notifyOperatorOnOverdue.value)
    ) {
      payload.notifyOperatorOnOverdue = values.notifyOperatorOnOverdue.value;
    }

    if (
      values.nonCompliantFormat &&
      ![null, undefined].includes(values.nonCompliantFormat.value)
    ) {
      payload.nonCompliantFormat = values.nonCompliantFormat.value;
      // unset rpaEnabled if non-compliant
      delete payload.rpaEnabled;
    } else {
      payload.nonCompliantFormat = false;
    }

    // Convert DocumentType from string id or react-select object to an {id:} object for JPA
    if (values.documentType) {
      payload.documentType = {
        id:
          (values.documentType && values.documentType.value) ||
          values.documentType,
      };
    }

    const documentTypeObj =
      (values.documentType && values.documentType.obj) ||
      props.allDocumentTypes.find((t: any) => t.id === values.documentType);

    if (
      (payload.documentType && payload.documentType.id) === "TRIAL_BALANCE" &&
      values.glDateSetting
    ) {
      payload.glDateSetting =
        (values.glDateSetting && values.glDateSetting.value) ||
        values.glDateSetting;
    } else {
      payload.glDateSetting = null;
    }

    if (values.accountMappingSet) {
      payload.accountMappingSet = {
        id:
          (values.accountMappingSet && values.accountMappingSet.value) ||
          values.accountMappingSet,
      };
    }
    if (values.entityMappingSet) {
      payload.entityMappingSet = {
        id:
          (values.entityMappingSet && values.entityMappingSet.value) ||
          values.entityMappingSet,
      };
    }
    if (
      comboMappingDocTypeIds.includes(
        payload.documentType && payload.documentType.id,
      ) &&
      values.comboMappingSet
    ) {
      payload.comboMappingSet = {
        id:
          (values.comboMappingSet && values.comboMappingSet.value) ||
          values.comboMappingSet,
      };
    } else {
      payload.comboMappingSet = null;
    }
    if (
      segmentMappingDocTypeIds.includes(
        payload.documentType && payload.documentType.id,
      ) &&
      values.subledgerMappingSet // TODO yardi finally rename?
    ) {
      payload.subledgerMappingSet = {
        id:
          (values.subledgerMappingSet && values.subledgerMappingSet.value) ||
          values.subledgerMappingSet,
      };
    } else {
      payload.subledgerMappingSet = null;
    }
    if (needsChartOfAccounts(documentTypeObj) && values.chartOfAccounts) {
      payload.chartOfAccounts = {
        id:
          (values.chartOfAccounts && values.chartOfAccounts.value) ||
          values.chartOfAccounts,
      };
    } else {
      payload.chartOfAccounts = null;
    }
    if (values.operator) {
      payload.operator = {
        id: (values.operator && values.operator.value) || values.operator,
      };
    }
    if (!payload.endDate) {
      // Replace `false` with an empty string for Jackson deserialization
      payload.endDate = "";
    }

    let method: Method, url;
    if (props.requirement && props.requirement.id) {
      method = "PUT";
      url = `/api/operators/${props.requirement.operator.id}/requirements/${props.requirement.id}`;
    } else {
      const operatorId =
        (props.operator && props.operator.id) ||
        (values.operator && values.operator.value);
      method = "POST";
      url = `/api/operators/${operatorId}/requirements`;
    }

    const config: AxiosRequestConfig = {
      headers: {
        "content-type": "application/json",
        Authorization: getOktaTokenAuthHeader(props.authState),
      },
      //credentials: "same-origin",
      //mode: "cors",
      method,
      url,
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        setSubmitting(false);
        props.receiveUpdatedRequirement(response.data);
        props.closeModal && props.closeModal();
      })
      .catch((error) => {
        setErrors({ submission: error.message });
      });

    return;
  },

  displayName: "RequirementEditForm", // helps with React DevTools
})(RequirementEditForm);

// This FormWrapper was being used to capture a message after submission on the OM site.
// These state-loaded options could probably be moved up or down a layer
const RequirementEditFormWrapper = withOktaAuth(
  class RequirementEditFormWrapper extends React.Component<any, any> {
    constructor(props: any) {
      super(props);
      this.state = {
        allDocumentTypes: [],
        allFrequencies: [],
        allOverdueDaysTypes: [],
        allGlDateSettings: [],
        allAccountMappingSets: undefined,
        allEntityMappingSets: undefined,
        allComboMappingSets: undefined,
        allSegmentMappingSets: undefined,
        allAccountNormalizationMethods: undefined,
        allPostingBooks: undefined,
        allPortfolios: undefined,
        allCountries: undefined,
        allReportingPriorities: undefined,
        allRpaEnabledTypes: undefined,
        allChartsOfAccounts: undefined,
        allNonCompliantTypes: [
          { id: false, name: "Standard template" },
          { id: true, name: "Non-compliant" },
        ],
      };
    }
    componentDidMount() {
      this.loadTypes();
    }

    async loadTypes() {
      fetchJsonWithToken("/api/documentTypes", this.props.authState).then(
        (data: any) => {
          data.forEach((docType: any) => {
            docType.isDisabled = !canEditRequirements(
              this.props.authState,
              null,
              {
                documentType: docType,
              },
            );
          });
          this.setState({
            allDocumentTypes: data,
          });
        },
      );
      fetchJsonWithToken(
        "/api/requirementFrequencies",
        this.props.authState,
      ).then((data: any) => this.setState({ allFrequencies: data }));
      fetchJsonWithToken("/api/overdueDaysTypes", this.props.authState).then(
        (data: any) => this.setState({ allOverdueDaysTypes: data }),
      );
      fetchJsonWithToken("/api/glDateSettings", this.props.authState).then(
        (data: any) => this.setState({ allGlDateSettings: data }),
      );
      fetchToState(this, "allAccountMappingSets", "/api/accountMappingSets");
      fetchToState(this, "allEntityMappingSets", "/api/entityMappingSets");
      fetchToState(this, "allComboMappingSets", "/api/comboMappingSets");
      fetchToState(this, "allSegmentMappingSets", "/api/segmentMappingSets");
      fetchToState(
        this,
        "allAccountNormalizationMethods",
        "/api/accountNormalizationMethods",
      );
      fetchToState(this, "allRpaEnabledTypes", "/api/rpaEnabledTypes");
      fetchToState(this, "allPostingBooks", "/api/postingBooks");
      fetchToState(this, "allPortfolios", "/api/portfolios");
      fetchToState(this, "allCountries", "/api/countries");
      fetchToState(this, "allReportingPriorities", "/api/reportingPriorities");
      fetchToState(this, "allChartsOfAccounts", "/api/chartsOfAccounts");
    }

    render() {
      // We're okay with empty lists of some pickers (just assume they'll figure it out)
      // but other lists expect to have some values and we assume undefined/empty means wait for them to load

      const notYetLoaded = (list: any) => list === undefined;
      const empty = (list: any) => list === undefined || list.length === 0;

      // Formik builds its initial values on first render, so don't render a form before we have the async data.
      if (
        notYetLoaded(this.state.allAccountMappingSets) ||
        notYetLoaded(this.state.allEntityMappingSets) ||
        notYetLoaded(this.state.allComboMappingSets) ||
        notYetLoaded(this.state.allSegmentMappingSets) ||
        empty(this.state.allDocumentTypes) ||
        empty(this.state.allOverdueDaysTypes) ||
        empty(this.state.allGlDateSettings) ||
        empty(this.state.allFrequencies) ||
        empty(this.state.allAccountNormalizationMethods) ||
        empty(this.state.allPostingBooks) ||
        empty(this.state.allPortfolios) ||
        empty(this.state.allCountries) ||
        empty(this.state.allReportingPriorities) ||
        empty(this.state.allRpaEnabledTypes) ||
        empty(this.state.allChartsOfAccounts)
      )
        return null; // TODO - loading spinner?

      return (
        <EnhancedForm
          {...this.props}
          allDocumentTypes={this.state.allDocumentTypes}
          allFrequencies={this.state.allFrequencies}
          allOverdueDaysTypes={this.state.allOverdueDaysTypes}
          allGlDateSettings={this.state.allGlDateSettings}
          allAccountMappingSets={this.state.allAccountMappingSets}
          allEntityMappingSets={this.state.allEntityMappingSets}
          allComboMappingSets={this.state.allComboMappingSets}
          allSegmentMappingSets={this.state.allSegmentMappingSets}
          allAccountNormalizationMethods={
            this.state.allAccountNormalizationMethods
          }
          allPostingBooks={this.state.allPostingBooks}
          allPortfolios={this.state.allPortfolios}
          allCountries={this.state.allCountries}
          allReportingPriorities={this.state.allReportingPriorities}
          allRpaEnabledTypes={this.state.allRpaEnabledTypes}
          allChartsOfAccounts={this.state.allChartsOfAccounts}
          allNonCompliantTypes={this.state.allNonCompliantTypes}
        />
      );
    }
  },
);

const RequirementEditFormButton = WithModal(
  ({ requirement, ...props }: { requirement: Requirement } & Partial<any>) => (
    <>
      <Button
        color={"primary"}
        className="wtop-btn"
        disabled={props.disabled || false}
        onClick={props.showModal}
      >
        {requirement && requirement.id ? "Edit" : "New Requirement"}
      </Button>
      {props.modalContent(
        <RequirementEditFormWrapper requirement={requirement} {...props} />,
        {
          showCloseIcon: true,
          modalStyles: { width: "800px" },
          closeOnOverlayClick: false,
        },
      )}
    </>
  ),
);

export default RequirementEditFormButton;
