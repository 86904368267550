/** @format */

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCopy,
  faFile,
  faFileArchive,
  faStar as farStar,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBackward,
  faCalendarAlt,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faCircleExclamation,
  faDumpsterFire,
  faExclamation,
  faExclamationTriangle,
  faFileDownload,
  faFileInvoice,
  faFileLines,
  faLock,
  faLockOpen,
  faMagnifyingGlass,
  faMinusCircle,
  faMoneyCheckAlt,
  faPlay,
  faPlusCircle,
  faSkullCrossbones,
  faSortDown,
  faSortUp,
  faSpinner,
  faStar,
  faTimes,
  faTimesCircle,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faClipboardListCheck,
  faHorse,
  faHotdog,
  faNotesMedical,
  faPaperPlane,
  faProcedures,
  faServer,
  faSnooze,
  faThumbsDown,
  faThumbsUp,
  faUserMd,
  faUserNinja,
  faUserShield,
  faWagonCovered,
} from "@fortawesome/pro-duotone-svg-icons";
import { faRocketLaunch, faUserRobot } from "@fortawesome/pro-light-svg-icons";

library.add(faBackward);
library.add(faCalendarAlt);
library.add(faCaretDown);
library.add(faCaretLeft);
library.add(faCaretRight);
library.add(faCaretUp);
library.add(faCheck);
library.add(faCheckCircle);
library.add(faChevronDown);
library.add(faChevronRight);
library.add(faCircleExclamation);
library.add(faClipboardListCheck);
library.add(faCopy);
library.add(faDumpsterFire);
library.add(faExclamation);
library.add(faExclamationTriangle);
library.add(faFile);
library.add(faFileArchive);
library.add(faFileDownload);
library.add(faFileInvoice);
library.add(faFileLines);
library.add(faHorse);
library.add(faHotdog);
library.add(faLock);
library.add(faLockOpen);
library.add(faMagnifyingGlass);
library.add(faMinusCircle);
library.add(faMoneyCheckAlt);
library.add(faNotesMedical);
library.add(faPaperPlane);
library.add(faPlay);
library.add(faPlusCircle);
library.add(faProcedures);
library.add(faRocketLaunch);
library.add(faServer);
library.add(faSkullCrossbones);
library.add(faSnooze);
library.add(faSortDown);
library.add(faSortUp);
library.add(faSpinner);
library.add(faStar);
library.add(faThumbsDown);
library.add(faThumbsUp);
library.add(faTimes);
library.add(faTimesCircle);
library.add(faTrashAlt);
library.add(faUserMd);
library.add(faUserNinja);
library.add(faUserRobot);
library.add(faUserShield);
library.add(faUsers);
library.add(faWagonCovered);
library.add(farStar);
