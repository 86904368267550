/** @format */
import React from "react";
import wretch, { Wretcher } from "wretch";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import { AbstractAuthState } from "./OktaUtils";

export function authToTokenString(auth: AbstractAuthState): string | undefined {
  return auth?.accessToken?.accessToken;
}

// TODO - migrate any axios usages to FetchUtils and stop exporting
export const getOktaTokenAuthHeader = (auth: AbstractAuthState): string =>
  !auth ||
  //  (typeof token === "string" && token.startsWith("WELL")) ||
  authToTokenString(auth)?.startsWith("WELL")
    ? ""
    : `Bearer ${authToTokenString(auth)}`;

// TODO - migrate any axios usages to FetchUtils and stop exporting
export const getWelltowerTokenHeaders = (
  auth: AbstractAuthState,
): { [headerName: string]: string } =>
  auth &&
  // ((typeof token === "string" && token.startsWith("WELL")) ||
  authToTokenString(auth)?.startsWith("WELL") //)
    ? { "X-WELLTOWER-TOKEN": authToTokenString(auth) ?? "" }
    : {};

const fetchWithToken = (
  url: string,
  authState: AbstractAuthState,
): Wretcher => {
  return wretch()
    .url(url)
    .auth(getOktaTokenAuthHeader(authState))
    .headers(getWelltowerTokenHeaders(authState))
    .options({ credentials: "same-origin", mode: "cors" });
};

const fetchJsonWithToken = (
  url: string,
  authState: AbstractAuthState,
): Promise<any> => {
  return fetchWithToken(url, authState)
    .get()
    .badRequest(logAndThrowMessage)
    .unauthorized(logAndThrowMessage)
    .forbidden(logAndThrowMessage)
    .notFound(logAndThrowMessage)
    .timeout(logAndThrowMessage)
    .internalError(logAndThrowMessage)
    .json((_) => _);
};

// TODO replace this with useFetchWithLoadingHook
const fetchToState = async <T extends IOktaContext>(
  thisObj: React.Component<T, any>,
  stateVar: string,
  url: string,
) => {
  return fetchJsonWithToken(url, thisObj.props.authState).then((data) => {
    thisObj.setState({
      [stateVar]: data,
    });
  });
};

const logAndThrowMessage = (err: any) => {
  let m;
  try {
    let o = JSON.parse(err.message || err.text);
    m = o.message || o.text;
  } catch (ignored) {
    m = err.message || err.text || err;
  }
  console.error(m);
  throw m;
};

// TODO these are the same function with and without return types
const fetchPageableAllWithToken = (
  url: string,
  authState: AbstractAuthState,
  maxResults: number = 200,
  resultsCollector: any[] = [],
  _initialUrl?: string,
): Promise<any> => {
  return fetchWithToken(url, authState)
    .get()
    .badRequest(logAndThrowMessage)
    .unauthorized(logAndThrowMessage)
    .forbidden(logAndThrowMessage)
    .notFound(logAndThrowMessage)
    .timeout(logAndThrowMessage)
    .internalError(logAndThrowMessage)
    .json((json) => {
      if (json.content) {
        resultsCollector = resultsCollector.concat(json.content);
        if (json.last) {
          // This was old Spring Data PageImpl, now we're using PagedModel
          //console.debug("last page");
          return resultsCollector;
        }
        if (json.page) {
          // This is new Spring Data PagedModel
          // Comparing a zero-based page number with a count of pages, because why would that be a bad design?
          if (json.page.number + 1 >= json.page.totalPages) {
            // >= as a minor safeguard against blowing off the edge?
            return resultsCollector;
          }
        }
        if (resultsCollector.length > maxResults) {
          //console.debug("hit maxResults");
          return resultsCollector;
        }
        //console.debug(`fetching ${_initialUrl}?page=${json.number + 1}&size=${json.size}`);
        if (!_initialUrl) _initialUrl = url;
        // Assume we're always fetching and getting the default page size - don't include in URL
        let thisPageNumber = json.page?.number ?? json.number;
        if (thisPageNumber === null || thisPageNumber === undefined) {
          console.error(`unexpected missing page number from ${url}`);
          return resultsCollector;
        }
        return fetchPageableAllWithToken(
          `${_initialUrl}?page=${thisPageNumber + 1}`,
          authState,
          maxResults,
          resultsCollector,
          _initialUrl,
        );
      } else {
        console.debug(
          `fetchPageable() call to ${url} returned non-paged results.`,
        );
        return json;
      }
    });
};
async function fetchPageableAll<T>(
  url: string,
  authState: AbstractAuthState,
  maxResults: number = 200,
  resultsCollector: T[] = [],
  _initialUrl?: string,
): Promise<T[]> {
  return fetchWithToken(url, authState)
    .get()
    .badRequest(logAndThrowMessage)
    .unauthorized(logAndThrowMessage)
    .forbidden(logAndThrowMessage)
    .notFound(logAndThrowMessage)
    .timeout(logAndThrowMessage)
    .internalError(logAndThrowMessage)
    .json((json) => {
      if (json.content) {
        resultsCollector = resultsCollector.concat(json.content as T[]);
        if (json.last) {
          // This was old Spring Data PageImpl, now we're using PagedModel
          //console.debug("last page");
          return resultsCollector;
        }
        if (json.page) {
          // This is new Spring Data PagedModel
          // Comparing a zero-based page number with a count of pages, because why would that be a bad design?
          if (json.page.number + 1 >= json.page.totalPages) {
            // >= as a minor safeguard against blowing off the edge?
            return resultsCollector;
          }
        }
        if (resultsCollector.length > maxResults) {
          //console.debug("hit maxResults");
          return resultsCollector;
        }
        //console.debug(`fetching ${_initialUrl}?page=${json.number + 1}&size=${json.size}`);
        if (!_initialUrl) _initialUrl = url;
        // Assume we're always fetching and getting the default page size - don't include in URL
        let thisPageNumber = json.page?.number ?? json.number;
        if (thisPageNumber === null || thisPageNumber === undefined) {
          console.error(`unexpected missing page number from ${url}`);
          return resultsCollector;
        }
        return fetchPageableAll(
          `${_initialUrl}?page=${thisPageNumber + 1}`,
          authState,
          maxResults,
          resultsCollector,
          _initialUrl,
        );
      } else {
        console.debug(
          `fetchPageable() call to ${url} returned non-paged results.`,
        );
        return json as T[];
      }
    });
}

const deleteWithToken = (
  url: string,
  authState: AbstractAuthState,
): Promise<any> => {
  return fetchWithToken(url, authState)
    .delete()
    .badRequest(logAndThrowMessage)
    .unauthorized(logAndThrowMessage)
    .forbidden(logAndThrowMessage)
    .notFound(logAndThrowMessage)
    .timeout(logAndThrowMessage)
    .internalError(logAndThrowMessage)
    .json();
};

const postWithToken = (
  url: string,
  authState: AbstractAuthState,
  data: any,
): Promise<any> => {
  return fetchWithToken(url, authState)
    .post(data)
    .badRequest(logAndThrowMessage)
    .unauthorized(logAndThrowMessage)
    .forbidden(logAndThrowMessage)
    .notFound(logAndThrowMessage)
    .timeout(logAndThrowMessage)
    .internalError(logAndThrowMessage)
    .json();
};

const putWithToken = (
  url: string,
  authState: AbstractAuthState,
  data: any,
): Promise<any> => {
  return fetchWithToken(url, authState)
    .put(data)
    .badRequest(logAndThrowMessage)
    .unauthorized(logAndThrowMessage)
    .forbidden(logAndThrowMessage)
    .notFound(logAndThrowMessage)
    .timeout(logAndThrowMessage)
    .internalError(logAndThrowMessage)
    .json();
};

const patchWithToken = (
  url: string,
  authState: AbstractAuthState,
  data: any,
): Promise<any> => {
  return fetchWithToken(url, authState)
    .patch(data)
    .badRequest(logAndThrowMessage)
    .unauthorized(logAndThrowMessage)
    .forbidden(logAndThrowMessage)
    .notFound(logAndThrowMessage)
    .timeout(logAndThrowMessage)
    .internalError(logAndThrowMessage)
    .json();
};

export {
  fetchWithToken,
  fetchJsonWithToken,
  fetchToState,
  fetchPageableAllWithToken,
  fetchPageableAll,
  deleteWithToken,
  postWithToken,
  putWithToken,
  patchWithToken,
};
