/** @format */

import {
  deleteWithToken,
  fetchJsonWithToken,
  fetchPageableAllWithToken,
  postWithToken,
} from "../util/FetchUtils";
import { AbstractAuthState } from "../util/OktaUtils";

export const loadOperatorCaptionsForCOA = async (
  chartOfAccountsId: string,
  auth: AbstractAuthState,
) => {
  const url = `/api/chartsOfAccounts/${chartOfAccountsId}/operatorCaptions`;
  return fetchPageableAllWithToken(url, auth);
};

export const loadWelltowerBusinessUnits = async (
  operatorId: number,
  auth: AbstractAuthState,
) => {
  let url = `/api/businessUnits`;
  // TODO YARDI - by source
  if (operatorId !== 0) {
    url += `/byOperator/${operatorId}`;
  } else {
    console.error(`LOADING BUs WITHOUT OPERATOR - stop doing this`);
  }
  return fetchPageableAllWithToken(url, auth);
};

export const loadWelltowerAccounts = async (auth: AbstractAuthState) => {
  // TODO YARDI - by source?
  return fetchPageableAllWithToken("/api/accounts", auth);
};

export const loadDocumentTypes = async (auth: AbstractAuthState) => {
  return fetchJsonWithToken("/api/documentTypes", auth);
};

export const loadHeadings = async (auth: AbstractAuthState) => {
  return fetchJsonWithToken("/api/headerIdentifiers", auth);
};

export const getFavoriteDocumentTypes = (auth: AbstractAuthState) => {
  return fetchJsonWithToken(`/api/documentTypes/my`, auth);
};

export const getAvailablePeriodMonths = (auth: AbstractAuthState) => {
  return fetchJsonWithToken("/api/requirementDueDates/dashboardMonths", auth);
};

export const addDocumentTypeAsFavorite = (
  documentTypeId: number | string,
  auth: AbstractAuthState,
) => {
  const url = `/api/documentTypes/${documentTypeId}/my`;
  return postWithToken(url, auth, {});
};

/** @deprecated Remove with DocumentTypePickerClassic */
export const deleteDocumentTypeAsFavorite = (
  documentTypeId: number,
  auth: AbstractAuthState,
) => {
  const url = `/api/documentTypes/my/${documentTypeId}`;
  return deleteWithToken(url, auth);
};

export const deleteDocumentTypeAsFavorite2 = (
  documentTypeId: number,
  auth: AbstractAuthState,
) => {
  const url = `/api/documentTypes/${documentTypeId}/my`;
  return deleteWithToken(url, auth);
};
