/** @format */
import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { isWelltower } from "../../util/OktaUtils";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import dayjs from "dayjs";
import {
  formatDate,
  formatDateISO,
  formatPeriod,
  parseDate,
} from "../../util/DateUtils";
import { welltowerName } from "../../util/NameUtils";
import RequirementDueDate from "../../types/RequirementDueDate";
import DatePicker from "react-datepicker";
import { LoadingSpinner } from "../../components/LoadingSpinners";
import {
  DocumentTypeContextProps,
  OperatorContextProps,
  withDocumentTypeContext,
  withOperatorContext,
} from "../../components/filters/PickerContext";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import useFetchJson from "../../hooks/useFetchJson";
import { ContextOperatorPicker } from "../../components/filters/ContextOperatorPicker";
import { ContextDocumentTypePicker } from "../../components/filters/ContextDocumentTypePicker";
import { downloadUrl } from "../../util/OneTimeDownloader";

const OverduePreviewPage = (
  props: Partial<
    OperatorContextProps & DocumentTypeContextProps & IOktaContext
  >,
) => {
  const { authState } = useOktaAuth();
  const [date, setDate] = useState(dayjs().add(1, "day").toDate());

  let url: string | null;
  if (props.selectedOperatorId === "ALL") {
    url = `/api/requirementDueDates/overdueOn/${formatDateISO(date)}`;
  } else if (props.selectedOperatorObj) {
    url = `/api/operators/${
      props.selectedOperatorId
    }/requirementDueDates/overdueOn/${formatDateISO(date)}`;
  } else {
    url = null;
  }
  const { data, loading } = useFetchJson(url, { keepDataAcrossReload: true });

  if (!authState?.isAuthenticated) return null;

  if (!isWelltower(authState))
    return (
      <Container>
        <Card>
          <CardBody>Access denied.</CardBody>
        </Card>
      </Container>
    );

  const downloadExcel = () => {
    let exportUrl = url ? url + "/downloadToken" : null;
    if (exportUrl && props.selectedDocumentTypeId) {
      exportUrl += `?documentType=${props.selectedDocumentTypeId}`;
    }
    return exportUrl && downloadUrl(authState, exportUrl);
  };

  const filteredData = data?.filter(
    (rdd: RequirementDueDate) =>
      !props.isDocumentTypeSelected ||
      props.isDocumentTypeSelected(rdd.requirement.documentType),
  );
  return (
    <Container>
      <Row>
        <Col sm="4" />
        <Col>
          <h3>Overdue Preview</h3>
        </Col>
      </Row>
      <Row style={{ padding: "0.75rem" }}>
        <Col sm={"4"}>
          <ContextOperatorPicker showAllChoice default={"NOT_SELECTED"} />
        </Col>
        <Col sm={"3"}>
          <DatePicker
            name={`date`}
            className={"form-control"}
            onChange={(value: Date) => setDate(value)}
            selected={parseDate(date)}
            isClearable={false}
          />
        </Col>
        <Col sm={"3"}>
          <ContextDocumentTypePicker showAllChoice />
        </Col>
        <Col sm={"2"}>
          <Button
            color={"link"}
            onClick={downloadExcel}
            className={"wtop-btn cursor-pointer"}
          >
            Export
          </Button>
        </Col>
      </Row>

      {loading && <LoadingSpinner />}
      {!loading && (
        <Table>
          <thead>
            <tr>
              <th>Operator</th>
              <th>Type</th>
              <th>Requirement Name</th>
              <th>Period End</th>
              <th>Due Date</th>
              <th>Status</th>
              <th>Notify Operator?</th>
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((rdd: RequirementDueDate) => (
              <tr key={rdd.id}>
                <td>{welltowerName(rdd.requirement.operator)}</td>
                <td>{rdd.requirement.documentType.name}</td>
                <td>{welltowerName(rdd.requirement)}</td>
                <td>{formatPeriod(rdd)}</td>
                <td>{formatDate(rdd.dueDate)}</td>
                <td>{rdd.status.operatorStatusLong}</td>
                <td>
                  {rdd.requirement.notifyOperatorOnOverdue ? "Yes" : "No"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default withOperatorContext(withDocumentTypeContext(OverduePreviewPage));
