/** @format */

import React from "react";
import { Button, Table } from "reactstrap";
import ConfirmationDialog from "./ConfirmationDialog";
import OperatorUserFormButton from "./OperatorUserFormButton";
import OktaUser from "../../types/OktaUser";

const OktaUserRow = ({
  user,
  actions,
  showDetails,
  showOperator,
}: {
  user: OktaUser;
  actions: any;
  showDetails?: boolean;
  showOperator: boolean;
}) => {
  return (
    <tr>
      <td>
        {user.profile.firstName} {user.profile.lastName}
      </td>
      <td>
        {user.profile.login}
        {user.profile.login !== user.profile.email && (
          <> ({user.profile.email})</>
        )}
      </td>
      {showOperator && <td>{user.operatorName}</td>}
      <td>{user.status}</td>
      {showDetails && <td>{user.created && user.created.substr(0, 10)}</td>}
      {showDetails && <td>{user.lastLogin && user.lastLogin.substr(0, 10)}</td>}
      {actions && (
        <td>
          {user.status === "STAGED" && (
            <ConfirmationDialog
              title={"Re-send activation email?"}
              message={`Are you sure you want to re-send the activation email to ${user.profile.login}?`}
              action={() => {
                actions.activateUser(user.id);
              }}
            >
              {({ onClick }: { onClick: any }) => (
                <Button onClick={onClick} color={"success"}>
                  Re-send activation
                </Button>
              )}
            </ConfirmationDialog>
          )}
          {user.status === "PROVISIONED" && (
            <ConfirmationDialog
              title={"Re-send activation email?"}
              message={`Are you sure you want to re-send the activation email to ${user.profile.login}?`}
              action={() => {
                actions.reactivateUser(user.id);
              }}
            >
              {({ onClick }: { onClick: any }) => (
                <Button onClick={onClick} color={"success"}>
                  Re-send activation
                </Button>
              )}
            </ConfirmationDialog>
          )}
          {(user.status === "ACTIVE" || user.status === "PROVISIONED") && (
            <ConfirmationDialog
              buttonColor={"danger"}
              title={"Suspend user?"}
              message={`Are you sure you want to suspend ${user.profile.login}?`}
              action={() => {
                actions.suspendUser(user.id);
              }}
            >
              {({ onClick }: { onClick: any }) => (
                <Button onClick={onClick} color={"warning"}>
                  Suspend
                </Button>
              )}
            </ConfirmationDialog>
          )}
          {user.status === "SUSPENDED" && (
            <>
              <ConfirmationDialog
                title={"Unuspend user?"}
                buttonColor={"success"}
                message={`Are you sure you want to unsuspend ${user.profile.login}?`}
                action={() => {
                  actions.unsuspendUser(user.id);
                }}
              >
                {({ onClick }: { onClick: any }) => (
                  <Button onClick={onClick} color={"success"}>
                    Unsuspend
                  </Button>
                )}
              </ConfirmationDialog>
              <ConfirmationDialog
                buttonColor={"danger"}
                title={"Deactivate user?"}
                message={`Are you sure you want to deactivate ${user.profile.login}?`}
                action={() => {
                  actions.deactivateUser(user.id);
                }}
              >
                {({ onClick }: { onClick: any }) => (
                  <Button onClick={onClick} color={"danger"}>
                    Deactivate
                  </Button>
                )}
              </ConfirmationDialog>
            </>
          )}
          {user.status === "DEPROVISIONED" && (
            <ConfirmationDialog
              buttonColor={"danger"}
              title={"Delete user?"}
              message={`Are you sure you want to delete ${user.profile.login}?`}
              action={() => {
                actions.deleteUser(user.id);
              }}
            >
              {({ onClick }: { onClick: any }) => (
                <Button onClick={onClick} color={"danger"}>
                  Delete
                </Button>
              )}
            </ConfirmationDialog>
          )}
        </td>
      )}
      {actions && (
        <td>
          <OperatorUserFormButton
            user={user}
            saveAction={actions.updateUser.bind(undefined, user.id)}
          />
        </td>
      )}
    </tr>
  );
};

const OktaUsersTable = ({
  oktaUsers,
  userActions,
  showDetails,
  showOperator,
}: {
  oktaUsers: OktaUser[];
  userActions: any;
  showDetails?: boolean;
  showOperator: boolean;
}) =>
  oktaUsers && oktaUsers.length > 0 ? (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Login (email)</th>
          {showOperator && <th>Operator</th>}
          <th>Status</th>
          {showDetails && <th>Created</th>}
          {showDetails && <th>Last Login</th>}
          {userActions && <th>Actions</th>}
          {userActions && <th>Edit</th>}
        </tr>
      </thead>
      <tbody>
        {oktaUsers.map((u: any) => (
          <OktaUserRow
            user={u}
            key={u.id}
            actions={userActions}
            showDetails={showDetails}
            showOperator={showOperator}
          />
        ))}
      </tbody>
    </Table>
  ) : (
    <p>No users found in Okta</p>
  );

export default OktaUsersTable;
