/** @format */

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./App.scss";
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { CompatRoute, CompatRouter } from "react-router-dom-v5-compat";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, OktaAuthOptions, toRelativeUrl } from "@okta/okta-auth-js";
import Landing from "./Landing";
import TokenPage from "./pages/TokenPage";
import "./FontAwesomeIcons";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import RequirementDashboard from "./pages/RequirementDashboard";
import OperatorSettingsPage from "./pages/operator-settings/OperatorSettingsPage";
import AccountMappings from "./pages/accountant-mappings/AccountMappings";
import EntityMappings from "./pages/accountant-mappings/EntityMappings";
import Operators from "./pages/operators/Operators";
import SegmentMappings from "./pages/accountant-mappings/SegmentMappings";
import NotificationPreferences from "./pages/notification-preferences/NotificationPreferences";
import AccountantDashboard from "./pages/accountant-dashboard/AccountantDashboard";
import RPADashboard from "./pages/rpa-dashboard/RPADashboard";
import Navbar from "./components/navbar/Navbar";
import { DeveloperModeWrapper } from "./components/DeveloperMode";
import ComboMappings from "./pages/accountant-mappings/ComboMappings";
import OperatorDashboard from "./pages/OperatorDashboard";
import Users from "./pages/users/Users";
import MasterDataPage from "./pages/master-data/MasterDataPage";
import AccountantOperatorRddsPage from "./pages/accountant-operator-rdds/AccountantOperatorRddsPage";
import OverduePreviewPage from "./pages/overdue/OverduePreviewPage";
import Reports from "./pages/reports/Reports";
import WelltowerBuSettingsPage from "./pages/welltower-bu-settings/WelltowerBuSettingsPage";
import AdHocSubmissionDashboard from "./pages/ad-hoc-tokens/AdHocSubmissionDashboard";
import AdHocTokenDashboard from "./pages/ad-hoc-tokens/AdHocTokenDashboard";
import RPADownload from "./pages/rpa-download/RPADownload";
import NationalHolidaysPage from "./pages/utility-data/NationalHolidaysPage";
import HopperStatusPage from "./pages/HopperStatusPage";
import { PickerContextWrapper } from "./components/filters/PickerContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SecureRouteCompat } from "./util/okta/SecureRoute6";
import OperatorDashboardOld from "./pages/OperatorDashboardOld";
import AccountantOperatorRddsPageOld from "./pages/accountant-operator-rdds/AccountantOperatorRddsPageOld";
import ColumnMappingsPage from "./pages/accountant-mappings/ColumnMappingsPage";

const queryClient = new QueryClient();

// Add dayjs plugins
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);

// Set up Okta security
const oktaConfig: OktaAuthOptions = {
  issuer: process.env.REACT_APP_OKTA_OAUTH2_ISSUER!,
  redirectUri: window.location.origin + "/implicit/callback",
  clientId: process.env.REACT_APP_OKTA_OAUTH2_CLIENTID,
  scopes: ["openid", "email", "profile", "offline_access"],
  //tokenManager: {
  //  expireEarlySeconds: 3540,
  //},
};

export const appLoadTime = new Date();
const oktaAuth = new OktaAuth(oktaConfig);

// From https://github.com/okta/okta-oidc-js/issues/926#issuecomment-761536013 ; TODO remove if they fix (maybe this wasn't our problem, but I've seen blank /implicit/redirect a few times)
// TODO - Okta says this should be fixed. Remove this component and test with a user who isn't assigned to this app, and they should see an error message.
function CustomLoginCallback() {
  const location = useLocation();
  const errorDescription = new URLSearchParams(location.search).get(
    "error_description",
  );
  return errorDescription ? (
    <div>
      <h2>Authentication Failure</h2>
      <p>{errorDescription}</p>
    </div>
  ) : (
    <LoginCallback />
  );
}

const App = () => {
  const history = useHistory();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/" exact={true}>
          <Landing />
        </Route>
        <Route path="/implicit/callback">
          <CustomLoginCallback />
        </Route>
        <SecureRoute path="/login" exact={true}>
          <Landing />
        </SecureRoute>
        <Route path="*">
          <Navbar />
          <PickerContextWrapper>
            <Switch>
              <CompatRoute path="/submission">
                <AdHocSubmissionDashboard />
              </CompatRoute>
              <SecureRoute path="/dashboard">
                <AccountantDashboard />
              </SecureRoute>
              <SecureRouteCompat path="/accountant-requirement-due-dates-old/:operatorId">
                <AccountantOperatorRddsPageOld />
              </SecureRouteCompat>
              <SecureRouteCompat path="/accountant-requirement-due-dates/:operatorId">
                <AccountantOperatorRddsPage />
              </SecureRouteCompat>
              <SecureRoute path="/operator-dashboard-old">
                <OperatorDashboardOld />
              </SecureRoute>
              <SecureRouteCompat path="/operator-dashboard">
                <OperatorDashboard />
              </SecureRouteCompat>
              <SecureRoute path="/requirements">
                <RequirementDashboard />
              </SecureRoute>
              <SecureRoute path="/adHocTokens">
                <AdHocTokenDashboard />
              </SecureRoute>
              <SecureRouteCompat path="/token">
                <TokenPage />
              </SecureRouteCompat>
              <SecureRoute path="/hopper-status">
                <HopperStatusPage />
              </SecureRoute>
              <SecureRoute path="/operator-settings">
                <OperatorSettingsPage />
              </SecureRoute>
              <SecureRoute path="/users">
                <Users />
              </SecureRoute>
              {/* I think these mappings pages need to be component={ClassName} because they still expect props.location to be injected by Router*/}
              <SecureRouteCompat path="/account-mappings">
                <AccountMappings />
              </SecureRouteCompat>
              <SecureRouteCompat path="/entity-mappings">
                <EntityMappings />
              </SecureRouteCompat>
              <SecureRouteCompat path="/combo-mappings">
                <ComboMappings />
              </SecureRouteCompat>
              <SecureRouteCompat path="/segment-mappings">
                <SegmentMappings />
              </SecureRouteCompat>
              <SecureRouteCompat path="/column-mappings">
                <ColumnMappingsPage />
              </SecureRouteCompat>
              <SecureRoute path="/operators">
                <Operators />
              </SecureRoute>
              <SecureRoute path="/notification-preferences">
                <NotificationPreferences />
              </SecureRoute>
              <SecureRoute path="/rpa-dashboard/:documentType?">
                <RPADashboard />
              </SecureRoute>
              <SecureRoute path="/rpa-download/:documentType?">
                <RPADownload />
              </SecureRoute>
              <SecureRouteCompat path="/master-data">
                <MasterDataPage />
              </SecureRouteCompat>
              <SecureRouteCompat path="/national-holidays">
                <NationalHolidaysPage />
              </SecureRouteCompat>
              <SecureRoute path="/overdue">
                <OverduePreviewPage />
              </SecureRoute>
              <SecureRoute path="/reports">
                <Reports />
              </SecureRoute>
              <SecureRouteCompat exact path="/welltower-bu-settings">
                <WelltowerBuSettingsPage />
              </SecureRouteCompat>
              <SecureRouteCompat path="/welltower-bu-settings/:operatorId">
                <WelltowerBuSettingsPage />
              </SecureRouteCompat>
            </Switch>
          </PickerContextWrapper>
        </Route>
      </Switch>
    </Security>
  );
};

// I think this was just a lazy way to not affect indentation above. Could just combine these.
const WrappedApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <DeveloperModeWrapper>
        <BrowserRouter>
          <CompatRouter>
            <App />
          </CompatRouter>
        </BrowserRouter>
      </DeveloperModeWrapper>
    </QueryClientProvider>
  );
};

export default WrappedApp;
