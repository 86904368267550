/** @format */
import React from "react";
import {
  Row,
  Col,
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import "./MappingsTable.scss";
import Modal from "react-responsive-modal";

interface IMappingsTableProps {
  data: Array<any>;
  headers: Array<string>;
  fields: Array<string>;
  columnClassNames?: Array<string>;
  noDataMsg: string;
  searchInputPlaceholder: string;
  mappingsType: string;
  getEditForm: (mapping: any, callback?: Function) => React.Component | any;
  canEdit: boolean;
  ignoreStartMonth: boolean; // Set for ColumnMappings to simplify the workflow
}

interface IMappingsTableState {
  query: string;
  filteredData: Array<any>;
  showEditModal: boolean;
  currentMapping: any;
}

export default class MappingsTable extends React.Component<
  IMappingsTableProps,
  IMappingsTableState
> {
  constructor(props: any) {
    super(props);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.state = {
      query: "",
      filteredData: [],
      showEditModal: false,
      currentMapping: null,
    };
  }

  static defaultProps = {
    data: [],
    headers: [],
    fields: [],
    showEditModal: false,
    ignoreStartMonth: false,
  };

  onQueryChange(event: any) {
    let query = event.target.value;
    this.setState({ query });
  }

  renderHeader(label: string, index: number) {
    return <th key={index}>{label}</th>;
  }

  toggleEditModal(mappingData: any) {
    this.setState({ showEditModal: true, currentMapping: mappingData });
  }

  renderRow(
    fieldIteratees: any[],
    columnClassNames: Array<string>,
    rowData: any,
  ) {
    let fields = fieldIteratees.map((f: any, fieldIndex: number) => {
      return (
        <td key={fieldIndex} className={columnClassNames[fieldIndex] || ""}>
          {f(rowData) || "-"}
        </td>
      );
    });
    if (this.props.canEdit) {
      if (this.props.ignoreStartMonth) {
        fields.push(
          <td key={`mappings-edit-${rowData.id}`}>
            <Button
              color={"link"}
              style={{ color: "#007bff", padding: 0 }} // This alignment is still off, I think from vertical-align top on this table?
              onClick={() => this.toggleEditModal(rowData)}
              data-testid={"edit-mapping-menu-edit-existing"}
            >
              Edit
            </Button>
          </td>,
        );
      } else {
        fields.push(
          <td key={`mappings-edit-${rowData.id}`}>
            <UncontrolledDropdown
              data-testid={"edit-mapping-menu"}
              className={"d-inline align-baseline actions-dropdown"}
            >
              <DropdownToggle caret color={"link"} className={"py-0"}>
                <small data-testid={"edit-mapping-menu-toggle"}>Edit</small>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.toggleEditModal(rowData)}
                  data-testid={"edit-mapping-menu-edit-existing"}
                >
                  <small>Edit existing</small>
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    this.toggleEditModal(
                      Object.assign({}, rowData, {
                        id: null,
                        startMonth: null,
                      }),
                    )
                  }
                >
                  <small>Copy with new start month</small>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>,
        );
      }
    }

    return <tr key={rowData.id}>{fields}</tr>;
  }

  render() {
    const {
      data,
      fields,
      noDataMsg,
      searchInputPlaceholder,
      headers,
      getEditForm,
    } = this.props;
    const { query, currentMapping, showEditModal } = this.state;

    if (data.length === 0) {
      return (
        <Row>
          <Col>{noDataMsg}</Col>
        </Row>
      );
    }

    // Only map these once (per-render) and pass to renderRow.
    let fieldIteratees = fields.map(_.iteratee);

    let rows =
      query.length === 0
        ? data
        : data.filter((d) => {
            let matchesQuery = false;
            fieldIteratees.forEach((f) => {
              if (
                (f(d) || "")
                  .toString()
                  .toLowerCase()
                  .includes(query.toLowerCase())
              ) {
                matchesQuery = true;
              }
            });
            return matchesQuery;
          });
    return (
      <div className="filterable-table">
        <Modal
          open={showEditModal}
          showCloseIcon={true}
          closeOnOverlayClick={true} // TODO - should this be false? I don't like true.
          onClose={() => {
            this.setState({ showEditModal: false, currentMapping: null });
          }}
          styles={{ modal: { width: "600px" } }}
        >
          <h2>Edit Mapping</h2>
          <Row id={"edit-mappings-modal"}>
            <Col>
              {currentMapping
                ? getEditForm(currentMapping, () => {
                    this.setState({
                      showEditModal: false,
                      currentMapping: null,
                    });
                  })
                : null}
            </Col>
          </Row>
        </Modal>
        <Row>
          <Col sm="6">
            <InputGroup>
              <Input
                onChange={this.onQueryChange}
                placeholder={searchInputPlaceholder}
                value={this.state.query}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FontAwesomeIcon icon={"magnifying-glass"} title={"Search"} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table size={"sm"}>
              <thead>
                <tr>{headers.concat([""]).map(this.renderHeader)}</tr>
              </thead>
              <tbody className={"text-nowrap"}>
                {rows.length > 1000 ? (
                  <tr>
                    <td colSpan={headers.length}>
                      More than 1,000 mappings to display. Please use the search
                      box above to limit your results.
                    </td>
                  </tr>
                ) : (
                  rows.map((rowData: any) =>
                    this.renderRow(
                      fieldIteratees,
                      this.props.columnClassNames || [],
                      rowData,
                    ),
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}
