/** @format */

import { useState, useEffect, Dispatch, SetStateAction } from "react";

function getLocalStorageOrDefault(key: string, defaultValue: any) {
  const stored = localStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export const useLocalStorage = (
  key: string,
  defaultValue: any,
): [any, Dispatch<SetStateAction<any>>] => {
  const [value, setValue] = useState(
    getLocalStorageOrDefault(key, defaultValue),
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
