/** @format */

import React from "react";
import {
  getFileOperatorSummary,
  getFileWelltowerSummary,
} from "../../api/SubmittedFileAPI";
import { Button } from "reactstrap";
import { formatDateTime } from "../../util/DateUtils";
import WelltowerAccountingLoad from "../../types/WelltowerAccountingLoad";
import {
  recordsBatchNumber,
  statusTextColor,
  welltowerStatusMessage,
} from "../../util/StatusUtils";

import SubmittedFileActionMenu from "./SubmittedFileActionMenu";
import { DeveloperModeOnly } from "../DeveloperMode";
import { canRecordWelltowerAccountingLoad } from "../../util/OktaUtils";
import WelltowerAccountingLoadEditButton from "./WelltowerAccountingLoadEditButton";

export default class AccountantSubmittedFile extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { summary: null, operatorSummary: null };
  }

  componentDidMount() {
    this.loadSummary();
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.file.id !== this.props.file.id ||
      prevProps.file.status.id !== this.props.file.status.id
    ) {
      this.loadSummary();
    }
  }

  private async loadSummary() {
    const { file, authState } = this.props;
    if (!file.requirementDueDate.requirement.portalProcessed) return; // TODO - currently only SHO TB, but at least skipping this for unprocessed will be a big win
    let summary = null;
    try {
      summary = await getFileWelltowerSummary(
        file.requirementDueDate,
        file.id,
        authState,
      );
      summary = summary.find(
        (a: any) => a && a.submittedFile && a.submittedFile.id === file.id,
      );
      this.setState({ summary });
    } catch (error) {
      console.error(error);
      return null;
    }
    let operatorSummary = null;
    try {
      operatorSummary = await getFileOperatorSummary(
        file.requirementDueDate,
        file.id,
        authState,
      );
      operatorSummary = operatorSummary.find(
        (a: any) => a && a.submittedFile && a.submittedFile.id === file.id,
      );
      this.setState({ operatorSummary });
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  render() {
    const {
      file,
      downloadFile,
      downloadFullLogEntry,
      resumeFile,
      failFile,
      reuploadFile,
      retractFile,
      deleteFile,
      authState,
      locked,
    } = this.props;
    const {
      operatorSummary,
      summary,
      jdeInfoId,
      glDate,
      jdeBatchNumber,
      jdeInfoObject,
    } = this.state;
    const welltowerStatus = welltowerStatusMessage(file);
    const hasOperatorSummary = !!operatorSummary;
    const hasSummary = !!summary;
    const { downloadable } =
      file.requirementDueDate.requirement.documentType.uploadMethod;

    return (
      <li className={`submittedFile-${welltowerStatus.statusColor}`}>
        <DeveloperModeOnly>
          <span className={"text-muted"}>
            {file.requirementDueDate.requirement.operator.id}.
            {file.requirementDueDate.requirement.id}.
            {file.requirementDueDate.id}.{file.id}{" "}
          </span>
        </DeveloperModeOnly>
        {downloadable ? (
          <Button
            color={"link"}
            style={{ color: "#007bff" }}
            className={"font-weight-light pr-1 align-baseline"}
            onClick={() => downloadFile(file)}
          >
            {file.originalFilename}
          </Button>
        ) : (
          <span className={"font-weight-light px-2 align-baseline"}>
            {file.originalFilename}
          </span>
        )}
        <span>
          {" "}
          <small>{`${file.uploadUserDisplayName} on ${formatDateTime(
            file.uploadTime,
          )}`}</small>
        </span>
        <SubmittedFileActionMenu
          file={file}
          locked={locked}
          downloadFullLogEntry={downloadFullLogEntry}
          retractFile={retractFile}
          resumeFile={resumeFile}
          failFile={failFile}
          reuploadFile={reuploadFile}
          deleteFile={deleteFile}
          authState={authState}
          jdeInfoId={jdeInfoId}
          jdeInfoObject={jdeInfoObject}
          glDate={glDate}
          jdeBatchNumber={jdeBatchNumber}
          onSaveJDEInfo={(data?: any) => {
            //            this.loadJDEInfo(data);
            this.props.onSaveJDEInfo(data);
          }}
          preDownloadCallback={this.props.preDownloadCallback}
          postDownloadCallback={this.props.postDownloadCallback}
        />
        <ul className={"file-summary-list"}>
          {(file.requirementDueDate.requirement.portalProcessed ||
            welltowerStatus.statusColor !== "SUCCESS") && (
            <li className={statusTextColor(welltowerStatus.statusColor)}>
              <small>{welltowerStatus.statusLong}</small>
            </li>
          )}

          {file.welltowerAccountingLoads.map((wal: WelltowerAccountingLoad) => (
            <li data-testid={"jde-status-info"} key={wal.id}>
              <span>
                <small>
                  {wal.status === "WAITING" ? (
                    `${wal.id} - file ${wal.uploadFilename} waiting on ${wal.destination?.displayName} ${wal.destinationType} ETL`
                  ) : (
                    <>
                      {recordsBatchNumber(
                        file.requirementDueDate.requirement.documentType,
                        wal,
                      ) &&
                        `${wal.destination?.displayName} Batch #: ${
                          wal.uploadIdentifier || "unknown"
                        }, `}
                      {recordsBatchNumber(
                        file.requirementDueDate.requirement.documentType,
                        wal,
                      ) && `General Ledger Date: ${wal.glDate || "unknown"}, `}
                      {wal.status === "FAILED"
                        ? `failed`
                        : wal.status === "REVERSED"
                          ? `reversed`
                          : `loaded`}
                      {wal.uploadUserDisplayName &&
                        ` by ${wal.uploadUserDisplayName}`}
                      {` on ${formatDateTime(wal.uploadTime)}`}
                    </>
                  )}
                  {canRecordWelltowerAccountingLoad(
                    this.props.authState,
                    file.requirementDueDate.requirement.operator,
                    file.requirementDueDate.requirement,
                  ) && (
                    <WelltowerAccountingLoadEditButton
                      wal={wal}
                      file={file}
                      buttonType={"link"}
                      documentType={
                        file.requirementDueDate.requirement
                          .documentType /* because latestFile often doesn't include rdd to avoid a cycle */
                      }
                      afterSave={(data: any) => {
                        this.props.onSaveJDEInfo(data);
                      }}
                    />
                  )}
                </small>
              </span>
            </li>
          ))}

          {hasOperatorSummary && (
            <li>
              <small>
                Operator:{" "}
                {`${operatorSummary.summaryValues.lines[0]} lines, ${operatorSummary.summaryValues.accounts[0]} accounts, ${operatorSummary.summaryValues.properties[0]} properties `}
              </small>
            </li>
          )}
          {hasOperatorSummary &&
            operatorSummary.summaryValues.lines[0] !==
              operatorSummary.summaryValues.databaseLines[0] && (
              <li className={"text-danger"}>
                <small>
                  Loaded file had {operatorSummary.summaryValues.lines[0]}{" "}
                  lines, but the Portal database contains{" "}
                  {operatorSummary.summaryValues.databaseLines[0]} records. This
                  file was processed more than once and the Portal likely has
                  mis-counted totals. To address this, pick Reupload File from
                  the Actions menu, and then after that completes and a new file
                  appears below, update the {file.destination?.displayName} load
                  info on the new file and delete this file.
                </small>
              </li>
            )}
          {hasSummary && (
            <li>
              <small>
                Welltower:{" "}
                {`${summary.summaryValues.properties[0]} BU${
                  summary.summaryValues.properties[0] === 1 ? "" : "s"
                }, ${summary.summaryValues.accounts[0]} accounts, ${
                  summary.summaryValues.lines[0]
                } lines`}
                {Object.keys(summary.summaryValues).includes("subledgered") &&
                  summary.summaryValues.subledgered[0] > 0 &&
                  `, ${summary.summaryValues.subledgered[0]} with segments`}
              </small>
            </li>
          )}
          {hasSummary &&
            summary.summaryValues.lines[0] !==
              summary.summaryValues.databaseLines[0] && (
              <li className={"text-danger"}>
                <small>
                  Mapped file should have {summary.summaryValues.lines[0]}{" "}
                  Welltower lines, but the Portal database contains{" "}
                  {summary.summaryValues.databaseLines[0]} records. This file
                  was processed more than once and the Portal likely has
                  mis-counted totals. To address this, pick Reupload File from
                  the Actions menu, and then after that completes and a new file
                  appears below, update the {file.destination?.displayName} load
                  info on the new file and delete this file.
                </small>
              </li>
            )}
        </ul>
      </li>
    );
  }
}
