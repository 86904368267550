/** @format */

import React from "react";
import { Button, Col, Row } from "reactstrap";
import WithModal from "../WithModal";
import OperatorUnmappedItemList from "./OperatorUnmappedItemList";
import DocumentType from "../../types/DocumentType";

export type UNMAPPED_ITEM_TYPE = "account" | "property" | "column";

const MapItemsButton = WithModal(
  ({
    unmappedItems,
    onSaveUnmappedItems,
    type,
    operatorAccountGroupings,
    documentType,
    ...props
  }: {
    unmappedAccounts: Array<any>;
    onSaveUnmappedItems: any;
    type: UNMAPPED_ITEM_TYPE;
    documentType: DocumentType;
  } & Partial<any>) => (
    <div>
      <Row className={"mb-3"}>
        <Col sm={"4"} />
        <Col sm={"4"}>
          <Button
            block
            className={"py-3"}
            color={"primary"}
            onClick={props.showModal}
            data-testid={
              type === "account"
                ? "unmapped-accounts-link"
                : type === "property"
                  ? "unmapped-properties-link"
                  : type === "column"
                    ? "unmapped-columns-link"
                    : "unmapped-unknown-link"
            }
          >
            {type === "account"
              ? "ACCOUNT"
              : type === "property"
                ? "PROPERTY"
                : type === "column"
                  ? "COLUMN"
                  : "MAPPING"}{" "}
            {`DETAILS (${unmappedItems.length})`}
          </Button>
        </Col>
      </Row>
      {props.modalContent(
        <div>
          <h4>
            {type === "account"
              ? "Account"
              : type === "property"
                ? "Property"
                : type === "column"
                  ? "Column"
                  : "MAPPING"}
            {" Information"}
          </h4>
          <OperatorUnmappedItemList
            operatorAccountGroupings={operatorAccountGroupings}
            unmappedItems={unmappedItems}
            onSaveUnmappedItems={(data: any) => {
              onSaveUnmappedItems(data);
              props.closeModal();
            }}
            type={type}
            documentType={documentType}
          />
        </div>,
        {
          showCloseIcon: true,
          modalStyles: { width: "1024px", maxWidth: "100%" },
          closeOnOverlayClick: false,
        },
      )}
    </div>
  ),
);

const OperatorUnmappedItemSummary = ({
  unmappedAccounts,
  unmappedProperties,
  unmappedColumns,
  operatorAccountGroupings,
  onSaveUnmappedItems,
  documentType,
}: {
  unmappedAccounts: Array<any>;
  unmappedProperties: Array<any>;
  unmappedColumns: Array<any>;
  operatorAccountGroupings: Array<any>;
  onSaveUnmappedItems: any;
  documentType: DocumentType;
}) => {
  return (
    <React.Fragment>
      {unmappedAccounts.length > 0 && (
        <MapItemsButton
          unmappedItems={unmappedAccounts}
          operatorAccountGroupings={operatorAccountGroupings}
          onSaveUnmappedItems={(data: any) => {
            onSaveUnmappedItems(data, "account");
          }}
          type={"account"}
          documentType={documentType}
        />
      )}
      {unmappedProperties.length > 0 && (
        <MapItemsButton
          unmappedItems={unmappedProperties}
          onSaveUnmappedItems={(data: any) => {
            onSaveUnmappedItems(data, "property");
          }}
          type={"property"}
          documentType={documentType}
        />
      )}
      {unmappedColumns.length > 0 && (
        <MapItemsButton
          unmappedItems={unmappedColumns}
          onSaveUnmappedItems={(data: any) => {
            onSaveUnmappedItems(data, "column");
          }}
          type={"column"}
          documentType={documentType}
        />
      )}
    </React.Fragment>
  );
};

export default OperatorUnmappedItemSummary;
