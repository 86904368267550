/** @format */

import { ErrorMessage, Field, FieldInputProps } from "formik";
import React from "react";

interface WrappedTextInputProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
}

const WrappedTextInput = (props: WrappedTextInputProps) => {
  const { id, label, placeholder, required, disabled, className } = props;
  return (
    <div className={`editFormInput ${className || ""}`} key={id}>
      <label htmlFor={id} className={required ? "label-required" : ""}>
        {label}
      </label>
      <Field type="text" name={id} placeholder={placeholder}>
        {({ field, form }: { field: FieldInputProps<string>; form: any }) => (
          <input
            type="text"
            id={id}
            {...field}
            className={
              form.errors[id] && form.touched[id]
                ? "form-control text-input error"
                : "form-control text-input"
            }
            placeholder={placeholder || undefined}
            disabled={disabled}
            required={required}
          />
        )}
      </Field>
      <ErrorMessage name={id} component={"div"} className={"input-feedback"} />
    </div>
  );
};
export default WrappedTextInput;
