/** @format */
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import RequirementDueDate from "../types/RequirementDueDate";

const dateFormatsUS = {
  monthYear: "MMMM YYYY",
  monthDay: "MMM D",
  monthDayShort: "M/D",
  month: "MMMM",
  dayShort: "ll",
  date: "L",
  dateShort: "l",
  dateTime: "L LT",
  dateTimeSeconds: "L LTS",
  iso: "YYYY-MM-DD",
  isoMonthYear: "YYYY-MM",
};

//const dateFormatsUK = Object.assign({}, dateFormatsUS, { monthDay: "D MMMM" });

const dateFormats = dateFormatsUS;

const formatDateMonthYear = (d: Date | string) =>
  !!d && dayjs(d).format(dateFormats.monthYear);
const formatDateMonth = (d: Date | string) =>
  !!d && dayjs(d).format(dateFormats.month);
const formatDateMonthDay = (d: Date | string) =>
  !!d && dayjs(d).format(dateFormats.monthDay);
const formatDateMonthDayShort = (d: Date | string | undefined) =>
  !!d && dayjs(d).format(dateFormats.monthDayShort);
const formatDateDayShort = (d: Date | string) =>
  !!d && dayjs(d).format(dateFormats.dayShort);
const formatDate = (d: Date | string) =>
  !!d && dayjs(d).format(dateFormats.date);
const formatDateShort = (d: Date | string | undefined) =>
  !!d && dayjs(d).format(dateFormats.dateShort);
const formatDateISO = (d: Date | string) =>
  !!d && dayjs(d).format(dateFormats.iso);
const formatDateTime = (d: Date | string) =>
  !!d && dayjs(d).format(dateFormats.dateTime);
const formatDateTimeSeconds = (d: Date | string) =>
  !!d && dayjs(d).format(dateFormats.dateTimeSeconds);
const formatDateISOMonthYear = (d: Date | string) =>
  !!d && dayjs(d).format(dateFormats.isoMonthYear);
const formatDateDayOfWeek = (d: Date | string) =>
  !!d && dayjs(d).format("dddd");

const parseDate = (d: string | Date | undefined) =>
  d ? dayjs(d).toDate() : undefined;

const formatPeriodEnd = (rdd: RequirementDueDate) => {
  // TODO - international formatting?
  if (rdd.requirement.frequency.name === "MONTHLY") {
    return formatDateMonth(rdd.periodEndDate);
  } else {
    return rdd.periodEndDate;
  }
};
const formatPeriod = (rdd: RequirementDueDate) => {
  // TODO - international formatting?
  if (rdd.requirement.frequency.name === "MONTHLY") {
    return formatDateMonthYear(rdd.periodEndDate);
  } else {
    return formatDate(rdd.periodEndDate);
  }
};

const isoDateToMonth = (s: string) => !!s && s.substr(0, 7);

const getDefaultMonth = (months: string[]) => {
  if (!months) return months;
  if (
    process.env.REACT_APP_DEFAULT_MONTH &&
    months.includes(process.env.REACT_APP_DEFAULT_MONTH)
  ) {
    return process.env.REACT_APP_DEFAULT_MONTH;
  }
  // Look for last month
  const lastMonthISO = dayjs().subtract(1, "month").format("YYYY-MM");
  if (months.includes(lastMonthISO)) return lastMonthISO;

  // Look for this month
  const thisMonthISO = dayjs().format("YYYY-MM");
  if (months.includes(thisMonthISO)) return thisMonthISO;

  return months[0];
};

const calculateStartMonthOptions = (
  defaultStartDate: string | number | Date | Dayjs,
  todayOverride?: string | number | Date | Dayjs,
) => {
  const today = todayOverride || new Date();
  const todayStartYear = dayjs(today).year();
  const todayStartMonth = dayjs(today).month();
  const defaultPeriod =
    defaultStartDate && dayjs(defaultStartDate).format("YYYY-MM-DD");
  const todayPeriod = dayjs()
    .set("year", todayStartYear)
    .set("month", todayStartMonth)
    .set("date", 1)
    .format("YYYY-MM-DD");
  const previousPeriod = dayjs()
    .set("year", todayStartYear)
    .set("month", todayStartMonth - 1)
    .set("date", 1)
    .format("YYYY-MM-DD");
  const nextPeriod = dayjs()
    .set("year", todayStartYear)
    .set("month", todayStartMonth + 1)
    .set("date", 1)
    .format("YYYY-MM-DD");
  let periods = [
    defaultPeriod && {
      label: formatDateMonthYear(defaultPeriod),
      value: defaultPeriod,
    },
    { label: formatDateMonthYear(previousPeriod), value: previousPeriod },
    { label: formatDateMonthYear(nextPeriod), value: nextPeriod },
    {
      label: formatDateMonthYear(todayPeriod),
      value: todayPeriod,
    },
  ].filter((x: any) => !!x);

  return _.sortBy(_.uniqWith(periods, _.isEqual), "value");
};

const calculateEndMonthOptions = (
  existingStartDate: string | number | Date | Dayjs,
  defaultEndDate?: string | number | Date | Dayjs,
  todayOverride?: string | number | Date | Dayjs,
) => {
  const today = todayOverride || new Date();
  const todayStartYear = dayjs(today).year();
  const todayStartMonth = dayjs(today).month();
  const defaultPeriod =
    defaultEndDate && dayjs(defaultEndDate).format("YYYY-MM-DD");
  const todayPeriod = dayjs()
    .set("year", todayStartYear)
    .set("month", todayStartMonth)
    .set("date", 1)
    .format("YYYY-MM-DD");
  const previousPeriod = dayjs()
    .set("year", todayStartYear)
    .set("month", todayStartMonth - 1)
    .set("date", 1)
    .format("YYYY-MM-DD");
  const nextPeriod = dayjs()
    .set("year", todayStartYear)
    .set("month", todayStartMonth + 1)
    .set("date", 1)
    .format("YYYY-MM-DD");
  let periods = [
    defaultPeriod && {
      label: formatDateMonthYear(defaultPeriod),
      value: defaultPeriod,
    },
    { label: formatDateMonthYear(previousPeriod), value: previousPeriod },
    { label: formatDateMonthYear(nextPeriod), value: nextPeriod },
    {
      label: formatDateMonthYear(todayPeriod),
      value: todayPeriod,
    },
  ].filter((x: any) => !!x);

  return _.sortBy(_.uniqWith(periods, _.isEqual), "value");
};

const relativeTimeShort = (
  then: Date | Dayjs,
  now?: Date | Dayjs | undefined,
) => {
  const nowSec = dayjs(now ?? new Date()).unix();
  const thenSec = dayjs(then).unix();

  const isFuture = thenSec > nowSec;
  let diff = Math.abs(thenSec - nowSec);

  let str = "";

  const handleInterval = (length_in_seconds: number, abbreviation: string) => {
    const x = Math.floor(diff / length_in_seconds);
    if (str.length && x && x < 10) str += "0";
    if (x || (!str.length && length_in_seconds === 1))
      // If this is seconds (1) and we haven't written anything yet, write "0s"
      str += `${x}${abbreviation}`;
    diff = diff % length_in_seconds;
  };

  handleInterval(24 * 60 * 60, "d");
  handleInterval(60 * 60, "h");
  handleInterval(60, "m");
  handleInterval(1, "s");

  return isFuture ? `in ${str}` : `${str} ago`;
};

export {
  formatDateMonthYear,
  formatDateMonthDay,
  formatDateMonthDayShort,
  formatDateDayShort,
  formatDate,
  formatDateShort,
  formatDateISO,
  formatDateTime,
  formatDateTimeSeconds,
  formatDateISOMonthYear,
  formatDateDayOfWeek,
  parseDate,
  formatPeriod,
  formatPeriodEnd,
  isoDateToMonth,
  getDefaultMonth,
  calculateStartMonthOptions,
  calculateEndMonthOptions,
  relativeTimeShort,
};
