/** @format */
import React from "react";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { ErrorMessage, Field, FormikProps } from "formik";
import WrappedSelectInput from "../../form/WrappedSelectInput";
import SimpleMappedColumnOptionDto from "../../../types/SimpleMappedColumnOptionDto";
import UnmappedColumnDto from "../../../types/UnmappedColumnDto";

interface IUnmappedColumnProps {
  unmappedItem: UnmappedColumnDto;
  index: number;
  //operatorAccountGroupings: Array<any>;
  //welltowerAccounts?: Array<any>;
  columnOptions: SimpleMappedColumnOptionDto[];
  isWelltowerAccountant: boolean;
  formikProps: FormikProps<any>;
}

// TODO - can we share some of these fields with ColumnMappingForm?
export const UnmappedColumnForm = (props: IUnmappedColumnProps) => {
  const renderEditableFields = () => {
    let {
      unmappedItem,
      formikProps,
      index,
      columnOptions,
      isWelltowerAccountant,
    } = props;

    return [
      <Col
        key={`unmappedItem.${index}.${index}`}
        sm={!isWelltowerAccountant ? "4" : ""}
      >
        <WrappedSelectInput
          id={`unmappedItems.${index}.operatorSelectedOptionId`}
          label={
            isWelltowerAccountant
              ? "Operator Mapping"
              : "Welltower template value"
          }
          value={columnOptions?.find(
            (it: any) => it.value === unmappedItem.operatorSelectedOptionId,
          )}
          required={true}
          options={columnOptions}
          onChange={formikProps.setFieldValue}
          onBlur={formikProps.setFieldTouched}
        />
      </Col>,
      <Col
        key={`unmappedItem.${index}.${index + 1}`}
        sm={!isWelltowerAccountant ? "6" : ""}
      >
        <label
          htmlFor={`unmappedItems.${index}.additionalInformation`}
          className={"label-required"}
        >
          Detailed Description
        </label>
        <Field
          name={`unmappedItems.${index}.additionalInformation`}
          validate={(value: string) => {
            if (value && value.length > 255) {
              return "Must be less than 255 characters";
            }
          }}
        >
          {({ field }: any) => (
            <>
              <Input
                {...field}
                value={unmappedItem.additionalInformation || ""}
                title={unmappedItem.additionalInformation || ""}
              />
              <ErrorMessage
                name={`unmappedItems.${index}.additionalInformation`}
                component={"div"}
                className={"input-feedback"}
              />
            </>
          )}
        </Field>
      </Col>,
    ];
  };

  let {
    unmappedItem,
    formikProps,
    index,
    columnOptions,
    isWelltowerAccountant,
  } = props;

  if (!columnOptions || columnOptions.length === 0) return null;
  return (
    <Card key={`${unmappedItem.id}-${index}`}>
      <CardBody className={"px-0 py-3"}>
        <Container>
          <Row className="unmapped-account">
            <Col>
              {unmappedItem.documentTypeName || ""} -{" "}
              {unmappedItem.columnName || ""} -{" "}
              <strong>{unmappedItem.operatorValue || ""}</strong>
            </Col>
          </Row>
          {!isWelltowerAccountant && (
            <Row className={"mt-3"}>{renderEditableFields()}</Row>
          )}
          {isWelltowerAccountant && (
            <Row className={"mt-3"}>
              {renderEditableFields()}

              <Col>
                <WrappedSelectInput
                  id={`unmappedItems.${index}.welltowerSelectedOptionId`}
                  label={"Welltower Mapping"}
                  value={columnOptions?.find(
                    (it: any) =>
                      it.value === unmappedItem.welltowerSelectedOptionId,
                  )}
                  required={true}
                  options={columnOptions}
                  onChange={formikProps.setFieldValue}
                  onBlur={formikProps.setFieldTouched}
                />
              </Col>
            </Row>
          )}
        </Container>
      </CardBody>
    </Card>
  );
};
