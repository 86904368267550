/** @format */
import NotificationPreference from "../../types/NotificationPreference";
import React, { FC, useState } from "react";
import {
  deleteNotificationPreference,
  updateNotificationPreferences,
} from "../../api/NotificationPreferencesAPI";
import ToggleSwitch from "../../components/toggle-switch/ToggleSwitch";
import { getUserLogin } from "../../util/OktaUtils";
import { useOktaAuth } from "@okta/okta-react";

interface IPreferenceToggleProps {
  disabled?: boolean;
  requirementId: number;
  operatorId: number;
  documentTypeId: number | string;
  statusType: string | null;
  preference?: NotificationPreference;
  onUpdatePref: () => Promise<void>;
  title?: string;
}

const PreferenceToggle: FC<IPreferenceToggleProps> = (props) => {
  const { authState } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const onPreferenceToggle = async (isToggledOn: any) => {
    if (props.disabled) return;
    setLoading(true);
    if (isToggledOn) {
      const { requirementId, operatorId, statusType, documentTypeId } = props;

      const newPreference = {
        operator: { id: operatorId },
        requirement: { id: requirementId },
        documentType: { id: documentTypeId },
        userId: getUserLogin(authState),
        statusType: statusType,
        overdue: statusType ? null : true,
      };

      await updateNotificationPreferences(authState, newPreference);
    } else if (props.preference) {
      await deleteNotificationPreference(authState, props.preference.id);
    }
    return props.onUpdatePref().then(() => setLoading(false));
  };

  return (
    <ToggleSwitch
      disabled={loading || props.disabled}
      wait={loading}
      isChecked={!!props.preference}
      onValueChanged={onPreferenceToggle}
      title={props.title}
    />
  );
};

export default PreferenceToggle;
