/** @format */

import React from "react";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "../../util/DateUtils";
import {
  deleteFileJDEBatch,
  saveFileJDEBatch,
  updateFileJDEBatch,
} from "../../api/SubmittedFileAPI";
import { getUserLogin } from "../../util/OktaUtils";
import { welltowerName } from "../../util/NameUtils";
import "./SubmittedFileComponent.scss";
import WelltowerAccountingLoad from "../../types/WelltowerAccountingLoad";

export default class EditSpotDbForm extends React.Component<any, any> {
  static defaultProps = {
    uploadIdentifier: "",
  };
  state = {
    submitting: false,
  };

  onSaveJDEInfo = async (failed: boolean, callback: Function) => {
    let payload = {
      uploadIdentifier: this.props.uploadIdentifier,
      uploadTime: new Date(),
      uploadUserId: getUserLogin(this.props.authState),
      //failed: failed,
      //reversed: this.props.reversed,
      status: this.props.reversed ? "REVERSED" : failed ? "FAILED" : "SUCCESS",
    };

    try {
      if (this.props.jdeInfoId) {
        await updateFileJDEBatch(
          payload as WelltowerAccountingLoad,
          this.props.file.requirementDueDate.id,
          this.props.file.id,
          this.props.jdeInfoId,
          this.props.authState,
        );
      } else {
        await saveFileJDEBatch(
          payload as WelltowerAccountingLoad,
          this.props.file.requirementDueDate.id,
          this.props.file.id,
          this.props.authState,
        );
      }
      callback && callback.call(null);
      this.props.onSaveJDEInfo();
    } catch (err) {
      console.error(err);
    }
  };

  deleteJDEInfo = async (callback?: Function) => {
    try {
      let data;
      if (this.props.jdeInfoId) {
        data = await deleteFileJDEBatch(
          this.props.file.requirementDueDate.id,
          this.props.file.id,
          this.props.jdeInfoId,
          this.props.authState,
        );
      }
      // TODO - have the delete return the RDD, then pass back to callback, then have onSaveJDEInfo pass to BaseRddCard's this.props.receiveUpdatedRequirementDueDate(data, true); this.loadFiles(data, undefined, true);
      callback && callback.call(null);
      this.props.onSaveJDEInfo(data);
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    let { file, jdeInfoId } = this.props;

    return (
      <div className={"editForm edit-jdeinfo-form"}>
        <h2 className="propertyName">
          {welltowerName(file.requirementDueDate.requirement.operator)}
        </h2>
        <Row>
          <Col>
            <i>{`${welltowerName(
              file.requirementDueDate.requirement,
            )}, Due ${formatDate(file.requirementDueDate.dueDate)}`}</i>
          </Col>
        </Row>
        <Row className={"mb-4"}>
          <Col>
            <i>{`${file.originalFilename}, Submitted to Portal: ${formatDate(
              file.uploadTime,
            )}`}</i>
          </Col>
        </Row>

        {jdeInfoId && (
          <Button
            onClick={() => this.deleteJDEInfo()}
            color={"danger"}
            disabled={this.state.submitting}
          >
            <FontAwesomeIcon
              icon={["far", "trash-alt"]}
              style={{
                cursor: "pointer",
              }}
            />
          </Button>
        )}

        <Button
          type={"submit"}
          color={"success"}
          disabled={this.state.submitting}
          className={"float-right wtop-btn"}
          onClick={() => {
            this.setState({ submitting: true });
            this.onSaveJDEInfo(false, () =>
              this.setState({ submitting: false }),
            );
          }}
        >
          Batch Success
        </Button>
        <Button
          type={"submit"}
          color={"dark"}
          disabled={this.state.submitting}
          className={"float-right wtop-btn mr-2"}
          onClick={() => {
            this.setState({ submitting: true });
            this.onSaveJDEInfo(true, () =>
              this.setState({ submitting: false }),
            );
          }}
        >
          Batch Failed
        </Button>
      </div>
    );
  }
}
