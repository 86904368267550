/** @format */
import SubmissionStatus, {
  SubmissionStatusDto,
} from "../types/SubmissionStatus";
import SubmittedFile from "../types/SubmittedFile";
import RequirementDueDate from "../types/RequirementDueDate";
import DocumentType from "../types/DocumentType";
import StatusMessage from "../types/StatusMessage";
import WelltowerAccountingLoad from "../types/WelltowerAccountingLoad";

// TODO - probably time to move all of these to backend/database flags for easier editing

// NOTE - keep these as === tests, because IDEA/TypeScript will check those, but doesn't appear to check ["xyz","abc"].includes(documentType.id)

export const hasFailureStatus = (status: SubmissionStatus) => status.failed;

export const hasErrorStatus = (status: SubmissionStatus) =>
  status.name === "FAILED_BALANCE" ||
  status.name === "MISSING_MAPPINGS" ||
  status.name === "FAILED_MAPPED_BALANCE";

export const hasNoFileStatus = (status: SubmissionStatus) =>
  status.name === "NO_FILE";

export const hasMissingMappingsStatus = (status: SubmissionStatus) =>
  status.name === "SUBMITTED_MAPPINGS" || status.name === "MISSING_MAPPINGS";

export const hasOperatorCompleteStatus = (status: SubmissionStatus) =>
  status.operatorComplete && !status.welltowerComplete;

export const hasWelltowerCompleteStatus = (status: SubmissionStatus) =>
  status.operatorComplete && status.welltowerComplete;

export const hasReadyForETLStatus = (
  sf: SubmittedFile | undefined,
  rdd: RequirementDueDate,
) => {
  // RDD.latestFile.rdd isn't set, so we can't just accept a file and assume we can walk to documentType
  const f = sf || rdd.latestFile;
  return (
    rdd.requirement.portalProcessed && f?.status.id === "ACCEPTED_WELLTOWER"
  );
};
export const hasCanRecordLoadStatus = (
  sf: SubmittedFile | undefined,
  rdd: RequirementDueDate,
) => {
  // RDD.latestFile.rdd isn't set, so we can't just accept a file and assume we can walk to documentType
  const f = sf || rdd.latestFile;
  return hasReadyForETLStatus(sf, rdd) || (f && hasAwaitingETLStatus(f.status));
};

// TODO - rename to something like hasERPLoadedStatus? And/or move to a flag on the status object
export const hasJDEStatus = (status: SubmissionStatus) =>
  status.id === "LOADED_WELLTOWER" ||
  status.id === "FAILED_WELLTOWER" ||
  status.id === "FAILED_FAILED_REVALIDATION" ||
  status.id === "LOADED_FAILED_REVALIDATION" ||
  status.id === "REVERSED_WELLTOWER";

// TODO - rename to something like hasUncancelableRPAStatus? And/or move to a flag on the status object
export const hasAwaitingETLStatus = (status: SubmissionStatus) =>
  status.id === "AWAITING_RPA" ||
  status.id === "AWAITING_PULL_RESULTS" ||
  status.id === "AWAITING_ETL_RESULTS" ||
  status.id === "GENERATING_ETL" ||
  status.id === "SUBMITTING_ETL";

export const hasCancelableRPAStatus = (status: SubmissionStatus) =>
  status.id === "AWAITING_RPA" || status.id === "AWAITING_PULL_RESULTS";

export const isSendToRPAEnabled = (requirementDueDate: RequirementDueDate) =>
  requirementDueDate.requirement.rpaEnabled &&
  requirementDueDate.requirement.rpaEnabled.name !== "OFF";

export const recordsBatchNumber = (
  documentType: DocumentType | any,
  wal?: WelltowerAccountingLoad,
  otherData: { fileId?: number } = {},
) => {
  return (
    documentType.id === "TRIAL_BALANCE" ||
    documentType.id === "TRIAL_BALANCE_NNN" ||
    documentType.id === "TRIAL_BALANCE_HLBV" ||
    (wal?.destination?.name === "YARDI" &&
      wal?.destinationType === "FinJournals") ||
    (otherData?.fileId !== undefined && otherData?.fileId > 99000000)
  );
};

export const recordsSpotDbLoad = (documentType: DocumentType) =>
  documentType.id === "WEEKLY_CENSUS";

export const hasInProgressStatus = (status: SubmissionStatus) =>
  status.type === "IN_PROGRESS";

export const hasDeletedOrRetractedStatus = (
  status: SubmissionStatus | SubmissionStatusDto,
) => ("deleted" in status && status.deleted) || status.retracted;

export const welltowerStatusBucket = (
  status: SubmissionStatus | SubmissionStatusDto,
) => {
  if (status.failed) {
    return { order: 150, label: "Error" };
  } else if (status.id === "ACCEPTED_UNPROCESSED") {
    return { order: 16, label: "Accepted" }; // TODO - better label
  } else if (
    status.id === "LOADED_WELLTOWER" ||
    status.id === "REVERSED_WELLTOWER"
  ) {
    return { order: 15, label: "Loaded" }; // TODO - better label // TODO yardi
  } else if (
    status.id === "ACCEPTED_WELLTOWER" ||
    status.id === "AWAITING_RPA"
  ) {
    return { order: 12, label: "Ready to Load" };
  } else if (status.type === "IN_PROGRESS") {
    return { order: 100, label: "Processing" };
  } else if (status.type === "HOLD_WELLTOWER") {
    return { order: 10, label: "Waiting on Welltower" };
  } else if (status.type === "HOLD_OPERATOR") {
    return { order: 5, label: "Waiting on Operator" };
  } else if (status.id === "NO_FILE" || hasDeletedOrRetractedStatus(status)) {
    return { order: 1, label: "Not Started" };
  } else if (
    status.id === "AWAITING_TB_FILE" ||
    status.id === "AWAITING_RF_FILE" ||
    status.id === "AWAITING_CAPEX_FILE"
  ) {
    return { order: 11, label: "In Queue : Waiting for File" };
  } else {
    return { order: 200, label: status.id };
  }
};

export const statusTextColor = (statusColor?: string) => {
  let textColorClass = "";
  if (statusColor === "ERROR") {
    textColorClass = "text-danger";
  } else if (statusColor === "WARNING") {
    textColorClass = "text-muted";
  } else if (statusColor === "SUCCESS") {
    textColorClass = "text-success";
  } else if (statusColor === "IN_PROGRESS") {
    textColorClass = "text-primary";
  }
  return textColorClass;
};

export const operatorStatusMessage = (status: SubmissionStatus) =>
  ({
    id: status.id,
    statusShort: status.operatorStatusShort,
    statusLong: status.operatorStatusLong,
    statusColor: status.operatorStatusColor,
  }) as StatusMessage;
export const welltowerStatusMessage = (f: SubmittedFile | RequirementDueDate) =>
  ({
    id: f.status.id,
    statusShort: f.status.welltowerStatusShort,
    statusLong: f.welltowerStatusLong || f.status.welltowerStatusLong,
    statusColor: f.status.welltowerStatusColor,
  }) as StatusMessage;
