/** @format */

import React from "react";
import _ from "lodash";
import { Button } from "reactstrap";
import StatusMessage from "../../types/StatusMessage";
import { statusTextColor } from "../../util/StatusUtils";

export const LogEntryLevelSummary = ({
  logLevel,
  logEntries,
  downloadFullLogEntry,
}: {
  logLevel: string;
  logEntries: Array<any>;
  downloadFullLogEntry: any;
}) => {
  const uniquedLogEntries = _.uniqBy(logEntries, (x: any) =>
    JSON.stringify(_.pick(x, ["level", "type", "message"])),
  );
  let uniqueMessages = Object.keys(_.groupBy(uniquedLogEntries, "message"));
  let numOfMessages = uniqueMessages.length;
  let width = "w-75";
  let countsByMessage: any = {};
  if (numOfMessages > 5) {
    // too many messages, group by message type instead
    width = "w-50";
    const byType = _.groupBy(uniquedLogEntries, "type.displayName");
    uniqueMessages = Object.keys(byType).map((type) => {
      const message = `${byType[type].length} ${type} ${logLevel.toLowerCase()}${
        byType[type].length !== 1 ? "s" : ""
      }`;
      countsByMessage[message] = logEntries.filter(
        (le) => le.type.displayName === type,
      ).length;
      return message;
    });
  } else {
    uniqueMessages.forEach((m) => {
      countsByMessage[m] = logEntries.filter((le) => le.message === m).length;
    });
  }

  const alertClassName =
    logLevel === "WARNING" ? "alert-warning" : "alert-danger";
  const downloadText =
    logLevel === "WARNING" ? "Download details" : "Download error details";

  return (
    <>
      <div
        className={`alert ${alertClassName} ${width} m-auto text-left`}
        data-testid={`log-entry-${logLevel}-alert`}
        role="alert"
      >
        <h4
          data-testid={"log-entry-level-summary-count"}
          className="alert-heading"
        >{`${numOfMessages} ${_.capitalize(logLevel)}${
          numOfMessages !== 1 ? "s" : ""
        }: `}</h4>
        <ul>
          {uniqueMessages.map((m) => {
            const count = countsByMessage[m];
            const countStr = count > 1 ? ` (${count} occurrences)` : "";
            return (
              <li key={m}>
                <small>
                  {m}
                  {countStr}
                </small>
              </li>
            );
          })}
        </ul>
        <div className={"text-center"}>
          <Button
            color={"link"}
            onClick={() => downloadFullLogEntry()}
            title={"Download full log of file errors"}
            className={"alert-link mt-n1 px-0"}
          >
            <small>{downloadText}</small>
          </Button>
        </div>
      </div>
    </>
  );
};

const RddStatusMessage = ({
  statusMessage,
  logEntries,
  downloadFullLogEntry,
  uploadingFilename,
  uploadingBatchProgress,
  uploadingFilePercent,
  allFileStatuses, // Only pass in if multi-file (!process)
}: {
  statusMessage: StatusMessage;
  logEntries: Array<any>;
  downloadFullLogEntry: any;
  uploadingFilename?: string;
  uploadingBatchProgress?: string;
  uploadingFilePercent?: number;
  allFileStatuses?: Array<StatusMessage>;
}) => {
  const groupedLogEntries = _.groupBy(logEntries, "level");
  // Sort by severity
  const allLogLevels = ["ERROR", "WARNING", "INFO"];
  const logLevels = allLogLevels.filter((l) =>
    Object.keys(groupedLogEntries).includes(l),
  );

  const countedStatuses = _.countBy(allFileStatuses, (sm) =>
    JSON.stringify(sm),
  );

  return (
    <div>
      {uploadingFilename && (
        <p className={statusTextColor(statusMessage.statusColor)}>
          Uploading {uploadingFilename} {uploadingBatchProgress} ...{" "}
          {uploadingFilePercent}%
        </p>
      )}
      {_.keys(countedStatuses)?.length > 1 ? (
        _.keys(countedStatuses).map((k) => {
          const obj = JSON.parse(k) as StatusMessage;
          return (
            <p
              key={obj.statusLong}
              className={statusTextColor(obj.statusColor)}
            >
              {obj.statusLong} ({countedStatuses[k]} file
              {countedStatuses[k] === 1 ? "" : "s"})
            </p>
          );
        })
      ) : (
        <p className={statusTextColor(statusMessage.statusColor)}>
          {statusMessage.statusLong}
        </p>
      )}

      {logLevels.map((level: string) => (
        <LogEntryLevelSummary
          key={level}
          logLevel={level}
          logEntries={groupedLogEntries[level]}
          downloadFullLogEntry={downloadFullLogEntry}
        />
      ))}
    </div>
  );
};

export default RddStatusMessage;
