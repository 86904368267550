/** @format */
import React from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { withOktaAuth } from "@okta/okta-react";
import "./OperatorSettingsPage.scss";
import OperatorCustomHeadingFormButton from "../../components/operator-custom-heading/OperatorCustomHeadingFormButton";
import Operator from "../../types/Operator";
import { startCase } from "lodash";
import {
  getOperatorCustomHeadings,
  getOperatorSettings,
  updateOperatorSettings,
} from "../../api/OperatorAPI";
import DocumentType from "../../types/DocumentType";
import { loadDocumentTypes, loadHeadings } from "../../api/SharedAPI";
import OperatorCustomHeadingDeleteButton from "../../components/operator-custom-heading/OperatorCustomHeadingDeleteButton";
import {
  canEditCustomHeadings,
  canManageOperatorCensusSettings,
  canManageOperatorOtherDocsSettings,
  canManageOperatorTbSettings,
} from "../../util/OktaUtils";
import OperatorSettingsEditButton from "./OperatorSettingsEditButton";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectTheme } from "../../components/form/WrappedSelectInput";
import { OperatorPickerId } from "../../types/PickerTypes";
import { ContextOperatorPicker } from "../../components/filters/ContextOperatorPicker";
import {
  OperatorContextProps,
  withOperatorContext,
} from "../../components/filters/PickerContext";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";

interface ISettingsState {
  settings: any;
  errors: any;
  portfolio: string;
  portfolios: Array<any>;
  documentTypes: Array<any>;
  headerIdentifiers: Array<any>;
  customHeadings: Array<any>;
}

class OperatorSettingsPage extends React.Component<
  OperatorContextProps & IOktaContext & Partial<any>,
  ISettingsState
> {
  constructor(props: any) {
    super(props);
    this.renderSettingsField = this.renderSettingsField.bind(this);
    this.updateCurrentOperator = this.updateCurrentOperator.bind(this);
    this.updateCurrentPortfolio = this.updateCurrentPortfolio.bind(this);
    this.state = {
      settings: {},
      portfolio: "SHO",
      portfolios: [],
      documentTypes: [],
      headerIdentifiers: [],
      customHeadings: [],
      errors: {},
    };
  }
  async componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { authState } = this.props;
    try {
      const documentTypes: Array<DocumentType> =
        await loadDocumentTypes(authState);
      const headerIdentifiers: Array<any> = await loadHeadings(authState);

      let transformedDocumentTypes = documentTypes
        ? documentTypes
            .filter((dt) => dt.process)
            .map((dt: any) => {
              return { value: dt.id, label: dt.name };
            })
        : [];
      let transformedHeaderIdentifiers = headerIdentifiers
        ? headerIdentifiers.map((hi: any) => {
            return { value: hi.name, label: hi.displayName, obj: hi };
          })
        : [];
      let transformedPortfolios = ["SHO", "NNN"].map((p: any) => {
        return { value: p, label: p };
      });
      this.setState({
        documentTypes: transformedDocumentTypes,
        headerIdentifiers: transformedHeaderIdentifiers,
        portfolios: transformedPortfolios,
      });
    } catch (error) {
      console.error(error);
    }
  };

  async loadOperatorCustomHeadings(operatorId?: OperatorPickerId) {
    if (typeof operatorId === "number") {
      try {
        const customHeadings = await getOperatorCustomHeadings(
          operatorId,
          this.props.authState,
        );
        this.setState({ customHeadings });
      } catch (error) {
        console.error(error);
      }
    }
  }

  async loadOperatorSettings(operatorId: number, portfolio: string) {
    try {
      const settings = await getOperatorSettings(
        operatorId,
        portfolio,
        this.props.authState,
      );
      this.setState({ settings });
    } catch (error) {
      console.error(error);
    }
  }

  async updateSettings(field: string, value: any, isNumber?: boolean) {
    const patchObj: any = {};
    patchObj[field] = isNumber ? Number.parseFloat(value) : value;
    try {
      await updateOperatorSettings(
        this.state.settings.id,
        this.props.authState,
        patchObj,
      );
    } catch (error) {
      console.error(error);
    }
  }

  // TODO - stop copying operatorId to state (maybe as conversion to functional component?)
  updateCurrentOperator(
    operatorId: OperatorPickerId,
    op?: Operator | Operator[],
  ) {
    //debugger;
    if (typeof operatorId === "number" && !Array.isArray(op)) {
      //this.setState({
      //  currentOperatorId: operatorId,
      //  currentOperatorName: op ? op.name : "",
      //});

      this.loadOperatorSettings(operatorId, this.state.portfolio);
      this.loadOperatorCustomHeadings(operatorId);
    }
  }

  updateCurrentPortfolio(portfolio: any) {
    console.log(portfolio.value);
    this.setState({
      portfolio: portfolio.value,
    });
    if (typeof this.props.selectedOperatorId === "number" && portfolio)
      this.loadOperatorSettings(this.props.selectedOperatorId, portfolio.value);
  }

  renderSettingsField(key: string, value: any, canEdit: boolean) {
    if (key === "id" || key === "operator") {
      return null;
    }

    let type = "boolean";
    if (
      [
        "occupancyMaxPct",
        "balanceCompensationMaxThreshold",
        "capacityRelativeToVantageThreshold",
        "occupancyChangeThreshold",
      ].includes(key)
    ) {
      type = "number";
    } else if (["balanceCompensationAccount"].includes(key)) {
      type = "string";
    }

    const getFieldLabel = (fieldKey: string) => {
      if (fieldKey === "enforcePrevPlusActivityEqualsCurrent") {
        return "Enforce Previous Month YTD plus Current Month Activity equals Current Month YTD";
      } else if (fieldKey === "warnPrevFileEndEqualsCurrentFileBegin") {
        return "Warn if Current Month YTD from last month's (LOADED) file doesn't match Previous Month YTD from this month's file";
      } else if (fieldKey === "balanceCompensationMaxThreshold") {
        return "Adjust for out-of-balance TBs up to (dollars)";
      } else if (fieldKey === "balanceCompensationAccount") {
        return "Book out-of-balance TB adjustments to account";
      } else if (fieldKey === "mapZeroActivityTBRecords") {
        return "Require mappings for accounts with no MTD or YTD activity";
      } else if (fieldKey === "enforceOperatorPrevCurrentBalancing") {
        return "Require Previous Month YTD and Current Month YTD columns to balance";
      } else if (fieldKey === "markNoNetChangeShoTbAsLoaded") {
        return "Mark No Net Change SHO TB As Loaded";
      } else if (fieldKey === "allowPostingToRetainedEarningsOpen") {
        return "Allow Posting to Retained Earnings Opening Account";
      } else if (fieldKey === "validateWeeklyLeasingSummaryUnitsPrevFile") {
        return "Validate Weekly Leasing Summary file units against last week's (LOADED) file";
      }
      return startCase(fieldKey);
    };

    return (
      <Row key={key} className="settings-field">
        <Col xs="12" sm="5">
          <span>{getFieldLabel(key)}</span>
        </Col>
        <Col xs="12" sm="2">
          {type === "boolean" &&
            (value ? (
              <FontAwesomeIcon size={"2x"} icon={"check"} color={"#28a745"} />
            ) : (
              "-"
            ))}
          {type !== "boolean" && value}
        </Col>
        {canEdit && (
          <Col xs="12" sm="2">
            <OperatorSettingsEditButton
              label={getFieldLabel(key)}
              type={type}
              initialValue={value}
              key={`${this.props.selectedOperatorId}-${this.state.portfolio}-${key}-${value}`}
              errors={this.state.errors}
              onSave={(newVal: any) => {
                const settings: any = this.state.settings;
                settings[key] = newVal;
                this.setState({ settings }, () => {
                  this.updateSettings(key, newVal, type === "number");
                });
              }}
            />
          </Col>
        )}
      </Row>
    );
  }

  renderCustomHeader = (header: any, canEdit: boolean) => {
    return (
      <tr key={header.id}>
        <td>{header.documentType.name}</td>
        <td>
          {this.state.headerIdentifiers.find(
            (hi) =>
              hi.value === header.headerIdentifier &&
              hi.obj?.documentTypeId === header.documentType.id,
          )?.label || header.headerIdentifier}
        </td>
        <td>{header.headerContent}</td>
        <td>
          {canEdit && (
            <OperatorCustomHeadingDeleteButton
              header={header}
              authState={this.props.authState}
              onDeleteCustomHeading={(header: any) => {
                let customHeaders = this.state.customHeadings.filter((ch) => {
                  return ch.id !== header.id;
                });
                this.setState({ customHeadings: customHeaders });
              }}
            />
          )}
        </td>
      </tr>
    );
  };

  render() {
    const canEditTbSettings = canManageOperatorTbSettings(
      this.props.authState,
      this.state.portfolio,
    );
    const canEditCensusSettings = canManageOperatorCensusSettings(
      this.props.authState,
    );
    const canEditOtherSettings = canManageOperatorOtherDocsSettings(
      this.props.authState,
    );
    const canEditHeadings = canEditCustomHeadings(this.props.authState);

    return (
      <Container className="wtop-settings">
        <Row>
          <Col sm="3">
            <ContextOperatorPicker
              requireChoice={true}
              showAllChoice={false}
              onSelectionChange={this.updateCurrentOperator}
            />
          </Col>
          <Col sm="2">
            <Select
              id={"portfolio-picker"}
              theme={selectTheme}
              options={this.state.portfolios}
              onChange={this.updateCurrentPortfolio}
              onBlur={() => {}}
              value={this.state.portfolios.find(
                (a: any) => a.value === this.state.portfolio,
              )}
            />
          </Col>
          <Col>
            <h3>{`${this.props.selectedOperatorObj?.name ?? "-"} ${this.state.portfolio} Settings`}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Turning off these rules will bypass the validation checks when
              uploading a file to the Operator Portal.
            </p>
          </Col>
        </Row>
        <div className="pt-2 pb-5">
          <h3>TB Settings</h3>
          <div className="ms-4">
            {this.state.settings.tbSettings &&
              this.state.settings.tbSettings.map((key: string) => {
                return this.renderSettingsField(
                  key,
                  this.state.settings[key],
                  canEditTbSettings,
                );
              })}
          </div>

          <h3>Census Settings</h3>
          <div className="ms-4">
            {this.state.settings.censusSettings &&
              this.state.settings.censusSettings.map((key: string) => {
                return this.renderSettingsField(
                  key,
                  this.state.settings[key],
                  canEditCensusSettings,
                );
              })}
          </div>

          <h3>Other Settings</h3>
          <div className="ms-4">
            {this.state.settings.otherSettings &&
              this.state.settings.otherSettings.map((key: string) => {
                return this.renderSettingsField(
                  key,
                  this.state.settings[key],
                  canEditOtherSettings,
                );
              })}
          </div>
        </div>
        <Row>
          <Col md={"7"}>
            <h3>Custom Headings</h3>
          </Col>
          <Col>
            {canEditHeadings && (
              <OperatorCustomHeadingFormButton
                authState={this.props.authState}
                documentTypes={this.state.documentTypes}
                headerIdentifiers={this.state.headerIdentifiers}
                onSaveCustomHeading={() => {
                  this.loadOperatorCustomHeadings(
                    this.props.selectedOperatorId,
                  );
                }}
                operatorId={this.props.selectedOperatorId}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="pt-2 pb-5">
            {this.state.customHeadings.length === 0 && (
              <p>No custom headers are defined.</p>
            )}
            {this.state.customHeadings.length > 0 && (
              <Table>
                <thead>
                  <tr>
                    <th>Document Type</th>
                    <th>Column</th>
                    <th>Custom Heading</th>
                    <th>&nbsp;</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.state.customHeadings.map((h) =>
                    this.renderCustomHeader(h, canEditHeadings),
                  )}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withOktaAuth(withOperatorContext(OperatorSettingsPage));
