/** @format */

import { fetchJsonWithToken } from "../util/FetchUtils";
import { useAsync, UseAsyncOptions } from "react-async-hook";
import { useOktaAuth } from "@okta/okta-react";
import { AuthState } from "@okta/okta-auth-js";

// Wrap react-async-hook (https://sebastienlorber.com/handling-api-request-race-conditions-in-react) with Okta auth, keeping the API of Tim's earlier useFetchJsonWithLoading
// Pass in an empty/falsy string to make this look like it's loading (e.g. if you don't have all the IDs to build a URL yet)

/** @deprecated TODO - replace with useOktaQuery() that wraps react-query */
const useFetchJson = <T = any>(
  url: string | null | undefined, // pass null for a no-op because you can't conditionally call this hook if you haven't loaded a needed ID yet
  options: { keepDataAcrossReload: boolean; overrideAuthState?: AuthState } = {
    keepDataAcrossReload: false,
    overrideAuthState: undefined, // really only used for our fake non-Okta ad-hoc tokens
  },
): {
  data?: T;
  loading: boolean;
  error: any;
  forceReload: () => Promise<T>;
} => {
  const { authState } = useOktaAuth();

  const hasAuthAndUrl = !!(authState?.accessToken && url);

  const fetchJson = async (url: string, token: any) =>
    hasAuthAndUrl
      ? fetchJsonWithToken(url, token)
      : Promise.reject(new Error("Missing either token or URL"));

  let asyncOptions: UseAsyncOptions<T> = {};
  if (options.keepDataAcrossReload) {
    asyncOptions.setLoading = (state) => ({ ...state, loading: true }); // from docs to keep data across reload
  }
  // Intentionally using url and authState as deps, because the url and authState obj could change, but this rule wants to include hasAuthAndUrl and then wants to remove url and authState.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const asyncRes = useAsync<T>(fetchJson, [url, authState], asyncOptions);

  // Don't return our .reject() above as an error (due to asyncs, we may get here after the token has come in, but still with the old asyncRes
  // TODO - should authState.accessToken be in the hook params? Or would we still have this problem?
  const ignoreError = asyncRes.error?.message === "Missing either token or URL";
  // I thought about ...asyncRes, but I'd rather leave my return keys alone, and maybe replace with react-query or something different later.
  if (ignoreError)
    return {
      error: undefined,
      data: undefined,
      loading: true,
      forceReload: () => Promise.resolve(undefined as T),
    };
  return {
    error: hasAuthAndUrl && asyncRes.error && !ignoreError, // Don't return our .reject() above as an error
    data: asyncRes.result,
    forceReload: () => asyncRes.execute(url, authState), // Looks like you have to pass in params here, contrary to docs
    loading: !hasAuthAndUrl || asyncRes.loading || ignoreError,
  };
};

export default useFetchJson;
