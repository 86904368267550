/** @format */

import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  useDocumentTypeContext,
  useOperatorContext,
} from "../../components/filters/PickerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { canEditMappings, isWelltower } from "../../util/OktaUtils";
import { Link, Redirect, useLocation } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { ContextOperatorPicker } from "../../components/filters/ContextOperatorPicker";
import { formatDateTime } from "../../util/DateUtils";
import {
  ArrayHelpers,
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import MappingsTable from "../../components/mappings-table/MappingsTable";
import { useOktaQuery } from "../../hooks/useOktaQuery";
import { ContextDocumentTypePicker } from "../../components/filters/ContextDocumentTypePicker";
import DocumentType from "../../types/DocumentType";
import ColumnMappingForm from "./ColumnMappingForm";
import { useOktaMutation } from "../../hooks/useOktaMutation";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { UnmappedColumnForm } from "../../components/submitted-file/unmapped-items/UnmappedColumnForm";
import UnmappedColumnDto from "../../types/UnmappedColumnDto";
import Modal from "react-responsive-modal";
import SimpleColumnMappingDto from "../../types/SimpleColumnMappingDto";
import { useDownloadUrl } from "../../util/OneTimeDownloader";
import FileUploadButton from "../../components/file-upload-button/FileUploadButton";
import Exception from "../../components/Exception";
/*
class SegmentMappings extends BaseMappingsPage {
  async componentDidMount() {
    super.componentDidMount();
    //this.loadMappingSets(0);
    //this.loadBusinessUnits(0);
    // TODO - load
  }

  getEditForm(mapping: any, callback?: Function) {
    if (!Object.prototype.hasOwnProperty.call(mapping, "active")) {
      // if we got a new mapping, start with active: true
      mapping.active = true;
    }
    return (
      <ColumnMappingForm
        initialValues={mapping}
        onSubmitMapping={this.onEditFormSubmit(callback)}
        onDeleteMapping={(rawData: any) => {
          if (callback) {
            callback();
          }
          this.onDeleteMapping(rawData);
        }}
      />
    );
  }

  getPageTitle() {
    return "Column Mappings";
  }

  getFilterableTableProps() {
    return {
      headers: COLUMN_MAPPING_HEADERS,
      fields: COLUMN_MAPPING_FILTERABLE_FIELDS,
      noDataMsg: "There are currently no column mappings.",
      searchInputPlaceholder: "Search Column mappings",
    };
  }

  formatIndividualMapping(rawMappingData: any): any {
    return Object.assign({}, rawMappingData, {
      startMonth: rawMappingData.startMonth.value
        ? rawMappingData.startMonth.value
        : rawMappingData.startMonth,
      endMonth:
        rawMappingData.endMonth && rawMappingData.endMonth.value
          ? rawMappingData.endMonth.value
          : rawMappingData.endMonth,
      account: rawMappingData.account.value
        ? rawMappingData.account.value
        : rawMappingData.account,
      businessUnitCode: rawMappingData.businessUnitCode.value
        ? rawMappingData.businessUnitCode.value
        : rawMappingData.businessUnitCode,
      subledgerCode: rawMappingData.subledgerCode?.value
        ? rawMappingData.subledgerCode.value
        : rawMappingData.subledgerCode,
    });
  }

  getMappingType() {
    return "column";
  }

  getMappingSetUrl(operatorId: number) {
    return "/api/segmentMappingSets";
  }

  getMappingsUrl(operatorId: number, mappingSetId: number) {
    return `/api/segmentMappingSets/${mappingSetId}/mappings`;
  }

  getMappingSetMappableAccountsUrl(
    operatorId: number,
    mappingSetId: number,
  ): string {
    return segmentMappingSetMappableAccountsUrl(operatorId, mappingSetId);
  }

  getMappingSetSegmentCodesUrl(
    operatorId: number,
    mappingSetId: number,
  ): string {
    return segmentMappingSetSegmentCodesUrl(operatorId, mappingSetId);
  }

  deleteMappingsUrl(
    operatorId: number,
    mappingSetId: number,
    mappingId: number,
  ) {
    return `/api/segmentMappingSets/${mappingSetId}/mappings/${mappingId}`;
  }

  getRequirementsUrl(operatorId: number, mappingSetId: number) {
    return `/api/segmentMappingSets/${mappingSetId}/requirements`; // TODO -yardi finally rename?
  }

  showOperatorPicker() {
    return false;
  }
}
*/

export const COLUMN_MAPPING_HEADERS = [
  "Column",
  "Operator Value",
  "Description",
  "Operator Mapping",
  "Welltower Mapping",
];

export const COLUMN_MAPPING_FILTERABLE_FIELDS = [
  "columnName",
  "operatorValue",
  "detailedDescription",
  "operatorSelectedOptionValue",
  "welltowerSelectedOptionValue",
];

const getFilterableTableProps = () => {
  return {
    headers: COLUMN_MAPPING_HEADERS,
    fields: COLUMN_MAPPING_FILTERABLE_FIELDS,
    //columnClassNames: ENTITY_MAPPING_COLUMN_CLASSNAMES,
    noDataMsg: "There are currently no column mappings for this operator.",
    searchInputPlaceholder: "Search mappings",
  };
};

// TODO - can this be the same as getFormattedItems (used in unmapped section)
const formatIndividualMapping = (item: any): any => {
  return Object.assign({}, item, {
    documentTypeId: item.documentTypeId?.value ?? item.documentTypeId,
    columnId: item.columnId?.value ?? item.columnId,
    operatorSelectedOptionId:
      item.operatorSelectedOptionId?.value ?? item.operatorSelectedOptionId,
    welltowerSelectedOptionId:
      item.welltowerSelectedOptionId?.value ?? item.welltowerSelectedOptionId,
  });
};

const ColumnMappingsPage = (props: any) => {
  const { authState } = useOktaAuth();
  const { downloadUrl } = useDownloadUrl();
  const location = useLocation<any>();
  const isWelltowerAccountant = isWelltower(authState);
  const {
    selectedOperatorId,
    selectedOperatorObj,
    //setOperator,
    //isOperatorSelected,
  } = useOperatorContext();
  const { data: mappableDocumentTypes } = useOktaQuery(
    `/api/mappedColumns/documentTypes`,
  );
  const mappableDocumentTypeIds = mappableDocumentTypes?.map(
    (dt: DocumentType) => dt.id,
  );
  const {
    selectedDocumentTypeId,
    selectedDocumentTypeObj,
    //setDocumentType,
    //favoriteDocumentTypes,
    // TODO - should we abstract this out here? like FAVORITES ? favorites : NOT_SELECTED? [] : [obj]
    //isDocumentTypeSelected,
  } = useDocumentTypeContext();

  const canEdit = canEditMappings(authState, selectedOperatorObj!);

  // This probably won't be very many, so loading all columns and their options at once should be fine.
  const { data: allColumnOptions } = useOktaQuery(
    `/api/mappedColumns/${selectedDocumentTypeId}/options`,
  );
  const { data: allMappings } = useOktaQuery(
    `/api/operators/${selectedOperatorId}/columnMappings/${selectedDocumentTypeId}`,
  );

  const queryClient = useQueryClient();
  const deleteMutation = useOktaMutation(
    ({ id }: any) => `api/operators/${selectedOperatorId}/columnMappings/${id}`,
    {
      method: "DELETE",
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries({
          queryKey: [
            `/api/operators/${variables.operatorId}/columnMappings/${variables.documentTypeId}`,
          ],
          refetchType: "active",
        });
      },
    },
  );

  const onDeleteMapping = (callback: any) => {
    return (rawData: any) => {
      deleteMutation.mutateAsync(rawData).then(() => {
        if (callback) {
          callback();
        }
      });
    };
  };

  // TODO need to add back the Add new mapping button from BaseMappingPage and friends.

  const [showCreateMappingModal, setShowCreateMappingModal] = useState(false);
  const saveUnmappedItemsMutation = useOktaMutation(
    `/api/operators/${selectedOperatorId}/unmappedItems`,
    {
      method: "POST",
      onSuccess: async (data, variables: any) => {
        // we could try to pull documentTypeid from variables.columns, but I'm pretty sure we'll still be on the same page
        await queryClient.invalidateQueries({
          queryKey: [
            `/api/operators/${selectedOperatorId}/columnMappings/${selectedDocumentTypeId}`,
          ],
          refetchType: "active",
        });
        await queryClient.invalidateQueries({
          queryKey: [
            `/api/operators/${selectedOperatorId}/unmappedColumns/${selectedDocumentTypeId}`,
          ],
          refetchType: "active",
        });
      },
    },
  );

  const getFormattedItems = (data: any) => {
    let formattedItems = data.unmappedItems.map((item: any) => {
      return Object.assign({}, item, {
        operatorSelectedOptionId:
          item.operatorSelectedOptionId?.value ?? item.operatorSelectedOptionId,
        welltowerSelectedOptionId:
          item.welltowerSelectedOptionId?.value ??
          item.welltowerSelectedOptionId,
      });
    });
    return { columns: formattedItems };
  };
  //const[showSuccessMsg, setShowSuccessMsg] = useState(false)
  const onSaveUnmappedItems = async (data: any, actions: any) => {
    let formattedItems = getFormattedItems(data);

    saveUnmappedItemsMutation.mutateAsync(formattedItems).then(() => {
      //setShowSuccessMsg(true) // mutation result handles this
      //TODO maybe add back this.scrollIntoView();
      //this.loadOperatorUnmappedItems(this.props.selectedOperatorId); // mutation onSuccess handles this
      setTimeout(() => {
        //this.setState({ showSuccessMsg: false }, () => {});
        actions.setSubmitting(false);
      }, 2500);
    });
  };

  let submissionError = saveUnmappedItemsMutation.error,
    showSuccessMsg = saveUnmappedItemsMutation.isSuccess;

  const saveMappingMutation = useOktaMutation(
    ({ documentTypeId }: any) =>
      `/api/operators/${selectedOperatorId}/columnMappings/${documentTypeId}`,
    {
      method: "POST",
      onSuccess: async (data: SimpleColumnMappingDto, variables: any) => {
        // data is the response, variables is what we passed to mutate()?
        await queryClient.invalidateQueries({
          queryKey: [
            `/api/operators/${data.operatorId}/columnMappings/${data.documentTypeId}`,
          ],
          refetchType: "active",
        });
      },
    },
  );

  const onEditFormSubmit = (callback: any) => {
    return function (rawData: any, formikActions: FormikHelpers<any>) {
      const formattedMapping = formatIndividualMapping(rawData);

      return saveMappingMutation
        .mutateAsync(formattedMapping)
        .then((result) => {
          if (callback) {
            callback();
          }
          // mutation handles refreshing data
          if (!rawData.id) {
            setShowCreateMappingModal(false);
          }
          return result;
        })
        .catch((error: any) =>
          formikActions.setErrors({ submission: error?.message || error }),
        )
        .finally(() => formikActions.setSubmitting(false));
    };
  };

  let documentTypeColumnsMap = new Map<number, any>(); // By column ID, the columns themselves, not the options

  let columnOptions = new Map<number, any>();
  allColumnOptions?.forEach((co: any) => {
    if (!columnOptions.has(co.columnId)) {
      columnOptions.set(co.columnId, []);
    }
    columnOptions.get(co.columnId).push({
      value: co.id,
      label: co.optionDescription
        ? `${co.optionValue} - ${co.optionDescription}`
        : co.optionValue,
      object: co,
    });
    if (!documentTypeColumnsMap.has(co.columnId)) {
      documentTypeColumnsMap.set(co.columnId, {
        value: co.columnId,
        label: co.columnName,

        //object: co,
      });
    }
  });
  let documentTypeColumns = Array.from(documentTypeColumnsMap.values()); // because Map.values() returns an iterator, which isn't widely-supported

  // TODO - should we add a third picker for mapped column? Or do we think we can dump them all in the same table? (needs API)
  // (Rose/Kayla can probably handle, but we should do something nicer if we expect to expose a big mapping screen to the operator)

  // const operatorMappingsUpdateUrl = (operatorId: number) =>
  //   `/api/operators/${operatorId}/unmappedItems`;

  const { data: unmappedItems } = useOktaQuery(
    `/api/operators/${selectedOperatorId}/unmappedColumns/${selectedDocumentTypeId}`,
  );

  const [uploadError, setUploadError] = useState();
  const [showUploadErrorModal, setShowUploadErrorModal] = useState(false);
  // BEGIN unmapped items collection TODO extract to a separate component?

  const [collapsedFiles, setCollapsedFiles] = useState(new Map());
  let items = _.orderBy(unmappedItems, ["submittedFileSorter"], ["desc"]);

  let unmappedItemsGroupedByFile = _.groupBy(items, "submittedFileDescription");
  let fileDescriptions = new Map();
  Object.keys(unmappedItemsGroupedByFile).forEach((d) => {
    let parts = d.split("|||");

    // TODO - this is different by type? TODO make this consistent upstream

    fileDescriptions.set(d, {
      filename: parts[0],
      uploadUser: parts[1],
      uploadTime: parts[2], // not formatted
      requirementName: parts[3],
      date: parts[4], // already formatted
      //mappingSetId: parts[5],
      //mappingSetDestination: parts[6],
      //mappingSetName: parts[7],
    });
  });

  // TODO - if we need another one of these Map<String,bool> with configurable default, extract to helper/hook

  const initialCollapsed = fileDescriptions.size > 1;
  const isCollapsed = (fileDescription: string) => {
    if (!collapsedFiles.has(fileDescription)) return initialCollapsed;
    return collapsedFiles.get(fileDescription);
  };
  const toggleCollapse = (fileDescription: string) => {
    setCollapsedFiles((prevMap: Map<any, any>) => {
      const collapsedMap = new Map(prevMap); // I think we need to create a new map here for useState to notice the change (but we didn't do this in the old class-state version?)

      // mutate the same object but set it immediately
      const prevCollapsed = collapsedMap.has(fileDescription)
        ? collapsedMap.get(fileDescription)
        : initialCollapsed;

      collapsedMap.set(fileDescription, !prevCollapsed);
      return collapsedMap;
    });
  };

  const getEditForm = (mapping: any, callback?: Function) => (
    <ColumnMappingForm
      initialValues={mapping}
      documentTypeColumns={documentTypeColumns}
      columnOptions={columnOptions.get(mapping.columnId)}
      getColumnOptions={(id: number) => columnOptions.get(id)}
      onSubmitMapping={onEditFormSubmit(callback)}
      onDeleteMapping={onDeleteMapping(callback)}
    />
  );
  // END unmapped items collection

  const pageTitle = "Column Mappings";

  if (!authState?.isAuthenticated)
    return (
      <FontAwesomeIcon
        icon={"spinner"}
        spin
        size={"3x"}
        color={"#888"}
        className={"mt-5"}
      />
    );
  if (!isWelltower(authState)) {
    return <Redirect to="/" />;
  }

  return (
    <Container className="wtop-accountant-mappings">
      <Row className="header-row">
        <Col sm="4"></Col>
        <Col sm="4">
          <h4>{pageTitle}</h4>
        </Col>
      </Row>
      <Row className="header-row">
        <Col sm="4">
          <ContextOperatorPicker requireChoice />
        </Col>
        <Col sm="4">
          <ContextDocumentTypePicker
            requireChoice
            visibilityFilter={(dt) =>
              !mappableDocumentTypeIds ||
              mappableDocumentTypeIds.includes(dt.id)
            }
          />
        </Col>
        <Col sm={"2"}>
          {
            /*this.state.currentMappingSet &&*/ <UncontrolledButtonDropdown
              className={"mapping-set-action-dropdown"}
            >
              <DropdownToggle caret>Actions</DropdownToggle>
              <DropdownMenu>
                {/*canEdit && this.state.currentMappingSet && (
                  <DropdownItem
                    onClick={() => {
                      this.setState({
                        showRenameMappingSetModal: true,
                      });
                    }}
                    data-testid="rename-mapping-set-menu-item"
                  >
                    Rename
                  </DropdownItem>
                )*/}

                <DropdownItem
                  onClick={() =>
                    downloadUrl(
                      `/api/operators/${selectedOperatorId}/columnMappings/${selectedDocumentTypeId}/downloadToken`,
                    )
                  }
                  data-testid="download-column-mappings-menu-item"
                >
                  Download
                </DropdownItem>

                {/*canEdit && this.state.currentMappingSet && (
                  <DropdownItem
                    onClick={() => {
                      this.setState({ showConfirmDeleteModal: true });
                    }}
                    data-testid={"delete-mapping-set-menu-item"}
                  >
                    Delete
                  </DropdownItem>
                )*/}
                <DropdownItem
                  onClick={() => {
                    setShowCreateMappingModal(true);
                  }}
                  data-testid={"create-new-mapping-menu-item"}
                >
                  Create New Mapping
                </DropdownItem>
                {
                  /*this.getMappingType() === "column" &&*/ <DropdownItem
                    onClick={() =>
                      downloadUrl(
                        `/api/mappedColumns/${selectedDocumentTypeId}/options/downloadToken`,
                      )
                    }
                    data-testid={"download-available-columnn-options-menu-item"}
                  >
                    Download List of Available Column Options
                  </DropdownItem>
                }
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          }
        </Col>
        {canEdit && (
          <Col sm={"2"}>
            <FileUploadButton
              onFileUploadSuccess={async (data: any) => {
                await queryClient.invalidateQueries({
                  queryKey: [
                    `/api/operators/${selectedOperatorId}/columnMappings/${selectedDocumentTypeId}`,
                  ],
                  refetchType: "active",
                });
                await queryClient.invalidateQueries({
                  queryKey: [
                    `/api/operators/${selectedOperatorId}/unmappedColumns/${selectedDocumentTypeId}`,
                  ],
                  refetchType: "active",
                });
              }}
              btnLabel="Upload"
              uploadUrl={`/api/operators/${selectedOperatorId}/columnMappings/${selectedDocumentTypeId}/upload`}
              onFileUploadError={(err: any) => {
                setShowUploadErrorModal(true);
                setUploadError(err?.response?.data);
              }}
            />
          </Col>
        )}
      </Row>
      {canEdit && (
        <Row>
          <Col>
            <p>
              Uploading a file will replace <em>all</em> column mappings for
              this operator and document type. Download the existing mappings
              first, then make your changes, and then upload the file.
            </p>
          </Col>
        </Row>
      )}
      {/* TODO fill in more content here */}

      <Modal
        open={showUploadErrorModal}
        onClose={() => {
          setShowUploadErrorModal(false);
        }}
        showCloseIcon={true}
        closeOnOverlayClick={true}
        styles={{ modal: { width: "600px" } }}
      >
        <Row>
          <Col>
            <Row>
              <Col>
                <FontAwesomeIcon
                  icon={"circle-exclamation"}
                  size={"3x"}
                  color={"#dc3545"}
                />
                <h4 className={"text-center"} style={{ marginTop: "-2rem" }}>
                  {"Error"}
                </h4>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>An error occurred uploading the file.</p>
                <Exception e={uploadError} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      {/* BEGIN unmapped items forms - TODO extract this into a component that gets fed a form generator? */}
      {/* TODO - move this error/success handling down to the Formik per-file form */}
      {submissionError && (
        <Alert color="danger" fade={true}>
          {submissionError}
        </Alert>
      )}
      {showSuccessMsg && (
        <Alert color="success" fade={true}>
          {`Mapping saved.`}
        </Alert>
      )}

      {location.state && location.state.backLink && (
        <p>
          Back to{" "}
          <Link to={location.state.backLink}>
            {location.state.backLinkName}
          </Link>
        </p>
      )}

      {items.length > 0 && <h3>Unmapped Columns:</h3>}

      {Object.keys(unmappedItemsGroupedByFile).map((fileDescription) => {
        const descriptionObj = fileDescriptions.get(fileDescription);
        return (
          <Card key={`${fileDescription}`}>
            <CardHeader>
              <div className={"d-flex align-items-center"}>
                <div
                  onClick={() => toggleCollapse(fileDescription)}
                  className={"mr-3"}
                >
                  <FontAwesomeIcon
                    size={"2x"}
                    color={"#007bff"}
                    icon={
                      isCollapsed(fileDescription)
                        ? "chevron-right"
                        : "chevron-down"
                    }
                    className={"mr-2"}
                    title={isCollapsed(fileDescription) ? "Show" : "Hide"}
                  />
                </div>
                <div>
                  <div className={""}>
                    <h5 className={"d-inline font-weight-bold mr-3"}>
                      {descriptionObj.requirementName}
                    </h5>
                    <span className={"text-muted"}>{descriptionObj.date}</span>
                  </div>
                  <div>
                    <span className={"font-weight-light align-baseline"}>
                      {descriptionObj.filename}{" "}
                      <small>{`${descriptionObj.uploadUser} on ${formatDateTime(
                        descriptionObj.uploadTime,
                      )}`}</small>
                    </span>
                  </div>
                  <div>
                    <span>
                      {unmappedItemsGroupedByFile[fileDescription].length}{" "}
                      unmapped column values
                    </span>
                  </div>
                </div>
              </div>
            </CardHeader>
            <Collapse isOpen={!isCollapsed(fileDescription)}>
              <CardBody className={"zzpx-0 zzpy-3"}>
                {/* TODO - refactor this whole form out so it can track its own validation/success/failure separately from any other submittedFile forms */}
                <Formik
                  initialValues={{
                    unmappedItems: unmappedItemsGroupedByFile[fileDescription],
                  }}
                  enableReinitialize={true}
                  onSubmit={canEdit ? onSaveUnmappedItems : () => {}}
                >
                  {(props: FormikProps<any>) => {
                    return (
                      <Form>
                        <FieldArray
                          name={"unmappedItems"}
                          render={(arrayHelpers: ArrayHelpers) =>
                            props.values.unmappedItems.map(
                              (
                                unmappedItem: UnmappedColumnDto,
                                index: number,
                              ) => {
                                return (
                                  <UnmappedColumnForm
                                    unmappedItem={unmappedItem}
                                    index={index}
                                    formikProps={props}
                                    key={`${unmappedItem.operatorValue}-${index}`}
                                    columnOptions={columnOptions.get(
                                      unmappedItem.columnId,
                                    )}
                                    isWelltowerAccountant={
                                      isWelltowerAccountant
                                    }
                                  />
                                );
                              },
                            )
                          }
                        />
                        <div className={"text-right"}>
                          <Button
                            type={"submit"}
                            color={"primary"}
                            disabled={!canEdit || props.isSubmitting}
                            className={"wtop-btn"}
                          >
                            Save Updates
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Collapse>
          </Card>
        );
      })}
      {/* END unmapped items forms */}

      {/* TODO mappingSet picker went here in other pages*/}

      <MappingsTable
        data={allMappings}
        getEditForm={getEditForm}
        mappingsType={"column"}
        canEdit={canEdit}
        ignoreStartMonth={true}
        {...getFilterableTableProps()}
        //updateIndividualMapping={this.updateIndividualMapping}
      />

      <Modal
        data-testid={"create-mapping"}
        open={showCreateMappingModal}
        onClose={() => {
          setShowCreateMappingModal(false);
        }}
        showCloseIcon={true}
        closeOnOverlayClick={false}
        styles={{ modal: { width: "600px" } }}
      >
        <h2>Create New Mapping</h2>
        {getEditForm({
          documentTypeId: selectedDocumentTypeId,
          documentTypeName: selectedDocumentTypeObj?.name,
        })}
      </Modal>
    </Container>
  );
};
export default ColumnMappingsPage;
