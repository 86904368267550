/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const LoadingSpinner = () => (
  <FontAwesomeIcon icon={["fas", "spinner"]} pulse />
);
// TODO - rename small one to Inline?
// TODO - set common colors
export const LoadingSpinnerBig = () => (
  <FontAwesomeIcon
    icon={["fas", "spinner"]}
    pulse
    size={"3x"}
    color={"#888"}
    className={"mt-5"}
  />
);
