/** @format */

import WithModal from "../WithModal";
import React from "react";
import { Button } from "reactstrap";
import OperatorUserForm from "./OperatorUserForm";

const OperatorUserFormButton = WithModal(
  ({
    saveAction,
    user,
    ...props
  }: {
    user: any;
    saveAction: Function;
  } & Partial<any>) => (
    <div>
      <Button color={"primary"} className="wtop-btn" onClick={props.showModal}>
        {user ? "Edit" : "Add User"}
      </Button>
      {props.modalContent(
        <OperatorUserForm saveAction={saveAction} user={user} />,
        {
          showCloseIcon: true,
          modalStyles: { width: "600px" },
          closeOnOverlayClick: true,
        },
      )}
    </div>
  ),
);

export default OperatorUserFormButton;
