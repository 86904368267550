/** @format */
import NotificationPreference from "../types/NotificationPreference";
import {
  deleteWithToken,
  fetchPageableAllWithToken,
  postWithToken,
} from "../util/FetchUtils";
import { AbstractAuthState } from "../util/OktaUtils";

export const notificationPreferencesUrl = "/api/notificationPreferences";

export const getNotificationPreferences = async (
  auth: AbstractAuthState,
): Promise<Array<NotificationPreference>> => {
  return fetchPageableAllWithToken(`${notificationPreferencesUrl}/my`, auth);
};

export const updateNotificationPreferences = async (
  auth: AbstractAuthState,
  preference: any,
) => {
  return postWithToken(notificationPreferencesUrl, auth, preference);
};

export const deleteNotificationPreference = async (
  auth: AbstractAuthState,
  preferenceId: number,
) => {
  return deleteWithToken(`${notificationPreferencesUrl}/${preferenceId}`, auth);
};
