/** @format */

import React, { PropsWithChildren } from "react";
import { Formik, FormikProps, Form } from "formik";
import { Button } from "reactstrap";
import OktaUser from "../../types/OktaUser";
import WrappedTextInput from "../form/WrappedTextInput";

export default class OperatorUserForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { error: undefined };
  }
  render() {
    let { user }: { user: OktaUser } & PropsWithChildren<any> = this.props;
    let initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      login: "",
      groupManager: false,
      attestor: false,
    };
    if (user && user.profile) {
      initialValues = Object.assign(initialValues, user.profile);
    }
    //if (user && user.groupNames) {
    //  initialValues = Object.assign(initialValues, {
    //    groupManager: false, //user.groupNames.includes("GroupManagers"),
    //    attestor: false, //user.groupNames.includes("Attestors"),
    //  });
    //}
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          this.props
            .saveAction(values)
            .then(() => actions.setSubmitting(false))
            .catch((err: any) => {
              this.setState({ error: err.message });
              actions.setSubmitting(false);
            });
        }}
      >
        {(props: FormikProps<any>) => {
          return (
            <div className={"editForm"}>
              <h2 className="propertyName">Add User</h2>

              <Form className="editFormForm">
                {!!this.state.error && (
                  <div className="input-feedback">{this.state.error}</div>
                )}
                <div className="form-group">
                  <WrappedTextInput
                    id="firstName"
                    required={true}
                    label="First Name"
                  />
                  <WrappedTextInput
                    id="lastName"
                    required={true}
                    label="Last Name"
                  />
                </div>
                <div className="form-group">
                  <WrappedTextInput
                    id="login"
                    required={true}
                    label="Login (for testing, you can make this one up)"
                  />
                  <WrappedTextInput
                    id="email"
                    required={true}
                    label="Email (for testing, use your email here)"
                  />
                </div>
                {/*<div className="form-group">
                  {WrappedCheckboxInput(
                    {
                      id: "groupManager",
                      required: false,
                      label: "Can manage operator users",
                    },
                    props
                  )}
                  {WrappedCheckboxInput(
                    {
                      id: "attestor",
                      required: false,
                      label: "Can attest financial results"
                    },
                    props
                  )}
                </div>*/}

                <Button
                  type={"submit"}
                  color={"primary"}
                  disabled={props.isSubmitting}
                  className={"float-right wtop-btn"}
                  onClick={() => {
                    props.handleSubmit();
                  }}
                >
                  Save
                </Button>
              </Form>
            </div>
          );
        }}
      </Formik>
    );
  }
}
