/** @format */
import React, { useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useOktaAuth } from "@okta/okta-react";
import OperatorUsers from "../../components/operator-users/OperatorUsers";
import { isWelltower } from "../../util/OktaUtils";
import AllOperatorUsers from "../../components/operator-users/AllOperatorUsers";
import { downloadUrl } from "../../util/OneTimeDownloader";
import { StandaloneOperatorPicker } from "../../components/filters/ContextOperatorPicker";

const UsersPage = () => {
  const { authState } = useOktaAuth();
  const welltower = isWelltower(authState);
  //const { selectedId: selectedOperatorId, selectedObj: selectedOperatorObj } =
  //  useContext(OperatorContext);
  const [selectedOperatorId, setSelectedOperatorId] = useState();
  const [selectedOperatorObj, setSelectedOperatorObj] = useState();
  const pickOperator = (id: any, obj: any) => {
    setSelectedOperatorId(id);
    setSelectedOperatorObj(obj);
  };
  return (
    <Container>
      <Row style={{ padding: "0.75rem" }}>
        <Col sm={"4"}>
          <StandaloneOperatorPicker
            showAllChoice={welltower}
            default={welltower ? "NOT_SELECTED" : undefined}
            onSelectionChange={pickOperator}
          />
        </Col>
        <Col sm />
        <Col sm={"auto"} className={"text-end"}>
          <Button
            size={"md"}
            color={"link"}
            onClick={() =>
              downloadUrl(
                authState,
                welltower && selectedOperatorId === "ALL"
                  ? "/api/users/downloadToken"
                  : `/api/operators/${selectedOperatorId}/downloadToken`,
              )
            }
          >
            Export Users
          </Button>
        </Col>
      </Row>
      {!selectedOperatorId || selectedOperatorId === "NOT_SELECTED" ? (
        <p>Select an operator</p>
      ) : null}
      {selectedOperatorId &&
        selectedOperatorId !== "NOT_SELECTED" &&
        selectedOperatorId !== "ALL" &&
        selectedOperatorObj && <OperatorUsers operator={selectedOperatorObj} />}
      {welltower && selectedOperatorId === "ALL" && <AllOperatorUsers />}
    </Container>
  );
};

export default UsersPage;
