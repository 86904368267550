/** @format */

import { ErrorMessage, Field, FieldInputProps } from "formik";
import React from "react";
import { FieldAttributes } from "formik/dist/Field";

interface WrappedNumericInputProps {
  id: string;
  label?: string;
  //value?: any;
  placeholder?: string | number;
  required?: boolean;
  //disabled?: boolean;
  //className?: string;
  fieldProps?: FieldAttributes<any>;
}

const WrappedNumericInput = (props: WrappedNumericInputProps) => {
  const { id, label, placeholder, required, fieldProps } = props;
  return (
    <div className="editFormInput" key={id}>
      <label htmlFor={id} className={required ? "label-required" : ""}>
        {label}
      </label>
      <Field type="number" name={id} placeholder={placeholder}>
        {({ field, form }: { field: FieldInputProps<any>; form: any }) => (
          <input
            type="text"
            id={id}
            {...field}
            {...fieldProps}
            className={
              form.errors[id] && form.touched[id]
                ? "form-control text-input error"
                : "form-control text-input"
            }
          />
        )}
      </Field>
      <ErrorMessage name={id} component={"div"} className={"input-feedback"} />
    </div>
  );
};

export default WrappedNumericInput;
