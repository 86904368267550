/** @format */

import WithModal from "../WithModal";
import React from "react";
import { deleteOperatorCustomHeading } from "../../api/OperatorAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "reactstrap";

/** @format */

const OperatorCustomHeaderDeleteButton = WithModal(
  ({
    header,
    authState,
    onDeleteCustomHeading,
    ...props
  }: {
    header: any;
    authState: any;
    onDeleteCustomHeading: Function;
  } & Partial<any>) => (
    <div>
      <span onClick={props.showModal}>
        <FontAwesomeIcon
          icon={["far", "trash-alt"]}
          color={"#dc3545"}
          style={{
            cursor: "pointer",
          }}
        />
      </span>
      {props.modalContent(
        <Row>
          <Col>
            <Row>
              <Col>
                <h4 className={"text-center"}>{"Are you sure?"}</h4>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>{`Are you sure you want to delete the custom heading: ${header.headerContent}`}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  color={"danger"}
                  className={"float-right wtop-btn"}
                  onClick={async () => {
                    console.log("delete custom header");
                    try {
                      await deleteOperatorCustomHeading(
                        header.operator.id,
                        header.id,
                        authState,
                      );
                      onDeleteCustomHeading(header);
                      props.closeModal();
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  Confirm
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>,
        {
          showCloseIcon: true,
          modalStyles: { width: "600px" },
          closeOnOverlayClick: false,
        },
      )}
    </div>
  ),
);

export default OperatorCustomHeaderDeleteButton;
