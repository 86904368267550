/** @format */

export const helperObjectToSelectOption = (s: any) => {
  if (typeof s === "boolean") {
    return { value: s, label: s ? "Yes" : "No" };
  }
  if (typeof s === "string") {
    return { value: s, label: s };
  }
  if (s.displayName) {
    let label = s.displayName;
    if (s.source?.displayName) {
      label += ` (${s.source.displayName})`;
    } else if (s.destination?.displayName) {
      label += ` (${s.destination.displayName})`;
    }
    return {
      value: s.id || s.name,
      label,
      obj: s,
      isDisabled: s.isDisabled,
    };
  } else {
    let label = s.name;
    if (s.source?.displayName) {
      label += ` (${s.source.displayName})`;
    } else if (s.destination?.displayName) {
      label += ` (${s.destination.displayName})`;
    }
    return { value: s.id, label, obj: s, isDisabled: s.isDisabled };
  }
};

export const realValueToSelectOption = (v: any, all: any[]) =>
  all
    .map(helperObjectToSelectOption)
    .find((t) => t.value === v || (v && v.value && t.value === v.value));
