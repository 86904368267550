/** @format */

import { withOktaAuth } from "@okta/okta-react";

import {
  unmappedEntitiesByOperatorUrl,
  operatorMappingsUpdateUrl,
  entityMappingSetsUrl,
  entityMappingsUrl,
  entityMappingSetRequirementsUrl,
  deleteEntityMappingsUrl,
} from "../../util/ApiUrlUtils";

import {
  ENTITY_MAPPING_COLUMN_CLASSNAMES,
  ENTITY_MAPPING_FILTERABLE_FIELDS,
  ENTITY_MAPPING_HEADERS,
} from "../../util/constants";

import BaseMappingsPage from "./BaseMappingsPage";
import PropertyMappingForm from "./PropertyMappingForm";
import React from "react";
import { withOperatorContext } from "../../components/filters/PickerContext";
import withRouterCompat from "../../util/router/WithRouterCompat";

class EntityMappings extends BaseMappingsPage {
  async componentDidMount() {
    super.componentDidMount();
    if (this.props.selectedOperatorId)
      this.loadBusinessUnits(this.props.selectedOperatorId);
  }
  async componentDidUpdate(prevProps: any, prevState: any) {
    super.componentDidUpdate(prevProps, prevState);
    if (prevProps.selectedOperatorId !== this.props.selectedOperatorId) {
      this.loadBusinessUnits(this.props.selectedOperatorId);
    }
  }

  getEditForm(mapping: any, callback?: Function) {
    return (
      <PropertyMappingForm
        initialValues={mapping}
        welltowerBusinessUnits={this.state.buCodes}
        currentStartDate={mapping ? mapping.startMonth : new Date()}
        onSubmitMapping={this.onEditFormSubmit(callback)}
        onDeleteMapping={(rawData: any) => {
          if (callback) {
            callback();
          }
          this.onDeleteMapping(rawData);
        }}
      />
    );
  }

  getFormattedItems(data: any) {
    let formattedItems = data.unmappedItems.map((item: any) => {
      return Object.assign({}, item, {
        operatorSelectedBUCode: item.operatorSelectedBUCode
          ? item.operatorSelectedBUCode.value
          : null,
        welltowerSelectedBUCode: item.welltowerSelectedBUCode
          ? item.welltowerSelectedBUCode.value
          : null,
        destination: item.destination ? item.destination.name : null,
      });
    });
    return { entities: formattedItems };
  }

  formatIndividualMapping(rawMappingData: any): any {
    return Object.assign({}, rawMappingData, {
      startMonth: rawMappingData.startMonth.value
        ? rawMappingData.startMonth.value
        : rawMappingData.startMonth,
      welltowerBusinessUnitCode: rawMappingData.welltowerBusinessUnitCode.value
        ? rawMappingData.welltowerBusinessUnitCode.value
        : rawMappingData.welltowerBusinessUnitCode,
    });
  }

  getSaveMappingsUrl(operatorId: number) {
    return operatorMappingsUpdateUrl(operatorId);
  }

  getUnmappedItemsUrl(operatorId: number) {
    return unmappedEntitiesByOperatorUrl(operatorId);
  }

  getPageTitle() {
    return "Property Mappings";
  }

  getFilterableTableProps() {
    return {
      headers: ENTITY_MAPPING_HEADERS,
      fields: ENTITY_MAPPING_FILTERABLE_FIELDS,
      columnClassNames: ENTITY_MAPPING_COLUMN_CLASSNAMES,
      noDataMsg: "There are currently no property mappings for this operator.",
      searchInputPlaceholder: "Search Properties",
    };
  }

  getMappingType() {
    return "property";
  }
  getMappingTypeDisplayName() {
    return "property";
  }
  getMappingTypeDisplayNamePlural() {
    return "properties";
  }

  getMappingSetUrl(operatorId: number) {
    return entityMappingSetsUrl(operatorId);
  }

  getMappingsUrl(operatorId: number, mappingSetId: number) {
    return entityMappingsUrl(operatorId, mappingSetId);
  }

  deleteMappingsUrl(
    operatorId: number,
    mappingSetId: number,
    mappingId: number,
  ) {
    return deleteEntityMappingsUrl(operatorId, mappingSetId, mappingId);
  }

  getRequirementsUrl(operatorId: number, mappingSetId: number) {
    return entityMappingSetRequirementsUrl(operatorId, mappingSetId);
  }
}

export default withRouterCompat(
  withOktaAuth(withOperatorContext(EntityMappings)),
);
