/** @format */

import React from "react";
import OperatorRddCard from "../../components/operator-rdd-card/OperatorRddCard";
import RddPeriod from "../rdd-card/RddPeriod";
import CloudticityRddCard from "../cloudticity-rdd-card/CloudticityRddCard";

export default class OperatorRddPeriod extends RddPeriod {
  renderRddCard(rdd: any) {
    const { receiveUpdatedRequirementDueDate } = this.props;
    if (rdd.requirement.documentType.uploadMethod.name === "CLOUDTICITY") {
      return (
        <CloudticityRddCard
          authState={this.props.authState}
          key={rdd.id}
          requirementDueDate={rdd}
          receiveUpdatedRequirementDueDate={receiveUpdatedRequirementDueDate}
        />
      );
    } else {
      return (
        <OperatorRddCard
          authState={this.props.authState}
          key={rdd.id}
          requirementDueDate={rdd}
          receiveUpdatedRequirementDueDate={receiveUpdatedRequirementDueDate}
        />
      );
    }
  }
}
