/** @format */

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeveloperModeOnly } from "./DeveloperMode";
import { useOktaQuery } from "../hooks/useOktaQuery";
import useAdHocTokenAuth from "../hooks/useAdHocTokenAuth";

const DbWakingUp = (
  <small className={"text-warning"}>
    <FontAwesomeIcon
      icon={["fad", "server"]}
      style={{ marginLeft: "0.75em", marginRight: "0.25em" }}
    />
    <FontAwesomeIcon
      icon={["fad", "snooze"]}
      style={{ marginLeft: "0.25em", marginRight: "0.25em" }}
    />
    Database waking up...
  </small>
);

const RefreshMessage = (
  <span
    className={"text-success cursor-pointer"}
    onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
      document.location.reload();
    }}
  >
    <FontAwesomeIcon
      icon={["fad", "server"]}
      style={{ marginLeft: "0.75em", marginRight: "0.25em" }}
    />
    <FontAwesomeIcon
      icon={["fad", "thumbs-up"]}
      style={{ marginLeft: "0.25em", marginRight: "0.25em" }}
    />
    <small>Click to reconnect to the server.</small>
  </span>
);

const GoodHealth = (health: any) => (
  <DeveloperModeOnly>
    <span className={"text-success"}>
      <FontAwesomeIcon
        icon={["fad", "server"]}
        style={{ marginLeft: "0.75em", marginRight: "0.25em" }}
      />
      <FontAwesomeIcon
        icon={["fad", "thumbs-up"]}
        style={{ marginLeft: "0.25em", marginRight: "0.75em" }}
      />
      <small>{health.poolConnections} db connections</small>
    </span>
  </DeveloperModeOnly>
);
const BadHealth = (
  <span className={"text-danger"}>
    <FontAwesomeIcon
      icon={["fad", "server"]}
      style={{ marginLeft: "0.75em", marginRight: "0.25em" }}
    />
    <FontAwesomeIcon
      icon={["fad", "thumbs-down"]}
      style={{ marginLeft: "0.25em", marginRight: "0.75em" }}
    />
    <small>Backend server unavailable...</small>
  </span>
);

const ServerHealthIndicator = ({ delayMs = 10000 }: { delayMs?: number }) => {
  let fakeAuthState = useAdHocTokenAuth();

  const {
    data: health,
    isLoading: healthLoading,
    isError: serverError,

    dataUpdatedAt,
    errorUpdatedAt,
  } = useOktaQuery<any>("/api/health", {
    authState: fakeAuthState,
    refetchInterval: delayMs, // Refetch every x ms
    retry: false, // Don't let react-query retry on failures; we want to see those reflected here right away
  });
  const updatedAt = Math.max(dataUpdatedAt, errorUpdatedAt);

  const serverHealthBad =
    serverError || (!healthLoading && (!health || !health.poolConnections));
  const [prevError, setPrevError] = useState(false);
  const [prevHealthBad, setPrevHealthBad] = useState(false);
  const [showRefreshMessage, setShowRefreshMessage] = useState(false);
  // useInterval(() => {
  //   setPrevError(serverError);
  //   setPrevHealthBad(serverHealthBad);
  //   refetch();
  // }, delayMs);
  // Whenever the updatedAt changes, store the previous statuses
  useEffect(() => {
    setPrevError(serverError);
    setPrevHealthBad(serverHealthBad);
  }, [serverError, serverHealthBad, updatedAt]);

  // If currently loading, use the previous value to avoid blinking.
  const checkError = healthLoading ? prevError : serverError;
  const checkPoolBad = healthLoading ? prevHealthBad : serverHealthBad;
  const allGoodNow = !checkError && !checkPoolBad;
  if (!showRefreshMessage && (prevHealthBad || prevError) && allGoodNow) {
    // If we just transitioned from bad to good, turn on the refresh message
    //document.location.reload();
    setShowRefreshMessage(true);
  }

  return (
    <>
      {checkError
        ? BadHealth
        : checkPoolBad
          ? DbWakingUp
          : health && GoodHealth(health)}
      {showRefreshMessage && RefreshMessage}
    </>
  );
};

export default ServerHealthIndicator;
