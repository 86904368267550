/** @format */

import React from "react";
import { Button, Col, Row } from "reactstrap";
import WithModal from "../WithModal";
import ConfirmationDialog from "../operator-users/ConfirmationDialog";
import { AbstractAuthState } from "../../util/OktaUtils";
import SubmittedFile from "../../types/SubmittedFile";

export default WithModal(
  ({
    file,
    authState,
    buttonType = "button",
    sendToRPA,
    ...props
  }: {
    file: SubmittedFile;
    authState: AbstractAuthState;
    buttonType: string;
    sendToRPA: Function;
  } & Partial<any>) => {
    // TODO - allow toggling RPA on/off?

    let dialogTitle = "Send this file to ETL?";
    let dialogText = "Send this file to ETL?";
    let dialogText2 = <></>;
    let buttonText = "Send to ETL";
    let confirmLabel = "Send to ETL";
    let cancelLabel = "Don't send";

    if (file?.destination?.name === "YARDI") {
      dialogTitle = "Send this file to Yardi ETL?";
      dialogText = "Send this file to Yardi ETL?";
      dialogText2 = (
        <p className={"text-danger text-uppercase font-weight-bolder"}>
          This action cannot be canceled.
        </p>
      );
      confirmLabel = "Send to Yardi";
      buttonText = "Send to Yardi ETL";
    } else if (file?.destination?.name === "SNOWFLAKE_PULL") {
      dialogTitle = "Send this file to Snowflake?";
      dialogText = "Send this file to Snowflake?";
      dialogText2 = (
        <p className={"text-warning text-uppercase font-weight-bolder"}>
          This action may not be cancellable.
        </p>
      );
      confirmLabel = "Send to Snowflake";
      buttonText = "Send to Snowflake";
    }

    const dialogWithButton = (button: any) => {
      return (
        <ConfirmationDialog
          title={dialogTitle}
          confirmLabel={confirmLabel}
          cancelLabel={cancelLabel}
          buttonColor={"success"}
          message={
            <>
              <p>{dialogText}</p>
              <p>
                <i>{file ? file.originalFilename : ""}</i>
              </p>
              {dialogText2}
            </>
          }
          action={() => {
            sendToRPA(file);
          }}
        >
          {button}
        </ConfirmationDialog>
      );
    };

    if (buttonType === "button") {
      const button = ({ onClick }: { onClick: any }) => (
        <Button
          block
          className={"py-3 send-to-rpa-btn text-uppercase"}
          color={"primary"}
          onClick={onClick}
          data-testid={"send-to-rpa-button"}
        >
          {buttonText}
        </Button>
      );

      return (
        <Row className={"mb-3"}>
          <Col>
            <Row>
              <Col sm="4" className={"text-center offset-sm-4"}>
                {dialogWithButton(button)}
              </Col>
            </Row>
          </Col>
        </Row>
      );
    } else {
      const button = ({ onClick }: { onClick: any }) => {
        return (
          <div className={"d-inline"}>
            {buttonType === "link" && (
              <Button
                color={"white"}
                onClick={onClick}
                className={"ml-2 send-to-rpa-link"}
                data-testid={"send-to-rpa-button"}
              >
                <small className={"ml-1"}>{buttonText}</small>
              </Button>
            )}
          </div>
        );
      };
      return dialogWithButton(button);
    }
  },
);
