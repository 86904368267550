/** @format */

import React, { useContext } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Select from "react-select";
import { ContextDocumentTypePicker } from "../components/filters/ContextDocumentTypePicker";
import { LoadingSpinnerBig } from "../components/LoadingSpinners";
import { useSearchParamsStateSticky } from "../hooks/useSearchParamsStateSticky";
import { usePeriodPicker } from "../components/filters/PeriodPicker";
import DashboardPeriodDto from "../types/DashboardPeriodDto";
import { useOktaQuery } from "../hooks/useOktaQuery";
import OperatorRddPeriod from "../components/operator-rdd-card/OperatorRddPeriod";
import { useOktaAuth } from "@okta/okta-react";
import dayjs from "dayjs";
import RequirementDueDate from "../types/RequirementDueDate";
import { DocumentTypeContext } from "../components/filters/PickerContext";

export const openOverdueOptions = [
  { value: "all", label: "All Requirements" },
  { value: "open", label: "Open Requirements" },
  { value: "open-overdue", label: "Overdue Requirements" },
];

export const filterRequirementDueDatesByDocumentType = (
  rdds: DashboardPeriodDto[],
  isDocumentTypeSelected: any,
) => {
  let filteredReqs: Array<any> = [];

  rdds.forEach((rdd) => {
    const rddCopy = { ...rdd };
    rddCopy.dashboardRequirementDueDates =
      rdd.dashboardRequirementDueDates.filter((item: RequirementDueDate) =>
        isDocumentTypeSelected(item.requirement.documentType),
      );
    if (rddCopy.dashboardRequirementDueDates.length > 0) {
      filteredReqs.push(rddCopy);
    }
  });

  return filteredReqs;
};

export const filterRequirementDueDatesByOpenOverdue = (
  rdds: DashboardPeriodDto[],
  filter: string,
) => {
  let filteredReqs: Array<any> = rdds;

  if (filter === "open" || filter === "open-overdue") {
    filteredReqs = [];
    rdds.forEach((rdd) => {
      const rddCopy = { ...rdd };
      rddCopy.dashboardRequirementDueDates =
        rdd.dashboardRequirementDueDates.filter(
          (item: any) => !item.status.operatorComplete,
        );
      if (rddCopy.dashboardRequirementDueDates.length > 0) {
        filteredReqs.push(rddCopy);
      }
    });

    if (filter === "open-overdue") {
      const filteredAgain: Array<any> = [];
      const today = dayjs();
      filteredReqs.forEach((rdd) => {
        const rddCopy = { ...rdd };
        rddCopy.dashboardRequirementDueDates =
          rdd.dashboardRequirementDueDates.filter(
            (item: any) => today.diff(dayjs(item.dueDate), "day") >= 0,
          );
        if (rddCopy.dashboardRequirementDueDates.length > 0) {
          filteredAgain.push(rddCopy);
        }
      });

      filteredReqs = filteredAgain;
    }
  }

  return filteredReqs;
};

const OperatorDashboard: React.FC = () => {
  let { authState } = useOktaAuth();
  let [filterOpenOverdue, setFilterOpenOverdue] = useSearchParamsStateSticky(
    "filterOpenOverdue",
    "open",
  );

  const { isSelected: isDocumentTypeSelected } =
    useContext(DocumentTypeContext);

  let {
    renderPicker: renderPeriodPicker,
    currentPeriod,
    availablePeriods,
    isLoading: periodPickerLoading,
    isError: periodPickerError,
  } = usePeriodPicker({
    fetchFromStorage: false, // just default to 60D, which is fine for operators
    defaultPeriod: "60D",
  });

  const {
    data: unfilteredRddPeriods,
    isLoading: rddPeriodsLoading,
    //isError: rddPeriodsError,
    refetch,
  } = useOktaQuery<DashboardPeriodDto[]>(
    `/api/requirementDueDates/operatorDashboard/byPeriod/${currentPeriod}`,
    { enabled: !!currentPeriod },
  );

  if (periodPickerError)
    return (
      <Row>
        <Col>
          <Card>
            <CardBody>An error has occurred.</CardBody>
          </Card>
        </Col>
      </Row>
    );
  if (periodPickerLoading) return <LoadingSpinnerBig />;

  let rddPeriods = unfilteredRddPeriods
    ? filterRequirementDueDatesByDocumentType(
        filterRequirementDueDatesByOpenOverdue(
          unfilteredRddPeriods,
          filterOpenOverdue,
        ),
        isDocumentTypeSelected,
      )
    : [];

  return (
    <Container>
      <Row>
        <Col md={"3"}>{renderPeriodPicker()}</Col>
        <Col md="4">
          <Select
            id={"open-overdue-select"}
            options={openOverdueOptions}
            onChange={(v: any) => {
              setFilterOpenOverdue(v.value);
            }}
            onBlur={() => {}}
            value={openOverdueOptions.find(
              (a: any) => a.value === filterOpenOverdue,
            )}
          />
        </Col>
        <Col md={"3"}>
          <ContextDocumentTypePicker showAllChoice />
        </Col>
      </Row>
      {!rddPeriodsLoading &&
        rddPeriods &&
        rddPeriods.map((rddPeriod: any) => {
          return (
            <OperatorRddPeriod
              authState={authState}
              key={rddPeriod.id}
              rddPeriod={rddPeriod}
              keepExpanded={/^\d{4}-\d{2}$/.test(currentPeriod)}
              receiveUpdatedRequirementDueDate={
                () => refetch() //receiveUpdatedRequirementDueDate
                // TODO react-query updates from mutations https://tanstack.com/query/latest/docs/framework/react/guides/updates-from-mutation-responses
              }
            />
          );
        })}
      {availablePeriods?.length === 0 && !rddPeriodsLoading && (
        <Row>
          <Col>
            <Card>
              <CardBody>
                No required documents found. Please contact your Welltower
                accountant for assistance.
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {availablePeriods?.length !== 0 &&
        !rddPeriodsLoading &&
        rddPeriods?.length === 0 && (
          <Row>
            <Col>
              <Card>
                <CardBody>No requirements match the selected filters</CardBody>
              </Card>
            </Col>
          </Row>
        )}
    </Container>
  );
};
/*
  receiveUpdatedRequirementDueDate(rdd: RequirementDueDate) {
    if (!rdd) return;

    const rddPeriods = [...this.state.rddPeriods];
    if (rddPeriods) {
      let hasChange = false;
      rddPeriods.forEach((period: any) => {
        const existingIdx = _.findIndex(
          period.dashboardRequirementDueDates,
          (rdd2: RequirementDueDate) => rdd2.id === rdd.id,
        );
        if (existingIdx !== -1) {
          period.dashboardRequirementDueDates[existingIdx] = rdd;
          hasChange = true;
        }
      });
      if (hasChange) {
        this.setState({ rddPeriods });
      }
    }
  }

*/
export default OperatorDashboard;
