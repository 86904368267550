/** @format */

import React from "react";
import _ from "lodash";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  DropdownToggleProps,
} from "reactstrap";
import Operator from "../../types/Operator";
import { getAllOperators } from "../../api/OperatorAPI";
import "./Filters.scss";
import { DeveloperModeOnly } from "../DeveloperMode";
import { AbstractAuthState } from "../../util/OktaUtils";
import { OperatorPickerId } from "../../types/PickerTypes";

interface IOperatorPickerProps {
  selectedOperatorId: OperatorPickerId;
  onSelectionChange: (id: OperatorPickerId, op?: any) => void;
  requireOperatorChoice?: boolean;
  showAllOperatorChoice?: boolean;
  authState: AbstractAuthState;
  operators?: Array<Operator>;
}

interface IOperatorPickerState {
  dropdownOpen: boolean;
  operators: Array<Operator>;
}

/**
 * @deprecated Keep around just so we don't break the Blackline bot
 */
export default class OperatorPickerClassic extends React.Component<
  DropdownToggleProps & IOperatorPickerProps,
  IOperatorPickerState
> {
  constructor(props: IOperatorPickerProps) {
    super(props);
    this.state = {
      dropdownOpen: false,
      operators: [],
    };

    this.toggle = this.toggle.bind(this);
    this.autoPickFirstOperatorOrAll =
      this.autoPickFirstOperatorOrAll.bind(this);
  }
  async componentDidMount() {
    this.loadOperatorData();
  }

  loadOperatorData = async () => {
    const { authState } = this.props;
    try {
      const operators: Array<Operator> =
        this.props.operators || (await getAllOperators(authState));

      this.setState(
        {
          operators,
        },
        this.autoPickFirstOperatorOrAll,
      );
    } catch (error) {
      console.error(error);
    }
  };

  autoPickFirstOperatorOrAll() {
    const {
      requireOperatorChoice,
      showAllOperatorChoice,
      selectedOperatorId,
      onSelectionChange,
    } = this.props;

    const operatorSelected =
      selectedOperatorId && selectedOperatorId !== "NOT_SELECTED";
    if (
      requireOperatorChoice &&
      !operatorSelected &&
      this.state.operators &&
      this.state.operators.length > 0
    ) {
      const sortedOperators = _.sortBy(this.state.operators, ["name"]);
      onSelectionChange(sortedOperators[0].id, sortedOperators[0]);
    } else if (
      !requireOperatorChoice &&
      !operatorSelected &&
      showAllOperatorChoice
    ) {
      onSelectionChange("ALL");
    }
  }

  toggle() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  render() {
    if (this.state.operators.length === 0) return null;
    const enableAll = this.props.showAllOperatorChoice;
    const chosenOperator =
      this.props.selectedOperatorId &&
      this.state.operators.find(
        (opr: Operator) => opr.id === this.props.selectedOperatorId,
      );
    let chosenOperatorName =
      this.props.selectedOperatorId === "ALL"
        ? "All Operators"
        : chosenOperator
          ? chosenOperator.name
          : "NOT_SELECTED";

    if (
      this.props.requireOperatorChoice &&
      this.state.operators &&
      this.state.operators.length === 1
    ) {
      return null;
    }

    return (
      <ButtonDropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        style={this.props.style}
        data-testid={"operator-picker"}
        className={"operator-picker-classic"}
      >
        <DropdownToggle caret color={"light"}>
          {chosenOperatorName}
        </DropdownToggle>
        <DropdownMenu>
          {enableAll && (
            <React.Fragment>
              <DropdownItem onClick={() => this.props.onSelectionChange("ALL")}>
                All Operators
              </DropdownItem>
            </React.Fragment>
          )}
          {_.sortBy(this.state.operators, ["name"]).map((opr: Operator) => (
            <DropdownItem
              key={opr.id}
              onClick={() => this.props.onSelectionChange(opr.id, opr)}
            >
              <DeveloperModeOnly>
                <span className={"text-muted"}>{opr.id}</span>{" "}
              </DeveloperModeOnly>
              <span>{opr.name}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}
