/** @format */

import React from "react";
import { Button, CardBody } from "reactstrap";
import { ArrayHelpers, FieldArray, Form, Formik, FormikProps } from "formik";
import UnmappedAccount from "../submitted-file/unmapped-items/UnmappedAccount";
import UnmappedProperty from "../submitted-file/unmapped-items/UnmappedProperty";
import { UNMAPPED_ITEM_TYPE } from "./OperatorUnmappedItemSummary";
import { UnmappedColumnForm } from "../submitted-file/unmapped-items/UnmappedColumnForm";
import { useOktaQuery } from "../../hooks/useOktaQuery";
import DocumentType from "../../types/DocumentType";
import { useDownloadUrl } from "../../util/OneTimeDownloader";

const OperatorUnmappedItemList = ({
  unmappedItems,
  onSaveUnmappedItems,
  type = "account",
  operatorAccountGroupings = [],
  documentType,
}: {
  unmappedItems: Array<any>;
  onSaveUnmappedItems: any;
  type: UNMAPPED_ITEM_TYPE;
  operatorAccountGroupings?: Array<any>;
  documentType: DocumentType;
}) => {
  // This probably won't be very many, so loading all columns and their options at once should be fine.
  const { data: allColumnOptions } = useOktaQuery(
    `/api/mappedColumns/${documentType?.id}/options`,
    { enabled: type === "column" && !!documentType },
  );
  const { downloadUrl } = useDownloadUrl();
  let columnOptions = new Map<number, any>();
  allColumnOptions?.forEach((co: any) => {
    if (!columnOptions.has(co.columnId)) {
      columnOptions.set(co.columnId, []);
    }
    columnOptions.get(co.columnId).push({
      value: co.id,
      label: co.optionDescription
        ? `${co.optionValue} - ${co.optionDescription}`
        : co.optionValue,
      object: co,
    });
  });

  return (
    <>
      {type === "column" && (
        <CardBody>
          <p style={{ marginBottom: 0 }}>
            Please map the below values from your file to the choices in
            "Welltower template value", and provide any additional information
            or clarification in the "Detailed Description" column.{" "}
            <Button
              color={"link"}
              onClick={() =>
                downloadUrl(
                  `/api/mappedColumns/${documentType.id}/options/downloadToken`,
                )
              }
            >
              Download a list of column options
            </Button>
          </p>
        </CardBody>
      )}
      <Formik initialValues={{ unmappedItems }} onSubmit={onSaveUnmappedItems}>
        {(props: FormikProps<any>) => {
          return (
            <Form>
              <FieldArray
                name={"unmappedItems"}
                render={(arrayHelpers: ArrayHelpers) =>
                  props.values.unmappedItems.map(
                    (unmappedItem: any, index: number) => {
                      if (type === "account") {
                        return (
                          <UnmappedAccount
                            unmappedItem={unmappedItem}
                            index={index}
                            formikProps={props}
                            key={`${unmappedItem.identifier}-${index}`}
                            operatorAccountGroupings={operatorAccountGroupings}
                            welltowerAccounts={[]}
                            isWelltowerAccountant={false}
                          />
                        );
                      } else if (type === "property") {
                        return (
                          <UnmappedProperty
                            unmappedItem={unmappedItem}
                            index={index}
                            formikProps={props}
                            key={`${unmappedItem.identifier}-${index}`}
                            buCodes={[]}
                            isWelltowerAccountant={false}
                          />
                        );
                      } else if (type === "column") {
                        return (
                          <UnmappedColumnForm
                            unmappedItem={unmappedItem}
                            index={index}
                            formikProps={props}
                            key={`${unmappedItem.identifier}-${index}`}
                            columnOptions={columnOptions.get(
                              unmappedItem.columnId,
                            )}
                            isWelltowerAccountant={false}
                          />
                        );
                      } else return null;
                    },
                  )
                }
              />
              <Button
                type={"submit"}
                color={"primary"}
                disabled={props.isSubmitting}
                className={"float-right wtop-btn mt-3"}
                onClick={() => {
                  props.handleSubmit();
                }}
              >
                Save Updates
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default OperatorUnmappedItemList;
