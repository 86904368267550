/** @format */

import React from "react";
import { withOktaAuth } from "@okta/okta-react";
import { Button, Col, Container, Row, Table } from "reactstrap";
import _ from "lodash";
import {
  deleteWithToken,
  fetchToState,
  postWithToken,
} from "../../util/FetchUtils";
import AdHocUploadToken from "../../types/AdHocUploadToken";
import { DeveloperModeOnly } from "../../components/DeveloperMode";
import TokenEditFormButton from "../../components/ad-hoc-tokens/TokenEditFormButton";
import WithModal from "../../components/WithModal";
import { formatDateTime } from "../../util/DateUtils";
import dayjs from "dayjs";
import ConfirmationDialog from "../../components/operator-users/ConfirmationDialog";
import ToggleSwitch from "../../components/toggle-switch/ToggleSwitch";

interface AdHocTokenDashboardState {
  tokens: AdHocUploadToken[];
  includeExpired: boolean;
  error?: string;
}

const FilePathsModal = WithModal(
  ({ token, ...props }: { token: AdHocUploadToken } & Partial<any>) => (
    <>
      <Button
        color={"link"}
        className={"wtop-btn"}
        style={{ fontSize: "initial" }}
        disabled={token.filePaths.length === 0}
        onClick={props.showModal}
      >
        {token.filePaths.length}
      </Button>
      {props.modalContent(
        <ul>
          {token.filePaths.map((s) => (
            <li key={s}>{s}</li>
          ))}
        </ul>,
        {
          showCloseIcon: true,
          modalStyles: { width: "800px", margin: "auto auto" },
          closeOnOverlayClick: true,
        },
      )}
    </>
  ),
);

const AdHocTokenDashboard = withOktaAuth(
  class AdHocTokenDashboard extends React.Component<
    any,
    AdHocTokenDashboardState
  > {
    constructor(props: any) {
      super(props);
      this.state = {
        tokens: [],
        includeExpired: false,
      };
      this.loadTokens = this.loadTokens.bind(this);
      this.receiveUpdatedToken = this.receiveUpdatedToken.bind(this);
      this.deleteToken = this.deleteToken.bind(this);
    }

    componentDidMount() {
      this.loadTokens();
    }

    async loadTokens() {
      fetchToState(
        this,
        "tokens",
        `/api/adHocTokens?includeExpired=${this.state.includeExpired}`,
      );
    }

    async extendToken(token: AdHocUploadToken) {
      postWithToken(
        `/api/adHocTokens/${token.id}/extend`,
        this.props.authState,
        {},
      ).then(this.receiveUpdatedToken);
    }
    async expireToken(token: AdHocUploadToken) {
      postWithToken(
        `/api/adHocTokens/${token.id}/expire`,
        this.props.authState,
        {},
      ).then(this.receiveUpdatedToken);
    }
    async deleteToken(token: AdHocUploadToken, props: any) {
      if (!token.id) return;

      const url = `/api/adHocTokens/${token.id}`;
      return deleteWithToken(url, this.props.authState).then((response) => {
        this.setState((prevState: any) => ({
          tokens: prevState.tokens.filter((o2: any) => o2.id !== token.id),
        }));

        return response;
      });
    }

    receiveUpdatedToken(r: AdHocUploadToken) {
      if (!r) return;
      // If our save endpoint returns the full Requirement object in the shape we want, replace the one in state with the updated copy
      this.setState((prevState) => ({
        tokens: [r].concat(prevState.tokens.filter((r2) => r2.id !== r.id)),
      }));
    }

    render() {
      const canEdit = true; // TODO

      const renderTokenRow = (token: AdHocUploadToken, canEdit: boolean) => {
        return (
          <tr key={token.id}>
            <DeveloperModeOnly>
              <td className={"text-muted"} style={{ paddingTop: "1.25rem" }}>
                {token.id}
              </td>
              <td className={"text-muted"} style={{ paddingTop: "1.25rem" }}>
                {token.token}
              </td>
            </DeveloperModeOnly>
            <td style={{ paddingTop: "1.25rem" }}>
              <a href={`/submission?token=${token.token}`}>
                {token.submissionName}
              </a>
            </td>
            <td style={{ paddingTop: "1.25rem" }}>{token.uploadUserId}</td>
            <td>
              <FilePathsModal token={token} />
            </td>
            <td style={{ paddingTop: "1.25rem" }}>
              {formatDateTime(token.createdTime)}
            </td>
            <td style={{ paddingTop: "1.25rem" }}>
              <span title={formatDateTime(token.expirationTime) || ""}>
                {dayjs(token.expirationTime).fromNow()}
              </span>
            </td>
            <td>
              {dayjs(token.expirationTime).isBefore(dayjs()) ? (
                <ConfirmationDialog
                  title={"Extend this upload link?"}
                  message={`Are you sure you want to make this upload link valid for 24 more hours?`}
                  action={() => {
                    this.extendToken(token);
                  }}
                >
                  {({ onClick }: { onClick: any }) => (
                    <Button
                      onClick={onClick}
                      className={"wtop-btn"}
                      color={"success"}
                      style={{ display: "block" }}
                    >
                      Extend
                    </Button>
                  )}
                </ConfirmationDialog>
              ) : (
                <ConfirmationDialog
                  title={"Expire this upload link?"}
                  message={`Are you sure you want to invalidate this upload link?`}
                  action={() => {
                    this.expireToken(token);
                  }}
                >
                  {({ onClick }: { onClick: any }) => (
                    <Button
                      onClick={onClick}
                      className={"wtop-btn"}
                      color={"warning"}
                      style={{ display: "block" }}
                    >
                      Expire Now
                    </Button>
                  )}
                </ConfirmationDialog>
              )}
            </td>

            <td>
              {canEdit && (
                <TokenEditFormButton
                  token={token}
                  authState={this.props.authState}
                  onSaveToken={this.loadTokens}
                  deleteToken={this.deleteToken}
                />
              )}
            </td>
          </tr>
        );
      };

      return (
        <Container>
          <Row>
            <Col sm="4" />
            <Col>
              <h3>Ad-Hoc Upload Links</h3>
            </Col>
          </Row>
          <Row style={{ padding: "0.75rem" }}>
            <Col sm={"2"}>
              {/*<OperatorPicker
                authState={this.props.authState}
                selectedOperatorId={this.state.filterOperatorId}
                onSelectionChange={this.setFilterOperatorId}
              />*/}
            </Col>
            <Col sm={"2"}>
              {/*<DocumentTypePicker
                selectedDocumentTypeId={this.state.filterDocumentTypeId}
                onSelectionChange={this.setFilterDocumentTypeId}
                authState={this.props.authState}
              />*/}
            </Col>
            <Col sm={"5"} style={{ display: "flex", alignItems: "center" }}>
              <ToggleSwitch
                isChecked={this.state.includeExpired}
                onValueChanged={(newVal: boolean) => {
                  this.setState(
                    (prevState) => ({
                      includeExpired: newVal,
                    }),
                    () => {
                      this.loadTokens();
                    },
                  );
                }}
              />
              <span className={"mx-2"}>Show Expired</span>
            </Col>
            <Col sm={"3"} className={"text-right"}>
              {canEdit && (
                <TokenEditFormButton
                  receiveUpdatedToken={this.receiveUpdatedToken}
                  canEdit={canEdit}
                  authState={this.props.authState}
                  onSaveToken={this.loadTokens}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <DeveloperModeOnly>
                      <th>ID</th>
                      <th>Token</th>
                    </DeveloperModeOnly>
                    <th>Name</th>
                    <th>User</th>
                    <th>Uploaded files</th>
                    <th>Created</th>
                    <th>Expiration</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {_.sortBy(this.state.tokens, "submissionName").map((o) =>
                    renderTokenRow(o, canEdit),
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      );
    }
  },
);

export default AdHocTokenDashboard;
