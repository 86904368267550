/** @format */
import { getOktaTokenAuthHeader } from "./FetchUtils";
import { AbstractAuthState } from "./OktaUtils";
import { useOktaAuth } from "@okta/okta-react";

const downloadFile = (url: string) => {
  // const openResult = window.open(url);
  // window.open() doesn't do anything in iOS (even with some web results that say it should work fine)
  // and in recent desktop Safari, the window stays open after download, which we don't want.
  // Just download in the same window, which appears to work everywhere.
  /*if (!openResult) */
  document.location.href = url;
  // document.location.href for a long load seems to trigger the health check red, but that doesn't run in prod, so I'm not too worried about it.
};

// TODO - may want to break up callbacks.setLoading() to have a separate callback if this is a job that we're polling for status?
//        or just work to move all downloads to the job/poll style

const downloadUrl = async (
  auth: AbstractAuthState,
  url: string,
  callbacks?: any,
) => {
  callbacks?.setLoading?.(true);
  return fetch(url, {
    headers: {
      Authorization: getOktaTokenAuthHeader(auth),
    },
    credentials: "same-origin",
    mode: "cors",
  }) //.then(this.recordFetchTiming(startTime, url))
    .then((response: Response) => {
      if (response.ok) {
        return response;
      } else {
        let error = new Error(
          /*response.statusText ||*/ "An error has occurred",
        );
        //error.response = response;
        throw error; // Throw to stop following then()s
      }
    })
    .then((response) => {
      if (response.status === 204) {
        // No Content
        return {};
      } else if (response.status === 301) {
        // redirect, probably just from switch_user // TODO - this is an old Valet comment, probably not seeing 301/302 from uploads in Portal?
        return {};
      } else if (response.status === 302) {
        // redirect, probably just from switch_user // TODO - this is an old Valet comment, probably not seeing 301/302 from uploads in Portal?
        return {};
      } else {
        return response.json();
      }
    })
    .then((data: any) => {
      //if (!(options.signal && options.signal.aborted)) {
      if (data.jobStatus) {
        if (data.jobStatus.waiting) {
          callbacks?.setPercent?.(data.jobStatus.percentStatus);
          setTimeout(() => {
            downloadUrl(auth, data.jobStatus.statusURI, callbacks);
          }, 5000);
        } else if (data.jobStatus.downloadURI) {
          downloadFile(data.jobStatus.downloadURI);
          callbacks?.setLoading?.(false);
        }
      } else {
        if (data.downloadURI) {
          downloadFile(data.downloadURI);
          callbacks?.setLoading?.(false);
        }
      }
      //}
    })
    .catch((error) => {
      //console.error(error);
      // TODO - what to do in this case? Just nothing?
      //if (componentThis && !(options.signal && options.signal.aborted))
      let error2 = new Error(
        /*response.statusText ||*/ "An error has occurred",
      );
      callbacks?.setLoading?.(false);
      //error.response = response;
      throw error2;
    });
  // We don't want to setLoading(false) in a .finally() because most of the time this chain will just call itself to refresh status
};

const useDownloadUrl = (): {
  downloadUrl: (url: string, callbacks?: any) => any;
} => {
  const { authState } = useOktaAuth();

  function download(url: string, callbacks?: any) {
    return downloadUrl(authState, url, callbacks);
  }
  return { downloadUrl: download };
};

export { downloadUrl, useDownloadUrl };
