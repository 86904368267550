/** @format */

import React from "react";
import { Container, Card, CardBody, CardGroup, Button } from "reactstrap";
import { canManageAdHocTokens, isWelltower } from "./util/OktaUtils";
import { Redirect } from "react-router-dom";
import "./Landing.scss";
import { Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

const Landing = () => {
  const { authState } = useOktaAuth();

  if (!authState) {
    return null;
  }

  if (authState.isAuthenticated) {
    if (isWelltower(authState)) {
      console.debug("Landing isWelltower");
      return <Redirect to="/dashboard" />;
    } else if (canManageAdHocTokens(authState)) {
      console.debug("Landing canManageAdHoc");
      return <Redirect to="/adHocTokens" />;
    } else {
      console.debug("Landing operator dashboard");
      return <Redirect to="/operator-dashboard" />;
    }
  }
  return (
    <div style={{ minHeight: "100vh", background: "white" }}>
      <Container id={"landing"} className={"p-5"}>
        <CardGroup>
          <Card>
            <CardBody>
              <h2 className={"text-center"}>
                <div
                  className={"welltower-logo"}
                  style={{
                    display: "inline-block",
                    width: "5em",
                    height: "1.25em",
                    marginRight: "0.5em",
                    marginLeft: "0.5em",
                    verticalAlign: "baseline",
                  }}
                >
                  {" "}
                  <div className="corner-ribbon top-left">
                    {process.env.REACT_APP_STAGE_LABEL}
                  </div>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "baseline",
                  }}
                >
                  Operator Portal
                </div>
              </h2>
            </CardBody>
            <CardBody>
              Welltower’s simple, secure portal to quickly and accurately submit
              data files.
            </CardBody>
            <CardBody className={"text-center"}>
              <Link to={"/login"}>
                <Button
                  color="primary"
                  size="large"
                  style={{ padding: "1rem 2rem" }}
                >
                  Log in
                </Button>
              </Link>
            </CardBody>
            <CardBody className={"text-center"}>
              {process.env.REACT_APP_STAGE_LABEL && (
                <a
                  href="https://welltower.okta.com/login/default"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"small"}
                >
                  Test account login
                </a>
              )}
            </CardBody>
          </Card>
          <Card>
            <CardBody></CardBody>
            <CardBody>
              <img
                src={"/landing_screenshot.png"}
                style={{ width: "100%" }}
                alt={"screenshot"}
              />
            </CardBody>
            <CardBody></CardBody>
          </Card>
        </CardGroup>
      </Container>
    </div>
  );
};

export default Landing;
