/** @format */

import React from "react";
import { getFileOperatorSummary } from "../../api/SubmittedFileAPI";
import { Button } from "reactstrap";
import { formatDateTime } from "../../util/DateUtils";
import { DeveloperModeOnly } from "../DeveloperMode";
import RddRetractFileButton from "../rdd-card/RddRetractFileButton";
import {
  hasAwaitingETLStatus,
  hasJDEStatus,
  statusTextColor,
} from "../../util/StatusUtils";
import { canRetractFiles } from "../../util/OktaUtils";
import SubmittedFile from "../../types/SubmittedFile";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";

interface OperatorSubmittedFileProps extends Omit<IOktaContext, "oktaAuth"> {
  file: SubmittedFile;
  downloadFile?: any;
  downloadFullLogEntry?: any;
  retractFile?: any;
  locked: boolean;
}
export default class OperatorSubmittedFile extends React.Component<
  OperatorSubmittedFileProps,
  any
> {
  constructor(props: any) {
    super(props);
    this.state = { summary: null };
  }

  componentDidMount() {
    this.loadSummary();
  }

  private async loadSummary() {
    const { file, authState } = this.props;
    if (!file.requirementDueDate.requirement.portalProcessed) return; // TODO - currently only SHO TB, but at least skipping this for unprocessed will be a big win
    let summary = null;
    try {
      summary = await getFileOperatorSummary(
        file.requirementDueDate,
        file.id,
        authState,
      );
      summary = summary.find(
        (a: any) => a && a.submittedFile && a.submittedFile.id === file.id,
      );
      this.setState({ summary });
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  render() {
    const {
      file,
      downloadFile,
      downloadFullLogEntry,
      retractFile,
      authState,
      locked,
    } = this.props;
    const { summary } = this.state;
    const status = file.status;
    const hasSummary = !!summary;
    const { downloadable, deleteable } =
      file.requirementDueDate.requirement.documentType.uploadMethod;

    const canRetract =
      !locked &&
      canRetractFiles(
        authState,
        file.requirementDueDate.requirement.operator,
        file.requirementDueDate.requirement,
      ) &&
      !hasJDEStatus(status) &&
      !hasAwaitingETLStatus(status) &&
      deleteable;

    return (
      <li className={`submittedFile-${status.operatorStatusColor}`}>
        <DeveloperModeOnly>
          <span className={"text-muted"}>
            {file.requirementDueDate.requirement.operator.id}.
            {file.requirementDueDate.requirement.id}.
            {file.requirementDueDate.id}.{file.id}{" "}
          </span>
        </DeveloperModeOnly>
        {downloadable ? (
          <Button
            color={"link"}
            style={{ color: "#007bff" }}
            className={"font-weight-light pr-0"}
            title={"Download the file"}
            onClick={() => downloadFile(file)}
          >
            {file.originalFilename}
          </Button>
        ) : (
          <span className={"font-weight-light px-2 align-baseline"}>
            {file.originalFilename}
          </span>
        )}
        <span className={"mr-2"}>
          {" "}
          <small>{`${file.uploadUserDisplayName} on ${formatDateTime(
            file.uploadTime,
          )}`}</small>
        </span>
        {file.logEntries && file.logEntries.length > 0 && (
          <React.Fragment>
            <Button
              color={"link"}
              onClick={() => downloadFullLogEntry(file)}
              title={"Download full log of file errors"}
              className={"mt-n1 px-0"}
            >
              <small>Download error details</small>
            </Button>
          </React.Fragment>
        )}
        {file.logEntries && file.logEntries.length > 0 && canRetract && (
          <small className={"text-muted"}>{" | "}</small>
        )}
        {canRetract && (
          <RddRetractFileButton small retractFile={retractFile} file={file} />
        )}
        <ul className={"file-summary-list"}>
          {(file.requirementDueDate.requirement.portalProcessed ||
            status.operatorStatusColor !== "SUCCESS") && (
            <li className={statusTextColor(status.operatorStatusColor)}>
              <small>{status.operatorStatusLong}</small>
            </li>
          )}

          {hasSummary && (
            <li>
              <span>
                <small>{`${summary.summaryValues.lines[0]} lines, ${summary.summaryValues.accounts[0]} accounts, ${summary.summaryValues.properties[0]} properties `}</small>
              </span>
            </li>
          )}
        </ul>
      </li>
    );
  }
}
