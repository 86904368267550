/** @format */
import React from "react";
import Select, { Options } from "react-select";
import { ErrorMessage, useFormikContext } from "formik";

interface WrappedSelectInputProps {
  id: string;
  label?: string;
  value?: any;
  required?: boolean;
  clearable?: boolean;
  options?: Options<any>;
  onChange?: (s: string, v?: any) => void; // TODO remove from callers
  onBlur?: (s: string, v?: any) => void; // TODO remove from callers
  disabled?: boolean;
  className?: string;
}

// TODO - change these variables to --bs-primary- for Bootstrap 5

export const selectTheme = (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary75: "var(--primary-75)", // Not sure where this is used
    primary50: "var(--primary-50)", // click background
    primary25: "var(--primary-25)", // hover background
    primary: "var(--primary)", // selected background, outlines
  },
});

const WrappedSelectInput = (props: WrappedSelectInputProps) => {
  const {
    id,
    label,
    value,
    required,
    clearable,
    options,
    onChange, // TODO - remove from callers because we can get this from FormikContext
    onBlur, // TODO - remove from callers because we can get this from FormikContext
    disabled,
    className,
  } = props;

  const formik = useFormikContext();
  /* TODO - all callers had been including
  onChange={formikProps.setFieldValue}
  onBlur={formikProps.setFieldTouched}
  We ca pull this out of a Formik context, so these can be removed.
  */

  // noinspection JSUnusedGlobalSymbols // Not sure why IDEA thinks these object keys are unused globals
  const customStyles = {
    multiValueLabel: (base: any) => ({ ...base, color: "#3697B5" }),
    menu: (base: any) => ({ ...base, marginTop: "2px" }),
    menuList: (base: any) => ({
      ...base,
      paddingTop: "0",
      paddingBottom: "0",
    }),
  };
  return (
    <div className={`mb-3 ${className || ""}`}>
      <label htmlFor={id} className={required ? "label-required" : ""}>
        {label}
      </label>
      <Select
        inputId={id}
        options={options}
        onChange={(value: any) => {
          // this is going to call setFieldValue and manually update formik values
          (onChange || formik.setFieldValue)(id, value);
        }}
        onBlur={() => {
          // this is going to call setFieldTouched and manually update formik touched
          (onBlur || formik.setFieldTouched)(id, true);
        }}
        value={value}
        theme={selectTheme}
        styles={customStyles}
        isDisabled={disabled || false}
        isClearable={clearable === undefined ? true : clearable}
      />
      <ErrorMessage name={id} component={"div"} className={"input-feedback"} />
    </div>
  );
};

export default WrappedSelectInput;
