/** @format */

import React from "react";
import { Button } from "reactstrap";
import ConfirmationDialog from "../operator-users/ConfirmationDialog";

// TODO - probably just inline this now. (I think I based ConfirmationDialog off of this or a similar class, so no sense in nesting them)

const RddRetractFileButton = ({
  file,
  retractFile,
  text = "Retract",
  ...props
}: {
  file: any;
  text?: string;
  retractFile: any;
} & Partial<any>) => (
  <ConfirmationDialog
    title={"Retract file?"}
    confirmLabel={"Retract file"}
    buttonColor={"danger"}
    message={
      <>
        <p>Are you sure you want to retract this file?</p>
        <p>
          <i>{file ? file.originalFilename : ""}</i>
        </p>
      </>
    }
    action={() => {
      retractFile(file);
    }}
  >
    {({ onClick }: { onClick: any }) => (
      <Button
        color={"link"}
        onClick={onClick}
        className={"px-0"}
        data-testid={"retract-file-button"}
      >
        {Object.keys(props).includes("small") ? <small>{text}</small> : text}
      </Button>
    )}
  </ConfirmationDialog>
);

export default RddRetractFileButton;
