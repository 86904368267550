/** @format */

import React from "react";
import { Button, DropdownItem } from "reactstrap";
import WithModal from "../WithModal";
import EditJDEInfoForm from "../submitted-file/EditJDEInfoForm";
import { recordsBatchNumber, recordsSpotDbLoad } from "../../util/StatusUtils";
import DocumentType from "../../types/DocumentType";
import EditSpotDbForm from "../submitted-file/EditSpotDbForm";
import { AbstractAuthState } from "../../util/OktaUtils";

const JDEInfoButton = WithModal(
  ({
    file,
    authState,
    documentType,
    jdeBatchNumber = "",
    glDate = null,
    jdeInfoId = null,
    buttonType = "button",
    onSaveJDEInfo,
    failed,
    reversed,
    ...props
  }: {
    file: any;
    authState: AbstractAuthState;
    documentType?: DocumentType;
    jdeBatchNumber?: any;
    glDate?: any;
    jdeInfoId?: any;
    buttonType: string;
    onSaveJDEInfo?: Function;
  } & Partial<any>) => {
    let requireBatchNumber = recordsBatchNumber(
      documentType ?? file?.requirementDueDate?.requirement?.documentType,
    );
    const spotDbLoad = recordsSpotDbLoad(
      documentType ?? file?.requirementDueDate?.requirement?.documentType,
    );

    const modalContent = props.modalContent(
      spotDbLoad ? (
        <EditSpotDbForm
          authState={authState}
          file={file}
          uploadIdentifier={jdeBatchNumber}
          glDate={glDate}
          jdeInfoId={jdeInfoId}
          failed={failed}
          reversed={reversed}
          onSaveJDEInfo={(data: any) => {
            props.closeModal();
            onSaveJDEInfo && onSaveJDEInfo(data);
          }}
        />
      ) : (
        <EditJDEInfoForm
          authState={authState}
          file={file}
          uploadIdentifier={jdeBatchNumber}
          glDate={glDate}
          jdeInfoId={jdeInfoId}
          failed={failed}
          reversed={reversed}
          onSaveJDEInfo={(data: any) => {
            props.closeModal();
            onSaveJDEInfo && onSaveJDEInfo(data);
          }}
        />
      ),
      {
        showCloseIcon: true,
        modalStyles: { width: "600px" },
        closeOnOverlayClick: false,
        noAutoFocus: true,
      },
    );

    if (buttonType === "button") {
      return (
        <>
          <Button
            size={"sm"}
            className={"jde-info-btn text-uppercase"}
            color={"primary"}
            onClick={props.showModal}
            data-testid={"jde-info-button"}
          >
            {requireBatchNumber
              ? `Enter ${file?.destination?.displayName || "JDE"} batch number`
              : `Record ${file?.destination?.displayName || ""} load`}
          </Button>
          {modalContent}
        </>
      );
    } else if (buttonType === "dropdownItem") {
      let title = requireBatchNumber
        ? `Edit ${file?.destination?.displayName || "JDE"} batch number`
        : `Edit ${file?.destination?.displayName || ""} load`;
      return (
        <>
          <DropdownItem title={title} onClick={props.showModal}>
            <small data-testid={"jde-info-button"}>{title}</small>
          </DropdownItem>
          {modalContent}
        </>
      );
    }
  },
);

export default JDEInfoButton;
