/** @format */

import React from "react";
import { withFormik } from "formik";
import WithModal from "./WithModal";
import { Button } from "reactstrap";
import axios, { AxiosRequestConfig } from "axios";
import { withOktaAuth } from "@okta/okta-react";
import RequirementDueDate from "../types/RequirementDueDate";
import { formatDate } from "../util/DateUtils";
import WrappedDateInput from "./form/WrappedDateInput";
import { DeveloperModeOnly } from "./DeveloperMode";
import {
  describeDueDatePeriodEnd,
  describeFrequencyType,
  welltowerName,
} from "../util/NameUtils";
import { getOktaTokenAuthHeader } from "../util/FetchUtils";
import { LoadingSpinner } from "./LoadingSpinners";

const RequirementDueDateGlDateEditForm = (props: any) => {
  const {
    requirementDueDate,
    values,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    closeModal,
  } = props;

  return (
    <div className="editForm">
      <p>
        {welltowerName(requirementDueDate.requirement.operator)}{" "}
        {welltowerName(requirementDueDate.requirement)}
        <br />
        {describeFrequencyType(requirementDueDate.requirement)}
        <br />
        {describeDueDatePeriodEnd(requirementDueDate)}
        <br />
        <DeveloperModeOnly>
          {" "}
          <span className={"text-muted"}>
            {requirementDueDate.requirement.operator.id}.
            {requirementDueDate.requirement.id}.{requirementDueDate.id}
          </span>
        </DeveloperModeOnly>
      </p>
      <form className="editFormForm" onSubmit={handleSubmit}>
        <div className={"form-group"}>
          <WrappedDateInput
            id={"glDate"}
            label={"GL Date"}
            value={values.glDate}
            required={true}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          />
          <div className={"input-feedback info"}>
            {requirementDueDate.glDateOverride
              ? "Manually-entered GL date for this month's file(s)"
              : `From requirement setting to post on ${
                  requirementDueDate.requirement.glDateSetting
                    ? requirementDueDate.requirement.glDateSetting.displayName
                    : "Month End"
                }`}
          </div>
        </div>

        <div className="editFormButton">
          <span className="requiredNote" style={{ float: "left" }}>
            * required fields
          </span>
          <Button onClick={closeModal} color={"link"} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" color={"primary"} disabled={isSubmitting}>
            Update
          </Button>
        </div>
        {isSubmitting && (
          <div
            style={{
              position: "absolute",
              top: "1.2rem",
              height: "calc( 100% - 2.4rem)",
              width: "calc( 100% - 2.4rem)",
              backgroundColor: "white",
              opacity: 0.5,
            }}
          >
            <div
              style={{
                fontSize: "48px",
                position: "relative",
                top: "calc(50% - 24px)",
                left: "calc(50% - 24px)",
              }}
            >
              <LoadingSpinner />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

const EnhancedForm = withOktaAuth(
  withFormik({
    mapPropsToValues: (props) => {
      return {
        glDate: props.requirementDueDate && props.requirementDueDate.glDate,
      };
    },
    /* Building a Yup schema with a cyclic dependency and the noSortEdges "escape hatch" worked in Chrome, but somehow blew up in IE.
    I tried to figure it out, but eventually had to give up and write a simple custom validation.
     */
    validate: (values: any, props) => {
      let errors: any = {};

      if (!values.glDate) {
        errors.glDate = "Please enter a GL date";
      }

      return errors;
    },

    handleSubmit: async (
      values: any,
      { props, setSubmitting }: { props: any; setSubmitting: any },
    ) => {
      const config: AxiosRequestConfig = {
        headers: {
          "content-type": "application/json",
          Authorization: getOktaTokenAuthHeader(props.authState),
        },
        //credentials: "same-origin",
        //mode: "cors",
        method: "PUT",
        url: `/api/operators/${props.requirementDueDate.requirement.operator.id}/requirements/${props.requirementDueDate.requirement.id}/dueDates/${props.requirementDueDate.id}/glDate`,
        data: { glDate: values.glDate },
      };

      axios.request(config).then((response) => {
        props.submitCallback && props.submitCallback(response.data);
        setSubmitting(false);
        props.closeModal && props.closeModal();
      });

      return;
    },

    displayName: "RequirementDueDateGlDateEditForm", // helps with React DevTools
  })(RequirementDueDateGlDateEditForm),
);

const RequirementDueDateGlDateEditFormButton = WithModal(
  ({
    requirementDueDate,
    canEdit,
    ...props
  }: {
    requirementDueDate: RequirementDueDate;
    canEdit: boolean;
  } & Partial<any>) => {
    return (
      <>
        <Button
          color={"link"}
          style={{ marginLeft: "-0.5em" }}
          disabled={!canEdit}
          onClick={props.showModal}
        >
          {requirementDueDate.glDate
            ? formatDate(requirementDueDate.glDate)
            : ""}
        </Button>
        {props.modalContent(
          <EnhancedForm requirementDueDate={requirementDueDate} {...props} />,
          {
            showCloseIcon: true,
            modalStyles: { width: "400px" },
            closeOnOverlayClick: false,
            modalProps: { center: true },
            noAutoFocus: true,
          },
        )}
      </>
    );
  },
);

export default RequirementDueDateGlDateEditFormButton;
