/** @format */

import React, { useState } from "react";
import Modal from "react-responsive-modal";

const useModal = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const modalWrap = (
    component,
    {
      showCloseIcon = false,
      closeOnOverlayClick = true,
      modalStyles = {},
      modalProps = {},
    } = {},
  ) => {
    //onEntered={trackPath && (() => sendModalView(trackPath))}

    return (
      <Modal
        open={open}
        onClose={handleCloseModal}
        showCloseIcon={showCloseIcon}
        closeOnOverlayClick={closeOnOverlayClick}
        styles={{
          modal: modalStyles,
        }}
        {...modalProps}
      >
        {component}
      </Modal>
    );
  };
  return { showModal, closeModal: handleCloseModal, modalContent: modalWrap };
};

const WithModal = (ComposedComponent) => {
  // This seems a little confusing... But maybe it's better to contain the complexity here.
  const ModalWrapperComponent = (props) => {
    /*
    const [open, setOpen] = useState(false);
    const showModal = () => {
      setOpen(true);
    };
    const handleCloseModal = () => {
      setOpen(false);
    };

    const modalWrap = (
      component,
      {
        showCloseIcon = false,
        closeOnOverlayClick = true,
        modalStyles = {},
        modalProps = {},
        noAutoFocus = false,
      } = {},
    ) => {
      //onEntered={trackPath && (() => sendModalView(trackPath))}

      // For RRM 5, we tried to set initialFocusRef to the modal's content itself, but Modal doesn't accept a ref, so that doesn't work.
      // Just turning off focusTrapped appears to be good enough? Maybe try again to switch date pickers and upgrade to RRM 6?
      return (
        <Modal
          focusTrapped={!noAutoFocus}
          open={open}
          onClose={handleCloseModal}
          showCloseIcon={showCloseIcon}
          closeOnOverlayClick={closeOnOverlayClick}
          styles={{
            overlay: {
              backgroundColor: "rgba(79, 79, 79, 0.45)",
            },
            modal: Object.assign(
              {},
              {
                borderRadius: "1em",
              },
              modalStyles,
            ),
          }}
          {...modalProps}
        >
          {component}
        </Modal>
      );
    };
*/
    const useModalProps = useModal();
    return <ComposedComponent {...props} {...useModalProps} />;
  };
  return ModalWrapperComponent;
};

export default WithModal;
export { useModal };
