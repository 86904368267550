/** @format */
import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import "./AccountantDashboard.scss";

import { requirementsEndpoint } from "../../api/RequirementsAPI";
import _ from "lodash";
import { isWelltower } from "../../util/OktaUtils";

import DocumentTypeSubmissionStats from "./DocumentTypeSubmissionStats";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Portfolio from "../../types/Portfolio";
import RequirementDueDateDashboardDto from "../../types/RequirementDueDateDashboardDto";
import { DocumentTypeId } from "../../types/DocumentType";
import { usePeriodPicker } from "../../components/filters/PeriodPicker";
import { useOktaQuery } from "../../hooks/useOktaQuery";
import Country from "../../types/Country";
import ReportingPriority from "../../types/ReportingPriority";
import dayjs from "dayjs";

const AccountantDashboard: React.FC = () => {
  let { authState } = useOktaAuth();
  let {
    renderPicker: renderPeriodPicker,
    currentPeriod,
    //availablePeriods,
    isLoading: periodPickerLoading,
    //isError: periodPickerError, // TODO
  } = usePeriodPicker({
    // Starting view of "last month"
    defaultPeriod: dayjs().subtract(1, "month").format("YYYY-MM"),
    monthsOnly: true,
    fetchFromStorage: false, // use queryString param, then default, and that's it; but save to storage so picks here stick on other pages
  });

  let [activeTab, setActiveTab] = useState("TRIAL_BALANCE");

  const {
    data: portfolioOptions,
    isLoading: portfoliosLoading,
    //isError: portfoliosError, // TODO
  } = useOktaQuery<Portfolio[]>("/api/portfolios");
  const {
    data: countryOptions,
    isLoading: countriesLoading,
    //isError: countriesError, // TODO
  } = useOktaQuery<Country[]>("/api/countries");
  const {
    data: reportingPriorityOptions,
    isLoading: reportingPrioritiesLoading,
    //isError: reportingPrioritiesError, // TODO
  } = useOktaQuery<ReportingPriority[]>("/api/reportingPriorities");

  const {
    data: requirementDueDates,
    isLoading,
    isError,
    error,
  } = useOktaQuery<RequirementDueDateDashboardDto[]>(
    `${requirementsEndpoint}/${currentPeriod}`,
    { enabled: !periodPickerLoading && !!currentPeriod },
  );

  const loading =
    periodPickerLoading ||
    portfoliosLoading ||
    countriesLoading ||
    reportingPrioritiesLoading ||
    isLoading;

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  if (!authState?.isAuthenticated) {
    return null;
  }
  if (!isWelltower(authState)) {
    return <Redirect to="/" />;
  }

  const dashboardGroups: {
    id?: DocumentTypeId | string;
    label: string;
    ids?: DocumentTypeId[];
  }[] = [
    {
      id: "TRIAL_BALANCE",
      label: "Trial Balance",
    },
    {
      id: "MONTHLY_CENSUS",
      label: "Monthly Census",
    },
    {
      id: "WEEKLY_CENSUS",
      label: "Weekly Census",
    },
    {
      id: "CAP_EX",
      label: "Cap Ex",
    },
    {
      id: "ROLL_FORWARD",
      label: "Roll Forward",
    },
    {
      id: "TRIAL_BALANCE_NNN",
      label: "NNN Trial Balance",
    },
    {
      id: "MONTHLY_CENSUS_NNN",
      label: "NNN Monthly Census",
    },
    {
      id: "TRIAL_BALANCE_HLBV",
      label: "HLBV Trial Balance",
    },
    {
      id: "MONTHLY_CENSUS_HLBV",
      label: "HLBV Monthly Census",
    },
    {
      id: "WELLNESS_WEEKLY",
      ids: ["WEEKLY_LEASING_SUMMARY", "WEEKLY_SALES_FUNNEL"],
      label: "Wellness Housing",
    },
    {
      id: "OTHER_OPERATING_DATA",
      label: "Other Operating Data",
    },
    {
      id: "RENT_ROLL",
      label: "Rent Roll",
    },
    {
      id: "BUDGET",
      ids: ["BUDGET", "BUDGET_TB", "BUDGET_CAP_EX"],
      label: "Budgets",
    },
    {
      id: "WAGE_DATA",
      label: "Wage Data",
    },
    {
      id: "OTHER",
      label: "Other",
    },
  ];

  // Group RDDs by document type
  const groupedRDDs = _.groupBy(
    requirementDueDates,
    (rdddDto: RequirementDueDateDashboardDto) => {
      const id = rdddDto.documentTypeId;
      return (
        dashboardGroups.find((o: any) => o.id === id || o.ids?.includes(id))
          ?.id || "OTHER"
      );
    },
  );

  return (
    <Container className={"accountant-dashboard"}>
      <Row>
        <Col className={"text-center"}>
          <h3>Dashboard</h3>
        </Col>
      </Row>
      <Row>
        <Col md={"3"}>{renderPeriodPicker()}</Col>
      </Row>
      {loading ? (
        <FontAwesomeIcon
          icon={"spinner"}
          spin
          size={"3x"}
          color={"#888"}
          className={"mt-5"}
        />
      ) : isError ? (
        <p>{error}</p>
      ) : (
        <Row>
          <Col>
            <Nav tabs>
              {dashboardGroups.map((o: any) => (
                <NavItem key={o.id}>
                  <NavLink
                    className={activeTab === o.id ? "active" : ""}
                    onClick={() => {
                      toggleTab(o.id);
                    }}
                  >
                    {o.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              {dashboardGroups.map((o: any) => (
                <TabPane key={o.id} tabId={o.id}>
                  <DocumentTypeSubmissionStats
                    rdds={groupedRDDs[o.id]}
                    portfolioOptions={portfolioOptions ?? []}
                    countryOptions={countryOptions ?? []}
                    reportingPriorityOptions={reportingPriorityOptions ?? []}
                  />
                </TabPane>
              ))}
            </TabContent>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default AccountantDashboard;
