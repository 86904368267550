/** @format */

import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Container, Card, CardBody, CardHeader } from "reactstrap";
import useFetchJson from "../hooks/useFetchJson";
import _ from "lodash";
import { LoadingSpinner } from "../components/LoadingSpinners";
import { isWelltower } from "../util/OktaUtils";

function ListOrItem(data: any): any {
  if (_.isArray(data)) {
    return (
      <ul>
        {data.map((i) => (
          <li key={JSON.stringify(i)}>{ListOrItem(i)}</li>
        ))}
      </ul>
    );
  } else if (_.isObject(data)) {
    return (
      <ul>
        {Object.keys(data).map((k) => (
          <li key={k}>
            {k}
            {(data as any)[k] === null ||
              (data as any)[k] === undefined ||
              ": "}
            {ListOrItem((data as any)[k])}
          </li>
        ))}
      </ul>
    );
  } else {
    return data;
  }
}

const SFTPHealth = () => {
  const { data, loading, error } = useFetchJson("/api/yardi/etlstatus");

  return loading ? <LoadingSpinner /> : error ? <p>Error</p> : ListOrItem(data);
};

const HopperStatusPage = () => {
  const { authState } = useOktaAuth();

  if (!authState?.isAuthenticated || !isWelltower(authState)) return null;
  return (
    <Container>
      <Card>
        <CardHeader tag={"h5"}>Yardi ETL SFTP status:</CardHeader>
        <CardBody>
          <SFTPHealth />
        </CardBody>
      </Card>
    </Container>
  );
};

export { HopperStatusPage as default, ListOrItem };
