/** @format */

import React from "react";
import { Button, DropdownItem } from "reactstrap";
import WithModal from "../WithModal";
import EditWelltowerAccountingLoadForm from "../submitted-file/EditWelltowerAccountingLoadForm";
import { recordsBatchNumber } from "../../util/StatusUtils";
import DocumentType from "../../types/DocumentType";
import WelltowerAccountingLoad from "../../types/WelltowerAccountingLoad";
import { DeveloperModeOnly } from "../DeveloperMode";

const WelltowerAccountingLoadEditButton = WithModal(
  ({
    file,
    wal = undefined,
    documentType,
    //jdeBatchNumber = "",
    //glDate = null,
    buttonType = "button",
    afterSave,
    //failed,
    //reversed,
    ...props
  }: {
    file: any;
    wal?: WelltowerAccountingLoad;
    documentType?: DocumentType;
    buttonType: string;
    afterSave?: Function;
  } & Partial<any>) => {
    let requireBatchNumber = recordsBatchNumber(
      documentType ?? file?.requirementDueDate?.requirement?.documentType,
      wal,
    );

    const modalContent = props.modalContent(
      <EditWelltowerAccountingLoadForm
        file={file}
        wal={wal}
        afterSave={(data: any) => {
          props.closeModal();
          afterSave && afterSave(data);
        }}
      />,
      {
        showCloseIcon: true,
        modalStyles: { width: "600px" },
        closeOnOverlayClick: false,
      },
    );

    const destination = `${file?.destination?.displayName || ""} ${
      wal?.destinationType || ""
    }`.trim();
    let text = "";
    if (!wal || wal.status === "WAITING") {
      if (requireBatchNumber) text = `Enter ${destination} batch number`;
      else text = `Record ${destination} load`;
    } else {
      if (requireBatchNumber) text = `Edit ${destination} batch number`;
      else text = `Edit ${destination} load`;
    }

    // TODO - I don't think we're using this button in the WAL-style lists
    if (buttonType === "button") {
      return (
        <>
          <Button
            size={"sm"}
            className={"jde-info-btn text-uppercase"}
            color={"primary"}
            onClick={props.showModal}
            data-testid={"jde-info-button"}
          >
            {text}{" "}
            <DeveloperModeOnly>
              <span className={"text-muted"}>{wal?.id || ""}</span>
            </DeveloperModeOnly>
          </Button>
          {modalContent}
        </>
      );
    } else if (buttonType === "link") {
      return (
        <>
          <Button
            size={"sm"}
            className={"jde-info-btn text-uppercase"}
            color={"link"}
            onClick={props.showModal}
            data-testid={"jde-info-button"}
          >
            {text}{" "}
            <DeveloperModeOnly>
              <span className={"text-muted"}>{wal?.id || ""}</span>
            </DeveloperModeOnly>
          </Button>
          {modalContent}
        </>
      );
    } else if (buttonType === "dropdownItem") {
      //if (wal?.uploadFilename) {
      //  text = wal.uploadFilename + " - " + text;
      //}
      return (
        <>
          <DropdownItem title={text} onClick={props.showModal}>
            <small data-testid={"jde-info-button"}>{text}</small>{" "}
            <DeveloperModeOnly>
              <span className={"text-muted"}>{wal?.id || ""}</span>
            </DeveloperModeOnly>
          </DropdownItem>
          {modalContent}
        </>
      );
    }
  },
);

export default WelltowerAccountingLoadEditButton;
