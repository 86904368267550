/** @format */
import _ from "lodash";
import HasTwoNames from "../types/HasTwoNames";
import { isWelltower } from "./OktaUtils";
import Requirement from "../types/Requirement";
import { formatDate, formatDateMonthYear } from "./DateUtils";
import RequirementDueDate from "../types/RequirementDueDate";
import WelltowerBusinessUnit from "../types/WelltowerBusinessUnit";

const externalName = (o: HasTwoNames) => o.externalName || o.name;
const welltowerName = (o: HasTwoNames) => o.name;
const getNameFunction = (userinfo: any) =>
  isWelltower(userinfo) ? welltowerName : externalName;
const decideName = (userinfo: any, o: HasTwoNames) => {
  if (isWelltower(userinfo)) return welltowerName(o);
  return externalName(o);
};

const describeFrequencyType = (r: Requirement) => {
  const type =
    r.documentType.name + (r.nonCompliantFormat ? " (non-compliant)" : "");
  if (_.startsWith(r.documentType.name, r.frequency.displayName)) return type;
  return `${r.frequency.displayName} ${type}`;
};
const describeDueDatePeriodEnd = (rdd: RequirementDueDate) => {
  if (rdd.requirement.frequency.name === "MONTHLY") {
    return formatDateMonthYear(rdd.periodEndDate);
  }
  return formatDate(rdd.periodEndDate);
};

const buPickerLabel = (bu: WelltowerBusinessUnit) =>
  bu.source?.name === "JDE"
    ? `${bu.code} ${bu.description} (${bu.vantageCode || "NPXX"}) ${bu.type}`
    : bu.source?.name === "YARDI"
      ? `${bu.code} ${bu.description} (Yardi)`
      : `${bu.code} ${bu.description}`;

const mappingSetLabel = (ms: any) =>
  ms.destination?.displayName
    ? `${ms.name} (${ms.destination.displayName})`
    : ms.name;

export {
  externalName,
  welltowerName,
  decideName,
  getNameFunction,
  describeFrequencyType,
  describeDueDatePeriodEnd,
  buPickerLabel,
  mappingSetLabel,
};
