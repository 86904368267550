/** @format */

import React from "react";
import {
  calculateEndMonthOptions,
  calculateStartMonthOptions,
} from "../../util/DateUtils";
import { Formik, FormikProps, FormikValues } from "formik";
import WrappedSelectInput from "../../components/form/WrappedSelectInput";
import { Button } from "reactstrap";
import WrappedTextInput from "../../components/form/WrappedTextInput";

const ComboMappingForm = (props: any) => {
  const {
    initialValues,
    welltowerAccounts,
    welltowerBusinessUnits,
    segmentCodes,
    onSubmitMapping,
    onDeleteMapping,
    currentStartDate,
    currentEndDate,
  } = props;
  const startMonthOptions = calculateStartMonthOptions(currentStartDate);
  const endMonthOptions = calculateEndMonthOptions(
    currentStartDate,
    currentEndDate,
  );
  return (
    <Formik
      initialValues={initialValues || {}}
      onSubmit={(values, actions) => onSubmitMapping(values, actions)}
      validate={(values: any) => {
        let errors: any = {};

        if (!values.entityIdentifier) {
          errors.entityIdentifier = "Please enter an Operator property.";
        }

        if (!values.accountIdentifier) {
          errors.accountIdentifier = "Please enter an Operator account.";
        }

        if (!values.welltowerBusinessUnitCode) {
          errors.welltowerBusinessUnitCode =
            "Please select a Welltower BU/Entity.";
        }

        if (!values.welltowerAccount) {
          errors.welltowerAccount = "Please select a Welltower account.";
        }

        if (!values.segmentCode && !!values.segmentValue) {
          errors.segmentCode = "Please enter a segment type.";
        }

        if (!!values.segmentCode && !values.segmentValue) {
          errors.segmentValue = "Please enter a segment value.";
        }

        if (
          values.welltowerAccount &&
          values.welltowerBusinessUnitCode &&
          (values.welltowerAccount.value === "IGNORE") !==
            (values.welltowerBusinessUnitCode.value === "IGNORE")
        ) {
          errors.welltowerAccount =
            "Both mappings should be provided or both should be set to IGNORE.";
          errors.welltowerBusinessUnitCode =
            "Both mappings should be provided or both should be set to IGNORE.";
        }
        if (!values.startMonth) {
          errors.startMonth = "Please enter a mapping period.";
        }

        return errors;
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => {
        return (
          <form onSubmit={formikProps.handleSubmit} name={"Combo Mapping Form"}>
            <div className="form-group">
              <WrappedTextInput
                id="entityIdentifier"
                required={true}
                label="Property"
                disabled={initialValues && !!initialValues.id}
              />
            </div>
            <div className="form-group">
              <WrappedTextInput
                id="accountIdentifier"
                required={true}
                label="Account"
                disabled={initialValues && !!initialValues.id}
              />
            </div>
            <div className="form-group">
              <WrappedSelectInput
                id={`welltowerBusinessUnitCode`}
                label={"Welltower BU/Entity"}
                value={
                  welltowerBusinessUnits
                    ? welltowerBusinessUnits.find(
                        (it: any) =>
                          it.value ===
                          formikProps.values.welltowerBusinessUnitCode,
                      )
                    : null
                }
                required={true}
                options={welltowerBusinessUnits}
                onChange={formikProps.setFieldValue}
                onBlur={formikProps.setFieldTouched}
              />
            </div>
            <div className="form-group">
              <WrappedSelectInput
                id={`welltowerAccount`}
                label={"Welltower Account"}
                value={
                  welltowerAccounts
                    ? welltowerAccounts.find(
                        (it: any) =>
                          it.value === formikProps.values.welltowerAccount,
                      )
                    : null
                }
                required={true}
                options={welltowerAccounts}
                onChange={formikProps.setFieldValue}
                onBlur={formikProps.setFieldTouched}
              />
            </div>
            <div className="form-group">
              <WrappedSelectInput
                id={`segmentCode`}
                label={"Segment"}
                value={segmentCodes?.find(
                  (it: any) => it.value === formikProps.values.segmentCode,
                )}
                options={segmentCodes}
                onChange={formikProps.setFieldValue}
                onBlur={(id, val) => {
                  formikProps.setFieldTouched(id, val);
                  formikProps.setFieldTouched("segmentValue", val);
                  // TODO - can we make this cleaner? And can we blur segmentCode when segmentValue is blurred? Otherwise you could enter a value but never touch code and Formik won't show you the validation error on code because it wasn't touched.
                  // And we can't touch the other field when validating
                }}
              />
            </div>
            <div className="form-group">
              <WrappedTextInput id="segmentValue" label="Segment Value" />
            </div>

            <div className="form-group">
              <WrappedSelectInput
                id={`startMonth`}
                label={"Start Month"}
                value={
                  startMonthOptions
                    ? startMonthOptions.find(
                        (it: any) => it.value === formikProps.values.startMonth,
                      )
                    : null
                }
                required={true}
                options={startMonthOptions}
                onChange={formikProps.setFieldValue}
                onBlur={formikProps.setFieldTouched}
              />
              <WrappedSelectInput
                id={`endMonth`}
                label={"End Month"}
                value={
                  endMonthOptions
                    ? endMonthOptions.find(
                        (it: any) => it.value === formikProps.values.endMonth,
                      )
                    : null
                }
                required={false}
                options={endMonthOptions}
                onChange={formikProps.setFieldValue}
                onBlur={formikProps.setFieldTouched}
              />
            </div>
            {initialValues &&
              initialValues.id &&
              !initialValues.firstSeen &&
              !initialValues.lastSeen && (
                <Button
                  color={"danger"}
                  onClick={() => {
                    onDeleteMapping(initialValues);
                  }}
                  className={"float-left wtop-btn"}
                >
                  Delete
                </Button>
              )}
            <Button
              type={"submit"}
              color={"primary"}
              disabled={!formikProps.isValid || formikProps.isSubmitting}
              className={"float-right wtop-btn"}
            >
              Submit
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
export default ComboMappingForm;
