/** @format */

import WithModal from "../../components/WithModal";
import React, { useState } from "react";
import { Button, Col, FormFeedback, Input, Row } from "reactstrap";
import ToggleSwitch from "../../components/toggle-switch/ToggleSwitch";

/** @format */

const OperatorSettingsEditButton = WithModal(
  ({
    ModalContent,
    label,
    type,
    initialValue,
    onSave,
    ...props
  }: {
    auth: any;
  } & Partial<any>) => {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState<string>("");
    let renderedField = null;
    switch (type) {
      case "boolean":
        renderedField = (
          <ToggleSwitch
            isChecked={value}
            onValueChanged={(newVal: any) => {
              setValue(newVal);
            }}
          />
        );
        break;
      case "number":
      case "string":
        renderedField = (
          <>
            <Input
              type="text"
              placeholder=""
              value={value}
              invalid={!!error}
              onChange={(e: any) => {
                const newVal = e.target.value;
                const required = true;
                if (required && !newVal && newVal !== 0) {
                  setError("Required");
                } else if (type === "number" && isNaN(newVal)) {
                  setError("A number is required");
                } else {
                  setError("");
                }
                setValue(newVal);
              }}
            />
            {error && <FormFeedback>{error}</FormFeedback>}
          </>
        );
        break;
      default:
        break;
    }

    return (
      <div>
        <Button
          color={"primary"}
          className="wtop-btn"
          onClick={props.showModal}
        >
          Edit
        </Button>
        {props.modalContent(
          <div className={"editForm"}>
            <h2>Edit Field</h2>
            <Row className="p-3 pb-4">
              <Col xs={12} sm={9}>
                {label}
              </Col>
              <Col xs={12} sm={3}>
                {renderedField}
              </Col>
            </Row>

            <Button
              type={"submit"}
              color={"primary"}
              disabled={props.isSubmitting}
              className={"float-right wtop-btn"}
              onClick={() => {
                if (!error) {
                  props.closeModal();
                  onSave(value);
                }
              }}
            >
              Save
            </Button>
          </div>,
          {
            showCloseIcon: true,
            modalStyles: { width: "600px" },
            closeOnOverlayClick: false,
          },
        )}
      </div>
    );
  },
);

export default OperatorSettingsEditButton;
