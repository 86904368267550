/** @format */
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom-v5-compat";

// Custom wrapper just to re-inject some props that rr6 stops providing
const WithRouterCompat = (ComposedComponent: any) => {
  // This seems a little confusing... But maybe it's better to contain the complexity here.
  const RouterWrapperComponent = (props: any) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    return (
      <ComposedComponent
        {...props}
        location={location}
        match={{ params }}
        history={{ push: (to: any) => navigate(to) }} // TODO - old AccountantOperatorRddsPageOld may be the only one using this
      />
    );
  };

  return RouterWrapperComponent;
};

export default WithRouterCompat;
