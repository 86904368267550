/** @format */
import { useDeveloperMode } from "../../DeveloperMode";
import { components } from "react-select";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../favorite-toggle/FavoriteToggle.scss";

export const PickerSelectOption = (
  //props: OptionProps<DocumentTypeChoice> &
  //   Partial<{
  //     enableAllAndFavorites: boolean;
  //     onAddFavorite: any;
  //     onDeleteFavorite: any;
  //   }>
  props: any,
) => {
  const isDeveloperMode = useDeveloperMode();
  if (props.data.value === "DIVIDER") {
    return <hr />;
  }
  const itemId = props.data.obj?.id || 0;
  const favorite =
    props.data.obj && props.selectProps.getFavorite(props.data.obj);
  return (
    <div className={"d-flex"}>
      {props.data.obj && props.selectProps.enableFavorites && (
        <div
          onClick={(event) => {
            event.stopPropagation();
            if (favorite) {
              props.selectProps.onDeleteFavorite(itemId);
            } else {
              props.selectProps.onAddFavorite(itemId);
            }
            event.preventDefault();
          }}
          className={`favorite-toggle ${
            favorite ? "favorite" : "non-favorite"
          }`}
          style={{}}
        >
          <FontAwesomeIcon
            icon={[favorite ? "fas" : "far", "star"]}
            title={"Toggle favorite."}
          />
        </div>
      )}
      <components.Option {...props}>
        {isDeveloperMode ? (
          <div className={"d-flex justify-content-between"}>
            <span>{props.children}</span>
            {props.data.obj && Number.isInteger(props.data.obj.id) && (
              <span className={"text-muted"}>{props.data.obj.id}</span>
            )}
          </div>
        ) : (
          props.children
        )}
      </components.Option>
    </div>
  );
};
