/** @format */

import React from "react";
import { Field, Formik, FormikProps, ErrorMessage, Form } from "formik";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  formatDate,
  formatDateMonthYear,
  parseDate,
} from "../../util/DateUtils";
import WrappedDateInput from "../form/WrappedDateInput";
import {
  deleteFileJDEBatch,
  saveFileJDEBatch,
  updateFileJDEBatch,
} from "../../api/SubmittedFileAPI";
import { canDeleteJdeInfo, getUserLogin } from "../../util/OktaUtils";
import { welltowerName } from "../../util/NameUtils";
import WrappedCheckboxInput from "../form/WrappedCheckboxInput";
import { recordsBatchNumber } from "../../util/StatusUtils";
import "./SubmittedFileComponent.scss";
import WelltowerAccountingLoad from "../../types/WelltowerAccountingLoad";

export default class EditJDEInfoForm extends React.Component<any, any> {
  static defaultProps = {
    uploadIdentifier: "",
    glDate: "",
  };

  onSaveJDEInfo = async (data: any, callback: Function) => {
    let payload = {
      uploadIdentifier: data.uploadIdentifier,
      glDate: parseDate(data.glDate),
      uploadTime: new Date(),
      uploadUserId: getUserLogin(this.props.authState),
      status: data.reversed ? "REVERSED" : data.failed ? "FAILED" : "SUCCESS",
    };

    try {
      if (this.props.jdeInfoId) {
        await updateFileJDEBatch(
          payload as WelltowerAccountingLoad,
          this.props.file.requirementDueDate.id,
          this.props.file.id,
          this.props.jdeInfoId,
          this.props.authState,
        );
      } else {
        await saveFileJDEBatch(
          payload as WelltowerAccountingLoad,
          this.props.file.requirementDueDate.id,
          this.props.file.id,
          this.props.authState,
        );
      }
      callback && callback.call(null);
      this.props.onSaveJDEInfo();
    } catch (err) {
      console.error(err);
    }
  };

  deleteJDEInfo = async (callback?: Function) => {
    try {
      let data;
      if (this.props.jdeInfoId) {
        data = await deleteFileJDEBatch(
          this.props.file.requirementDueDate.id,
          this.props.file.id,
          this.props.jdeInfoId,
          this.props.authState,
        );
      }
      // TODO - have the delete return the RDD, then pass back to callback, then have onSaveJDEInfo pass to BaseRddCard's this.props.receiveUpdatedRequirementDueDate(data, true); this.loadFiles(data, undefined, true);
      callback && callback.call(null);
      this.props.onSaveJDEInfo(data);
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    let { uploadIdentifier, glDate, file, jdeInfoId, failed, reversed } =
      this.props;

    const requireBatchNumber = recordsBatchNumber(
      file.requirementDueDate.requirement.documentType,
    );
    const canDeleteLoad = canDeleteJdeInfo(
      this.props.authState,
      file.requirementDueDate.requirement.operator,
      file.requirementDueDate.requirement,
    );
    return (
      <Formik
        initialValues={{ uploadIdentifier, glDate, failed, reversed }}
        onSubmit={(values, actions) => {
          this.onSaveJDEInfo(values, () => actions.setSubmitting(false));
        }}
        validateOnBlur={false}
        validate={(values: any) => {
          let errors: any = {};

          if (
            requireBatchNumber &&
            !values.uploadIdentifier &&
            !values.failed
          ) {
            errors.uploadIdentifier = `Please enter a ${
              file.destination?.displayName || ""
            } batch number.`;
          }

          if (Object.keys(errors).length > 0) {
            return errors;
          } else {
            return {};
          }
        }}
      >
        {(props: FormikProps<any>) => {
          return (
            <div className={"editForm edit-jdeinfo-form"}>
              <h2 className="propertyName">
                {welltowerName(file.requirementDueDate.requirement.operator)}
              </h2>

              <Form className="editFormForm">
                <Row>
                  <Col>
                    <i>
                      {formatDateMonthYear(
                        file.requirementDueDate.periodEndDate,
                      )}{" "}
                      {welltowerName(file.requirementDueDate.requirement)}
                    </i>
                    <br />
                    <i>Due {formatDate(file.requirementDueDate.dueDate)}</i>
                  </Col>
                </Row>
                <Row className={"mb-4"}>
                  <Col>
                    <i>{`${
                      file.originalFilename
                    }, Submitted to Portal: ${formatDate(file.uploadTime)}`}</i>
                  </Col>
                </Row>
                {requireBatchNumber && (
                  <div className="form-group">
                    <label
                      htmlFor={`uploadIdentifier`}
                      className={"label-required"}
                    >
                      {file.destination?.displayName || ""} Batch #
                    </label>
                    <Field
                      name={`uploadIdentifier`}
                      type={"text"}
                      className={"form-control"}
                    />
                    <ErrorMessage
                      name={"uploadIdentifier"}
                      component={"div"}
                      className={"input-feedback"}
                    />
                  </div>
                )}
                <div className="form-group">
                  <WrappedDateInput
                    id={"glDate"}
                    label={"General Ledger Date"}
                    value={props.values.glDate}
                    required={false}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                  />
                </div>
                <div className="form-group">
                  <WrappedCheckboxInput id="failed" label="Batch failed?" />
                </div>

                {jdeInfoId && canDeleteLoad && (
                  <Button
                    onClick={() => this.deleteJDEInfo()}
                    color={"danger"}
                    disabled={props.isSubmitting}
                  >
                    <FontAwesomeIcon
                      icon={["far", "trash-alt"]}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Button>
                )}

                <Button
                  type={"submit"}
                  color={"primary"}
                  disabled={props.isSubmitting}
                  className={"float-right wtop-btn"}
                  onClick={() => {
                    props.handleSubmit();
                  }}
                >
                  Save Updates
                </Button>
              </Form>
            </div>
          );
        }}
      </Formik>
    );
  }
}
