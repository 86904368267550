/** @format */

import React from "react";
import { Alert } from "reactstrap";

const RddCardAlert = ({
  success,
  error,
  warning,
  toggleAlertVisibility,
}: {
  success: any | null;
  error: any | null;
  warning: any | null;
  toggleAlertVisibility: () => void;
}) => {
  if (!error && !success && !warning) {
    return null;
  }
  let color = "";
  let alertMessage = "";
  if (error) {
    color = "danger";
    alertMessage = error?.message ?? error;
  } else if (success) {
    color = "success";
    alertMessage = success?.message ?? success;
  } else if (warning) {
    color = "warning";
    alertMessage = warning?.message ?? warning;
  }
  return (
    <Alert
      color={color}
      isOpen={true}
      toggle={toggleAlertVisibility}
      className={"border-0 rounded-0"}
    >
      {alertMessage}
    </Alert>
  );
};

export default RddCardAlert;
