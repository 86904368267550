/** @format */

import React from "react";
import { Button, Col, Row } from "reactstrap";
import SubmissionStatus from "../../types/SubmissionStatus";
import {
  hasAwaitingETLStatus,
  hasCancelableRPAStatus,
  hasFailureStatus,
  hasJDEStatus,
  hasNoFileStatus,
} from "../../util/StatusUtils";
import RddRetractFileButton from "./RddRetractFileButton";
import SubmittedFile from "../../types/SubmittedFile";
import {
  AbstractAuthState,
  canApproveRPA,
  canRetractFiles,
  canUploadFiles,
  isWelltower,
} from "../../util/OktaUtils";
import Operator from "../../types/Operator";
import { commaConjunction } from "../../util/CollectionUtils";
import RequirementDueDate from "../../types/RequirementDueDate";

export const RddLockedMessage = ({ isWelltower }: { isWelltower: boolean }) => (
  <p className={"locked-message text-muted"}>
    This requirement period has been locked.
    {!isWelltower &&
      " Please contact your Welltower accountant to submit additional files."}
  </p>
);

const RddCallToAction = ({
  previousUploadStatus,
  locked,
  onOpenFileBrowser,
  retractFile,
  undoRPA,
  latestFile,
  operator,
  authState,
  requirementDueDate,
}: {
  previousUploadStatus: SubmissionStatus;
  onOpenFileBrowser: any;
  retractFile: any;
  undoRPA?: any;
  latestFile?: SubmittedFile;
  locked: boolean;
  operator: Operator;
  authState: AbstractAuthState;
  requirementDueDate: RequirementDueDate;
}) => {
  let btn = null;
  if (locked) {
    return !isWelltower(authState) ? (
      <Row className={"mb-4"}>
        <Col className={"text-center"}>
          <RddLockedMessage isWelltower={isWelltower(authState)} />
        </Col>
      </Row>
    ) : null;
  }
  const uploadMethod = requirementDueDate.requirement.documentType.uploadMethod;

  const canUpload = canUploadFiles(
    authState,
    operator,
    requirementDueDate.requirement,
  ); // TODO and is not awaiting RPA? Or is that okay for WELL because the operator could also upload a new file while ETL is in flight?
  const canRetract =
    canRetractFiles(authState, operator, requirementDueDate.requirement) &&
    latestFile &&
    !hasJDEStatus(latestFile.status) &&
    !hasAwaitingETLStatus(latestFile.status) &&
    uploadMethod.deleteable;

  // TODO - remove after testing
  const canUndoRPA =
    canApproveRPA(authState, operator, requirementDueDate.requirement) &&
    latestFile &&
    hasCancelableRPAStatus(latestFile.status);

  if (!canUpload && !canRetract && !canUndoRPA) return null;

  if (
    hasNoFileStatus(previousUploadStatus) ||
    hasFailureStatus(previousUploadStatus)
  ) {
    btn = canUpload && (
      <Button
        block
        className={"py-3"}
        color={"primary"}
        onClick={onOpenFileBrowser}
      >
        UPLOAD
      </Button>
    );
  } else {
    let actionLinks = [];
    if (canRetract) {
      actionLinks.push(
        <RddRetractFileButton
          retractFile={retractFile}
          file={latestFile}
          text={"Retract this file"}
          key={"retract"}
        />,
      );
    }
    if (canUpload) {
      actionLinks.push(
        <Button
          color={"link"}
          className={"px-0"}
          onClick={onOpenFileBrowser}
          key={"upload"}
        >
          Upload a New File
        </Button>,
      );
    }
    if (canUndoRPA) {
      let cancelText = "Cancel Bot";
      if (latestFile?.destination?.name === "SNOWFLAKE_PULL") {
        cancelText = "Cancel Snowflake pull";
      }
      actionLinks.push(
        <Button
          color={"link"}
          onClick={() => undoRPA(latestFile)}
          className={"px-0"}
          data-testid={"retract-file-button"}
          key={"cancelRPA"}
        >
          {cancelText}
        </Button>,
      );
    }

    btn =
      actionLinks.length > 0 ? (
        <div className={"operator-upload-btn text-muted"}>
          {`You can ${actionLinks.length === 2 ? "either " : ""}`}
          {commaConjunction(actionLinks, "or")}
        </div>
      ) : null;
  }

  return btn ? (
    <Row className={"mb-4"}>
      <Col sm="4" className={"text-center offset-sm-4"}>
        {btn}
      </Col>
    </Row>
  ) : null;
};

export default RddCallToAction;
