/** @format */
import React from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import OperatorSubmissionStats from "./OperatorSubmissionStats";
import _ from "lodash";
import { welltowerStatusBucket } from "../../util/StatusUtils";
import Portfolio from "../../types/Portfolio";
import RequirementDueDateDashboardDto from "../../types/RequirementDueDateDashboardDto";
import { formatDateMonthDay } from "../../util/DateUtils";
import ReportingPriority from "../../types/ReportingPriority";
import Country from "../../types/Country";

interface IDocumentTypeSubmissionStatsProps {
  rdds: Array<RequirementDueDateDashboardDto>;
  portfolioOptions: Array<Portfolio>;
  countryOptions: Array<Country>;
  reportingPriorityOptions: Array<ReportingPriority>;
}

export default class DocumentTypeSubmissionStats extends React.Component<
  IDocumentTypeSubmissionStatsProps,
  any
> {
  constructor(props: IDocumentTypeSubmissionStatsProps) {
    super(props);
    this.state = {
      query: "",
      status: undefined,
      sortReverse: false,
      sort: undefined,
      portfolio: undefined,
      periodEndDateFilter: undefined,
    };
  }

  render() {
    const { rdds, portfolioOptions, countryOptions, reportingPriorityOptions } =
      this.props;
    if (!rdds || rdds.length === 0)
      return (
        <Row>
          <Col>
            <Card>
              <CardBody>No requirements match the selected criteria.</CardBody>
            </Card>
          </Col>
        </Row>
      );
    const {
      query,
      status,
      sort,
      sortReverse,
      portfolio,
      country,
      reportingPriority,
      periodEndDateFilter,
    } = this.state;
    const filteredRdds =
      _.filter(rdds, (o) => {
        const matchesName = o.operatorName
          .toLowerCase()
          .includes(query.toLowerCase());
        const matchesExternalName = o.operatorExternalName
          ?.toLowerCase()
          ?.includes(query.toLowerCase());
        const matchesStatus =
          !status || welltowerStatusBucket(o.status).label === status;
        const matchesPortfolio =
          !portfolio ||
          (portfolio === "null" && !o.portfolio) ||
          (o.portfolio && o.portfolio.name) === portfolio;
        const matchesCountry =
          !country ||
          (country === "null" && !o.country) ||
          (o.country && o.country.name) === country;
        const matchesReportingPriority =
          !reportingPriority ||
          (reportingPriority === "null" && !o.reportingPriority) ||
          (o.reportingPriority && o.reportingPriority.name) ===
            reportingPriority;

        const matchPeriodEndDate =
          !periodEndDateFilter || o.periodEndDate === periodEndDateFilter;

        return (
          (matchesExternalName || matchesName) &&
          matchesStatus &&
          matchesPortfolio &&
          matchesCountry &&
          matchesReportingPriority &&
          matchPeriodEndDate
        );
      }) || [];
    const rddsByOperator = _.groupBy(filteredRdds, "operatorName");
    const operators = Object.keys(rddsByOperator).sort();

    const allBucketObjs =
      rdds &&
      _.uniqBy(
        rdds.map((rdddDto) => welltowerStatusBucket(rdddDto.status)),
        "label",
      );
    const allBucketLabels = _.sortBy(allBucketObjs, "order").map(
      (o) => o.label,
    );

    const allPortfolios = [
      { value: "", label: "All Portfolios" },
      { value: "null", label: "(blank)" },
    ].concat(
      portfolioOptions.map((l) => ({
        value: l.name,
        label: l.displayName,
      })),
    );

    const allCountries = [
      { value: "", label: "All Countries" },
      { value: "null", label: "(blank)" },
    ].concat(
      countryOptions.map((l) => ({
        value: l.name,
        label: l.displayName,
      })),
    );

    const allReportingPriorities = [
      { value: "", label: "All Priorities" },
      { value: "null", label: "(blank)" },
    ].concat(
      reportingPriorityOptions.map((l) => ({
        value: l.name,
        label: l.displayName,
      })),
    );

    const periodEndDates =
      rdds &&
      _.sortBy(_.uniq(rdds.map((rdd) => rdd.periodEndDate))).map((p) => ({
        value: p,
        label: formatDateMonthDay(p),
      }));

    const periodEndDateOptions: any = [{ value: "", label: "All Dates" }];
    if (periodEndDates) {
      periodEndDateOptions.push(...periodEndDates);
    }

    const bucketObjs =
      rdds &&
      _.uniqBy(
        filteredRdds.map((rdddDto) => welltowerStatusBucket(rdddDto.status)),
        "label",
      );
    const bucketLabels = _.sortBy(bucketObjs, "order").map((o) => o.label);
    const rddsByBucket = _.groupBy(
      rdds,
      (rdddDto) => welltowerStatusBucket(rdddDto.status).label,
    );
    const hasRequirements = rdds && rdds.length > 0;

    const byOperatorsByBucket = operators.reduce((m: any, o) => {
      m[o] = _.groupBy(
        rddsByOperator[o],
        (rdddDto) => welltowerStatusBucket(rdddDto.status).label,
      );
      return m;
    }, {});

    const sortOrder =
      !sort || sort === "due"
        ? sortReverse
          ? "desc"
          : "asc"
        : sortReverse
          ? "asc"
          : "desc";
    const sortIcon =
      sortOrder === "asc" ? (
        <FontAwesomeIcon icon={"sort-up"} style={{ marginLeft: "5px" }} />
      ) : (
        <FontAwesomeIcon icon={"sort-down"} style={{ marginLeft: "5px" }} />
      );
    const sortedOperators = _.orderBy(
      operators,
      [
        (o) => {
          switch (sort) {
            case undefined:
              return o;
            case "due":
              return _.min(rddsByOperator[o].map((rdddDto) => rdddDto.dueDate));
            case "length":
              return rddsByOperator[o].length;
            case "locked":
              return rddsByOperator[o].filter((rdddDto) => rdddDto.locked)
                .length;
            default:
              return byOperatorsByBucket[o][sort]
                ? byOperatorsByBucket[o][sort].length
                : 0;
          }
        },
      ],
      [sortOrder],
    );

    const sortClick = (newSort?: string) => {
      let newSortReverse;
      if (sort === newSort) {
        newSortReverse = !sortReverse;
      } else {
        newSortReverse = false;
      }
      this.setState({ sort: newSort, sortReverse: newSortReverse });
    };
    return (
      <>
        <Row className={"status-tile-container"}>
          <Col>
            {allBucketLabels.map((bucket) => (
              <div key={bucket} className={"overall-status-tile"}>
                <h5>{bucket}</h5>
                <h3>{rddsByBucket[bucket].length}</h3>
              </div>
            ))}
          </Col>
        </Row>
        {hasRequirements && (
          <Row>
            <Col sm="2">
              <InputGroup>
                <Input
                  onChange={(e: any) => {
                    this.setState({ query: e.target.value });
                  }}
                  placeholder={"Operator"}
                  title={"Search"}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <FontAwesomeIcon icon={"search"} title={"Search"} />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col sm={"2"}>
              <Select
                id={"status-select"}
                options={[{ value: "", label: "All Statuses" }].concat(
                  allBucketLabels.map((l) => ({ value: l, label: l })),
                )}
                onChange={(e: any) => {
                  this.setState({ status: e.value });
                }}
                value={
                  [this.state.status].map((l) => ({
                    value: l,
                    label: l || "All Statuses",
                  }))[0]
                }
              />
            </Col>
            <Col sm={"2"}>
              <Select
                id={"portfolio-select"}
                options={allPortfolios}
                onChange={(e: any) => {
                  this.setState({ portfolio: e.value });
                }}
                value={allPortfolios.find(
                  (a: any) => a.value === (this.state.portfolio || ""),
                )}
              />
            </Col>
            <Col sm={"2"}>
              <Select
                id={"country-select"}
                options={allCountries}
                onChange={(e: any) => {
                  this.setState({ country: e.value });
                }}
                value={allCountries.find(
                  (a: any) => a.value === (this.state.country || ""),
                )}
              />
            </Col>
            <Col sm={"2"}>
              <Select
                id={"priority-select"}
                options={allReportingPriorities}
                onChange={(e: any) => {
                  this.setState({ reportingPriority: e.value });
                }}
                value={allReportingPriorities.find(
                  (a: any) => a.value === (this.state.reportingPriority || ""),
                )}
              />
            </Col>
            {periodEndDateOptions &&
              periodEndDateOptions.length > 2 && ( //only weekly and other will have more than 1 period
                <Col sm={"2"}>
                  <Select
                    id={"period-select"}
                    options={periodEndDateOptions}
                    onChange={(e: any) => {
                      this.setState({ periodEndDateFilter: e.value });
                    }}
                    value={periodEndDateOptions.find(
                      (a: any) =>
                        a.value === (this.state.periodEndDateFilter || ""),
                    )}
                  />
                </Col>
              )}
          </Row>
        )}
        {hasRequirements && (
          <Row className={"operator-grid-header"}>
            <Col
              md={"2"}
              onClick={() => sortClick()}
              style={{ cursor: "pointer" }}
            >
              Operator Name
              {!sort && sortIcon}
            </Col>
            <Col onClick={() => sortClick("due")} style={{ cursor: "pointer" }}>
              Due
              {sort === "due" && sortIcon}
            </Col>
            <Col
              onClick={() => sortClick("length")}
              style={{ cursor: "pointer" }}
            >
              Reqs
              {sort === "length" && sortIcon}
            </Col>
            <Col
              onClick={() => sortClick("locked")}
              style={{ cursor: "pointer" }}
            >
              Locked
              {sort === "locked" && sortIcon}
            </Col>
            {bucketLabels.map((bucket) => (
              <Col
                key={bucket}
                onClick={() => sortClick(bucket)}
                style={{ cursor: "pointer" }}
              >
                {bucket}
                {sort === bucket && sortIcon}
              </Col>
            ))}
          </Row>
        )}
        {sortedOperators &&
          sortedOperators.map((op) => (
            <OperatorSubmissionStats
              requirementDueDates={rddsByOperator[op]}
              bucketLabels={bucketLabels}
              byBucket={byOperatorsByBucket[op]}
              operatorName={op}
              key={_.uniqueId("op-stats")}
            />
          ))}
      </>
    );
  }
}
