/** @format */

const unmappedAccountsByOperatorUrl = (operatorId: number) =>
  `/api/operators/${operatorId}/unmappedAccounts`;

const unmappedEntitiesByOperatorUrl = (operatorId: number) =>
  `/api/operators/${operatorId}/unmappedEntities`;

const accountMappingSetsUrl = (operatorId: number) =>
  `api/operators/${operatorId}/accountMappingSets`;

const accountMappingsUrl = (operatorId: number, accountMappingSetId: number) =>
  `api/operators/${operatorId}/accountMappingSets/${accountMappingSetId}/mappings`;

const accountMappingsOperatorCaptionUrl = (
  operatorId: number,
  accountMappingSetId: number,
) =>
  `api/operators/${operatorId}/accountMappingSets/${accountMappingSetId}/chartOfAccountCaptions`;

const mappingSetMappableAccountsUrl = (
  operatorId: number,
  accountMappingSetId: number,
) =>
  `api/operators/${operatorId}/accountMappingSets/${accountMappingSetId}/mappableAccounts`;

const comboMappingSetMappableAccountsUrl = (
  operatorId: number,
  accountMappingSetId: number,
) =>
  `api/operators/${operatorId}/comboMappingSets/${accountMappingSetId}/mappableAccounts`;

const comboMappingSetSegmentCodesUrl = (
  operatorId: number,
  accountMappingSetId: number,
) =>
  `api/operators/${operatorId}/comboMappingSets/${accountMappingSetId}/segmentCodes`;

const segmentMappingSetMappableAccountsUrl = (
  operatorId: number,
  accountMappingSetId: number,
) => `api/segmentMappingSets/${accountMappingSetId}/mappableAccounts`;

const segmentMappingSetSegmentCodesUrl = (
  operatorId: number,
  accountMappingSetId: number,
) => `api/segmentMappingSets/${accountMappingSetId}/segmentCodes`;

const deleteAccountMappingsUrl = (
  operatorId: number,
  accountMappingSetId: number,
  mappingId: number,
) =>
  `api/operators/${operatorId}/accountMappingSets/${accountMappingSetId}/mappings/${mappingId}`;

const accountMappingSetRequirementsUrl = (
  operatorId: number,
  accountMappingSetId: number,
) =>
  `api/operators/${operatorId}/accountMappingSets/${accountMappingSetId}/requirements`;

const chartOfAccountsCaptionsUrl = () =>
  "api/chartsOfAccountsCaptions/downloadToken";

const entityMappingSetsUrl = (operatorId: number) =>
  `api/operators/${operatorId}/entityMappingSets`;

const entityMappingsUrl = (operatorId: number, entityMappingSetId: number) =>
  `api/operators/${operatorId}/entityMappingSets/${entityMappingSetId}/mappings`;

const deleteEntityMappingsUrl = (
  operatorId: number,
  entityMappingSetId: number,
  mappingId: number,
) =>
  `api/operators/${operatorId}/entityMappingSets/${entityMappingSetId}/mappings/${mappingId}`;

const entityMappingSetRequirementsUrl = (
  operatorId: number,
  entityMappingSetId: number,
) =>
  `api/operators/${operatorId}/entityMappingSets/${entityMappingSetId}/requirements`;

const comboMappingSetsUrl = (operatorId: number) =>
  `api/operators/${operatorId}/comboMappingSets`;

const comboMappingsUrl = (operatorId: number, comboMappingSetId: number) =>
  `api/operators/${operatorId}/comboMappingSets/${comboMappingSetId}/mappings`;

const deleteComboMappingsUrl = (
  operatorId: number,
  comboMappingSetId: number,
  mappingId: number,
) =>
  `api/operators/${operatorId}/comboMappingSets/${comboMappingSetId}/mappings/${mappingId}`;

const comboMappingSetRequirementsUrl = (
  operatorId: number,
  comboMappingSetId: number,
) =>
  `api/operators/${operatorId}/comboMappingSets/${comboMappingSetId}/requirements`;

const operatorMappingsUpdateUrl = (operatorId: number) =>
  `/api/operators/${operatorId}/unmappedItems`;

const operatorsUrl = () => `/api/operators`;

const updateOperatorUrl = (operatorId: number) =>
  `/api/operators/${operatorId}`;

const adHocTokensUrl = () => `/api/adHocTokens`;

const updateAdHocTokenUrl = (tokenId: number) => `/api/adHocTokens/${tokenId}`;

const buSettingsUrl = (operatorId: number) =>
  `/api/welltowerBusinessUnitSettings/operator/${operatorId}`;

const updateBuSettingUrl = (buCode: String) =>
  `/api/welltowerBusinessUnitSettings/buCode/${buCode}`;

const buSettingsVantageCapacityUrl = (vantageCode: String) =>
  `/api/welltowerBusinessUnitSettings/vantageCapacity/${vantageCode}`;

export {
  accountMappingSetsUrl,
  accountMappingsUrl,
  accountMappingSetRequirementsUrl,
  entityMappingSetsUrl,
  entityMappingsUrl,
  entityMappingSetRequirementsUrl,
  comboMappingSetsUrl,
  comboMappingsUrl,
  comboMappingSetRequirementsUrl,
  unmappedAccountsByOperatorUrl,
  unmappedEntitiesByOperatorUrl,
  operatorMappingsUpdateUrl,
  operatorsUrl,
  updateOperatorUrl,
  adHocTokensUrl,
  updateAdHocTokenUrl,
  buSettingsUrl,
  updateBuSettingUrl,
  buSettingsVantageCapacityUrl,
  deleteAccountMappingsUrl,
  deleteEntityMappingsUrl,
  deleteComboMappingsUrl,
  accountMappingsOperatorCaptionUrl,
  mappingSetMappableAccountsUrl,
  comboMappingSetMappableAccountsUrl,
  comboMappingSetSegmentCodesUrl,
  segmentMappingSetMappableAccountsUrl,
  segmentMappingSetSegmentCodesUrl,
  chartOfAccountsCaptionsUrl,
};
