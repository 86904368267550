/** @format */
import React, { useState } from "react";
import { useOktaAuth, withOktaAuth } from "@okta/okta-react";
import { canViewReports } from "../../util/OktaUtils";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { downloadUrl } from "../../util/OneTimeDownloader";
import DatePicker from "react-date-picker";
import DatePicker2, { ReactDatePickerProps } from "react-datepicker";
import {
  parseDate,
  formatDateISOMonthYear,
  formatDateISO,
} from "../../util/DateUtils";
import { getAllOperators } from "../../api/OperatorAPI";
import dayjs from "dayjs";
import Select from "react-select";
import { fetchJsonWithToken } from "../../util/FetchUtils";
import Portfolio from "../../types/Portfolio";
import { LoadingSpinner } from "../../components/LoadingSpinners";
import { StandaloneDocumentTypePicker } from "../../components/filters/ContextDocumentTypePicker";
import {
  DocumentTypePickerId,
  OperatorPickerId,
} from "../../types/PickerTypes";
import { StandaloneOperatorPicker } from "../../components/filters/ContextOperatorPicker";
import { selectTheme } from "../../components/form/WrappedSelectInput";
import OperatorPickerClassic from "../../components/filters/OperatorPickerClassic";

// remove hints once we start using this component
// eslint-disable-next-line
const MonthPickerNew = ({
  name,
  onChange,
  selected,
}: Partial<ReactDatePickerProps>) => (
  <DatePicker2
    name={name}
    className="form-control"
    dateFormat="MM/yyyy"
    showMonthYearPicker
    showFullMonthYearPicker
    // onChange={(val: Date) => {
    //   tbOperatorRow.periodEndDate = val;
    //   this.setState({ tbOperatorRow });
    // }}
    // selected={parseDate(tbOperatorRow.periodEndDate)}
    onChange={onChange!}
    selected={selected}
  />
);

const FileErrorsReportRow = () => {
  const { authState } = useOktaAuth();
  const downloadTokenLink = (monthEnd: string, documentType: string) =>
    `api/report/fileErrorReport/${monthEnd}/${documentType}/downloadToken`;

  const [loading, setLoading] = useState(false);
  const [monthEnd, setMonthEnd] = useState(new Date()); // TODO - default to last month
  const [documentType, setDocumentType] =
    useState<DocumentTypePickerId>("TRIAL_BALANCE");

  return (
    <>
      <Row className={"pt-3"}>
        <Col xs={2} />
        <Col xs={3} />
        <Col xs={3}>
          <label htmlFor={"fileErrors-month"}>Period</label>
        </Col>
        <Col xs={2} />
        <Col xs={2} />
      </Row>
      <Row className={"pb-3"}>
        <Col xs={2}>
          <strong style={{ verticalAlign: "middle" }}>File Warnings</strong>
        </Col>
        <Col xs={3}>
          <StandaloneDocumentTypePicker
            selectedId={documentType}
            onSelectionChange={(selected, selection) => {
              setDocumentType(selected);
            }}
            requireChoice={true}
          />
        </Col>
        <Col xs={3}>
          <div
            style={{
              maxWidth: "10em",
              display: "inline-block",
            }}
          >
            <MonthPickerNew
              name="period-date-tbOperatorRow"
              onChange={(val: Date) => {
                setMonthEnd(val);
              }}
              selected={monthEnd}
              isClearable={false}
            />
          </div>
        </Col>
        <Col xs={2}>
          <small>All loaded files</small>
        </Col>
        <Col xs={2}>
          <Button
            color={"primary"}
            onClick={() => {
              if (monthEnd && documentType) {
                downloadUrl(
                  authState,
                  downloadTokenLink(
                    formatDateISOMonthYear(monthEnd) || "",
                    documentType,
                  ),
                  { setLoading },
                );
              }
            }}
            disabled={loading}
            className={"wtop-btn"}
          >
            {loading ? <LoadingSpinner /> : "Download"}
          </Button>
        </Col>
      </Row>
    </>
  );
};

const RequirementCollectionReportRow = () => {
  const { authState } = useOktaAuth();
  const [startDate, setStartDate] = useState<Date>(
    dayjs().date(1).subtract(1, "month").toDate(),
  );
  const [endDate, setEndDate] = useState<Date>();
  const [operator, setOperator] = useState<OperatorPickerId>();
  const [includeFileDetail, setIncludeFileDetail] = useState(false);
  const [loading, setLoading] = useState(false);

  const downloadTokenLink = (
    startDate: string,
    endDate: string,
    operatorId: OperatorPickerId,
    includeFileDetail: boolean,
    timezone: string | undefined,
  ) => {
    let link = `/api/requirementDueDates/collectionReport/${startDate}/${endDate}/downloadToken`;
    let params = "";
    if (typeof operatorId === "number") {
      params += `&operator=${operatorId}`;
    }
    if (timezone) {
      params += `&timezone=${encodeURIComponent(timezone)}`;
    }
    if (includeFileDetail) {
      params += `&includeFileDetail=true`;
    }
    return link + (params ? `?${params}` : "");
  };

  return (
    <>
      <Row className={"pt-3"}>
        <Col xs={2} />
        <Col xs={3}>
          <label htmlFor={"operatorCollectionReportRow-operator-picker"}>
            Operator
          </label>
        </Col>
        <Col xs={5}>
          <label htmlFor={"start-date-operatorCollectionReportRow"}>
            Period
          </label>
        </Col>
        <Col xs={2}></Col>
      </Row>
      <Row className={"pb-3"}>
        <Col xs={2}>
          <strong style={{ verticalAlign: "middle" }}>
            Requirement Collection Report
          </strong>
        </Col>
        <Col xs={3}>
          <StandaloneOperatorPicker
            id="operatorCollectionReportRow-operator-picker"
            onSelectionChange={(selected, selection) => {
              setOperator(selected);
            }}
            requireChoice
            showAllChoice
            default={"NOT_SELECTED"}
          />
        </Col>
        {/*
        <Col xs={3}>
          <StandaloneDocumentTypePicker
            selectedId={documentType}
            onSelectionChange={(selected, selection) => {
              setDocumentType(selected);
            }}
            requireChoice={true}
          />
        </Col>*/}
        <Col xs={5}>
          <div>
            <div
              style={{
                maxWidth: "10em",
                display: "inline-block",
              }}
            >
              <DatePicker
                name="start-date-operatorCollectionReportRow"
                className="form-control"
                format="MM/dd/yyyy"
                onChange={setStartDate}
                value={startDate}
                calendarType={"US" /* TODO - localize by user?*/}
                clearIcon={null}
              />
            </div>
            <span className={"px-2"}>-</span>
            <div
              style={{
                maxWidth: "12em",
                display: "inline-block",
              }}
            >
              <DatePicker
                name="end-date-operatorCollectionReportRow"
                className="form-control"
                format="MM/dd/yyyy"
                onChange={setEndDate}
                value={endDate}
                calendarType={"US" /* TODO - localize by user?*/}
                clearIcon={null}
              />
            </div>
          </div>
          <label>
            <input
              type={"checkbox"}
              checked={includeFileDetail}
              onChange={() => {
                setIncludeFileDetail(!includeFileDetail);
              }}
              value={includeFileDetail.toString()}
            />
            Include File Details (slow)
          </label>
        </Col>
        <Col xs={2}>
          <Button
            color={"primary"}
            onClick={() => {
              if (startDate && endDate && operator) {
                downloadUrl(
                  authState,
                  downloadTokenLink(
                    formatDateISO(startDate) || "",
                    formatDateISO(endDate) || "",
                    operator,
                    includeFileDetail,
                    Intl.DateTimeFormat().resolvedOptions().timeZone,
                  ),
                  { setLoading },
                );
              }
            }}
            disabled={loading}
            className={"wtop-btn"}
          >
            {loading ? <LoadingSpinner /> : "Download"}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export class Reports extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    const blacklineFileFormatOptions = [
      { label: "Excel", value: false },
      { label: "Text", value: true },
    ];

    this.state = {
      operators: [],
      tbOperatorRow: {
        name: "TB Operator Data",
        operatorSelectionInd: null,
        operatorFilter: null,
        periodEndDate: new Date(),
        downloadTokenLink: (monthEnd: string, operatorName: string) => {
          let link = `/api/operatorTrialBalance/${monthEnd}/downloadToken`;
          if (operatorName) {
            link += `?operatorName=${operatorName}`;
          }
          return link;
        },
        useDownloaderCallbacks: true, // Use for downloads that return a status response while the report is generating
      },
      blacklineReportRow: {
        name: "Blackline Rec",
        operatorSelectionInd: null,
        operatorFilter: null,
        periodEndDate: new Date(),
        formatOptions: blacklineFileFormatOptions,
        formatSelect: blacklineFileFormatOptions[0],
        downloadTokenLink: (
          monthEnd: string,
          operatorName: string,
          plaintext: boolean,
        ) => {
          let link = `/api/operatorTrialBalance/blacklineReport/${monthEnd}/downloadToken?1=1`;
          if (operatorName) {
            link += `&operatorName=${operatorName}`;
          }
          if (plaintext) {
            link += `&plaintext=true`;
          }
          return link;
        },
        useDownloaderCallbacks: true, // Use for downloads that return a status response while the report is generating
      },
      accountMappingsRow: {
        name: "New Account Mappings",
        operatorSelectionInd: null,
        operatorFilter: null,
        startMonthAfter: dayjs().date(1).subtract(1, "month"),
        startMonthBefore: null,
        downloadTokenLink: (
          periodStart: string,
          periodEnd: string,
          operatorName: string,
        ) => {
          let link = `/api/operatorAccountMapping/downloadToken?startMonthAfter=${periodStart}`;
          if (periodEnd) {
            link += `&startMonthBefore=${periodEnd}`;
          }
          if (operatorName) {
            link += `&operatorName=${operatorName}`;
          }
          return link;
        },
        useDownloaderCallbacks: true,
      },
      allAccountMappingsRow: {
        name: "All Account Mappings",
        portfolio: "",
        startMonthBefore: null,
        downloadTokenLink: (portfolio: string) => {
          let link = `/api/allOperatorAccountMapping/downloadToken`;
          if (portfolio) {
            link += `?portfolio=${portfolio}`;
          }
          return link;
        },
        allPortfolios: [],
        useDownloaderCallbacks: true,
      },
      spotOccupancyReportRow: {
        name: "Spot Occupancy",
        operatorSelectionInd: null,
        operatorFilter: null,
        startMonthAfter: dayjs().date(1).subtract(1, "month"),
        startMonthBefore: null,
        downloadTokenLink: (periodEnd: string, operatorName: string) => {
          let link = `/api/census/spotOccupancyReport/${periodEnd}/downloadToken`;
          if (operatorName) {
            link += `?operatorName=${operatorName}`;
          }
          return link;
        },
      },
      operatorSettingsRow: {
        name: "Operator Settings",
        downloadTokenLink: () => {
          let link = `/api/operatorSettings/downloadToken`;
          return link;
        },
      },
      requirementsRow: {
        name: "Requirements",
        operatorSelectionInd: null,
        operatorFilter: null,
        downloadTokenLink: (operatorId: OperatorPickerId) => {
          let link = `/api/requirement/downloadToken`;
          if (typeof operatorId === "number") {
            link += `?operator=${operatorId}`;
          }
          return link;
        },
      },
      dueDatesRow: {
        name: "Upcoming Due Dates",
        startDate: dayjs().date(1).subtract(1, "month"),
        endDate: null,
        downloadTokenLink: (startDate: string, endDate: string) => {
          return `/api/requirementDueDates/upcoming/${startDate}/${endDate}/downloadToken`;
        },
      },
      loading: true,
    };

    this.renderTbRow = this.renderTbRow.bind(this);
    this.renderAccountMappingsRow = this.renderAccountMappingsRow.bind(this);
    this.renderBlacklineReportRow = this.renderBlacklineReportRow.bind(this);
    this.renderSpotOccupancyReportRow =
      this.renderSpotOccupancyReportRow.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });

    fetchJsonWithToken("/api/portfolios", this.props.authState).then(
      (portfolioOptions: Array<Portfolio>) => {
        const allPortfolios = [
          { value: "", label: "All Portfolios" },
          { value: "null", label: "(blank)" },
        ].concat(
          portfolioOptions.map((l) => ({
            value: l.name,
            label: l.displayName,
          })),
        );

        const allAccountMappingsRow = { ...this.state.allAccountMappingsRow };
        allAccountMappingsRow.allPortfolios = allPortfolios;

        this.setState({ allAccountMappingsRow });
      },
    );

    const operators = await getAllOperators(this.props.authState);

    this.setState({ operators, loading: false });
  }

  renderTbRow() {
    const tbOperatorRow = this.state.tbOperatorRow;
    const loading = this.state.tbOperatorReportLoading;
    const downloaderCallbacks = tbOperatorRow.useDownloaderCallbacks && {
      setLoading: (b: boolean) => this.setState({ tbOperatorReportLoading: b }),
      setPercent: (p: number | undefined) =>
        this.setState({ tbOperatorReportPercent: p }),
    };

    return (
      <>
        <Row className={"pt-3"}>
          <Col xs={2} />
          <Col xs={3}>
            <label htmlFor={"tb-operator-picker"}>Operator</label>
          </Col>
          <Col xs={5}>
            <label htmlFor={"period-date-tbOperatorRow"}>Period Date </label>
          </Col>
          <Col xs={2} />
        </Row>
        <Row className={"pb-3"}>
          <Col xs={2}>
            <strong style={{ verticalAlign: "middle" }}>
              {tbOperatorRow.name}
            </strong>
          </Col>
          <Col xs={3}>
            <StandaloneOperatorPicker
              id="tb-operator-picker"
              onSelectionChange={(selectionInd, selection) => {
                tbOperatorRow.operatorSelectionInd = selectionInd;
                tbOperatorRow.operatorFilter =
                  selection && !Array.isArray(selection) && selection.name;
                this.setState({ tbOperatorRow });
              }}
              requireChoice
              showAllChoice
              default={"NOT_SELECTED"}
            />
          </Col>
          <Col xs={5}>
            <div
              style={{
                maxWidth: "10em",
                display: "inline-block",
              }}
            >
              <DatePicker
                name="period-date-tbOperatorRow"
                className="form-control"
                format="MM/yyyy"
                maxDetail="year"
                view="year"
                onChange={(val: Date) => {
                  tbOperatorRow.periodEndDate = val;
                  this.setState({ tbOperatorRow });
                }}
                value={parseDate(tbOperatorRow.periodEndDate)}
                calendarType={"US" /* TODO - localize by user?*/}
                clearIcon={null}
              />
            </div>
          </Col>
          <Col xs={2}>
            <Button
              color={"primary"}
              onClick={() => {
                downloaderCallbacks?.setPercent?.(undefined);
                if (tbOperatorRow.periodEndDate) {
                  downloadUrl(
                    this.props.authState,
                    tbOperatorRow.downloadTokenLink(
                      formatDateISOMonthYear(tbOperatorRow.periodEndDate),
                      tbOperatorRow.operatorFilter,
                    ),
                    downloaderCallbacks,
                  );
                }
              }}
              disabled={loading}
              className={"wtop-btn"}
            >
              {loading ? (
                <div style={{ minWidth: "5em" }}>
                  <LoadingSpinner />
                  {this.state.tbOperatorReportPercent && " "}
                  {this.state.tbOperatorReportPercent}
                  {this.state.tbOperatorReportPercent && "%"}
                </div>
              ) : (
                "Download"
              )}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderAccountMappingsRow() {
    const accountMappingsRow = this.state.accountMappingsRow;
    const loading = this.state.accountMappingsReportLoading;
    const downloaderCallbacks = accountMappingsRow.useDownloaderCallbacks && {
      setLoading: (b: boolean) =>
        this.setState({ accountMappingsReportLoading: b }),
    };
    return (
      <>
        <Row className={"pt-3"}>
          <Col xs={2} />
          <Col xs={3}>
            <label htmlFor={"tb-operator-picker"}>Operator</label>
          </Col>
          <Col xs={5}>
            <label htmlFor={"period-date-tbOperatorRow"}>Period</label>
          </Col>
          <Col xs={2} />
        </Row>
        <Row className={"pb-3"}>
          <Col xs={2}>
            <strong style={{ verticalAlign: "middle" }}>
              {accountMappingsRow.name}
            </strong>
          </Col>
          <Col xs={3}>
            <StandaloneOperatorPicker
              id="tb-operator-picker"
              onSelectionChange={(selectionInd, selection) => {
                accountMappingsRow.operatorSelectionInd = selectionInd;
                accountMappingsRow.operatorFilter =
                  selection && !Array.isArray(selection) && selection.name;
                this.setState({ accountMappingsRow });
              }}
              requireChoice
              showAllChoice
              default={"NOT_SELECTED"}
            />
          </Col>
          <Col xs={5}>
            <div
              style={{
                maxWidth: "10em",
                display: "inline-block",
              }}
            >
              <DatePicker
                name="period-date-start-account-mapping"
                className="form-control"
                format="MM/yyyy"
                maxDetail="year"
                view="year"
                onChange={(val: Date) => {
                  accountMappingsRow.startMonthAfter = val;
                  this.setState({ accountMappingsRow });
                }}
                value={parseDate(accountMappingsRow.startMonthAfter)}
                calendarType={"US" /* TODO - localize by user?*/}
                clearIcon={null}
              />
            </div>
            <span className={"px-2"}>-</span>
            <div
              style={{
                maxWidth: "12em",
                display: "inline-block",
              }}
            >
              <DatePicker
                name="period-date-end-account-mapping"
                className="form-control"
                format="MM/yyyy"
                maxDetail="year"
                view="year"
                onChange={(val: Date) => {
                  accountMappingsRow.startMonthBefore = val;
                  this.setState({ accountMappingsRow });
                }}
                value={parseDate(accountMappingsRow.startMonthBefore)}
                calendarType={"US" /* TODO - localize by user?*/}
              />
            </div>
          </Col>
          <Col xs={2}>
            <Button
              color={"primary"}
              onClick={() => {
                if (accountMappingsRow.startMonthAfter) {
                  downloadUrl(
                    this.props.authState,
                    accountMappingsRow.downloadTokenLink(
                      formatDateISOMonthYear(
                        accountMappingsRow.startMonthAfter,
                      ),
                      accountMappingsRow.startMonthBefore &&
                        formatDateISOMonthYear(
                          accountMappingsRow.startMonthBefore,
                        ),
                      accountMappingsRow.operatorFilter,
                    ),
                    downloaderCallbacks,
                  );
                }
              }}
              disabled={loading}
              className={"wtop-btn"}
            >
              {loading ? <LoadingSpinner /> : "Download"}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderAllAccountMappingsRow() {
    const allAccountMappingsRow = this.state.allAccountMappingsRow;
    const loading = this.state.allAccountMappingsReportLoading;
    const downloaderCallbacks =
      allAccountMappingsRow.useDownloaderCallbacks && {
        setLoading: (b: boolean) =>
          this.setState({ allAccountMappingsReportLoading: b }),
      };
    return (
      <>
        <Row className={"pt-3"}>
          <Col xs={2} />
          <Col xs={2}>
            <label htmlFor={"portfolio-select"}>Portfolio</label>
          </Col>
          <Col xs={8} />
        </Row>
        <Row className={"pb-3"}>
          <Col xs={2}>
            <strong style={{ verticalAlign: "middle" }}>
              {allAccountMappingsRow.name}
            </strong>
          </Col>
          <Col xs={2}>
            <Select
              id={"portfolio-select"}
              theme={selectTheme}
              options={allAccountMappingsRow.allPortfolios}
              onChange={(e: any) => {
                const allAccountMappingsRow = {
                  ...this.state.allAccountMappingsRow,
                };
                allAccountMappingsRow.portfolio = e.value;
                this.setState({ allAccountMappingsRow });
              }}
              value={allAccountMappingsRow.allPortfolios.find(
                (a: any) => a.value === (allAccountMappingsRow.portfolio || ""),
              )}
            />
          </Col>
          <Col xs={6} />
          <Col xs={2}>
            <Button
              color={"primary"}
              onClick={() =>
                downloadUrl(
                  this.props.authState,
                  allAccountMappingsRow.downloadTokenLink(
                    allAccountMappingsRow.portfolio,
                  ),
                  downloaderCallbacks,
                )
              }
              disabled={loading}
              className={"wtop-btn"}
            >
              {loading ? <LoadingSpinner /> : "Download"}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderOperatorSettingsRow() {
    const operatorSettingsRow = this.state.operatorSettingsRow;
    const loading = this.state.operatorSettingsRowLoading;
    const downloaderCallbacks = operatorSettingsRow.useDownloaderCallbacks && {
      setLoading: (b: boolean) =>
        this.setState({ operatorSettingsRowLoading: b }),
    };

    return (
      <>
        <Row className={"pb-3"}>
          <Col xs={2}>
            <strong style={{ verticalAlign: "middle" }}>
              {operatorSettingsRow.name}
            </strong>
          </Col>
          <Col xs={8} />
          <Col xs={2}>
            <Button
              color={"primary"}
              onClick={() => {
                downloadUrl(
                  this.props.authState,
                  operatorSettingsRow.downloadTokenLink(),
                  downloaderCallbacks,
                );
              }}
              disabled={loading}
              className={"wtop-btn"}
            >
              {loading ? <LoadingSpinner /> : "Download"}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderRequirementsRow() {
    const requirementsRow = this.state.requirementsRow;
    const loading = this.state.requirementsReportLoading;
    const downloaderCallbacks = requirementsRow.useDownloaderCallbacks && {
      setLoading: (b: boolean) =>
        this.setState({ requirementsReportLoading: b }),
    };
    return (
      <>
        <Row className={"pt-3"}>
          <Col xs={2} />
          <Col xs={3}>
            <label htmlFor={"tb-operator-picker"}>Operator</label>
          </Col>
          <Col xs={7} />
        </Row>
        <Row className={"pb-3"}>
          <Col xs={2}>
            <strong style={{ verticalAlign: "middle" }}>
              {requirementsRow.name}
            </strong>
          </Col>
          <Col xs={3}>
            <StandaloneOperatorPicker
              id="tb-operator-picker"
              onSelectionChange={(selectionInd, selection) => {
                requirementsRow.operatorSelectionInd = selectionInd;
                requirementsRow.operatorFilter =
                  selection && !Array.isArray(selection) && selection.name;
                this.setState({ requirementsRow });
              }}
              requireChoice
              showAllChoice
              default={"NOT_SELECTED"}
            />
          </Col>
          <Col xs={5} />
          <Col xs={2}>
            <Button
              color={"primary"}
              onClick={() => {
                downloadUrl(
                  this.props.authState,
                  requirementsRow.downloadTokenLink(
                    requirementsRow.operatorSelectionInd,
                  ),
                  downloaderCallbacks,
                );
              }}
              disabled={loading}
              className={"wtop-btn"}
            >
              {loading ? <LoadingSpinner /> : "Download"}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderRequirementDueDatesRow() {
    const dueDatesRow = this.state.dueDatesRow;
    const loading = this.state.dueDatesReportLoading;
    const downloaderCallbacks = dueDatesRow.useDownloaderCallbacks && {
      setLoading: (b: boolean) => this.setState({ dueDatesReportLoading: b }),
    };
    return (
      <>
        <Row className={"pt-3"}>
          <Col xs={2} />
          <Col xs={3} />
          <Col xs={5}>
            <label htmlFor={"start-date-dueDateReportRow"}>Period</label>
          </Col>
          <Col xs={2} />
        </Row>
        <Row className={"pb-3"}>
          <Col xs={2}>
            <strong style={{ verticalAlign: "middle" }}>
              {dueDatesRow.name}
            </strong>
          </Col>
          <Col xs={3}></Col>
          <Col xs={5}>
            <div
              style={{
                maxWidth: "10em",
                display: "inline-block",
              }}
            >
              <DatePicker
                name="start-date-dueDateReportRow"
                className="form-control"
                format="MM/dd/yyyy"
                onChange={(val: Date) => {
                  dueDatesRow.startDate = val;
                  this.setState({ dueDatesRow });
                }}
                value={parseDate(dueDatesRow.startDate)}
                calendarType={"US" /* TODO - localize by user?*/}
                clearIcon={null}
              />
            </div>
            <span className={"px-2"}>-</span>
            <div
              style={{
                maxWidth: "12em",
                display: "inline-block",
              }}
            >
              <DatePicker
                name="end-date-dueDateReportRow"
                className="form-control"
                format="MM/dd/yyyy"
                onChange={(val: Date) => {
                  dueDatesRow.endDate = val;
                  this.setState({ dueDatesRow });
                }}
                value={parseDate(dueDatesRow.endDate)}
                calendarType={"US" /* TODO - localize by user?*/}
                clearIcon={null}
              />
            </div>
          </Col>
          <Col xs={2}>
            <Button
              color={"primary"}
              onClick={() => {
                if (dueDatesRow.startDate && dueDatesRow.endDate) {
                  downloadUrl(
                    this.props.authState,
                    dueDatesRow.downloadTokenLink(
                      formatDateISO(dueDatesRow.startDate),
                      formatDateISO(dueDatesRow.endDate),
                    ),
                    downloaderCallbacks,
                  );
                }
              }}
              disabled={loading}
              className={"wtop-btn"}
            >
              {loading ? <LoadingSpinner /> : "Download"}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderBlacklineReportRow() {
    const blacklineReportRow = this.state.blacklineReportRow;
    const loading = this.state.blacklineReportLoading;
    const downloaderCallbacks = blacklineReportRow.useDownloaderCallbacks && {
      setLoading: (b: boolean) => this.setState({ blacklineReportLoading: b }),
    };

    return (
      <>
        <Row className={"pt-3"}>
          <Col xs={2} />
          <Col xs={3}>
            <label htmlFor={"blackline-rec-operator-picker"}>Operator</label>
          </Col>
          <Col xs={3}>
            <label htmlFor={"period-date-blacklineReportRow"}>
              Period Date
            </label>
          </Col>
          <Col xs={2}>
            <label htmlFor="blackline-select">Report Format</label>
          </Col>
          <Col xs={2} />
        </Row>
        <Row className={"pb-3"}>
          <Col xs={2}>
            <strong style={{ verticalAlign: "middle" }}>
              {blacklineReportRow.name}
            </strong>
          </Col>
          <Col xs={3}>
            {/* Not upgrading the Blackline row until we can confirm that the existing bot works with the new StandaloneOperatorPicker.  */}
            <OperatorPickerClassic
              id="blackline-rec-operator-picker"
              authState={this.props.authState}
              selectedOperatorId={blacklineReportRow.operatorSelectionInd}
              onSelectionChange={(selectionInd, selection) => {
                blacklineReportRow.operatorSelectionInd = selectionInd;
                blacklineReportRow.operatorFilter = selection && selection.name;
                this.setState({ blacklineReportRow });
              }}
              requireOperatorChoice
              showAllOperatorChoice
              operators={this.state.operators}
            />
          </Col>
          <Col xs={3}>
            <div
              style={{
                maxWidth: "10em",
                display: "inline-block",
              }}
            >
              <DatePicker
                name="period-date-blacklineReportRow"
                className="form-control"
                format="MM/yyyy"
                maxDetail="year"
                view="year"
                onChange={(val: Date) => {
                  blacklineReportRow.periodEndDate = val;
                  this.setState({ blacklineReportRow });
                }}
                value={parseDate(blacklineReportRow.periodEndDate)}
                calendarType={"US" /* TODO - localize by user?*/}
                clearIcon={null}
              />
            </div>
          </Col>
          <Col xs={2}>
            <Select
              theme={selectTheme}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  maxWidth: "10em",
                }),
              }}
              id="blackline-select"
              options={this.state.blacklineReportRow.formatOptions}
              onChange={(selected: any) => {
                const blacklineReportRow = {
                  ...this.state.blacklineReportRow,
                };
                blacklineReportRow.formatSelect = selected;
                this.setState({ blacklineReportRow });
              }}
              value={this.state.blacklineReportRow.formatSelect}
            />
          </Col>
          <Col xs={2}>
            <Button
              color={"primary"}
              onClick={() => {
                if (blacklineReportRow.periodEndDate) {
                  downloadUrl(
                    this.props.authState,
                    blacklineReportRow.downloadTokenLink(
                      formatDateISOMonthYear(blacklineReportRow.periodEndDate),
                      blacklineReportRow.operatorFilter,
                      blacklineReportRow.formatSelect.value,
                    ),
                    downloaderCallbacks,
                  );
                }
              }}
              disabled={loading}
              className={"wtop-btn"}
            >
              {loading ? <LoadingSpinner /> : "Download"}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderSpotOccupancyReportRow() {
    const spotOccupancyReportRow = this.state.spotOccupancyReportRow;
    const loading = this.state.spotOccupancyReportLoading;
    const downloaderCallbacks =
      spotOccupancyReportRow.useDownloaderCallbacks && {
        setLoading: (b: boolean) =>
          this.setState({ spotOccupancyReportLoading: b }),
      };

    return (
      <>
        <Row className={"pt-3"}>
          <Col xs={2} />
          <Col xs={3}>
            <label htmlFor={"spotDateReportRow-operator-picker"}>
              Operator
            </label>
          </Col>
          <Col xs={2}>
            <label htmlFor={"period-date-spotDateReportRow"}>Period Date</label>
          </Col>
          <Col xs={5} />
        </Row>
        <Row className={"pb-3"}>
          <Col xs={2}>
            <strong style={{ verticalAlign: "middle" }}>
              {spotOccupancyReportRow.name}
            </strong>
          </Col>
          <Col xs={3}>
            <StandaloneOperatorPicker
              id="spotDateReportRow-operator-picker"
              onSelectionChange={(selectionInd, selection) => {
                spotOccupancyReportRow.operatorSelectionInd = selectionInd;
                spotOccupancyReportRow.operatorFilter =
                  selection && !Array.isArray(selection) && selection.name;
                this.setState({ spotOccupancyReportRow });
              }}
              requireChoice
              showAllChoice
              default={"NOT_SELECTED"}
            />
          </Col>
          <Col xs={2}>
            <div
              style={{
                maxWidth: "10em",
                display: "inline-block",
              }}
            >
              <DatePicker
                name="period-date-spotDateReportRow"
                className="form-control"
                format="MM/dd/yyyy"
                onChange={(val: Date) => {
                  spotOccupancyReportRow.periodEndDate = val;
                  this.setState({ spotOccupancyReportRow });
                }}
                value={parseDate(spotOccupancyReportRow.periodEndDate)}
                calendarType={"US" /* TODO - localize by user?*/}
                clearIcon={null}
              />
            </div>
          </Col>
          <Col xs={3} />
          <Col xs={2}>
            <Button
              color={"primary"}
              onClick={() => {
                if (spotOccupancyReportRow.periodEndDate) {
                  downloadUrl(
                    this.props.authState,
                    spotOccupancyReportRow.downloadTokenLink(
                      formatDateISO(spotOccupancyReportRow.periodEndDate),
                      spotOccupancyReportRow.operatorFilter,
                    ),
                    downloaderCallbacks,
                  );
                }
              }}
              disabled={loading}
              className={"wtop-btn"}
            >
              {loading ? <LoadingSpinner /> : "Download"}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    if (!this.props.authState.isAuthenticated) return null;

    if (this.state.loading) {
      return <LoadingSpinner />;
    }

    if (!canViewReports(this.props.authState)) {
      return (
        <Container>
          <Card>
            <CardBody>Access denied.</CardBody>
          </Card>
        </Container>
      );
    }

    return (
      <Container className={"reports-dashboard"}>
        <Row className={"pb-3"}>
          <Col className={"text-center"}>
            <h3>Reports</h3>
          </Col>
        </Row>

        {this.renderTbRow()}
        {this.renderAccountMappingsRow()}
        {this.renderAllAccountMappingsRow()}
        {this.renderBlacklineReportRow()}
        {this.renderSpotOccupancyReportRow()}
        {this.renderOperatorSettingsRow()}
        {this.renderRequirementsRow()}
        {this.renderRequirementDueDatesRow()}
        <FileErrorsReportRow />
        <RequirementCollectionReportRow />
        <Row style={{ height: "20em" }}></Row>
      </Container>
    );
  }
}

export default withOktaAuth(Reports);
