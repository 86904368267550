/** @format */

import React from "react";
import { Formik, FormikProps, Form } from "formik";
import { Button } from "reactstrap";
import WrappedSelectInput from "../form/WrappedSelectInput";
import { addOperatorCustomHeading } from "../../api/OperatorAPI";
import _ from "lodash";
import WrappedTextInput from "../form/WrappedTextInput";

export default class OperatorCustomHeaderForm extends React.Component<
  any,
  any
> {
  static defaultProps = {
    documentType: "",
    headerIdentifier: "",
    headerContent: "",
  };

  onSaveCustomHeading = async (data: any, callback: Function) => {
    const { operatorId, authState, onSaveCustomHeading } = this.props;
    let formattedPayload = {
      operator: { id: operatorId },
      documentType: { id: data.documentType.value },
      headerIdentifier: data.headerIdentifier.value,
      headerContent: _.trim(data.headerContent),
    };
    try {
      const customHeader: any = await addOperatorCustomHeading(
        operatorId,
        formattedPayload,
        authState,
      );
      callback && callback.call(null);
      onSaveCustomHeading(customHeader);
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    let { documentType, headerIdentifier, headerContent } = this.props;

    return (
      <Formik
        initialValues={{ documentType, headerIdentifier, headerContent }}
        onSubmit={(values, actions) => {
          this.onSaveCustomHeading(values, () => actions.setSubmitting(false));
        }}
        validate={(values: any) => {
          let errors: any = {};

          if (!values.headerContent) {
            errors.headerContent = "Please enter the custom header name.";
          }

          return errors;
        }}
      >
        {(props: FormikProps<any>) => {
          return (
            <div className={"editForm"}>
              <h2 className="propertyName">Add Custom Header</h2>

              <Form className="editFormForm">
                <div className="form-group">
                  <WrappedSelectInput
                    id={`documentType`}
                    label={"Document Type"}
                    required={true}
                    options={this.props.documentTypes}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                  />
                </div>
                <div className="form-group">
                  <WrappedSelectInput
                    id={"headerIdentifier"}
                    label={"Column"}
                    required={true}
                    options={this.props.headerIdentifiers.filter(
                      (h: any) =>
                        h.obj.documentTypeId ===
                        props.values.documentType?.value,
                    )}
                    onChange={props.setFieldValue}
                    onBlur={props.setFieldTouched}
                  />
                </div>
                <div className="form-group">
                  <WrappedTextInput
                    id="headerContent"
                    label="Custom Header Name"
                  />
                </div>

                <Button
                  type={"submit"}
                  color={"primary"}
                  disabled={props.isSubmitting}
                  className={"float-right wtop-btn"}
                  onClick={() => {
                    props.handleSubmit();
                  }}
                >
                  Save
                </Button>
              </Form>
            </div>
          );
        }}
      </Formik>
    );
  }
}
