/** @format */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatusMessage from "../../types/StatusMessage";

const RddStatusIcon = ({
  statusMessage,
  allFileStatuses, // Only pass in if multi-file (!process)
  welltower,
}: {
  statusMessage: StatusMessage;
  allFileStatuses?: Array<StatusMessage>;
  welltower?: boolean;
}) => {
  let icon = null;

  const fileStatuses = allFileStatuses?.map((s) => s.statusColor);

  if (statusMessage.statusColor === "SUCCESS") {
    icon = (
      <FontAwesomeIcon
        icon={"check"}
        size={"2x"}
        data-testid="rdd-status-icon"
        className={`text-success rdd-status-icon ml-3`}
        title={"Success"}
      />
    );
  }

  if (
    statusMessage.statusColor === "WARNING" ||
    fileStatuses?.includes("WARNING")
  ) {
    icon = (
      <FontAwesomeIcon
        icon={"exclamation"}
        size={"2x"}
        data-testid="rdd-status-icon"
        className={`text-warning rdd-status-icon ml-3`}
        title={"Warning"}
      />
    );
  }

  if (
    statusMessage.statusColor === "ERROR" ||
    fileStatuses?.includes("ERROR")
  ) {
    icon = (
      <FontAwesomeIcon
        icon={"circle-exclamation"}
        size={"3x"}
        data-testid="rdd-status-icon"
        className="text-danger mt-n2 rdd-status-icon"
        title={"Error"}
      />
    );
  }
  if (statusMessage.statusColor === "IN_PROGRESS") {
    icon = (
      <FontAwesomeIcon
        icon={["fad", "clipboard-list-check"]}
        size={"2x"}
        data-testid="rdd-status-icon"
        className="text-primary ml-3 rdd-status-icon"
        title={"In progress"}
      />
    );
  }
  if (
    welltower &&
    (statusMessage.id === "AWAITING_RPA" ||
      statusMessage.id === "AWAITING_ETL_RESULTS" ||
      statusMessage.id === "AWAITING_PULL_RESULTS")
  ) {
    icon = (
      <FontAwesomeIcon
        icon={["fal", "user-robot"]}
        size={"2x"}
        data-testid="rdd-status-icon"
        className="text-primary ml-3 rdd-status-icon"
        title={"Awaiting load"}
      />
    );
  }

  return icon;
};

export default RddStatusIcon;
