/** @format */

import React from "react";
import { Field, Formik, FormikProps, ErrorMessage, Form } from "formik";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  formatDate,
  formatDateMonthYear,
  formatDateTimeSeconds,
  parseDate,
} from "../../util/DateUtils";
import WrappedDateInput from "../form/WrappedDateInput";
import {
  deleteFileJDEBatch,
  saveFileJDEBatch,
  updateFileJDEBatch,
} from "../../api/SubmittedFileAPI";
import { canDeleteJdeInfo, getUserLogin } from "../../util/OktaUtils";
import { welltowerName } from "../../util/NameUtils";
import WrappedCheckboxInput from "../form/WrappedCheckboxInput";
import { recordsBatchNumber } from "../../util/StatusUtils";
import "./SubmittedFileComponent.scss";
import WelltowerAccountingLoad from "../../types/WelltowerAccountingLoad";
import SubmittedFile from "../../types/SubmittedFile";
import { useOktaAuth } from "@okta/okta-react";
import { DeveloperModeOnly } from "../DeveloperMode";

interface EditWelltowerAccountingLoadFormProps {
  file: SubmittedFile;
  wal?: WelltowerAccountingLoad;
  //uploadIdentifier?: string;
  //glDate?: Date;
  //loadId?: number;
  //failed?: boolean; // Still using two bools for status in this form, because we don't want to break the form for the bot
  //reversed?: boolean; // Still using two bools for status in this form, because we don't want to break the form for the bot
  afterSave?: any;
}
const EditWelltowerAccountingLoadForm: React.FC<
  EditWelltowerAccountingLoadFormProps
> = (props) => {
  const { authState } = useOktaAuth();

  const onSaveJDEInfo = async (data: any, callback: Function) => {
    let payload = {
      uploadIdentifier: data.uploadIdentifier,
      glDate: parseDate(data.glDate),
      uploadTime: new Date(),
      uploadUserId: getUserLogin(authState),
      status: data.reversed ? "REVERSED" : data.failed ? "FAILED" : "SUCCESS",
    } as WelltowerAccountingLoad;

    try {
      if (props.wal) {
        await updateFileJDEBatch(
          payload as WelltowerAccountingLoad,
          props.file.requirementDueDate.id,
          props.file.id,
          props.wal.id,
          authState,
        );
      } else {
        await saveFileJDEBatch(
          payload as WelltowerAccountingLoad,
          props.file.requirementDueDate.id,
          props.file.id,
          authState,
        );
      }
      callback && callback.call(null);
      props.afterSave();
    } catch (err) {
      console.error(err);
    }
  };

  const deleteJDEInfo = async (callback?: Function) => {
    try {
      let data;
      if (props.wal) {
        data = await deleteFileJDEBatch(
          props.file.requirementDueDate.id,
          props.file.id,
          props.wal.id,
          authState,
        );
      }
      // TODO - have the delete return the RDD, then pass back to callback, then have onSaveJDEInfo pass to BaseRddCard's this.props.receiveUpdatedRequirementDueDate(data, true); this.loadFiles(data, undefined, true);
      callback && callback.call(null);
      props.afterSave(data);
    } catch (err) {
      console.error(err);
    }
  };

  let {
    uploadIdentifier,
    glDate,
    id: loadId,
    failed,
    reversed,
    uploadFilename,
    uploadTime,
    uploadUserId,
    uploadUserDisplayName, // Should be populated server-side, but make optional because we won't send it in a form submission.
    status,
  } = props.wal || {};
  let file = props.file;

  const requireBatchNumber = recordsBatchNumber(
    file.requirementDueDate.requirement.documentType,
    props.wal,
  );
  const canDeleteLoad = canDeleteJdeInfo(
    authState,
    file.requirementDueDate.requirement.operator,
    file.requirementDueDate.requirement,
  );
  return (
    <Formik
      initialValues={{ uploadIdentifier, glDate, failed, reversed }}
      onSubmit={(values, actions) => {
        onSaveJDEInfo(values, () => actions.setSubmitting(false));
      }}
      validateOnBlur={false}
      validate={(values: any) => {
        let errors: any = {};

        if (requireBatchNumber && !values.uploadIdentifier && !values.failed) {
          errors.uploadIdentifier = `Please enter a ${
            file.destination?.displayName || ""
          } batch number.`;
        }

        if (Object.keys(errors).length > 0) {
          return errors;
        } else {
          return {};
        }
      }}
    >
      {(props: FormikProps<any>) => {
        return (
          <div className={"editForm edit-jdeinfo-form"}>
            <h2 className="propertyName">
              {welltowerName(file.requirementDueDate.requirement.operator)}
            </h2>

            <Form className="editFormForm">
              <Row>
                <Col>
                  <i>
                    {formatDateMonthYear(file.requirementDueDate.periodEndDate)}{" "}
                    {welltowerName(file.requirementDueDate.requirement)}
                  </i>
                  <br />
                  <i>Due {formatDate(file.requirementDueDate.dueDate)}</i>
                  <DeveloperModeOnly>
                    <br />
                    <span className={"text-muted"}>WAL id: {loadId}</span>
                  </DeveloperModeOnly>
                </Col>
              </Row>
              <Row className={"mb-4"}>
                <Col>
                  <i>
                    Operator file:{" "}
                    {`${
                      file.originalFilename
                    }, Submitted to Portal: ${formatDate(file.uploadTime)}`}
                  </i>
                  {uploadFilename && (
                    <>
                      <br />
                      <i>ETL file: {uploadFilename}</i>
                    </>
                  )}
                </Col>
              </Row>
              {!!uploadTime && (
                <Row>
                  <Col>
                    <i>
                      ETL{" "}
                      {status === "WAITING"
                        ? "sent"
                        : status === "FAILED"
                          ? "failed"
                          : "loaded"}
                      : {formatDateTimeSeconds(uploadTime)}
                    </i>
                    {status !== "WAITING" && (
                      <>
                        <br />
                        <i>
                          By:{" "}
                          {uploadUserDisplayName || uploadUserId || "unknown"}
                        </i>
                      </>
                    )}
                  </Col>
                </Row>
              )}
              {requireBatchNumber && (
                <div className="form-group">
                  <label
                    htmlFor={`uploadIdentifier`}
                    className={"label-required"}
                  >
                    {file.destination?.displayName || ""} Batch #
                  </label>
                  <Field
                    name={`uploadIdentifier`}
                    type={"text"}
                    className={"form-control"}
                  />
                  <ErrorMessage
                    name={"uploadIdentifier"}
                    component={"div"}
                    className={"input-feedback"}
                  />
                </div>
              )}
              <div className="form-group">
                <WrappedDateInput
                  id={"glDate"}
                  label={"General Ledger Date"}
                  value={props.values.glDate}
                  required={false}
                  onChange={props.setFieldValue}
                  onBlur={props.setFieldTouched}
                />
              </div>
              <div className="form-group">
                <WrappedCheckboxInput id="failed" label="Batch failed?" />
              </div>

              {loadId && canDeleteLoad && (
                <Button
                  onClick={() => deleteJDEInfo()}
                  color={"danger"}
                  disabled={props.isSubmitting}
                >
                  <FontAwesomeIcon
                    icon={["far", "trash-alt"]}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Button>
              )}

              <Button
                type={"submit"}
                color={"primary"}
                disabled={props.isSubmitting}
                className={"float-right wtop-btn"}
                onClick={() => {
                  props.handleSubmit();
                }}
              >
                Save Updates
              </Button>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default EditWelltowerAccountingLoadForm;
