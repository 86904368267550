/** @format */

import * as React from "react";
import { CompatRoute } from "react-router-dom-v5-compat";
import { RequiredAuth } from "./RequiredAuth";
/*
const SecureRoute6 = (props: any) => {
  const { path, element } = props;
  return (
    <Route path={path} element={<RequiredAuth />}>
      <Route path="" element={element} />
    </Route>
  );
};


*/
export const SecureRouteCompat = (props: any) => {
  const { path, children } = props;
  return (
    <CompatRoute path={path}>
      <RequiredAuth>{children}</RequiredAuth>
    </CompatRoute>
  );
};
