/** @format */
import React, { useState } from "react";
import { Col, Collapse, Row } from "reactstrap";
import { RequirementSubmissionStats } from "./RequirementSubmissionStats";
import {
  formatDateISOMonthYear,
  formatDateMonthDayShort,
} from "../../util/DateUtils";
import { Link } from "react-router-dom";
import _ from "lodash";
import RequirementDueDateDashboardDto from "../../types/RequirementDueDateDashboardDto";

const OperatorSubmissionStats = (props: {
  operatorName: string;
  requirementDueDates: Array<RequirementDueDateDashboardDto>;
  bucketLabels: string[];
  byBucket: _.Dictionary<RequirementDueDateDashboardDto[]>;
}) => {
  const { operatorName, requirementDueDates, bucketLabels, byBucket } = props;
  const operatorId = requirementDueDates[0].operatorId;

  const [showDetails, setShowDetails] = useState(false);
  const toggle = () => {
    setShowDetails(!showDetails);
  };

  let docTypes = _.uniq(requirementDueDates.map((rdd) => rdd.documentTypeId));
  let typeParam = docTypes.length === 1 ? `&documentType=${docTypes[0]}` : "";

  return (
    <Row
      className={"operator-submission-stats"}
      data-testid={"operator-submission-stats"}
    >
      <Col onClick={toggle}>
        <Row className={"header-row"}>
          <Col md={"2"}>
            <Link
              to={`/accountant-requirement-due-dates/${operatorId}?period=${formatDateISOMonthYear(requirementDueDates[0].periodEndDate)}${typeParam}&filterOpenOverdue=all`}
            >
              {operatorName}
            </Link>
          </Col>
          <Col className={"text-center"}>
            {formatDateMonthDayShort(
              _.min(requirementDueDates.map((rdddDto) => rdddDto.dueDate)),
            )}
          </Col>
          <Col className={"text-center"}>{requirementDueDates.length}</Col>
          <Col className={"text-center"}>
            {requirementDueDates.filter((rdddDto) => rdddDto.locked).length}
          </Col>
          {bucketLabels.map((bucket) => (
            <Col className={"text-center"} key={bucket}>
              {byBucket[bucket] ? byBucket[bucket].length : ""}
            </Col>
          ))}
          {/*<Col md={"3"}>{stats.status || "-"}</Col>*/}
        </Row>
        <Row>
          <Col>
            <Collapse isOpen={showDetails} className={"requirement-stats"}>
              <Row className={"requirement-header-row"}>
                <Col md={"2"}>Requirement</Col>
                <Col md={"2"}>Due</Col>
                <Col md={"2"}>Failed Submissions</Col>
                <Col md={"2"}>Accepted Date</Col>
                <Col md={"2"}>Loaded Date</Col>
                <Col md={"2"}>Latest Update</Col>
              </Row>
              {_.sortBy(requirementDueDates, [
                (rdddDto: RequirementDueDateDashboardDto) =>
                  rdddDto.requirementName,
              ]).map((rdddDto: RequirementDueDateDashboardDto) => (
                <RequirementSubmissionStats
                  rdddDto={rdddDto}
                  key={rdddDto.id}
                />
              ))}
            </Collapse>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

// Add a React displayName for tests to find this component.
OperatorSubmissionStats.displayName = "OperatorSubmissionStats";

export default OperatorSubmissionStats;
