/** @format */

import WithModal from "../WithModal";
import React from "react";
import { Button } from "reactstrap";
import OperatorCustomHeadingForm from "./OperatorCustomHeadingForm";
import { AbstractAuthState } from "../../util/OktaUtils";

const OperatorCustomHeaderFormButton = WithModal(
  ({
    operatorId,
    authState,
    documentTypes,
    headerIdentifiers,
    onSaveCustomHeading,
    ...props
  }: {
    operatorId: number;
    authState: AbstractAuthState;
    documentTypes: Array<any>;
    headerIdentifiers: Array<any>;
    onSaveCustomHeading: Function;
  } & Partial<any>) => (
    <div>
      <Button color={"primary"} className="wtop-btn" onClick={props.showModal}>
        {"Add Custom Heading"}
      </Button>
      {props.modalContent(
        <OperatorCustomHeadingForm
          documentTypes={documentTypes}
          headerIdentifiers={headerIdentifiers}
          operatorId={operatorId}
          onSaveCustomHeading={() => {
            props.closeModal();
            onSaveCustomHeading();
          }}
          authState={authState}
        />,
        {
          showCloseIcon: true,
          modalStyles: { width: "600px" },
          closeOnOverlayClick: true,
        },
      )}
    </div>
  ),
);

export default OperatorCustomHeaderFormButton;
