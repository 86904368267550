/** @format */

import React from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { CLOUDTICITY_TYPES } from "../../util/constants";
import BaseRddCard, {
  LoadingIndicatorRow,
  nonEmptyFileValidator,
} from "../rdd-card/BaseRddCard";
import RddCardAlert from "../rdd-card/RddCardAlert";
import RddCardHeader from "../rdd-card/RddCardHeader";
import RddStatusIcon from "../rdd-card/RddStatusIcon";
import RddStatusMessage from "../rdd-card/RddStatusMessage";
import { RddLockedMessage } from "../rdd-card/RddCallToAction";
import SubmittedFile from "../../types/SubmittedFile";
import RddUploadHistory from "../rdd-card/RddUploadHistory";
import { canUploadFiles, isWelltower } from "../../util/OktaUtils";
import { Form, Formik } from "formik";
import { externalName } from "../../util/NameUtils";
import RddLockStatus from "../accountant-rdd-card/RddLockStatus";
import OperatorSubmittedFile from "../operator-rdd-card/OperatorSubmittedFile";
import WrappedSelectInput from "../form/WrappedSelectInput";
import {
  helperObjectToSelectOption,
  realValueToSelectOption,
} from "../../util/FormUtils";
import IconCarousel from "./IconCarousel";
import { operatorStatusMessage } from "../../util/StatusUtils";
import WrappedTextInput from "../form/WrappedTextInput";

interface CloudticityFormValues {
  type: any;
  description: string;
}

class CloudticityRddCard extends BaseRddCard {
  render() {
    const { requirementDueDate } = this.props;
    const { previousFiles } = this.state;

    const canUpload =
      canUploadFiles(
        this.props.authState,
        requirementDueDate.requirement.operator,
        requirementDueDate.requirement,
      ) && !requirementDueDate.locked;

    let uploadEnabled = canUpload;

    const showCloudticityForm = this.state.showCloudticityForm; /* ||
      (requirementDueDate.requirement.documentType.uploadMethod
        .twoStageUpload &&
        requirementDueDate.status.name === "NO_FILE");*/

    const setShowCloudticityForm = () =>
      this.setState({ showCloudticityForm: true });

    const setHideCloudticityForm = () =>
      this.setState({ showCloudticityForm: false });

    const welltower = isWelltower(this.props.authState);
    return (
      <div className={`rdd-card accountant-rdd-card`}>
        <Formik
          initialValues={{} as CloudticityFormValues}
          onSubmit={async (values: CloudticityFormValues) => {
            // await new Promise((r) => setTimeout(r, 500));
            // alert(JSON.stringify(values, null, 2));
          }}
          validate={(values: any) => {
            // if (values.type && values.description) {
            // }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            values,
            errors,
          }) => {
            let additionalData: any = {};
            if (showCloudticityForm) {
              uploadEnabled =
                canUpload &&
                values.type &&
                values.type.value &&
                !!values.description;
              additionalData.type = values.type && values.type.value;
              additionalData.description = values.description;
            } else {
              uploadEnabled = false;
            }

            // No accept={} - allow any file types
            // No maxSize={} - browser uploads directly to S3 and I think they'll accept anything.
            return (
              <Dropzone
                noClick={true}
                noKeyboard={true}
                onDrop={async (
                  accepted: File[],
                  fileRejections: FileRejection[],
                ) =>
                  this.onDrop(accepted, fileRejections, additionalData).then(
                    () => this.setState({ showCloudticityForm: false }),
                  )
                }
                minSize={1}
                validator={nonEmptyFileValidator}
                multiple={true}
                disabled={!uploadEnabled}
              >
                {({ getRootProps, getInputProps, isDragActive, open }) => {
                  return (
                    <div {...getRootProps()}>
                      <Card
                        className={`mt-5 card-hipaa ${
                          isDragActive ? "dropzone--isActive" : ""
                        }`}
                      >
                        <CardHeader>
                          <Row>
                            <Col className={"text-uppercase text-small"}>
                              Personal/Medical information (HIPAA/PII)
                              {/* <IconCarousel />*/}
                            </Col>
                            <Col md={"1"} className={"text-right  text-small"}>
                              <IconCarousel />
                            </Col>
                          </Row>
                        </CardHeader>
                        <RddCardAlert
                          error={this.state.error}
                          success={this.state.success}
                          warning={this.state.warning}
                          toggleAlertVisibility={() => {
                            this.setState(
                              {
                                error: null,
                                success: null,
                                warning: null,
                              },
                              () =>
                                this.loadFiles(this.props.requirementDueDate),
                            );
                          }}
                        />
                        <CardBody>
                          <input {...getInputProps()} />
                          {this.state.block && (
                            <LoadingIndicatorRow
                              onClose={() =>
                                this.setState({
                                  block: false,
                                  cancelFilesStatusLoop: true,
                                })
                              }
                              message={this.state.blockMessage}
                            />
                          )}
                          <Row>
                            <Col md={"1"}>
                              <RddStatusIcon
                                statusMessage={operatorStatusMessage(
                                  requirementDueDate.status,
                                )}
                              />
                            </Col>
                            <Col>
                              <RddCardHeader
                                rdd={requirementDueDate}
                                documentType={
                                  requirementDueDate.requirement.documentType
                                }
                                rddName={externalName(
                                  requirementDueDate.requirement,
                                )}
                                rddDueDate={requirementDueDate.dueDate}
                              />
                            </Col>
                            {welltower ? (
                              <Col md={"1"} className={"text-right"}>
                                <RddLockStatus
                                  rdd={requirementDueDate}
                                  callback={
                                    this.props.receiveUpdatedRequirementDueDate
                                  }
                                  onError={(error: any) =>
                                    this.setState({
                                      error,
                                      success: null,
                                      warning: null,
                                    })
                                  }
                                />
                              </Col>
                            ) : null}
                          </Row>
                          <Row className={"mb-3"}>
                            <Col className={"text-center"}>
                              <RddStatusMessage
                                uploadingFilename={this.state.uploadingFilename}
                                uploadingBatchProgress={
                                  this.state.uploadingBatchProgress
                                }
                                uploadingFilePercent={
                                  this.state.uploadingFilePercent
                                }
                                statusMessage={operatorStatusMessage(
                                  requirementDueDate.status,
                                )}
                                logEntries={[]}
                                downloadFullLogEntry={() => {}}
                              />
                            </Col>
                          </Row>

                          {showCloudticityForm && (
                            <Row className={"mb-3"}>
                              <Col>
                                <Row>
                                  <Col sm="6" className={"offset-sm-3"}>
                                    <Form>
                                      <p>
                                        First, provide some information about
                                        your file:
                                      </p>
                                      <WrappedSelectInput
                                        id={"type"}
                                        label={"Type"}
                                        value={realValueToSelectOption(
                                          values.type && values.type.value,
                                          CLOUDTICITY_TYPES,
                                        )}
                                        required={true}
                                        clearable={false}
                                        options={CLOUDTICITY_TYPES.map(
                                          helperObjectToSelectOption,
                                        )}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        className={"mb-3"}
                                      />
                                      <WrappedTextInput
                                        id="description"
                                        placeholder="tell us a little about this file"
                                        label="Description"
                                        required={true}
                                        className="mb-3"
                                      />
                                    </Form>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}
                          {requirementDueDate.locked ? (
                            welltower ? null : (
                              <Row className={"mb-4"}>
                                <Col className={"text-center"}>
                                  <RddLockedMessage isWelltower={welltower} />
                                </Col>
                              </Row>
                            )
                          ) : (
                            <Row className={"mb-4"}>
                              <Col sm="4" className={"text-center offset-sm-4"}>
                                {requirementDueDate.locked ? (
                                  welltower ? null : (
                                    <RddLockedMessage isWelltower={welltower} />
                                  )
                                ) : this.state.block ? null : canUpload &&
                                  !showCloudticityForm ? (
                                  previousFiles.length ? (
                                    <div
                                      className={
                                        "operator-upload-btn text-muted"
                                      }
                                    >
                                      {`You can `}
                                      <Button
                                        color={"link"}
                                        className={"px-0"}
                                        onClick={setShowCloudticityForm}
                                        key={"upload"}
                                      >
                                        Upload a New File
                                      </Button>
                                    </div>
                                  ) : (
                                    <Button
                                      block
                                      className={"py-3"}
                                      color={"primary"}
                                      onClick={setShowCloudticityForm}
                                    >
                                      UPLOAD
                                    </Button>
                                  )
                                ) : showCloudticityForm && uploadEnabled ? (
                                  <Button
                                    block
                                    className={"py-3"}
                                    color={"primary"}
                                    onClick={open}
                                  >
                                    UPLOAD
                                  </Button>
                                ) : null}
                                {showCloudticityForm && !uploadEnabled ? (
                                  <div
                                    className={"operator-upload-btn text-muted"}
                                  >
                                    <Button
                                      color={"link"}
                                      className={"px-0 text-small"}
                                      onClick={setHideCloudticityForm}
                                      key={"upload"}
                                    >
                                      cancel
                                    </Button>
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                          )}
                        </CardBody>
                        <RddUploadHistory
                          files={previousFiles}
                          locked={requirementDueDate.locked}
                        >
                          {previousFiles.map((file: SubmittedFile) => {
                            return (
                              <OperatorSubmittedFile
                                file={file}
                                authState={this.props.authState}
                                locked={requirementDueDate.locked}
                                key={file.id}
                              />
                            );
                          })}
                        </RddUploadHistory>
                      </Card>
                    </div>
                  );
                }}
              </Dropzone>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default CloudticityRddCard;
