/** @format */

import React from "react";
import { withFormik } from "formik";
import WithModal from "./WithModal";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withOktaAuth } from "@okta/okta-react";
import RequirementDueDate from "../types/RequirementDueDate";
import { formatDate } from "../util/DateUtils";
import {
  describeDueDatePeriodEnd,
  describeFrequencyType,
  welltowerName,
} from "../util/NameUtils";
import WrappedDateInput from "./form/WrappedDateInput";
import { DeveloperModeOnly } from "./DeveloperMode";
import { putWithToken } from "../util/FetchUtils";
import { LoadingSpinner } from "./LoadingSpinners";

const RequirementDueDateEditForm = (props: any) => {
  const {
    requirementDueDate,
    values,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    closeModal,
    deleteRequirementDueDate,
    errors,
    setErrors,
  } = props;

  return (
    <div className="editForm">
      <p>
        {welltowerName(requirementDueDate.requirement.operator)}{" "}
        {welltowerName(requirementDueDate.requirement)}
        <br />
        {describeFrequencyType(requirementDueDate.requirement)}
        <br />
        {describeDueDatePeriodEnd(requirementDueDate)}
        <DeveloperModeOnly>
          {" "}
          <span className={"text-muted"}>
            {requirementDueDate.requirement.operator.id}.
            {requirementDueDate.requirement.id}.{requirementDueDate.id}
          </span>
        </DeveloperModeOnly>
      </p>
      <form className="editFormForm" onSubmit={handleSubmit}>
        <div className={"form-group"}>
          <WrappedDateInput
            id={"dueDate"}
            label={"Due Date"}
            value={values.dueDate}
            required={true}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          />
        </div>
        {requirementDueDate.glDate &&
          requirementDueDate.glDate !== requirementDueDate.periodEndDate && (
            <div className={"form-group"}>
              <WrappedDateInput
                id={"glDate"}
                label={"GL Date"}
                value={values.glDate}
                required={true}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
              <div className={"input-feedback info"}>
                {requirementDueDate.glDateOverride
                  ? "Manually-entered GL date for this month's file(s)"
                  : `From requirement setting to post on ${
                      requirementDueDate.requirement.glDateSetting
                        ? requirementDueDate.requirement.glDateSetting
                            .displayName
                        : "Month End"
                    }`}
              </div>
            </div>
          )}
        <div className="editFormButton">
          <span className="requiredNote" style={{ float: "left" }}>
            * required fields
          </span>
          {errors && errors.submission && (
            <div
              className="invalid-feedback pr-3"
              style={{ display: "inline-block", width: "24em" }}
            >
              {errors.submission}
            </div>
          )}
          {requirementDueDate && requirementDueDate.id && (
            <Button
              onClick={() => {
                deleteRequirementDueDate(requirementDueDate)
                  .then((response: any) => {
                    if (response.data.status === "error") {
                      setErrors({ submission: response.data.error });
                    } else {
                      props.closeModal && props.closeModal();
                    }
                  })
                  .catch((error: any) => {
                    setErrors({ submission: error.message || error });
                  });
              }}
              color={"danger"}
              disabled={isSubmitting}
            >
              <FontAwesomeIcon
                icon={["far", "trash-alt"]}
                style={{
                  cursor: "pointer",
                }}
              />
            </Button>
          )}
          <Button onClick={closeModal} color={"link"} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" color={"primary"} disabled={isSubmitting}>
            {requirementDueDate && requirementDueDate.id ? "Update" : "Create"}
          </Button>
        </div>
        {isSubmitting && (
          <div
            style={{
              position: "absolute",
              top: "1.2rem",
              height: "calc( 100% - 2.4rem)",
              width: "calc( 100% - 2.4rem)",
              backgroundColor: "white",
              opacity: 0.5,
            }}
          >
            <div
              style={{
                fontSize: "48px",
                position: "relative",
                top: "calc(50% - 24px)",
                left: "calc(50% - 24px)",
              }}
            >
              <LoadingSpinner />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

const EnhancedForm = withOktaAuth(
  withFormik({
    mapPropsToValues: (props) => {
      // Return relation .id or enum.name here
      const requirement =
        (props.requirementDueDate && props.requirementDueDate.requirement) ||
        props.requirement;
      return {
        requirement: requirement && requirement.id,
        periodEndDate:
          props.requirementDueDate && props.requirementDueDate.periodEndDate,
        dueDate: props.requirementDueDate && props.requirementDueDate.dueDate,
        glDate: props.requirementDueDate && props.requirementDueDate.glDate,
      };
    },
    /* Building a Yup schema with a cyclic dependency and the noSortEdges "escape hatch" worked in Chrome, but somehow blew up in IE.
    I tried to figure it out, but eventually had to give up and write a simple custom validation.
     */
    validate: (values: any, props) => {
      let errors: any = {};

      if (!values.dueDate) {
        errors.dueDate = "Please enter a due date";
      }

      if (
        props.requirementDueDate.glDate &&
        props.requirementDueDate.glDate !==
          props.requirementDueDate.periodEndDate &&
        !values.glDate
      ) {
        errors.glDate = "Please enter a GL date";
      }

      return errors;
    },

    handleSubmit: async (
      values: any,
      { props, setSubmitting }: { props: any; setSubmitting: any },
    ) => {
      // Deep copy, just in case this values object isn't ours to mutate.
      let payload = JSON.parse(JSON.stringify(values));

      // Convert DocumentType from string id or react-select object to an {id:} object for JPA
      if (values.requirement) {
        payload.requirement = {
          id:
            (values.requirement && values.requirement.value) ||
            values.requirement,
        };
      }

      const data = Object.assign({}, props.requirementDueDate, payload);
      const url = `/api/operators/${props.requirementDueDate.requirement.operator.id}/requirements/${props.requirementDueDate.requirement.id}/dueDates/${props.requirementDueDate.id}`;

      putWithToken(url, props.authState, data).then((data) => {
        setSubmitting(false);
        props.receiveUpdatedRequirementDueDate(data);
        props.closeModal && props.closeModal();
      });

      return;
    },

    displayName: "RequirementDueDateEditForm", // helps with React DevTools
  })(RequirementDueDateEditForm),
);

const RequirementDueDateEditFormButton = WithModal(
  ({
    requirementDueDate,
    canEdit,
    ...props
  }: {
    requirementDueDate: RequirementDueDate;
    canEdit: boolean;
  } & Partial<any>) => {
    return (
      <div>
        <Button color={"link"} disabled={!canEdit} onClick={props.showModal}>
          <DeveloperModeOnly>
            <span className={"text-muted"}>
              {requirementDueDate.requirement.operator.id}.
              {requirementDueDate.requirement.id}.{requirementDueDate.id}
            </span>{" "}
          </DeveloperModeOnly>
          {formatDate(requirementDueDate.dueDate)}
        </Button>
        {props.modalContent(
          <EnhancedForm requirementDueDate={requirementDueDate} {...props} />,
          {
            showCloseIcon: true,
            modalStyles: { width: "400px" },
            closeOnOverlayClick: false,
            noAutoFocus: true,
          },
        )}
      </div>
    );
  },
);

export default RequirementDueDateEditFormButton;
