/** @format */
import _ from "lodash";
import DashboardPeriodDto from "../types/DashboardPeriodDto";
import { UNMAPPED_ITEM_TYPE } from "../components/operator-rdd-card/OperatorUnmappedItemSummary";

const alertDuplicateIds = (l: any[]) => {
  const ids = l && l.map((l) => l.id);
  if (ids && ids.length !== _.uniq(ids).length) {
    console.error("Duplicate IDs found");
  }
};

const groupLogEntriesByType = (logEntries: any) => {
  return _.countBy(logEntries, "message");
};

const formatUnmappedItems = (data: any, type: UNMAPPED_ITEM_TYPE) => {
  let formattedItems = data.unmappedItems.map((item: any) => {
    return Object.assign({}, item, {
      operatorAccountGrouping: item.operatorAccountGrouping
        ? item.operatorAccountGrouping.value
        : null,
      welltowerAccount: item.welltowerAccount
        ? item.welltowerAccount.value
        : null,
      operatorSelectedBUCode: item.operatorSelectedBUCode
        ? item.operatorSelectedBUCode.value
        : null,
      welltowerSelectedBUCode: item.welltowerSelectedBUCode
        ? item.welltowerSelectedBUCode.value
        : null,
      destination: item.destination ? item.destination.name : null,
      operatorSelectedOptionId: item.operatorSelectedOptionId?.value,
      welltowerSelectedOptionId: item.welltowerSelectedOptionId?.value,
    });
  });
  let unmappedItems =
    type === "account"
      ? { accounts: formattedItems }
      : type === "property"
        ? { entities: formattedItems }
        : type === "column"
          ? { columns: formattedItems }
          : {};

  return unmappedItems;
};

const commaConjunction = (l: any[], conjunction: any = "and") => {
  if (!l) return l;

  if (l.length === 1) return l[0];

  let retArr = [];
  if (l.length === 2) {
    retArr.push(l[0]);
    retArr.push(" ", conjunction, " ");
    retArr.push(l[1]);
  } else {
    const last = l.pop();
    l.forEach((i) => {
      retArr.push(i, ", ");
    });
    retArr.push(conjunction, " ");
    retArr.push(last);
  }
  return retArr;
};

const includesIgnoreCase = (
  list: string[],
  value: string | undefined,
): boolean =>
  !!value && list.map((s) => s.toLowerCase()).includes(value?.toLowerCase());

const getDefaultDashboardMonth = (available: DashboardPeriodDto[]) => {
  /*
  items look like
  {
      id: "YYYY-MM",
      displayName: "MMMM yyyy",
      currentCollectionMonth: boolean,
      dashboardRequirementDueDates: [],
      totalRequirementDueDateCount: int
  }
   */
  if (!available || available.length === 0) return undefined;

  let current = available.find((o: any) => o.currentCollectionMonth);
  if (!current) {
    // currentCollectionMonth is set based on today, but an operator may not have any requirements that cover today
    // in that case, just show the latest
    current = _.maxBy(available, "id"); // Find latest by YYYY-MM IDs
  }

  return current;
};

export {
  alertDuplicateIds,
  groupLogEntriesByType,
  formatUnmappedItems,
  commaConjunction,
  includesIgnoreCase,
  getDefaultDashboardMonth,
};
