/** @format */

import React from "react";
import RddCardHeader from "../rdd-card/RddCardHeader";
import { Card, CardBody, Col, Row } from "reactstrap";
import OperatorUnmappedItemSummary, {
  UNMAPPED_ITEM_TYPE,
} from "./OperatorUnmappedItemSummary";
import { saveFileUnmappedItem } from "../../api/SubmittedFileAPI";
import {
  ACCEPTABLE_PROCESSED_FILE_TYPES,
  DROPZONE_MAX_FILE_SIZE_MB,
  DROPZONE_MAX_PROCESSED_FILE_SIZE_MB,
} from "../../util/constants";
import Dropzone, { FileRejection } from "react-dropzone";
import BaseRddCard, {
  LoadingIndicatorRow,
  nonEmptyFileValidator,
} from "../rdd-card/BaseRddCard";
import RddStatusMessage from "../rdd-card/RddStatusMessage";
import { externalName } from "../../util/NameUtils";
import RddStatusIcon from "../rdd-card/RddStatusIcon";
import RddCardAlert from "../rdd-card/RddCardAlert";
import { formatUnmappedItems } from "../../util/CollectionUtils";
import RddCallToAction from "../rdd-card/RddCallToAction";
import RddUploadHistory from "../rdd-card/RddUploadHistory";
import SubmittedFile from "../../types/SubmittedFile";
import OperatorSubmittedFile from "./OperatorSubmittedFile";
import { canUploadFiles } from "../../util/OktaUtils";
import { operatorStatusMessage } from "../../util/StatusUtils";

class OperatorRddCard extends BaseRddCard {
  onSaveUnmappedItems = async (data: any, type: UNMAPPED_ITEM_TYPE) => {
    let unmappedItems = formatUnmappedItems(data, type);
    try {
      this.block();
      let data: any = await saveFileUnmappedItem(
        this.state.latestFile,
        this.props.authState,
        unmappedItems,
      );
      this.props.receiveUpdatedRequirementDueDate(
        data.requirementDueDate,
        true,
      );
      this.setState({
        success: `Thanks for providing detail on the new ${type}. We will
            contact you if we have questions.`,
        block: false,
      });
      this.loadFiles(this.props.requirementDueDate, this.loadFileUnmappedItems);
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { requirementDueDate } = this.props;
    const {
      unmappedAccounts,
      unmappedProperties,
      unmappedColumns,
      previousFiles,
      latestFile,
      operatorAccountGroupings,
    } = this.state;

    const canUpload =
      canUploadFiles(
        this.props.authState,
        requirementDueDate.requirement.operator,
        requirementDueDate.requirement,
      ) && !requirementDueDate.locked;

    const allFileStatuses = requirementDueDate.requirement.portalProcessed
      ? undefined
      : previousFiles?.map((f) => operatorStatusMessage(f.status));

    return (
      <Dropzone
        accept={
          requirementDueDate.requirement.portalProcessed
            ? ACCEPTABLE_PROCESSED_FILE_TYPES
            : undefined
        }
        validator={nonEmptyFileValidator}
        noClick={true}
        noKeyboard={true}
        minSize={1}
        maxSize={
          (requirementDueDate.requirement.portalProcessed
            ? DROPZONE_MAX_PROCESSED_FILE_SIZE_MB
            : DROPZONE_MAX_FILE_SIZE_MB) *
          1024 *
          1024
        }
        onDrop={(accepted: File[], fileRejections: FileRejection[]) =>
          this.onDrop(accepted, fileRejections)
        }
        multiple={!requirementDueDate.requirement.portalProcessed}
        disabled={!canUpload}
      >
        {({ getRootProps, getInputProps, isDragActive, open }) => {
          return (
            <div {...getRootProps()}>
              <Card
                className={`operator-rdd-card rdd-card mt-5 ${
                  isDragActive ? "dropzone--isActive" : ""
                }`}
              >
                <input {...getInputProps()} />
                <RddCardAlert
                  error={this.state.error}
                  success={this.state.success}
                  warning={this.state.warning}
                  toggleAlertVisibility={() => {
                    this.setState({ error: null, success: null }, () =>
                      this.loadFiles(this.props.requirementDueDate),
                    );
                  }}
                />
                <CardBody className={"pb-0"}>
                  {this.state.block && <LoadingIndicatorRow />}
                  <Row>
                    <Col md={"1"}>
                      <RddStatusIcon
                        statusMessage={operatorStatusMessage(
                          requirementDueDate.status,
                        )}
                        allFileStatuses={allFileStatuses}
                      />
                    </Col>
                    <Col>
                      <RddCardHeader
                        rdd={requirementDueDate}
                        documentType={
                          requirementDueDate.requirement.documentType
                        }
                        rddName={externalName(requirementDueDate.requirement)}
                        rddDueDate={requirementDueDate.dueDate}
                      />
                    </Col>
                  </Row>
                  <Row className={"mb-3"}>
                    <Col className={"text-center"}>
                      <RddStatusMessage
                        uploadingFilename={this.state.uploadingFilename}
                        uploadingBatchProgress={
                          this.state.uploadingBatchProgress
                        }
                        uploadingFilePercent={this.state.uploadingFilePercent}
                        statusMessage={operatorStatusMessage(
                          requirementDueDate.status,
                        )}
                        logEntries={latestFile ? latestFile.logEntries : []}
                        downloadFullLogEntry={() =>
                          this.downloadFileFullLogEntry(latestFile)
                        }
                        allFileStatuses={allFileStatuses}
                      />
                    </Col>
                  </Row>

                  <OperatorUnmappedItemSummary
                    operatorAccountGroupings={operatorAccountGroupings}
                    unmappedAccounts={unmappedAccounts || []}
                    unmappedProperties={unmappedProperties || []}
                    unmappedColumns={unmappedColumns || []}
                    onSaveUnmappedItems={this.onSaveUnmappedItems}
                    documentType={requirementDueDate.requirement.documentType}
                  />

                  <RddCallToAction
                    requirementDueDate={requirementDueDate}
                    locked={requirementDueDate.locked}
                    previousUploadStatus={requirementDueDate.status}
                    onOpenFileBrowser={open}
                    retractFile={this.retract}
                    latestFile={latestFile}
                    operator={requirementDueDate.requirement.operator}
                    authState={this.props.authState}
                  />
                </CardBody>
                <RddUploadHistory
                  files={previousFiles}
                  locked={requirementDueDate.locked}
                >
                  {previousFiles.map((file: SubmittedFile) => {
                    return (
                      <OperatorSubmittedFile
                        locked={requirementDueDate.locked}
                        file={file}
                        authState={this.props.authState}
                        downloadFile={this.downloadFile}
                        downloadFullLogEntry={this.downloadFileFullLogEntry}
                        retractFile={this.retract}
                        key={file.id}
                      />
                    );
                  })}
                </RddUploadHistory>
              </Card>
            </div>
          );
        }}
      </Dropzone>
    );
  }
}

export default OperatorRddCard;
