/** @format */
import SubmittedFile from "../types/SubmittedFile";
import RequirementDueDate from "../types/RequirementDueDate";
import {
  deleteWithToken,
  fetchJsonWithToken,
  fetchPageableAllWithToken,
  getOktaTokenAuthHeader,
  getWelltowerTokenHeaders,
  postWithToken,
  putWithToken,
} from "../util/FetchUtils";
import axios from "axios";
import WelltowerAccountingLoad from "../types/WelltowerAccountingLoad";
import { AbstractAuthState } from "../util/OktaUtils";

export const getSubmittedFiles = async (
  requirementDueDate: RequirementDueDate,
  auth: AbstractAuthState,
): Promise<Array<SubmittedFile>> => {
  const url = `/api/requirementDueDates/${requirementDueDate.id}/files`;
  return fetchPageableAllWithToken(url, auth);
};

export const deleteSubmittedFile = async (
  file: SubmittedFile,
  auth: AbstractAuthState,
): Promise<Array<SubmittedFile>> => {
  const url = `/api/requirementDueDates/${file.requirementDueDate.id}/files/${file.id}`;
  return deleteWithToken(url, auth);
};

export const submitFile = async (
  accepted: File[],
  auth: AbstractAuthState,
  requirementDueDate: RequirementDueDate,
  onUploadProgress: (
    progressEvent: ProgressEvent,
    filename?: string,
    batchProgress?: string,
  ) => void,
  additionalData?: any,
) => {
  if (requirementDueDate.requirement.documentType.uploadMethod.twoStageUpload) {
    console.info("twoStage");
    return submitFileTwoStage(
      accepted,
      auth,
      `/api/requirementDueDates/${requirementDueDate.id}/files`,
      additionalData,
      onUploadProgress,
    );
  } else {
    console.info("normal upload");
    return submitFileDirect(
      accepted,
      auth,
      `/api/requirementDueDates/${requirementDueDate.id}/files`,
      onUploadProgress,
    );
  }
};

export const submitFileDirect = async (
  accepted: File[],
  auth: AbstractAuthState,
  url: string,
  onUploadProgress: (
    progressEvent: ProgressEvent,
    filename?: string,
    batchProgress?: string,
  ) => void,
) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: getOktaTokenAuthHeader(auth),
    },
    credentials: "same-origin",
    mode: "cors",
    onUploadProgress: onUploadProgress,
  };

  // Trick to use reduce() to run sequentially instead of Promise.all(), because simultaneous hits for the same RDD can hit deadlocks
  // https://css-tricks.com/why-using-reduce-to-sequentially-resolve-promises-works/
  const fileCount = accepted.length;
  let uploadedCount = 0;

  return accepted.reduce((previousPromise: Promise<any>, f: File) => {
    return previousPromise.then(() => {
      uploadedCount++;
      console.info(f.name);
      let form = new FormData();
      if (Intl.DateTimeFormat().resolvedOptions().timeZone) {
        form.append(
          "timezone",
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        );
      }
      form.append("file", f, f.name);
      config.onUploadProgress = (progressEvent: ProgressEvent) => {
        onUploadProgress(
          progressEvent,
          f.name,
          fileCount > 1 ? `(${uploadedCount}/${fileCount})` : undefined,
        );
      };

      return axios.post(url, form, config).then((response) => {
        console.info(`then'd ${f.name}`);
        if (response.status === 204) {
          // No Content
          return {};
        } else if (response.status === 301) {
          // redirect, probably just from switch_user // TODO - this is an old Valet comment, probably not seeing 301/302 from uploads in Portal?
          return {};
        } else if (response.status === 302) {
          // redirect, probably just from switch_user // TODO - this is an old Valet comment, probably not seeing 301/302 from uploads in Portal?
          return {};
        } else {
          return response.data; //.json();
        }
      });
      /* OP-934 let this exception bubble up and show as a failed upload
      .catch((error2) => {
          console.error(error2);
        })*/
    });
  }, Promise.resolve());
};

export const submitFileTwoStage = async (
  accepted: File[],
  auth: AbstractAuthState,
  url: string,
  additionalData: any,
  onUploadProgress: (
    progressEvent: ProgressEvent,
    filename?: string,
    batchProgress?: string,
  ) => void,
) => {
  // Trick to use reduce() to run sequentially instead of Promise.all(), because simultaneous hits for the same RDD can hit deadlocks
  // https://css-tricks.com/why-using-reduce-to-sequentially-resolve-promises-works/
  // TODO - is this too confusing? Could we just do a normal for loop (not .forEach() because it has some expectations) and await each step?
  const trace = false;
  const fileCount = accepted.length;
  let uploadedCount = 0;

  return accepted.reduce((previousPromise: Promise<any>, f: File) => {
    return previousPromise.then(() => {
      uploadedCount++;
      trace && console.log(`about to upload file ${uploadedCount} ${f.name}`);
      let formPortal = new FormData();
      formPortal.append("filenames", f.name);
      formPortal.append("filesizes", "" + f.size);
      formPortal.append("contentTypes", "" + f.type);
      if (Intl.DateTimeFormat().resolvedOptions().timeZone) {
        formPortal.append(
          "timezone",
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        );
      }
      if (additionalData) {
        formPortal.append("additionalData", JSON.stringify(additionalData));
      }

      const configPortal: any = {
        headers: {
          "content-type": "multipart/form-data",
        },
        credentials: "same-origin",
        mode: "cors",
        //onUploadProgress: onUploadProgress,
      };

      if (auth) {
        if (Object.keys(getWelltowerTokenHeaders(auth)).length) {
          configPortal.headers["X-WELLTOWER-TOKEN"] =
            getWelltowerTokenHeaders(auth)["X-WELLTOWER-TOKEN"];
        } else {
          configPortal.headers["Authorization"] = getOktaTokenAuthHeader(auth);
        }
      }

      trace && console.log(`Upload step 1 for file ${uploadedCount} ${f.name}`);
      return (
        axios
          .post(url, formPortal, configPortal)
          //.then(this.recordFetchTiming(startTime, url))
          .then((response) => {
            trace &&
              console.log(
                `Upload step 1 completed for file ${uploadedCount} ${f.name}, status ${response.status}`,
              );
            if (response.status === 202 || response.status === 200) {
              // Accepted, proceed with file upload
              const { requirementDueDate, uploaded } = response.data;

              // Should just be one uploaded file now, since we're uploading Portal->S3->Callback for every accepted file
              return uploaded.reduce(
                (previousPromise2: Promise<any>, { id, uploadUrl }: any) => {
                  return previousPromise2.then(() => {
                    trace &&
                      console.log(
                        `Upload step 2 for file ${uploadedCount} ${f.name}`,
                      );
                    const markReceivedUrl =
                      requirementDueDate &&
                      id &&
                      `/api/requirementDueDates/${requirementDueDate.id}/files/${id}/twoStageReceived`;

                    const configUpload = {
                      headers: {
                        "content-type": "multipart/form-data",
                      },
                      credentials: "same-origin",
                      mode: "cors",
                      onUploadProgress: onUploadProgress,
                    };

                    configUpload.onUploadProgress = (
                      progressEvent: ProgressEvent,
                    ) => {
                      onUploadProgress(
                        progressEvent,
                        f.name,
                        fileCount > 1
                          ? `(${uploadedCount}/${fileCount})`
                          : undefined,
                      );
                    };

                    return axios
                      .put(uploadUrl, f, configUpload)
                      .then((stageTwoResponse) => {
                        trace &&
                          console.log(
                            `Upload step 2 completed for file ${uploadedCount} ${f.name}, status ${stageTwoResponse.status}`,
                          );
                        if (
                          stageTwoResponse.status === 202 ||
                          stageTwoResponse.status === 200
                        ) {
                          if (markReceivedUrl) {
                            // Third call, tell Portal we uploaded
                            trace &&
                              console.log(
                                `Upload step 3 for file ${uploadedCount} ${f.name}`,
                              );
                            return postWithToken(
                              markReceivedUrl,
                              auth,
                              {},
                            ).then((r) => {
                              trace &&
                                console.log(
                                  `Upload step 3 completed for file ${uploadedCount} ${f.name}`,
                                );
                              return r;
                            });
                          } else return response.data; //.json();
                        } else {
                          console.error("stage 2 other");
                          console.error(stageTwoResponse);
                          if (markReceivedUrl) {
                            trace &&
                              console.log(
                                `Upload step 3 for file ${uploadedCount} ${f.name}`,
                              );
                            // Third call, tell Portal the upload failed
                            return postWithToken(markReceivedUrl, auth, {
                              failed: true,
                            }).then((r) => {
                              trace &&
                                console.log(
                                  `Upload step 3 completed for file ${uploadedCount} ${f.name}`,
                                );
                              return r;
                            });
                          } else return response.data; //.json();
                        }
                      })
                      .catch((error2) => {
                        console.error(error2);
                        trace &&
                          console.log(
                            `Upload step 3 for file ${uploadedCount} ${f.name}`,
                          );
                        if (markReceivedUrl) {
                          // Third call, tell Portal the upload failed
                          return postWithToken(markReceivedUrl, auth, {
                            failed: true,
                          }).then((r) => {
                            trace &&
                              console.log(
                                `Upload step 3 completed for file ${uploadedCount} ${f.name}`,
                              );
                            return r;
                          });
                        } else return response.data; //.json();
                      });
                  });
                },
                Promise.resolve(),
              );
            } else {
              console.error("stage 1 other");
              console.error(response);
              let data = response.data;
              console.log(data);
              return data; //.json();
            }
          })
          .catch((error) => {
            console.error(error);
          })
      );
    });
  }, Promise.resolve());
};

export const loadFileUnmappedItems = async (
  file: SubmittedFile,
  auth: AbstractAuthState,
  unmappedItemType: string,
) => {
  let url = `/api/operators/${file.requirementDueDate.requirement.operator.id}/requirements/${file.requirementDueDate.requirement.id}/dueDates/${file.requirementDueDate.id}/files/${file.id}`;

  switch (unmappedItemType) {
    case "account":
      url += "/unmappedAccounts";
      break;
    case "entity":
      url += "/unmappedEntities";
      break;
    case "column":
      url += "/unmappedColumns";
      break;
  }

  return fetchPageableAllWithToken(url, auth);
};

export const saveFileUnmappedItem = async (
  file: SubmittedFile,
  auth: AbstractAuthState,
  unmappedItems: any,
) => {
  const url = `/api/operators/${file.requirementDueDate.requirement.operator.id}/requirements/${file.requirementDueDate.requirement.id}/dueDates/${file.requirementDueDate.id}/files/${file.id}/unmappedItems`;
  return postWithToken(url, auth, unmappedItems);
};

export const getFileWelltowerSummary = async (
  rdd: RequirementDueDate,
  fileId: string,
  auth: AbstractAuthState,
) => {
  const url = `/api/requirementDueDates/${rdd.id}/files/${fileId}/welltowerSummary`;
  return fetchPageableAllWithToken(url, auth);
};

export const getFileOperatorSummary = async (
  rdd: RequirementDueDate,
  fileId: number,
  auth: AbstractAuthState,
) => {
  const url = `/api/requirementDueDates/${rdd.id}/files/${fileId}/operatorSummary`;
  return fetchPageableAllWithToken(url, auth);
};

export const resumeFile = async (
  file: SubmittedFile,
  auth: AbstractAuthState,
) => {
  const url = `/api/requirementDueDates/${file.requirementDueDate.id}/files/${file.id}/resumeProcessing`;
  return postWithToken(url, auth, {});
};

export const failFile = async (
  file: SubmittedFile,
  auth: AbstractAuthState,
) => {
  const url = `/api/requirementDueDates/${file.requirementDueDate.id}/files/${file.id}/failFile`;
  return postWithToken(url, auth, {});
};

export const reuploadFile = async (
  file: SubmittedFile,
  auth: AbstractAuthState,
) => {
  const url = `/api/requirementDueDates/${file.requirementDueDate.id}/files/${file.id}/reupload`;
  return postWithToken(url, auth, {}).then((json) => json.requirementDueDate);
};

export const retractFile = async (
  file: SubmittedFile,
  auth: AbstractAuthState,
) => {
  const url = `/api/requirementDueDates/${file.requirementDueDate.id}/files/${file.id}/retract`;
  return postWithToken(url, auth, {});
};

export const sendToRPA = async (
  file: SubmittedFile,
  auth: AbstractAuthState,
) => {
  const url = `/api/requirementDueDates/${file.requirementDueDate.id}/files/${file.id}/sendToRPA`;
  return postWithToken(url, auth, {});
};

export const undoRPA = async (file: SubmittedFile, auth: AbstractAuthState) => {
  const url = `/api/requirementDueDates/${file.requirementDueDate.id}/files/${file.id}/undoSendToRPA`;
  return postWithToken(url, auth, {});
};

export const saveFileJDEBatch = async (
  accountingLoad: WelltowerAccountingLoad,
  requirementDueDateId: number,
  fileId: number,
  auth: AbstractAuthState,
) => {
  const url = `/api/requirementDueDates/${requirementDueDateId}/files/${fileId}/loads`;
  // TODO - does this need to handle existing WelltowerAccountingLoads? Or should the controller deal with that?
  return postWithToken(url, auth, accountingLoad);
};

export const deleteFileJDEBatch = async (
  requirementDueDateId: number,
  fileId: number,
  loadId: number,
  auth: AbstractAuthState,
) => {
  const url = `/api/requirementDueDates/${requirementDueDateId}/files/${fileId}/loads/${loadId}`;

  return deleteWithToken(url, auth);
};

export const updateFileJDEBatch = async (
  accountingLoad: WelltowerAccountingLoad,
  requirementDueDateId: number,
  fileId: number,
  loadId: number,
  auth: AbstractAuthState,
) => {
  const url = `/api/requirementDueDates/${requirementDueDateId}/files/${fileId}/loads/${loadId}`;

  return putWithToken(url, auth, accountingLoad);
};

export const getFileJDEInfo = async (
  requirementDueDateId: number,
  fileId: string,
  auth: AbstractAuthState,
) => {
  const url = `/api/requirementDueDates/${requirementDueDateId}/files/${fileId}/loads`;
  return fetchJsonWithToken(url, auth);
};
