/** @format */

import {
  fetchPageableAll,
  fetchPageableAllWithToken,
} from "../util/FetchUtils";
import RequirementDueDate from "../types/RequirementDueDate";
import { AbstractAuthState } from "../util/OktaUtils";
import DashboardPeriodDto from "../types/DashboardPeriodDto";

export const requirementsEndpoint = "/api/requirementDueDates/dashboard";

export const getPeriodRequirementDueDates = async (
  auth: AbstractAuthState,
  period: string,
): Promise<Array<RequirementDueDate>> => {
  let url = `${requirementsEndpoint}/${period}`;
  return fetchPageableAllWithToken(url, auth);
};

// This one uses the operator user's operator; unused after we replace OperatorDashboard.
export const getOperatorDashboardPeriods = async (
  auth: AbstractAuthState,
  period: string,
) => {
  let url;
  if (period?.includes("-")) {
    url = `/api/requirementDueDates/operatorDashboard/byYearMonth/${period}`;
  } else if (period) {
    url = `/api/requirementDueDates/operatorDashboard/byYear/${period}`;
  } else {
    // TODO - is anybody calling this without a year/month?
    url = `/api/requirementDueDates/operatorDashboard`;
  }
  return fetchPageableAll<DashboardPeriodDto>(url, auth);
};

// This one is for Welltower users and requests a single operator
export const getOperatorDashboardPeriodsByYearMonth = async (
  operatorId: number,
  period: string,
  auth: AbstractAuthState,
) => {
  let url;
  if (period?.includes("-")) {
    url = `/api/requirementDueDates/operatorDashboard/${operatorId}/byYearMonth/${period}`;
  } else {
    url = `/api/requirementDueDates/operatorDashboard/${operatorId}/byYear/${period}`;
  }
  return fetchPageableAll<DashboardPeriodDto>(url, auth);
};

export const getOperatorDashboardPeriodsByOperatorId = async (
  operatorId: number,
  auth: AbstractAuthState,
) => {
  let url = `/api/requirementDueDates/operatorDashboard/${operatorId}`;
  return fetchPageableAll<DashboardPeriodDto>(url, auth);
};
