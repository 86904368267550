/** @format */
import {
  deleteWithToken,
  fetchJsonWithToken,
  postWithToken,
} from "../util/FetchUtils";
import { AbstractAuthState } from "../util/OktaUtils";

export const getMappingSets = async (url: string, auth: AbstractAuthState) => {
  return fetchJsonWithToken(url, auth);
};

export const getMappings = async (url: string, auth: AbstractAuthState) => {
  return fetchJsonWithToken(url, auth);
};

export const getMappingSetOperatorCaption = async (
  url: string,
  auth: AbstractAuthState,
) => {
  return fetchJsonWithToken(url, auth);
};

export const getMappingSetMappableAccounts = async (
  url: string,
  auth: AbstractAuthState,
) => {
  return fetchJsonWithToken(url, auth);
};

export const getMappingSetSegmentCodes = async (
  url: string,
  auth: AbstractAuthState,
) => {
  return fetchJsonWithToken(url, auth);
};

export const getMappingSetRequirements = async (
  url: string,
  auth: AbstractAuthState,
) => {
  return fetchJsonWithToken(url, auth);
};

export const updateMapping = async (
  url: string,
  auth: AbstractAuthState,
  data: any,
) => {
  return postWithToken(url, auth, data);
};

export const deleteMapping = async (url: string, auth: AbstractAuthState) => {
  return deleteWithToken(url, auth);
};

export const getTotalRequirementCount = async (
  url: string,
  auth: AbstractAuthState,
) => {
  return fetchJsonWithToken(url, auth);
};
