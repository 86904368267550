/** @format */

import Operator from "../types/Operator";
import Requirement from "../types/Requirement";
import DocumentType from "../types/DocumentType";
import { AuthState, UserClaims } from "@okta/okta-auth-js";
import { includesIgnoreCase } from "./CollectionUtils";
import { UPLOAD_METHOD_PORTAL } from "../__fixtures__/testMiscEnums";

// TODO - break up Okta helpers vs app-level security checks?

declare type ExtUserClaims = UserClaims<{
  groups: string[];
  displayName?: string;
}>;

// Abstract some Okta pieces because they've changed in the past and will change again
// okta-auth-js 4.9 just returns AuthState, but 5.0 will return null if auth isn't ready
declare type MockAuthState = AuthState & { isMock: boolean };
export type AbstractAuthState = AuthState | MockAuthState | null;

export const buildMockAuthState = (
  token: string,
  groups: string[],
  isAuthenticated: boolean,
): MockAuthState | null => {
  if (!token) return null;
  return {
    idToken: {
      claims: {
        sub: `WELL${token}`,
        groups,
      },
      idToken: `WELL${token}`,
      issuer: "fakePortal",
      clientId: "fakePortal",
      expiresAt: -1,
      authorizeUrl: "fakePortal",
      scopes: ["fakePortal"],
    },
    accessToken: {
      claims: {
        sub: `WELL${token}`,
        groups,
      },
      accessToken: `WELL${token}`,
      expiresAt: -1,
      authorizeUrl: "fakePortal",
      tokenType: "fakePortal",
      userinfoUrl: "fakePortal",
      scopes: ["fakePortal"],
    },
    isAuthenticated,
    isMock: true,
  };
};

export const buildAdHocTokenAuthState = (token: string) =>
  buildMockAuthState(
    token,
    [`${process.env.REACT_APP_OKTA_GROUP_PREFIX}ADHOC`],
    false,
  );

const normalizeGroupName = (s: string | undefined) =>
  (s ?? "").replace(process.env.REACT_APP_OKTA_GROUP_PREFIX || "", "");

const toUserClaims = (
  authState: AbstractAuthState,
): ExtUserClaims | undefined =>
  (authState?.idToken?.claims as ExtUserClaims) || undefined;
// Pattern from https://github.com/okta/okta-auth-js/pull/1003#issuecomment-1040675512 , but they used .accessToken. We had been using idToken, and don't seem to get claims on our accessToken. Maybe figure out if we're doing something wrong?

export const getFilteredGroups = (userinfo: AbstractAuthState): string[] => {
  const claims = toUserClaims(userinfo);
  if (!claims || !claims.groups || claims.groups.length === 0) return [];
  if (!process.env.REACT_APP_OKTA_GROUP_PREFIX && !userinfo?.isMock) {
    console.error("env var REACT_APP_OKTA_GROUP_PREFIX not defined");
  }
  return claims.groups
    .filter(
      (g: string) =>
        (process.env.REACT_APP_OKTA_GROUP_PREFIX &&
          g.startsWith(process.env.REACT_APP_OKTA_GROUP_PREFIX)) ||
        userinfo?.isMock, // Hack around checking groups in tests
    )
    .map((g: string) => normalizeGroupName(g))
    .filter((s) => s); // remove any undefined/empty strings
};

export const getUserLogin = (userinfo: AbstractAuthState) => {
  const claims = toUserClaims(userinfo);
  return claims?.preferred_username ?? claims?.email ?? "__UNKNOWN_USER__";
};

export const getUserDisplayName = (userinfo: AbstractAuthState) => {
  const claims = toUserClaims(userinfo);
  return claims?.name ?? claims?.displayName ?? "__UNKNOWN_USER__";
};

function groupManagersGroupNameShort() {
  return (
    process.env.REACT_APP_OKTA_GROUP_SHORT_GROUP_MANAGERS || "GroupManagers"
  );
}

function welltowerGroupNameShort() {
  return process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER || "Welltower";
}

function welltowerAccountantGroupNameShort() {
  return (
    process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER_ACCOUNTANT ||
    "WelltowerAccountants"
  );
}

function welltowerNNNManagerGroupNameShort() {
  return (
    process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER_NNN_MANAGER ||
    "WelltowerNNNManagers"
  );
}

function welltowerCensusManagerGroupNameShort() {
  return (
    process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER_CENSUS_MANAGER ||
    "WelltowerCensusManagers"
  );
}

function welltowerRFCapExManagerGroupNameShort() {
  return (
    process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER_RFCAPEX_MANAGER ||
    "WelltowerRFCapExManagers"
  );
}

function welltowerOtherDocsManagerGroupNameShort() {
  return (
    process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER_OTHER_DOCS_MANAGER ||
    "WelltowerOtherDocsManagers"
  );
}

function welltowerDataAdminGroupNameShort() {
  return (
    process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER_DATA_ADMINS ||
    "WelltowerDataAdmins"
  );
}

function welltowerDataLoaderGroupNameShort() {
  return (
    process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER_DATA_LOADERSS ||
    "WelltowerDataLoaders"
  );
}

function welltowerAccountantAdminsGroupNameShort() {
  return (
    process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER_ACCOUNTANT_ADMINS ||
    "WelltowerAccountantAdmins"
  );
}

function welltowerAdminsGroupNameShort() {
  return (
    process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER_ADMINS || "WelltowerAdmins"
  );
}

function welltowerAdHocManagerGroupNameShort() {
  return (
    process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER_ADHOC_MANAGERS ||
    "WelltowerCloudticityUploadManagers"
  );
}

function welltowerUserManagerGroupNameShort() {
  return (
    process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER_USER_MANAGERS ||
    "WelltowerUserManagers"
  );
}

function welltowerRPAGroupNameShort() {
  return process.env.REACT_APP_OKTA_GROUP_SHORT_WELLTOWER_RPA || "WelltowerRPA";
}

export const forTestsAccountantGroupNames = [
  welltowerGroupNameShort(),
  welltowerAccountantGroupNameShort(),
];
export const forTestsAccountantAdminGroupNames = [
  welltowerGroupNameShort(),
  welltowerAccountantGroupNameShort(),
  welltowerAccountantAdminsGroupNameShort(),
];
export const forTestsSuperAdminGroupNames = [
  welltowerGroupNameShort(),
  welltowerAdminsGroupNameShort(),
];

export const isWelltower = (userinfo: AbstractAuthState) => {
  const groups = userinfo ? getFilteredGroups(userinfo) : [];
  return groups.includes(welltowerGroupNameShort());
};

const _isWelltowerNNNManager = (userinfo: AbstractAuthState) => {
  const groups = userinfo ? getFilteredGroups(userinfo) : [];
  return groups.includes(welltowerNNNManagerGroupNameShort());
};

const _isWelltowerCensusManager = (userinfo: AbstractAuthState) => {
  const groups = userinfo ? getFilteredGroups(userinfo) : [];
  return groups.includes(welltowerCensusManagerGroupNameShort());
};

const _isWelltowerRFCapExManager = (userinfo: AbstractAuthState) => {
  const groups = userinfo ? getFilteredGroups(userinfo) : [];
  return groups.includes(welltowerRFCapExManagerGroupNameShort());
};

const _isWelltowerOtherDocsManager = (userinfo: AbstractAuthState) => {
  const groups = userinfo ? getFilteredGroups(userinfo) : [];
  return groups.includes(welltowerOtherDocsManagerGroupNameShort());
};

const _isWelltowerAccountant = (userinfo: AbstractAuthState) => {
  const groups = userinfo ? getFilteredGroups(userinfo) : [];
  return groups.includes(welltowerAccountantGroupNameShort());
};

const _isWelltowerDataAdmin = (userinfo: AbstractAuthState) => {
  const groups = userinfo ? getFilteredGroups(userinfo) : [];
  return groups.includes(welltowerDataAdminGroupNameShort());
};

const _isWelltowerDataLoader = (userinfo: AbstractAuthState) => {
  const groups = userinfo ? getFilteredGroups(userinfo) : [];
  return groups.includes(welltowerDataLoaderGroupNameShort());
};

const _isUserWelltowerRequirementManger = (
  userinfo: AbstractAuthState,
  documentType: DocumentType,
) => {
  return (
    (documentType.accountantManaged && _isWelltowerAccountant(userinfo)) ||
    (documentType.nnnManaged && _isWelltowerNNNManager(userinfo)) ||
    (documentType.censusManaged && _isWelltowerCensusManager(userinfo)) ||
    (documentType.rfcapexManaged && _isWelltowerRFCapExManager(userinfo)) ||
    (documentType.otherManaged && _isWelltowerOtherDocsManager(userinfo)) ||
    (documentType.cloudicityManaged && _isAdHocUploadManager(userinfo)) ||
    _isWelltowerDataAdmin(userinfo)
  );
};

const _isUserWelltowerRequirementMangerAnyDocType = (
  userinfo: AbstractAuthState,
) => {
  return _isUserWelltowerRequirementManger(userinfo, {
    id: "OTHER",
    name: "Fake for any-type-manager",
    mapAccounts: false,
    mapEntities: false,
    process: false,
    rpaEligible: false,
    uploadMethod: UPLOAD_METHOD_PORTAL,
    // check any type-manager pemissions
    accountantManaged: true,
    nnnManaged: true,
    censusManaged: true,
    rfcapexManaged: true,
    otherManaged: true,
    cloudicityManaged: true,
  });
};

const _isUserWelltowerRequirementMangerAnyProcessedDocType = (
  userinfo: AbstractAuthState,
) => {
  return _isUserWelltowerRequirementManger(userinfo, {
    id: "OTHER",
    name: "Fake for any-processed-type-manager",
    mapAccounts: true,
    mapEntities: true,
    process: true,
    rpaEligible: false,
    uploadMethod: UPLOAD_METHOD_PORTAL,
    // Roughly assuming that any of these ___Managed doc types are Portal-processed (and have mappings and/or custom headings)
    accountantManaged: true,
    nnnManaged: true,
    censusManaged: true,
    rfcapexManaged: true,
    otherManaged: true,
    // Not these types
    cloudicityManaged: false,
  });
};

const _isWelltowerAccountantAdmin = (userinfo: AbstractAuthState) => {
  const groups = userinfo ? getFilteredGroups(userinfo) : [];
  return groups.includes(welltowerAccountantAdminsGroupNameShort());
};

// Don't export isOperator; use or create `canUser____` functions
const isOperator = (userinfo: AbstractAuthState, operator: Operator) => {
  const groups = (userinfo && getFilteredGroups(userinfo)) || ([] as string[]);
  return (
    operator.oktaGroupName &&
    includesIgnoreCase(groups, normalizeGroupName(operator.oktaGroupName))
  );
};

const _isOperatorGroupManager = (
  userinfo: AbstractAuthState,
  operator: Operator,
) => {
  const groups = userinfo ? getFilteredGroups(userinfo) : [];
  return (
    groups.includes(groupManagersGroupNameShort()) &&
    !!operator.oktaGroupName &&
    groups.includes(operator.oktaGroupName)
  );
};

const _isWelltowerUserManager = (userinfo: AbstractAuthState) => {
  const groups = userinfo ? getFilteredGroups(userinfo) : [];
  return groups.includes(welltowerUserManagerGroupNameShort());
};

const _isAdHocUploadManager = (userinfo: AbstractAuthState) => {
  const groups = userinfo ? getFilteredGroups(userinfo) : [];
  return groups.includes(welltowerAdHocManagerGroupNameShort());
};

// Don't export isWelltowerSuperAdmin; use or create `canUser____` functions
const isWelltowerSuperAdmin = (userinfo: AbstractAuthState) => {
  const superuserManualEmails = process.env.REACT_APP_SUPERUSER_EMAIL
    ? process.env.REACT_APP_SUPERUSER_EMAIL.split(",")
        .map((s) => s && s.trim().toLowerCase())
        .filter((s) => s)
    : [];
  return (
    (getUserLogin(userinfo) &&
      superuserManualEmails.includes(getUserLogin(userinfo).toLowerCase())) ||
    getFilteredGroups(userinfo).includes(welltowerAdminsGroupNameShort())
  );
};
const _isRPABot = (userinfo: AbstractAuthState) => {
  const rpaBotEmails = process.env.REACT_APP_RPA_BOT_EMAIL
    ? process.env.REACT_APP_RPA_BOT_EMAIL.split(",")
        .map((s) => s && s.trim().toLowerCase())
        .filter((s) => s)
    : [];
  return (
    (getUserLogin(userinfo) &&
      rpaBotEmails.includes(getUserLogin(userinfo).toLowerCase())) ||
    getFilteredGroups(userinfo).includes(welltowerRPAGroupNameShort())
  );
};
export const canSeeRPADashboard = (userinfo: AbstractAuthState) => {
  return isWelltowerSuperAdmin(userinfo) || _isRPABot(userinfo);
};
export const canManageAdHocTokens = (userinfo: AbstractAuthState) => {
  return isWelltowerSuperAdmin(userinfo) || _isAdHocUploadManager(userinfo);
};
export const canManageUsers = (
  userinfo: AbstractAuthState,
  operator: Operator,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isWelltowerUserManager(userinfo) ||
    _isOperatorGroupManager(userinfo, operator)
  );
};
export const canSeeUserDetails = (
  userinfo: AbstractAuthState,
  operator?: Operator,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isWelltowerDataAdmin(userinfo) ||
    (!!operator && _isOperatorGroupManager(userinfo, operator))
  );
};
export const canViewReports = (userinfo: AbstractAuthState) => {
  return isWelltower(userinfo);
};

export const canEditWelltowerBuSettings = (userinfo: AbstractAuthState) => {
  return isWelltowerSuperAdmin(userinfo) || _isWelltowerDataAdmin(userinfo);
};

export const canViewOperator = (
  userinfo: AbstractAuthState,
  operator: Operator,
) => {
  return isWelltower(userinfo) || isOperator(userinfo, operator);
};
export const canDownloadOperatorFiles = (
  userinfo: AbstractAuthState,
  operator: Operator,
) => {
  return canViewOperator(userinfo, operator);
};
export const canDownloadWelltowerFiles = (
  userinfo: AbstractAuthState,
  operator: Operator,
) => {
  return isWelltower(userinfo);
};
export const canUploadFiles = (
  userinfo: AbstractAuthState,
  operator: Operator,
  requirement: Requirement,
) => {
  // TODO - should be just Operator and AccountantAdmin soon
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isUserWelltowerRequirementManger(userinfo, requirement.documentType) ||
    isOperator(userinfo, operator)
  );
};
export const canDeleteFiles = (
  userinfo: AbstractAuthState,
  operator: Operator,
) => {
  return isWelltowerSuperAdmin(userinfo);
};
export const canRetractFiles = (
  userinfo: AbstractAuthState,
  operator: Operator,
  requirement: Requirement,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isUserWelltowerRequirementManger(userinfo, requirement.documentType) ||
    isOperator(userinfo, operator)
  );
};
export const canEditOperators = (userinfo: AbstractAuthState) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isWelltowerAccountantAdmin(userinfo) ||
    _isWelltowerDataAdmin(userinfo)
  );
};
export const canManageOperatorTbSettings = (
  userinfo: AbstractAuthState,
  portfolio: string,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isWelltowerDataAdmin(userinfo) ||
    (portfolio === "SHO" && _isWelltowerAccountant(userinfo)) ||
    (portfolio === "NNN" && _isWelltowerNNNManager(userinfo))
  );
};
export const canManageOperatorCensusSettings = (
  userinfo: AbstractAuthState,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isWelltowerDataAdmin(userinfo) ||
    _isWelltowerCensusManager(userinfo)
  );
};
export const canManageOperatorOtherDocsSettings = (userinfo: any) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isWelltowerDataAdmin(userinfo) ||
    _isWelltowerOtherDocsManager(userinfo)
  );
};

export const canManageWelltowerBusinessUnitCensusSettings = (
  userinfo: AbstractAuthState,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isWelltowerDataAdmin(userinfo) ||
    _isWelltowerCensusManager(userinfo)
  );
};

export const canEditRequirements = (
  userinfo: AbstractAuthState,
  operator: Operator | null,
  requirement: any,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isUserWelltowerRequirementManger(userinfo, requirement.documentType)
  );
};

export const canEditRequirementsAnyDoc = (
  userinfo: AbstractAuthState,
  operator: Operator,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isUserWelltowerRequirementMangerAnyDocType(userinfo)
  );
};

export const canEditDueDates = (
  userinfo: AbstractAuthState,
  operator: Operator,
  requirement: Requirement,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isUserWelltowerRequirementManger(userinfo, requirement.documentType)
  );
};
export const canLockDueDates = (
  userinfo: AbstractAuthState,
  operator: Operator,
  requirement: Requirement,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isUserWelltowerRequirementManger(userinfo, requirement.documentType)
  );
};
export const canRecordJdeInfo = (
  userinfo: AbstractAuthState,
  operator: Operator,
  requirement: Requirement,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isWelltowerDataAdmin(userinfo) ||
    _isWelltowerDataLoader(userinfo) ||
    _isUserWelltowerRequirementManger(userinfo, requirement.documentType)
  );
};
export const canRecordWelltowerAccountingLoad = (
  userinfo: AbstractAuthState,
  operator: Operator,
  requirement: Requirement,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isWelltowerDataAdmin(userinfo) ||
    _isWelltowerDataLoader(userinfo) ||
    _isUserWelltowerRequirementManger(userinfo, requirement.documentType)
  );
};
export const canDeleteJdeInfo = (
  userinfo: AbstractAuthState,
  operator: Operator,
  requirement: Requirement,
) => {
  return isWelltowerSuperAdmin(userinfo);
};
export const canResumeFiles = (
  userinfo: AbstractAuthState,
  operator: Operator,
  requirement: Requirement,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isUserWelltowerRequirementManger(userinfo, requirement.documentType)
  );
};
export const canEditMappings = (
  userinfo: AbstractAuthState,
  operator: Operator,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isUserWelltowerRequirementMangerAnyProcessedDocType(userinfo)
  );
};
export const canEditCustomHeadings = (userinfo: AbstractAuthState) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isUserWelltowerRequirementMangerAnyProcessedDocType(userinfo)
  );
};

export const canApproveRPA = (
  userinfo: AbstractAuthState,
  operator: Operator,
  requirement: Requirement,
) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isUserWelltowerRequirementManger(userinfo, requirement.documentType)
  );
};
export const canManageMasterData = (userinfo: AbstractAuthState) => {
  return (
    isWelltowerSuperAdmin(userinfo) ||
    _isWelltowerDataAdmin(userinfo) ||
    _isWelltowerAccountantAdmin(userinfo)
  );
};
export const isWelltowerDataAdmin = (userinfo: AbstractAuthState) => {
  return isWelltowerSuperAdmin(userinfo) || _isWelltowerDataAdmin(userinfo);
};
