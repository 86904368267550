/** @format */

import React, { useCallback, useState } from "react";
import { Button } from "reactstrap";
import { FileRejection, useDropzone } from "react-dropzone";
import axios from "axios";
import {
  ACCEPTABLE_PROCESSED_FILE_TYPES,
  DROPZONE_MAX_PROCESSED_FILE_SIZE_MB,
} from "../../util/constants";
import "./FileUploadButton.scss";
import { getOktaTokenAuthHeader } from "../../util/FetchUtils";
import { useOktaAuth } from "@okta/okta-react";

interface IFileUploadButtonProps {
  onFileUploadSuccess: Function;
  onFileUploadError?: Function;
  btnLabel: string;
  uploadUrl: string;
  extraFormParams?: any;
}

const FileUploadButton = (props: IFileUploadButtonProps) => {
  const [percent, setPercent] = useState<number>();
  const [uploading, setUploading] = useState(false);
  const { authState } = useOktaAuth();
  const { uploadUrl, extraFormParams, onFileUploadSuccess, onFileUploadError } =
    props;
  const onDrop = useCallback(
    (accepted: File[], fileRejections: FileRejection[]) => {
      let form = new FormData();
      if (Intl.DateTimeFormat().resolvedOptions().timeZone) {
        form.append(
          "timezone",
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        );
      }
      accepted.map((f) => form.append("file", f, f.name));
      if (extraFormParams) {
        Object.keys(extraFormParams).map((key) =>
          form.append(key, extraFormParams[key]),
        );
      }
      setPercent(0);
      setUploading(true);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: getOktaTokenAuthHeader(authState),
        },
        credentials: "same-origin",
        mode: "cors",
        onUploadProgress: (progressEvent: ProgressEvent) => {
          //TODO: mimic upload progress functionality like on RequirementDueDateCard
          var progressPct = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setPercent(Math.min(100, progressPct));
        },
      };

      // Using axios here for onUploadProgress callback. Probably not 100% necessary, but I think this is a useful UI affordance.
      // Maybe fetch will have this someday?
      axios
        .post(uploadUrl, form, config)
        .then((response) => {
          if (response.status === 204) {
            // No Content
            // Old comment from some Valet code? Do we ever send 204 for an upload?
            return {};
          } else {
            return response.data; //.json();
          }
        })
        .then((data: any) => {
          onFileUploadSuccess(data);
          setTimeout(() => {
            setUploading(false);
          }, 2000);
        })
        .catch((error) => {
          console.error(error);
          setPercent(0);
          setUploading(false);
          if (onFileUploadError) {
            onFileUploadError(error);
          }
        });
    },
    [
      extraFormParams,
      authState,
      uploadUrl,
      onFileUploadSuccess,
      onFileUploadError,
    ],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept: ACCEPTABLE_PROCESSED_FILE_TYPES,
    noClick: true,
    noKeyboard: true,
    maxSize: DROPZONE_MAX_PROCESSED_FILE_SIZE_MB * 1024 * 1024,
    multiple: false,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button
        color={isDragActive ? "info" : "primary"}
        onClick={open}
        className="wtop-btn"
      >
        {uploading ? `Uploading... ${percent}%` : props.btnLabel}
      </Button>
    </div>
  );
};

export default FileUploadButton;
