/** @format */
import React, { FC } from "react";
import Requirement from "../../types/Requirement";
import { Col, Row } from "reactstrap";
import _ from "lodash";
import PreferenceToggle from "./PreferenceToggle";
import { decideName } from "../../util/NameUtils";
import { isWelltower } from "../../util/OktaUtils";
import { useOktaAuth } from "@okta/okta-react";
import { statusLabels } from "./NotificationPreferences";

interface IRequirementPreferenceRowProps {
  requirement: Requirement;
  preferences: any;
  statuses: string[];
  firstColSM: number;
  colSM: number;
  onUpdatePref: () => Promise<void>;
}

const RequirementPreferenceRow: FC<IRequirementPreferenceRowProps> = (
  props,
) => {
  const { authState } = useOktaAuth();
  let { requirement, preferences } = props;
  const welltower = isWelltower(authState);
  let overduePreference = _.find(preferences, { overdue: true });
  if (!welltower) overduePreference = { someTruthyObject: true };
  return (
    <Row
      className={"requirement-preference-row"}
      data-testid={`req-${requirement.id}-preferences`}
    >
      <Col sm={props.firstColSM} className={"requirement-name"}>
        {decideName(authState, requirement)}
      </Col>
      {props.statuses.map((status: string) => {
        return (
          <Col sm={props.colSM} key={status}>
            <PreferenceToggle
              key={status}
              {...props}
              requirementId={requirement.id}
              operatorId={requirement.operator.id}
              documentTypeId={requirement.documentType.id}
              statusType={status}
              preference={_.find(preferences, {
                statusType: status,
              })}
              title={`${decideName(authState, requirement)} - ${statusLabels[status]}`}
            />
          </Col>
        );
      })}
      <Col sm={props.colSM}>
        <PreferenceToggle
          {...props}
          requirementId={requirement.id}
          operatorId={requirement.operator.id}
          documentTypeId={requirement.documentType.id}
          statusType={null}
          preference={overduePreference}
          disabled={!welltower}
        />
      </Col>
    </Row>
  );
};

export default RequirementPreferenceRow;
