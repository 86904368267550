/** @format */

import { withOktaAuth } from "@okta/okta-react";

import {
  SEGMENT_MAPPING_FILTERABLE_FIELDS,
  SEGMENT_MAPPING_HEADERS,
} from "../../util/constants";

import BaseMappingsPage from "./BaseMappingsPage";
import SegmentMappingForm from "./SegmentMappingForm";
import React from "react";
import {
  segmentMappingSetMappableAccountsUrl,
  segmentMappingSetSegmentCodesUrl,
} from "../../util/ApiUrlUtils";
import { withOperatorContext } from "../../components/filters/PickerContext";
import withRouterCompat from "../../util/router/WithRouterCompat";

class SegmentMappings extends BaseMappingsPage {
  async componentDidMount() {
    super.componentDidMount();
    this.loadMappingSets(0);
    this.loadBusinessUnits(0);
  }

  getEditForm(mapping: any, callback?: Function) {
    if (!Object.prototype.hasOwnProperty.call(mapping, "active")) {
      // if we got a new mapping, start with active: true
      mapping.active = true;
    }
    return (
      <SegmentMappingForm
        initialValues={mapping}
        welltowerAccounts={this.state.welltowerAccounts}
        welltowerBusinessUnits={this.state.buCodes}
        segmentCodes={this.state.segmentCodes}
        currentStartDate={mapping ? mapping.startMonth : new Date()}
        currentEndDate={mapping && mapping.endMonth}
        active={mapping && !!mapping.active}
        onSubmitMapping={this.onEditFormSubmit(callback)}
        onDeleteMapping={(rawData: any) => {
          if (callback) {
            callback();
          }
          this.onDeleteMapping(rawData);
        }}
      />
    );
  }

  getPageTitle() {
    return "Segment Mappings";
  }

  getFilterableTableProps() {
    return {
      headers: SEGMENT_MAPPING_HEADERS,
      fields: SEGMENT_MAPPING_FILTERABLE_FIELDS,
      noDataMsg: "There are currently no segment mappings.",
      searchInputPlaceholder: "Search Segment mappings",
    };
  }

  formatIndividualMapping(rawMappingData: any): any {
    return Object.assign({}, rawMappingData, {
      startMonth: rawMappingData.startMonth.value
        ? rawMappingData.startMonth.value
        : rawMappingData.startMonth,
      endMonth:
        rawMappingData.endMonth && rawMappingData.endMonth.value
          ? rawMappingData.endMonth.value
          : rawMappingData.endMonth,
      account: rawMappingData.account.value
        ? rawMappingData.account.value
        : rawMappingData.account,
      businessUnitCode: rawMappingData.businessUnitCode.value
        ? rawMappingData.businessUnitCode.value
        : rawMappingData.businessUnitCode,
      subledgerCode: rawMappingData.subledgerCode?.value
        ? rawMappingData.subledgerCode.value
        : rawMappingData.subledgerCode,
    });
  }

  getMappingType() {
    return "segment";
  }

  getMappingSetUrl(operatorId: number) {
    return "/api/segmentMappingSets";
  }

  getMappingsUrl(operatorId: number, mappingSetId: number) {
    return `/api/segmentMappingSets/${mappingSetId}/mappings`;
  }

  getMappingSetMappableAccountsUrl(
    operatorId: number,
    mappingSetId: number,
  ): string {
    return segmentMappingSetMappableAccountsUrl(operatorId, mappingSetId);
  }

  getMappingSetSegmentCodesUrl(
    operatorId: number,
    mappingSetId: number,
  ): string {
    return segmentMappingSetSegmentCodesUrl(operatorId, mappingSetId);
  }

  deleteMappingsUrl(
    operatorId: number,
    mappingSetId: number,
    mappingId: number,
  ) {
    return `/api/segmentMappingSets/${mappingSetId}/mappings/${mappingId}`;
  }

  getRequirementsUrl(operatorId: number, mappingSetId: number) {
    return `/api/segmentMappingSets/${mappingSetId}/requirements`; // TODO -yardi finally rename?
  }

  showOperatorPicker() {
    return false;
  }
}

export default withRouterCompat(
  withOktaAuth(withOperatorContext(SegmentMappings)),
);
