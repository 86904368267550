/** @format */

import React, { FC, useContext, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useOktaAuth } from "@okta/okta-react";
import { notificationPreferencesUrl } from "../../api/NotificationPreferencesAPI";
import _ from "lodash";
import "./NotificationPreferences.scss";
import RequirementPreferenceRow from "./RequirementPreferenceRow";
import { isWelltower } from "../../util/OktaUtils";
import { decideName } from "../../util/NameUtils";
import { useOktaMutation } from "../../hooks/useOktaMutation";
import { LoadingSpinner } from "../../components/LoadingSpinners";
import { ContextOperatorPicker } from "../../components/filters/ContextOperatorPicker";
import useFetchJson from "../../hooks/useFetchJson";
import { OperatorContext } from "../../components/filters/PickerContext";
import Requirement from "../../types/Requirement";

const SendTestEmailButton = () => {
  const mutation = useOktaMutation(
    "/api/notificationPreferences/sendTestEmail",
  );

  const disabledProps = mutation.isLoading ? { disabled: true } : {};
  return (
    <>
      {mutation.isError ? (
        <div>An error occurred {/*mutation.error.message*/}</div>
      ) : null}

      {mutation.isSuccess ? (
        <div>Test email sent!</div>
      ) : (
        <Button
          size={"sm"}
          color={"primary"}
          onClick={() => mutation.mutate()}
          {...disabledProps}
        >
          {mutation.isLoading ? (
            <LoadingSpinner />
          ) : (
            "Send me a test notification"
          )}
        </Button>
      )}
    </>
  );
};

export const statusLabels: any = {
  HOLD_OPERATOR: "Operator Action Required",
  HOLD_WELLTOWER: "Welltower Action Required",
  STOPPED_FAILURE: "Stopped/Failed",
  STOPPED_DONE: "Loaded",
};

const NotificationPreferences: FC = () => {
  const { authState } = useOktaAuth();
  const [isWelltowerAccountant] = useState(isWelltower(authState)); // TODO - should this just be live or a useMemo?

  const { data: myPreferences, forceReload: reloadMyPrefs } = useFetchJson(
    `${notificationPreferencesUrl}/my`,
    {
      keepDataAcrossReload: true,
    },
  );

  let preferences = _.groupBy(myPreferences, "requirement.id");

  const { selectedId: selectedOperatorId } = useContext(OperatorContext);

  const { data: operatorRequirements } = useFetchJson(
    typeof selectedOperatorId === "number"
      ? `/api/operators/${selectedOperatorId}/requirements`
      : null,
  );

  let requirements = _.groupBy(operatorRequirements, (r) =>
    decideName(authState, r.operator),
  );
  let operators = Object.keys(requirements).sort();

  let statuses = isWelltowerAccountant
    ? ["HOLD_OPERATOR", "HOLD_WELLTOWER", "STOPPED_FAILURE", "STOPPED_DONE"]
    : ["HOLD_OPERATOR", "STOPPED_FAILURE"];
  let firstColSM = isWelltowerAccountant ? 2 : 2;
  let colSM = isWelltowerAccountant ? 2 : 3;

  const getActiveReqs = (reqList: Requirement[]) => {
    return _.sortBy(
      reqList.filter((r) => !r.endDate),
      (r) => decideName(authState, r).toLowerCase(),
    );
  };
  const getInactiveReqs = (reqList: Requirement[]) => {
    return _.sortBy(
      reqList.filter((r) => r.endDate),
      (r) => decideName(authState, r).toLowerCase(),
    );
  };
  return (
    <Container
      className="wtop-notification-preferences"
      data-testid="notification-preferences"
    >
      <Row>
        <Col className={"text-center"}>
          <h3>Notification Preferences</h3>
        </Col>
      </Row>
      <Row>
        <Col className={"text-center"}>
          <SendTestEmailButton />
        </Col>
      </Row>
      <Row>
        <Col sm={"4"}>
          <ContextOperatorPicker requireChoice />
        </Col>
      </Row>
      <Row className={"column-label-row"}>
        <Col sm={firstColSM} />
        {statuses.map((status: string) => {
          return (
            <Col key={status} sm={colSM}>
              {statusLabels[status]}
            </Col>
          );
        })}
        <Col sm={colSM}>Overdue</Col>
      </Row>
      {preferences &&
        operators.map((op) => {
          const activeReqs = getActiveReqs(requirements[op]);
          const inactiveReqs = getInactiveReqs(requirements[op]);
          return (
            <React.Fragment key={op}>
              {activeReqs.map((req: any) => (
                <RequirementPreferenceRow
                  key={req.id}
                  requirement={req}
                  preferences={preferences[req.id]}
                  statuses={statuses}
                  firstColSM={firstColSM}
                  colSM={colSM}
                  onUpdatePref={reloadMyPrefs}
                />
              ))}
              {inactiveReqs?.length > 0 && (
                <>
                  <Row>
                    <Col>&nbsp;</Col>
                  </Row>
                  <Row className={"requirement-preference-row"}>
                    <Col sm={12} className={"requirement-name"}>
                      <strong>Inactive requirements:</strong>
                    </Col>
                  </Row>
                </>
              )}
              {inactiveReqs?.map((req: any) => (
                <RequirementPreferenceRow
                  key={req.id}
                  requirement={req}
                  preferences={preferences[req.id]}
                  statuses={statuses}
                  firstColSM={firstColSM}
                  colSM={colSM}
                  onUpdatePref={reloadMyPrefs}
                />
              ))}
            </React.Fragment>
          );
        })}
    </Container>
  );
};

export default NotificationPreferences;
