/** @format */

import React from "react";
import { calculateStartMonthOptions } from "../../util/DateUtils";
import { Formik, FormikProps, FormikValues } from "formik";
import WrappedSelectInput from "../../components/form/WrappedSelectInput";
import { Button } from "reactstrap";
import WrappedTextInput from "../../components/form/WrappedTextInput";

const AccountMappingForm = (props: any) => {
  const {
    initialValues,
    operatorAccountGroupings,
    welltowerAccounts,
    currentStartDate,
    onSubmitMapping,
    onDeleteMapping,
  } = props;
  const startMonthOptions = calculateStartMonthOptions(currentStartDate);
  return (
    <Formik
      initialValues={initialValues || {}}
      onSubmit={(values, actions) => {
        onSubmitMapping(values, actions);
      }}
      validate={(values: any) => {
        let errors: any = {};

        if (!values.identifier) {
          errors.identifier = "Please enter an Operator account.";
        }

        if (!values.description) {
          errors.description = "Please enter a description.";
        }

        if (!values.operatorAccountGrouping) {
          errors.operatorAccountGrouping = "Please enter an account caption.";
        }

        if (!values.welltowerAccount) {
          errors.welltowerAccount = "Please select a Welltower account.";
        }

        if (!values.startMonth) {
          errors.startMonth = "Please enter a mapping period.";
        }

        return errors;
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => {
        return (
          <form
            onSubmit={formikProps.handleSubmit}
            name={"Account Mapping Form"}
          >
            <div className="form-group">
              <WrappedTextInput
                id="identifier"
                required={true}
                label="Account"
                disabled={initialValues && !!initialValues.id}
              />
            </div>
            <div className="form-group">
              <WrappedTextInput id="description" required={true} label="Name" />
            </div>
            <div className="form-group">
              <WrappedTextInput
                id="detailedDescription"
                required={false}
                label="Description"
              />
            </div>
            <div className="form-group">
              <WrappedSelectInput
                id={`operatorAccountGrouping`}
                label={"Operator Account Caption"}
                value={
                  operatorAccountGroupings
                    ? operatorAccountGroupings.find(
                        (it: any) =>
                          it.value ===
                          formikProps.values.operatorAccountGrouping,
                      )
                    : null
                }
                required={true}
                options={operatorAccountGroupings}
                onChange={formikProps.setFieldValue}
                onBlur={formikProps.setFieldTouched}
              />
            </div>
            <div className="form-group">
              <WrappedSelectInput
                id={`welltowerAccount`}
                label={"Welltower Account"}
                value={
                  welltowerAccounts
                    ? welltowerAccounts.find(
                        (it: any) =>
                          it.value === formikProps.values.welltowerAccount,
                      )
                    : null
                }
                required={true}
                options={welltowerAccounts}
                onChange={formikProps.setFieldValue}
                onBlur={formikProps.setFieldTouched}
              />
            </div>
            <div className="form-group">
              <WrappedSelectInput
                id={`startMonth`}
                label={"Start Month"}
                value={
                  startMonthOptions
                    ? startMonthOptions.find(
                        (it: any) => it.value === formikProps.values.startMonth,
                      )
                    : null
                }
                required={true}
                options={startMonthOptions}
                onChange={formikProps.setFieldValue}
                onBlur={formikProps.setFieldTouched}
              />
            </div>
            {initialValues &&
              initialValues.id &&
              !initialValues.firstSeen &&
              !initialValues.lastSeen && (
                <Button
                  color={"danger"}
                  onClick={() => {
                    onDeleteMapping(initialValues);
                  }}
                  className={"float-left wtop-btn"}
                >
                  Delete
                </Button>
              )}
            <Button
              type={"submit"}
              color={"primary"}
              disabled={!formikProps.isValid || formikProps.isSubmitting}
              className={"float-right wtop-btn"}
            >
              Submit
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
export default AccountMappingForm;
