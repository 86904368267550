/** @format */

import React, { useState } from "react";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import {
  hasAwaitingETLStatus,
  hasCanRecordLoadStatus,
  hasDeletedOrRetractedStatus,
  hasInProgressStatus,
  hasJDEStatus,
} from "../../util/StatusUtils";
import JDEInfoButton from "./JDEInfoButton";
import { downloadUrl } from "../../util/OneTimeDownloader";
import ConfirmationDialog from "../operator-users/ConfirmationDialog";
import {
  canDeleteFiles,
  canDownloadOperatorFiles,
  canDownloadWelltowerFiles,
  canRecordJdeInfo,
  canRecordWelltowerAccountingLoad,
  canResumeFiles,
  canRetractFiles,
  canUploadFiles,
} from "../../util/OktaUtils";
import FileStatusChangeLogButton from "./FileStatusChangeLogModal";
import { DeveloperModeOnly, useDeveloperMode } from "../DeveloperMode";
import SubmittedFile from "../../types/SubmittedFile";
import { AuthState } from "@okta/okta-auth-js";
import WelltowerAccountingLoadEditButton from "./WelltowerAccountingLoadEditButton";

const SubmittedFileActionMenu = ({
  file,
  downloadFullLogEntry,
  onSaveJDEInfo,
  resumeFile,
  failFile,
  reuploadFile,
  retractFile,
  deleteFile,
  authState,
  jdeInfoId,
  glDate,
  jdeBatchNumber,
  jdeInfoObject,
  locked, // We're really only passing this through because we aren't reloading all files after changing the RDD lock status
  preDownloadCallback,
  postDownloadCallback,
}: {
  file: SubmittedFile;
  downloadFullLogEntry: any;
  onSaveJDEInfo: any;
  resumeFile: any;
  failFile: any;
  reuploadFile: any;
  retractFile: any;
  deleteFile: any;
  authState: AuthState;
  jdeInfoId: number;
  glDate: Date;
  jdeBatchNumber: any;
  jdeInfoObject: any;
  locked: boolean; // We're really only passing this through because we aren't reloading all files after changing the RDD lock status
  preDownloadCallback?: any;
  postDownloadCallback?: any;
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const developerMode = useDeveloperMode();
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const status = file.status;

  const operator = file.requirementDueDate.requirement.operator;
  const uploadMethod =
    file.requirementDueDate.requirement.documentType.uploadMethod;
  const canDownloadOperator =
    canDownloadOperatorFiles(authState, operator) && uploadMethod.downloadable;
  const canDownloadWelltower = canDownloadWelltowerFiles(authState, operator);
  const canRecordJde = canRecordJdeInfo(
    authState,
    operator,
    file.requirementDueDate.requirement,
  );
  const canReupload =
    canUploadFiles(authState, operator, file.requirementDueDate.requirement) &&
    uploadMethod.reuploadable &&
    file.requirementDueDate.requirement.portalProcessed;
  const canDelete =
    canDeleteFiles(authState, operator) &&
    uploadMethod.deleteable &&
    !hasJDEStatus(file.status) &&
    !hasAwaitingETLStatus(file.status);
  const canRetract =
    canRetractFiles(authState, operator, file.requirementDueDate.requirement) &&
    uploadMethod.deleteable &&
    !hasJDEStatus(file.status) &&
    !hasAwaitingETLStatus(file.status);
  const canResume = canResumeFiles(
    authState,
    operator,
    file.requirementDueDate.requirement,
  );
  const canFail = canDeleteFiles(authState, operator);

  let actions = [];

  if (canDownloadOperator && file.logEntries && file.logEntries.length > 0) {
    actions.push(
      <DropdownItem
        key={"Download full file log"}
        title={"Download full file log"}
        onClick={() => downloadFullLogEntry(file)}
      >
        <small>Download error details</small>
      </DropdownItem>,
    );
  }

  if (
    canDownloadWelltower &&
    file.welltowerDownloadTokenURIs &&
    Object.keys(file.welltowerDownloadTokenURIs).length > 0
  ) {
    actions = actions.concat(
      Object.keys(file.welltowerDownloadTokenURIs).map((fileName) => (
        <DropdownItem
          key={fileName}
          onClick={() =>
            downloadUrl(authState, file.welltowerDownloadTokenURIs[fileName], {
              setLoading: (loading: any) => {
                loading
                  ? preDownloadCallback &&
                    preDownloadCallback(`Generating ${fileName}...`)
                  : postDownloadCallback && postDownloadCallback();
              },
            })
          }
        >
          <small>Download {fileName}</small>
        </DropdownItem>
      )),
    );
  }

  if (
    canRecordWelltowerAccountingLoad(
      authState,
      file.requirementDueDate.requirement.operator,
      file.requirementDueDate.requirement,
    ) &&
    file.welltowerAccountingLoads?.length
  ) {
    actions.push(
      ...file.welltowerAccountingLoads.map((wal) => (
        <WelltowerAccountingLoadEditButton
          key={`wal-${wal.id}`}
          wal={wal}
          file={file}
          buttonType={"dropdownItem"}
          documentType={
            file.requirementDueDate.requirement
              .documentType /* because latestFile often doesn't include rdd to avoid a cycle */
          }
          afterSave={(data: any) => {
            onSaveJDEInfo(data);
          }}
        />
      )),
    );
  } else if (
    canRecordJde &&
    (hasCanRecordLoadStatus(file, file.requirementDueDate) ||
      hasJDEStatus(status))
  ) {
    actions.push(
      <JDEInfoButton
        key={"jde-info-button"}
        file={file}
        authState={authState}
        buttonType={"dropdownItem"}
        jdeBatchNumber={jdeBatchNumber || ""}
        glDate={glDate || ""}
        failed={jdeInfoObject && jdeInfoObject.failed}
        reversed={jdeInfoObject && jdeInfoObject.reversed}
        jdeInfoId={jdeInfoId || null}
        onSaveJDEInfo={onSaveJDEInfo}
      />,
    );
  }

  if (!locked) {
    if (canDelete && !hasDeletedOrRetractedStatus(file.status)) {
      actions.push(
        <ConfirmationDialog
          key={"Delete file?"}
          title={"Delete file?"}
          confirmLabel={"Delete file"}
          buttonColor={"danger"}
          message={
            <>
              <p>Are you sure you want to delete this file?</p>
              <p>
                <i>{file ? file.originalFilename : ""}</i>
              </p>
            </>
          }
          action={() => {
            deleteFile(file);
          }}
        >
          {({ onClick }: { onClick: any }) => (
            <DropdownItem title={"Delete file"} onClick={onClick}>
              <small data-testid={"delete-file-button"}>Delete File</small>
            </DropdownItem>
          )}
        </ConfirmationDialog>,
      );
    }
    if (canRetract && !hasJDEStatus(file.status)) {
      actions.push(
        <ConfirmationDialog
          key={"Retract file?"}
          title={"Retract file?"}
          confirmLabel={"Retract file"}
          buttonColor={"danger"}
          message={
            <>
              <p>Are you sure you want to retract this file?</p>
              <p>
                <i>{file ? file.originalFilename : ""}</i>
              </p>
            </>
          }
          action={() => {
            retractFile(file);
          }}
        >
          {({ onClick }: { onClick: any }) => (
            <DropdownItem title={"Retract file"} onClick={onClick}>
              <small data-testid={"retract-file-button"}>Retract File</small>
            </DropdownItem>
          )}
        </ConfirmationDialog>,
      );
    }
    if (canReupload && reuploadFile) {
      actions.push(
        <ConfirmationDialog
          key={"Reupload file?"}
          title={"Reupload file?"}
          buttonColor={"success"}
          message={`Are you sure you want to reupload this file?`}
          action={() => {
            reuploadFile(file);
          }}
        >
          {({ onClick }: { onClick: any }) => (
            <DropdownItem title={"Reupload file"} onClick={onClick}>
              <small data-testid={"reupload-file-button"}>Reupload File</small>
            </DropdownItem>
          )}
        </ConfirmationDialog>,
      );
    }
    if (
      canResume &&
      file.status.failed &&
      file.status.resumableAt &&
      resumeFile
    ) {
      actions.push(
        <DropdownItem
          key={"Resume file"}
          title={"Resume file"}
          onClick={() => {
            resumeFile(file);
          }}
        >
          <small data-testid={"resume-file-button"}>Resume File</small>
        </DropdownItem>,
      );
    }
    if (
      canFail &&
      !file.status.failed &&
      hasInProgressStatus(file.status) &&
      failFile
    ) {
      actions.push(
        <DeveloperModeOnly key={"Fail file?"}>
          <ConfirmationDialog
            key={"Fail file?"}
            title={"Fail file?"}
            buttonColor={"danger"}
            message={`Are you sure you want to mark this file as FAILED?`}
            action={() => {
              failFile(file);
            }}
          >
            {({ onClick }: { onClick: any }) => (
              <DropdownItem title={"Fail file"} onClick={onClick}>
                <small data-testid={"fail-file-button"}>Fail File</small>
              </DropdownItem>
            )}
          </ConfirmationDialog>
        </DeveloperModeOnly>,
      );
    }
  }
  if (canDownloadWelltower && developerMode) {
    actions.push(
      <FileStatusChangeLogButton file={file} key={"status-change-log"} />,
    );
  }
  if (actions.length === 0) return null;
  return (
    <Dropdown
      data-testid={"submitted-file-action-menu"}
      isOpen={dropdownOpen}
      inNavbar={true}
      toggle={toggle}
      direction={"up"}
      className={"d-inline align-baseline actions-dropdown"}
    >
      <DropdownToggle caret color={"link"} className={"py-0"}>
        <small data-testid={"submitted-file-action-menu-toggle"}>Actions</small>
      </DropdownToggle>
      <DropdownMenu>{actions}</DropdownMenu>
    </Dropdown>
  );
};
export default SubmittedFileActionMenu;
