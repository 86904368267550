/** @format */
import { isoDateToMonth } from "./DateUtils";

// Excel or CSV that we can process. Not handling zips yet.
export const ACCEPTABLE_PROCESSED_FILE_TYPES = {
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
  "text/plain": [".csv"],
};

export const ACCEPTABLE_PROCESSED_FILE_TYPES_READABLE =
  "Excel (xls, xlsx, xlsm) or CSV";

// Non-processed requirements and Cloudticity uploads don't restrict on file type

export const ACCOUNT_MAPPING_HEADERS = [
  "Account",
  "Name",
  "Description",
  "Operator Caption",
  "Welltower Account",
  "Start Month",
  "First Seen",
  "Last Seen",
];

export const ACCOUNT_MAPPING_FILTERABLE_FIELDS = [
  "identifier",
  "description",
  "detailedDescription",
  (m: any) =>
    m.operatorAccountGroupingObj && m.operatorAccountGroupingObj.displayName,
  (m: any) =>
    m.ignore
      ? "IGNORE"
      : `${m.welltowerAccount} ${m.welltowerAccountName || ""}`.trim(),
  (m: any) => isoDateToMonth(m.startMonth),
  (m: any) => isoDateToMonth(m.firstSeen),
  (m: any) => isoDateToMonth(m.lastSeen),
];

export const ACCOUNT_MAPPING_COLUMN_CLASSNAMES = [
  "",
  "text-wrap",
  "",
  "",
  "",
  "",
  "",
];

export const ENTITY_MAPPING_HEADERS = [
  "Property",
  "Name",
  "Description",
  "Welltower BU/Entity",
  "Start Month",
  "First Seen",
  "Last Seen",
];

export const ENTITY_MAPPING_FILTERABLE_FIELDS = [
  "identifier",
  "description",
  "detailedDescription",
  (m: any) =>
    m.ignore
      ? "IGNORE"
      : `${m.welltowerBusinessUnitCode} ${m.welltowerBusinessUnitName}`,
  (m: any) => isoDateToMonth(m.startMonth),
  (m: any) => isoDateToMonth(m.firstSeen),
  (m: any) => isoDateToMonth(m.lastSeen),
];

export const ENTITY_MAPPING_COLUMN_CLASSNAMES = [
  "",
  "text-wrap",
  "",
  "",
  "",
  "",
];

export const COMBO_MAPPING_HEADERS = [
  "Property",
  "Account",
  "WELL BU/Entity",
  "WELL Account",
  "WELL Segment",
  "WELL Segment Value",
  "Start Month",
  "End Month",
  "First Seen",
  "Last Seen",
];

export const COMBO_MAPPING_FILTERABLE_FIELDS = [
  "entityIdentifier",
  "accountIdentifier",
  (m: any) =>
    m.ignore
      ? "IGNORE"
      : `${m.welltowerBusinessUnitCode} ${m.welltowerBusinessUnitName}`,
  (m: any) =>
    m.ignore
      ? "IGNORE"
      : `${m.welltowerAccount} ${m.welltowerAccountName || ""}`.trim(),
  (m: any) => (m.ignore ? "IGNORE" : m.segmentCode || ""),
  (m: any) => (m.ignore ? "IGNORE" : m.segmentValue || ""),
  (m: any) => isoDateToMonth(m.startMonth),
  (m: any) => isoDateToMonth(m.endMonth),
  (m: any) => isoDateToMonth(m.firstSeen),
  (m: any) => isoDateToMonth(m.lastSeen),
];

export const SEGMENT_MAPPING_HEADERS = [
  "Welltower BU",
  "Account",
  "SubAccount", // TODO yardi  remove?
  "Multiplier",
  "Segment Name",
  "Segment Value",
  "Active",
  "Start Month",
  "End Month",
  "Last Seen",
];

export const SEGMENT_MAPPING_FILTERABLE_FIELDS = [
  "businessUnitCode",
  "account",
  "subAccount",
  "multiplier",
  "subledgerCode", // TODO yardi finally rename?
  "subledgerValue", // TODO yardi finally rename?
  (m: any) => (m.active ? "A" : "I"),
  (m: any) => isoDateToMonth(m.startMonth),
  (m: any) => isoDateToMonth(m.endMonth),
  (m: any) => isoDateToMonth(m.lastSeen),
];

export const UPLOAD_STATUS_REFRESH_INTERVAL = 1000;

export const COLLAPSIBLE_ERRORS_THRESHOLD: number = 5;

export const CLOUDTICITY_TYPES = [
  "Previous Residence Data",
  "Services and Care History",
  "Resident Attributes",
  "Referral Information",
];

// NOTE - also need to update Tomcat size limits in backend/src/main/resources/application.properties
// NOTE - also need to update EB nginx size limits in backend/.platform/nginx/conf.d/client_max_body_size.conf
export const DROPZONE_MAX_FILE_SIZE_MB = 100;
export const DROPZONE_MAX_PROCESSED_FILE_SIZE_MB = 50;
