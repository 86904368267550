/** @format */

import { withOktaAuth } from "@okta/okta-react";

import {
  unmappedAccountsByOperatorUrl,
  operatorMappingsUpdateUrl,
  accountMappingSetsUrl,
  accountMappingsUrl,
  accountMappingSetRequirementsUrl,
  deleteAccountMappingsUrl,
  accountMappingsOperatorCaptionUrl,
  mappingSetMappableAccountsUrl,
} from "../../util/ApiUrlUtils";

import {
  ACCOUNT_MAPPING_COLUMN_CLASSNAMES,
  ACCOUNT_MAPPING_FILTERABLE_FIELDS,
  ACCOUNT_MAPPING_HEADERS,
} from "../../util/constants";

import BaseMappingsPage from "./BaseMappingsPage";
import React from "react";
import AccountMappingForm from "./AccountMappingForm";
import { withOperatorContext } from "../../components/filters/PickerContext";
import withRouterCompat from "../../util/router/WithRouterCompat";

class AccountMappings extends BaseMappingsPage {
  getEditForm(mapping: any, callback?: Function) {
    return (
      <AccountMappingForm
        initialValues={mapping}
        welltowerAccounts={this.state.welltowerAccounts}
        operatorAccountGroupings={this.state.operatorAccountGroupings}
        currentStartDate={mapping ? mapping.startMonth : new Date()}
        onSubmitMapping={this.onEditFormSubmit(callback)}
        onDeleteMapping={(rawData: any) => {
          if (callback) {
            callback();
          }
          this.onDeleteMapping(rawData);
        }}
      />
    );
  }

  getFormattedItems(data: any) {
    let formattedItems = data.unmappedItems.map((item: any) => {
      return Object.assign({}, item, {
        operatorAccountGrouping: item.operatorAccountGrouping
          ? item.operatorAccountGrouping.value
          : null,
        welltowerAccount: item.welltowerAccount
          ? item.welltowerAccount.value
          : null,
        destination: item.destination ? item.destination.name : null,
      });
    });
    return { accounts: formattedItems };
  }

  formatIndividualMapping(rawMappingData: any): any {
    return Object.assign({}, rawMappingData, {
      startMonth: rawMappingData.startMonth.value
        ? rawMappingData.startMonth.value
        : rawMappingData.startMonth,
      operatorAccountGrouping: rawMappingData.operatorAccountGrouping.value
        ? rawMappingData.operatorAccountGrouping.value
        : rawMappingData.operatorAccountGrouping,
      welltowerAccount: rawMappingData.welltowerAccount.value
        ? rawMappingData.welltowerAccount.value
        : rawMappingData.welltowerAccount,
    });
  }

  getSaveMappingsUrl(operatorId: number) {
    return operatorMappingsUpdateUrl(operatorId);
  }

  getUnmappedItemsUrl(operatorId: number) {
    return unmappedAccountsByOperatorUrl(operatorId);
  }

  getPageTitle() {
    return "Account Mappings";
  }

  getFilterableTableProps() {
    return {
      headers: ACCOUNT_MAPPING_HEADERS,
      fields: ACCOUNT_MAPPING_FILTERABLE_FIELDS,
      columnClassNames: ACCOUNT_MAPPING_COLUMN_CLASSNAMES,
      noDataMsg: "There are currently no account mappings for this operator.",
      searchInputPlaceholder: "Search Accounts",
    };
  }

  getMappingType() {
    return "account";
  }

  getMappingTypeDisplayName() {
    return "account";
  }
  getMappingTypeDisplayNamePlural() {
    return "accounts";
  }

  getMappingSetUrl(operatorId: number) {
    return accountMappingSetsUrl(operatorId);
  }

  getMappingsUrl(operatorId: number, mappingSetId: number) {
    return accountMappingsUrl(operatorId, mappingSetId);
  }

  getOperatorAccountGroupingUrl(
    operatorId: number,
    mappingSetId: number,
  ): string {
    return accountMappingsOperatorCaptionUrl(operatorId, mappingSetId);
  }

  getMappingSetMappableAccountsUrl(
    operatorId: number,
    mappingSetId: number,
  ): string {
    return mappingSetMappableAccountsUrl(operatorId, mappingSetId);
  }

  deleteMappingsUrl(
    operatorId: number,
    mappingSetId: number,
    mappingId: number,
  ) {
    return deleteAccountMappingsUrl(operatorId, mappingSetId, mappingId);
  }

  getRequirementsUrl(operatorId: number, mappingSetId: number) {
    return accountMappingSetRequirementsUrl(operatorId, mappingSetId);
  }
}

export default withRouterCompat(
  withOktaAuth(withOperatorContext(AccountMappings)),
);
