/** @format */
import React from "react";
import _ from "lodash";

const Exception = ({ e }: { e: any }) =>
  // Sometimes this component renders with an undefined as React is populating state. Fall back to a string message in that case.
  _.isObject(e) ? (
    <>
      <p>{(e as any).message}</p>
      {(e as any).messages && (e as any).messages.length && (
        <ul>
          {(e as any).messages.map((m: any) => (
            <li key={m}>{m}</li>
          ))}
        </ul>
      )}
    </>
  ) : (
    e || "An error has occurred."
  );
export default Exception;
