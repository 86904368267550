/** @format */

import React from "react";
import RddPeriod from "../rdd-card/RddPeriod";
import AccountantRddCard from "../accountant-rdd-card/AccountantRddCard";
import CloudticityRddCard from "../cloudticity-rdd-card/CloudticityRddCard";

export default class AccountantRddPeriod extends RddPeriod {
  constructor(props: any) {
    super(props);
    this.rerenderParentCallback = this.rerenderParentCallback.bind(this);
  }

  rerenderParentCallback() {
    this.setState({ reload: !this.state.reload });
  }

  renderRddCard(rdd: any) {
    const { receiveUpdatedRequirementDueDate } = this.props;

    const { reload } = this.state;
    if (
      rdd.requirement.documentType.uploadMethod &&
      rdd.requirement.documentType.uploadMethod.name === "CLOUDTICITY"
    ) {
      return (
        <CloudticityRddCard
          authState={this.props.authState}
          key={rdd.id}
          requirementDueDate={rdd}
          receiveUpdatedRequirementDueDate={receiveUpdatedRequirementDueDate}
        />
      );
    } else {
      // TODO - remove this in favor of just passing around a link with the right params
      const location = { ...this.props.location };
      if (location) {
        location.state = {
          ...this.props.location?.state,
          rddId: rdd.id,
          rddPeriodEnd: rdd.periodEndDate,
        };
      }
      return (
        <AccountantRddCard
          authState={this.props.authState}
          key={rdd.id}
          requirementDueDate={rdd}
          receiveUpdatedRequirementDueDate={receiveUpdatedRequirementDueDate}
          location={location}
          rerenderParentCallback={this.rerenderParentCallback}
          reload={reload}
        />
      );
    }
  }
}
