/** @format */

import WithModal from "../WithModal";
import React from "react";
import Operator from "../../types/Operator";
import OperatorEditForm from "./OperatorEditForm";
import { Button } from "reactstrap";
import { AbstractAuthState } from "../../util/OktaUtils";

/** @format */

const OperatorEditFormButton = WithModal(
  ({
    operator,
    authState,
    onSaveOperator,
    ...props
  }: {
    operator: Operator;
    authState: AbstractAuthState;
    onSaveOperator: Function;
  } & Partial<any>) => (
    <div>
      <Button color={"primary"} className="wtop-btn" onClick={props.showModal}>
        {operator && operator.id ? "Edit" : "New Operator"}
      </Button>
      {props.modalContent(
        <OperatorEditForm
          operator={operator}
          {...props}
          authState={authState}
          onSaveOperator={onSaveOperator}
        />,
        {
          showCloseIcon: true,
          modalStyles: { width: "600px" },
          closeOnOverlayClick: false,
        },
      )}
    </div>
  ),
);

export default OperatorEditFormButton;
