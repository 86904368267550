/** @format */

import WithModal from "../WithModal";
import React from "react";
import { Button } from "reactstrap";
/*

Either provide simple children (just text, FA icon, etc.),
or add props like <ConfirmationDialog button color="danger" ...>
to have children rendered inside a button.

TODO - maybe change to a renderProps style so the user can build their own button
  or other clickable element, and so an async action can call a cleanup callback
  to close the modal.
TODO - error handling on action() to replace the modal content with an error message?
TODO -  migrate other helpers like OperatorCustomHeadingDeleteButton to use ConfirmationDialog

 */
const ConfirmationDialog = WithModal(
  ({
    action,
    title,
    message,
    buttonColor = "warning",
    confirmLabel = "Confirm",
    cancelLabel = "Cancel",
    showCancel = true,
    ...props
  }: {
    action: Function;
    title: string;
    buttonColor: string;
    confirmLabel: string;
    cancelLabel: string;
    showCancel: boolean;
    message: string | React.ElementType;
  } & Partial<any>) => (
    <>
      {typeof props.children === "function" ? (
        props.children({ onClick: props.showModal })
      ) : (
        <span onClick={props.showModal}>{props.children}</span>
      )}
      {props.modalContent(
        <>
          <h4 className={"text-center"}>{title}</h4>
          <hr />

          {typeof message === "string" ? <p>{message}</p> : <>{message}</>}

          <div>
            <Button
              color={buttonColor}
              className={"float-right wtop-btn"}
              onClick={async () => {
                try {
                  await action();
                  props.closeModal();
                } catch (error) {
                  console.error(error);
                }
              }}
            >
              {confirmLabel}
            </Button>
            {showCancel && (
              <Button
                color={"light"}
                className={"wtop-btn"}
                onClick={props.closeModal}
              >
                {cancelLabel}
              </Button>
            )}
          </div>
        </>,
        {
          showCloseIcon: false,
          modalStyles: { width: "600px" },
          closeOnOverlayClick: false,
        },
      )}
    </>
  ),
);

export default ConfirmationDialog;
